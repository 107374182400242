import { t } from "i18next";
import React from "react";
import { IAgency } from "../../../forms/AgenciesForm/AgenciesForm";
import moment from "moment";

export interface IAdvancePayment {
	id: number;
	isMaximumAdvanceRequested: boolean;
	requestedAmount: number;
	agency: IAgency;
	createdAt: string;
}

interface IAdvancePaymentProps {
	item: IAdvancePayment;
}

const AdvancePayment: React.FC<IAdvancePaymentProps> = ({ item }) => {
	return (
		<div className="flex flex-row items-center w-full justify-between  gap-m max-w-[500px]">
			<div className="flex flex-col">
				<b>
					{item.isMaximumAdvanceRequested
						? t("advancePayment.maxAmount")
						: t("advancePayment.amountValue", {
								amount: item?.requestedAmount,
						  })}
				</b>
				<p className="text-sm">
					{t("advancePayment.dateSubLabel", {
						date: moment(item?.createdAt, "DD-MM-YYYY").format(
							t("dates.format")
						),
					})}
				</p>
			</div>
			<div>
				<span>{item?.agency?.name}</span>
			</div>
		</div>
	);
};

export default AdvancePayment;
