import React from "react";
import "./SectorTile.scss";
import { ISectorInfos, SectorsPictos } from "./SectorTilesPictos";
import SVG from "../../atoms/SVG/SVG";
import cn from "../../../utils/cn";
import DefaultPicto from "../../../assets/illustrations/document.svg";

export interface ISector {
	canBeDelete: boolean;
	commonQualificationNameList: any[];
	id: number;
	mainQualification: boolean;
	name: string;
}

interface ISectorTileProps {
	sector: ISector;
	isSelected?: boolean;
	onClick?: () => void;
	className?: string;
}

const SectorTile: React.FC<ISectorTileProps> = ({
	onClick,
	isSelected,
	className,
	sector: { id, name },
}) => {
	const getPicto = () => {
		const picto = SectorsPictos.find((picto) => picto.id === id);

		return picto
			? picto
			: {
					id,
					label: name.toLocaleLowerCase(),
					picto: "",
					bottomOffset: "0",
			  };
	};

	const tilePicto: ISectorInfos = getPicto();

	return (
		<button
			className={cn([
				"relative duration-300 flex flex-col items-center p-4 overflow-hidden rounded-md min-w-[150px] min-h-[150px] aspect-video SectorTile bg-neutral-100 hover:bg-primary-300",
				className,
				isSelected && "!bg-primary-500 text-white",
			])}
			type="button"
			onClick={onClick}
		>
			<p className="z-10 text-sm font-bold text-center !capitalize">
				{tilePicto.label}
			</p>
			<SVG
				src={tilePicto.picto || DefaultPicto}
				className={cn([
					"z-0 absolute left-0 bottom-0 w-[100%] h-[90%]",
				])}
				style={{
					bottom: tilePicto.picto ? tilePicto.bottomOffset : "-40px",
				}}
			/>
		</button>
	);
};

export default SectorTile;
