import * as Yup from "yup";
import { CODE_REGEX } from "../../utils/regex";

export const RegisterCodeFormInitValues = {
	activationCode: "",
};
export const RegisterCodeFormValidation = (t) =>
	Yup.object().shape({
		activationCode: Yup.string()
			.test("code", t("registerCode.error.shouldBeNumber"), (value) =>
				value ? CODE_REGEX.test(value) : true
			)
			.required(t("registerCode.error.required")),
	});
