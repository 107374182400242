import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import AnnouncementService from "../services/AnnouncementService";
import { IOfferProps } from "../components/templates/OfferLayout/OfferLayout";

interface IUseFavoriteOptions {
	isFavored: boolean;
	id: string;
	items: IOfferProps | any;
}

interface IUseFavoriteResult {
	favorStatus: boolean;
	toggleFavorite: () => Promise<any>;
}

const useFavorite = (options: IUseFavoriteOptions): IUseFavoriteResult => {
	const queryClient = useQueryClient();
	const [favorStatus, setFavorStatus] = useState<boolean>(options.isFavored);

	const toggleFavorite = () => {
		setFavorStatus(!favorStatus);

		const announcement = {
			...options.items,
			isFavored: !options.isFavored,
			announcementId: options.id,
		};

		return AnnouncementService.postAnnouncement(announcement).then(
			(resp) => {
				queryClient.refetchQueries(["announcements"]);
				queryClient.refetchQueries(["applyOffers"]);
				queryClient.refetchQueries(["favoritesOffers"]);
			},
			(error) => {
				console.error("Error updating favorite status:", error);
				setFavorStatus(options.isFavored);
			}
		);
	};

	return { favorStatus, toggleFavorite };
};

export default useFavorite;
