import { INotificationCardProps } from "../components/molecules/NotificationCard/NotificationCard";
import { t } from "i18next";
import { IContract } from "../components/molecules/Contract/Contract";
import moment from "moment/moment";
import { IPayslip } from "../components/molecules/Payslip/Payslip";

const TreatNotifications = (
	contractsQuery: IContract[] = [],
	payslipsQuery: IPayslip[] = []
) => {
	const notifications: INotificationCardProps[] = [];

	let payslips = payslipsQuery.filter(
		(payslip: IPayslip) => payslip.isRead === false
	);

	payslips.forEach((payslip: IPayslip) => {
		if (payslip.isRead === true) {
			return false;
		}
		notifications.push({
			id: payslip.id,
			title: t("notifications.payslipTitle"),
			infos: `${moment(payslip.date * 1000).format(
				t("dates.formatYear")?.toString()
			)}`,
			type: "payslip",
			additionalInfos: payslip.agencyName,
			link: t("payslips.consult"),
			isNew: true,
		});
	});

	contractsQuery.forEach((contract: IContract) => {
		if (contract.isSignedByWorker === true) {
			return false;
		}
		notifications.push({
			id: contract.id,
			title: t("notifications.contractTitle"),
			text: ` ${contract.jobTitle} - ${contract.clientName}`,
			infos: `Du ${moment(contract.startDate).format(
				t("dates.format")?.toString()
			)} au ${moment(contract.finishDate).format(
				t("dates.format")?.toString()
			)}`,
			type: "contract",
			link: t("contracts.signBtn"),
			isNew: true,
		});
	});

	return notifications;
};

export default TreatNotifications;
