import React from "react";

import { Picto, Text } from "@zolteam/react-ras-library";

// Utils
import cn from "../../../utils/cn";

// Styles
import "./MenuTab.scss";
import { IRoute } from "../../../routes";
import { isRouteActive } from "../../organisms/Menu/Menu";
import { useSettings } from "../../../contexts/SettingsContext/SettingsContext";

export interface IMenuTab extends IRoute {
	children?: React.ReactNode;
}

interface IMenuTabProp extends IMenuTab {
	onClick: (item: IRoute, hasParent?: IRoute) => void;
}

const MenuTab: React.FC<IMenuTabProp> = (tabProps: IMenuTabProp) => {
	const {
		icon,
		childrens = [],
		active,
		children = <></>,
		onClick,
	} = tabProps;

	const { getSettings } = useSettings();

	const handleClick = () => {
		onClick(
			childrens?.length ? childrens[0] : tabProps,
			!childrens?.length ? undefined : tabProps
		);
	};

	return (
		<li
			className={cn([
				"MenuTab__container cursor-pointer z-1 relative flex flex-col flex-wrap",
				active && "active",
			])}
		>
			<button
				type="button"
				color="transparent"
				className="flex items-center flex-nowrap"
				onClick={handleClick}
			>
				<div className={"icon flex items-center justify-center"}>
					{typeof icon === "string" ? <Picto icon={icon} /> : icon}
				</div>
				<div className={"pl-l relative w-full text-left"}>
					{children}
				</div>
			</button>
			{childrens?.length ? (
				<ul
					className={
						"childs relative top-[0.5rem] origin-top overflow-hidden max-h-0 scale-y-0"
					}
				>
					{childrens?.map((child) => {
						if (
							child.disabled ||
							(child.devFeatureName &&
								!getSettings(child.devFeatureName, true))
						)
							return null;
						return (
							<Text
								size="paragraph02"
								lineHeight="m"
								tag="li"
								key={child.name}
								className={cn([
									"child hover:font-semibold active:text-neutral-500 pl-8",
									isRouteActive(child) && "active",
								])}
							>
								<button
									type="button"
									color="transparent"
									className="flex items-center leading-4 text-left flex-nowrap"
									onClick={() =>
										onClick(
											child,
											!childrens?.length
												? undefined
												: tabProps
										)
									}
								>
									{child.name}
								</button>
							</Text>
						);
					})}
				</ul>
			) : null}
		</li>
	);
};

export default MenuTab;
