import * as Yup from "yup";

export const AbsencesFormInitValues = {
	id: 0,
	reasonName: "",
	commonTimeOffReasonId: 0,
	formattedStartDate: "",
	startDate: "",
	formattedFinishDate: "",
	finishDate: "",
	startTime: {},
	finishTime: {},
	status: "",
	isAllDay: true,
	files: [],
	dateError: "",
};
export const AbsencesFormValidation = (t) =>
	Yup.object().shape({
		startDate: Yup.string().required(t("absences.error.required")),
		finishDate: Yup.string().required(t("absences.error.required")),
		reasonName: Yup.string().required(t("absences.error.required")),
	});
