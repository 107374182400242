export const phoneNumberFormat = (arg_number: string) => {
	if (typeof arg_number !== "string" || !arg_number?.length) return "";

	let number: string = arg_number.replace(/\s*/gim, "") ?? "";

	if (number && number.startsWith("+")) {
		const firstPart = number.slice(0, 4);
		const firstStart = firstPart.slice(0, 3);
		const firstEnd = firstPart.length > 3 ? firstPart.slice(-1) : "";

		const sliced = number.slice(4, number.length);

		const secondPart = ((sliced ?? "").match(/.{1,2}/g) ?? [number]).join(
			" "
		);

		return firstStart + " " + firstEnd + " " + secondPart;
	}
	number = (number.match(/.{1,2}/g) ?? [number]).join(" ");

	return number;
};
