import React from "react";
import cn from "../../../utils/cn";
import { Picto, RadioButton } from "@zolteam/react-ras-library";
import { ISelectValue } from "../Select/Select";
import { t } from "i18next";

interface IRadioListitem {
	item: ISelectValue;
	onRemove?: () => void;
	onClick?: (item: ISelectValue) => void;
	radio?: boolean;
	isSelected?: boolean;
	name?: string;
	className?: string;
}

const RadioListItem: React.FC<IRadioListitem> = ({
	item,
	onRemove,
	onClick,
	radio,
	isSelected,
	name,
	className,
}) => {
	return (
		<div
			className={cn([
				"flex items-center justify-between gap-2 px-4 py-4 hover:bg-neutral-100 rounded-8 duration-300",
				className,
				radio && "cursor-pointer",
				radio && "hover:bg-primary-50",
				radio &&
					isSelected &&
					"bg-primary-100 text-primary-500 font-bold",
			])}
			onClick={() => onClick && onClick(item)}
		>
			<div className="flex items-center gap-2">
				{radio && (
					<RadioButton
						name={name}
						color="primary"
						onChange={() => onClick && onClick(item)}
						checked={isSelected}
					/>
				)}
				{item.label}
			</div>
			{onRemove && (
				<button
					type="button"
					onClick={(e) => {
						e.stopPropagation();
						onRemove();
					}}
					title={t("global.delete").toString()}
				>
					<Picto icon="trash" className="text-primary-500" />
				</button>
			)}
		</div>
	);
};

export default RadioListItem;
