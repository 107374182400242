import React, { useState } from "react";
import { Checkbox, InfoMessage, Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import JobTypesService from "../../services/JobTypesService";
import Loader from "../../components/atoms/Loader/Loader";

interface IJobTypes {
	jobTypes: IJobTypes[];
	id: number;
	name: string;
	list: any;
}
const ContractsTypes = () => {
	const [isCheckedList, setIsCheckedList] = useState<(number | IJobTypes)[]>(
		[]
	);
	const [isError, setIsError] = useState(false);
	const queryClient = useQueryClient();
	const [userJobTypes, allJobTypes] = useQueries({
		queries: [
			{
				queryKey: ["userJobTypes"],
				queryFn: () => JobTypesService.getUserJobTypes(),
				onSuccess: (data: IJobTypes) => {
					const userJobTypesArray = data.jobTypes.map(
						(userJob: IJobTypes) => userJob.id
					);
					setIsCheckedList(userJobTypesArray);
				},
				onError: () => setIsError(true),
			},
			{
				queryKey: ["contractsJobTypes"],
				queryFn: () => JobTypesService.getJAllJobTypes(),
			},
		],
	});

	if (userJobTypes.isLoading || allJobTypes.isLoading) return <Loader />;

	const toggleCheckbox = (index: number) => {
		const sendCheckedList: (number | IJobTypes)[] = isCheckedList.includes(
			index
		)
			? isCheckedList.filter((item) => item !== index)
			: [...isCheckedList, index];
		setIsCheckedList(sendCheckedList);
		JobTypesService.postJobTypes({
			jobTypeIds: sendCheckedList,
		}).then(() => queryClient.refetchQueries(["userJobTypes"]));
	};

	return (
		<>
			<Title tag="h1">{t("profilePro.contractsTypes.title")}</Title>
			{isError && (
				<InfoMessage color="error" withIcon>
					{t("profilePro.contractsTypes.contractError")}
				</InfoMessage>
			)}
			<div className="max-w-[500px] ml-xxl">
				<Title tag="h2">
					{t("profilePro.contractsTypes.contractType")}
				</Title>

				{allJobTypes.data?.jobTypes
					.filter((job: IJobTypes) => job.list === 1)
					.map((job: IJobTypes) => (
						<Checkbox
							key={job.id}
							id={job.id.toString()}
							value={isCheckedList.includes(job.id)}
							onChange={() => {
								toggleCheckbox(job.id);
							}}
							label={job.name}
							theme="primary"
							className={`w-full p-s m-s rounded [&>label]:text-lg [&>label]:before:h-[16px] [&>label]:before:w-[16px] [&>label]:after:!left-0.5 ${
								isCheckedList.includes(job.id) &&
								"bg-primary-50"
							}`}
						/>
					))}

				<Title tag="h2">
					{t("profilePro.contractsTypes.contractTime")}
				</Title>
				{allJobTypes.data?.jobTypes
					.filter((job: IJobTypes) => job.list === 2)
					.map((job: IJobTypes) => (
						<Checkbox
							key={job.id}
							id={job.id.toString()}
							value={isCheckedList.includes(job.id)}
							onChange={() => {
								toggleCheckbox(job.id);
							}}
							label={job.name}
							theme="primary"
							className={`w-full p-s m-s rounded [&>label]:text-lg [&>label]:before:h-[16px] [&>label]:before:w-[16px] [&>label]:after:!left-0.5 ${
								isCheckedList.includes(job.id) &&
								"bg-primary-50"
							}`}
						/>
					))}
			</div>
		</>
	);
};

export default ContractsTypes;
