import * as Yup from "yup";

export const RetrievePasswordFormInitValues = {
	retrieveId: "",
};

export const RetrievePasswordFormValidation = (t) =>
	Yup.object().shape({
		retrieveId: Yup.string().required(t("auth.error.idRequired")),
	});
