import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { t } from "i18next";
import { Button } from "@zolteam/react-ras-library";
import { useNavigate } from "react-router-dom";

// Forms
import {
	InitializeFormInitValues,
	InitializeFormValidation,
} from "./validationForm";

// Components
import Field from "../../components/molecules/Field/Field";

import LoginIdService from "../../services/LoginIdService";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

interface InitializeFormProps {
	handleSubmitForm: (
		values: typeof InitializeFormInitValues,
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => void;
}
export const InitializeInput: React.FC = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="relative mb-xxl">
				<Field
					type="text"
					name="id"
					label={t("initialize.login.id")}
					className={"mb-s uppercase"}
					readOnly={false}
					maxLength={7}
				>
					<Button
						color="transparentPrimary"
						size="s"
						type="button"
						className="absolute right-[1rem] top-[110%]"
						onClick={() => {
							pushDataLayer({
								dataLayer: {
									event: "connexion__identifiant_oublie",
									pageUrl: window.location.href,
									pageTitle: t("initialize.title").toString(),
								},
							});
							navigate("/login-retrieve");
						}}
					>
						{t("initialize.login.idForgot")}
					</Button>
				</Field>
			</div>
		</>
	);
};

export const InitializeForm: React.FC<InitializeFormProps> = (
	props: InitializeFormProps
) => {
	const InitializeValueSchema = {
		...InitializeFormInitValues,
	};

	let previousId = LoginIdService.getId();
	if (previousId) InitializeValueSchema.id = previousId;

	return (
		<Formik
			initialValues={InitializeValueSchema}
			validationSchema={InitializeFormValidation(t)}
			onSubmit={(values, { resetForm }) => {
				props.handleSubmitForm(values, resetForm);
			}}
		>
			{({ isValid, dirty, values }) => {
				const isButtonDisabled =
					!(isValid && dirty) && values.id?.length !== 7;
				return (
					<div className="pt-xl pb-s">
						<Form>
							<InitializeInput />
							<Button
								type="submit"
								color={isButtonDisabled ? "grey" : "primary"}
								disabled={isButtonDisabled}
								size="m"
								className={`w-full ${
									isButtonDisabled ? "text-neutral-500" : ""
								}`}
								data-testid="login"
							>
								{t("initialize.login.continue")}
							</Button>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};
