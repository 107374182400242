import * as Yup from "yup";
import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/regex";

export const RegisterEmailFormInitValues = {
	email: "",
};

export const RegisterPhoneNumberFormInitValues = {
	phoneNumber: "",
};

export const RegisterEmailFormValidation = (t) =>
	Yup.object().shape({
		email: Yup.string()
			.required(t("registerEmail.error.required"))
			.test("email", t("registerEmail.error.shouldBeMail"), (value) =>
				value ? EMAIL_REGEX.test(value) : true
			),
	});

export const RegisterPhoneFormValidation = (t) =>
	Yup.object().shape({
		phoneNumber: Yup.string()
			.test(
				"phoneNumber",
				t("registerPhone.error.shouldBePhone"),
				(value) => (value ? PHONE_REGEX.test(value) : true)
			)
			.nullable(),
	});
