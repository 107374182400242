class RegisterInitServiceClass {
	getValues() {
		return JSON.parse(sessionStorage.getItem("RegisterForm") || "{}");
	}

	setValue(key: string, value: any) {
		let values = this.getValues();
		values[key] = value;
		this.setValues(values);
	}

	setValues(values: any) {
		let currentValues = this.getValues();

		sessionStorage.setItem(
			"RegisterForm",
			JSON.stringify({
				...currentValues,
				...values,
			})
		);
	}

	remove() {
		sessionStorage.removeItem("RegisterForm");
	}
}

const RegisterInitService = new RegisterInitServiceClass();

export default RegisterInitService;
