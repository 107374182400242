import React from "react";
import { Title, Tag } from "@zolteam/react-ras-library";
import SVG from "../../atoms/SVG/SVG";
import EuroIcon from "../../../assets/icons/euro.svg";
import LocationIcon from "../../../assets/icons/map.svg";
import moment from "moment";
import { t } from "i18next";
import getSalaryFormat from "../../../utils/salaryFormat";
import useFavorite from "../../../hooks/useFavorite";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";
import FavoriteButton from "../FavoriteButton/FavoriteButton";

interface IOfferProps {
	city: string;
	salary: {
		amount: number;
		salary_min: number;
		salary_max: number;
		salary_type: string;
	};
	title: string;
	contractTypes: string;
	publishedAt: string;
	isFavored: boolean;
	id: string;
	toApply: boolean;
}

const Offer: React.FC<IOfferProps> = (props) => {
	const {
		city,
		salary,
		title,
		contractTypes,
		publishedAt,
		isFavored,
		id,
		toApply,
	} = props;
	const { amount, salary_min, salary_max, salary_type } = salary;

	const salaryFormat = getSalaryFormat(
		amount,
		salary_min,
		salary_max,
		salary_type || ""
	);

	const { toggleFavorite } = useFavorite({
		items: props,
		isFavored,
		id,
	});

	return (
		<div className="w-full p-l rounded-xl  shadow-md hover:bg-transparent">
			<div className="flex justify-between">
				<Title tag="span" size="heading03" className="!leading-6 mb-2">
					{title}
				</Title>
				{!toApply && (
					<FavoriteButton
						className="w-5 h-5"
						onClick={(e) => {
							!isFavored &&
								pushDataLayer({
									dataLayer: {
										event: "liste_offres__favori",
									},
								});
							return toggleFavorite();
						}}
						isFavored={isFavored}
					/>
				)}
			</div>
			<div className="flex flex-wrap mb-m gap-l">
				<span className="flex items-center">
					<SVG src={LocationIcon} className="w-4 h-4 mr-s" />
					{city}
				</span>
				{salaryFormat && (
					<span className="flex items-center">
						<SVG src={EuroIcon} className="mr-s" />
						{salaryFormat}
					</span>
				)}
			</div>
			<div className="flex flex-wrap justify-between gap-s">
				<Tag color="primary">{contractTypes}</Tag>
				<span>
					{`${t("offers.searchOffers.publishDate")} ${moment(
						publishedAt
					).format(t("dates.formatWithDay")?.toString())}`}
				</span>
			</div>
		</div>
	);
};

export default Offer;
