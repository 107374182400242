import React from "react";
import { t } from "i18next";
import AnnouncementService from "../../services/AnnouncementService";
import Offer from "../../components/molecules/Offer/Offer";
import favorites from "../../assets/illustrations/favorites.svg";
import ListLayout from "../../components/templates/ListLayout/ListLayout";
import OfferLayout from "../../components/templates/OfferLayout/OfferLayout";
import { useParams } from "react-router-dom";

const FavoritesOffers: React.FC = () => {
	const params = useParams();
	const fetchAnnouncements = () =>
		AnnouncementService.getFavorites().then((resp) => {
			return {
				...resp,
				items: resp.announcements,
				total: resp.totalResult,
			};
		});

	return (
		<ListLayout
			title={t("offers.favoriteOffers.title").toString()}
			query={{
				key: ["favoritesOffers"],
				fn: fetchAnnouncements,
			}}
			activeItemId={params.id ?? null}
			noDataMessage={t("offers.favoriteOffers.noOffers")}
			backgroundImage={favorites}
			baseUrl="/offers/favorites"
			viewer={{
				render: ({ item, closeViewer }) => (
					<OfferLayout
						item={item}
						onBack={closeViewer}
						onBackText={t("offers.favoriteOffers.favoritesList")}
					/>
				),
				position: "absolute",
			}}
			filters={false}
		>
			{(item) => <Offer {...item} />}
		</ListLayout>
	);
};

export default FavoritesOffers;
