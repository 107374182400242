import React from "react";

import Calendar from "../Calendar/Calendar";
import { InfoMessage, Picto, PopoverCursor } from "@zolteam/react-ras-library";
import AbsencesService from "../../../services/AbsencesService";
import { useQueries } from "@tanstack/react-query";
import { t } from "i18next";
import ContractsService from "../../../services/ContractsService";
import { IContract } from "../Contract/Contract";
import Spinner from "../../atoms/Spinner/Spinner";
import moment from "moment";
import TimeOffStatus, {
	TIME_OFF_STATUS,
} from "../../atoms/TimeOffStatus/TimeOffStatus";
import { IAbsence } from "../Absence/Absence";

export interface IDate {
	from: Date;
	to: Date;
}
const HomeCalendar = () => {
	const [absencesQuery, contractsQuery] = useQueries({
		queries: [
			{
				queryKey: ["calendarAbsences"],
				queryFn: () => AbsencesService.getAbsences(),
			},
			{
				queryKey: ["contracts"],
				queryFn: () =>
					ContractsService.getContracts({
						sorting: "startDate",
					}),
			},
		],
	});

	const genTimesOff = (timeOffRequests: IAbsence[], status?: string) => {
		let timesOff: any[] = [];
		if (!timeOffRequests?.length) return timesOff;
		timesOff = timeOffRequests.map((time) => {
			if (status && time.status !== status) return false;
			if (time.status === TIME_OFF_STATUS.rejected) return false;
			return {
				from: new Date(time.startDate),
				to: new Date(time.finishDate),
			};
		});

		const ret: IDate[] = timesOff.filter((time) => time !== false);
		return ret;
	};

	const genContracts = (contracts: IContract[]) => {
		let contractsList: IDate[] = [];
		if (!contracts?.length) return contractsList;
		contractsList = contracts.map((contract) => {
			return {
				from: new Date(
					new Date(contract.startDate).toLocaleDateString("en-US")
				),
				to: new Date(
					new Date(contract.finishDate).toLocaleDateString("en-US")
				),
			};
		});
		return contractsList;
	};

	const hasAbsence = (day: Date) => {
		if (!absencesQuery?.data?.timeOffRequests?.length) return false;
		return absencesQuery?.data?.timeOffRequests?.find((absence) => {
			const startDate = moment(new Date(absence.startDate)).format(
				"YYYY-MM-DD"
			);
			const finishDate = moment(new Date(absence.finishDate)).format(
				"YYYY-MM-DD"
			);
			const formatedDay = moment(day).format("YYYY-MM-DD");

			return formatedDay >= startDate && formatedDay <= finishDate;
		});
	};

	const hasContract = (day: Date) => {
		if (!contractsQuery?.data?.items?.length) return false;
		return contractsQuery?.data?.items?.find((contract) => {
			if (contract.isCancelled) return false;
			const startDate = moment(new Date(contract.startDate)).format(
				"YYYY-MM-DD"
			);
			const finishDate = moment(new Date(contract.finishDate)).format(
				"YYYY-MM-DD"
			);
			const formatedDay = moment(day).format("YYYY-MM-DD");

			return formatedDay >= startDate && formatedDay <= finishDate;
		});
	};

	let isLoading = absencesQuery.isLoading || contractsQuery.isLoading;
	let hasError: boolean = !!absencesQuery.error || !!contractsQuery.error;

	return (
		<div className="relative">
			{hasError && (
				<div className="[&>div]:w-full">
					<InfoMessage color="error" withIcon>
						{contractsQuery?.error && (
							<div>{t("contracts.loadingError")}</div>
						)}
						{absencesQuery?.error && (
							<div>{t("absences.error.loading")}</div>
						)}
					</InfoMessage>
				</div>
			)}
			{isLoading && (
				<div className="absolute w-fulfl h-full -ml-1 w-[105%] flex items-center justify-center bg-white z-1 opacity-80">
					<Spinner text={"Chargement des événements"} />
				</div>
			)}
			<Calendar
				modifiers={{
					selected: [],
					absences: genTimesOff(absencesQuery?.data?.timeOffRequests),
					waitingAbsences: genTimesOff(
						absencesQuery?.data?.timeOffRequests,
						TIME_OFF_STATUS.pending
					),
					contracts: genContracts(contractsQuery?.data?.items),
				}}
				dayPickerProps={{
					renderDay: (day: Date, modifiers: Object) => {
						const absences = hasAbsence(day);
						const contracts = hasContract(day);

						if (absences || contracts) {
							const from = moment(
								absences
									? absences.startDate
									: contracts.startDate
							).format("DD/MM/YYYY");
							const to = moment(
								absences
									? absences.finishDate
									: contracts.finishDate
							).format("DD/MM/YYYY");
							return (
								<PopoverCursor
									content={
										<div
											className={`flex flex-col items-start !bg-white p-4  drop-shadow-lg rounded-8 border border-neutral-100`}
										>
											<div className="flex items-center justify-between w-full gap-m">
												<span className="text-md font-semibold">
													{t(
														absences
															? "absences.absence"
															: "contracts.contract"
													)}
												</span>
												{absences && (
													<TimeOffStatus
														className="text-xs"
														status={absences.status}
													/>
												)}
											</div>
											<span className="text-xs text-neutral-500 ">
												{from !== to
													? t("dates.fromTo", {
															from,
															to,
													  })
													: from}
											</span>
											<hr className="w-full my-2 bg-red-500 h-px" />
											<span className="text-sm">
												{absences
													? absences?.reasonName
													: contracts?.jobTitle}
											</span>
											{contracts && (
												<span className="text-xs text-neutral-600">
													{contracts?.clientName}
												</span>
											)}
										</div>
									}
									placement="top"
								>
									<span
										className={`w-full h-full flex items-center justify-center`}
									>
										{day.getDate()}
									</span>
								</PopoverCursor>
							);
						}

						return <span>{day.getDate()}</span>;
					},
				}}
			/>

			<div className="flex flex-wrap items-center gap-m mt-2">
				{contractsQuery?.data?.items?.length > 0 && (
					<span className="flex items-center gap-s text-sm text-paragraph-03 font-semibold">
						<Picto
							icon="circle"
							className="text-primary-500"
							style={{ width: "10px" }}
						/>
						<span>{t("calendar.contractsDates")}</span>
					</span>
				)}
				{absencesQuery?.data?.timeOffRequests?.length > 0 && (
					<span className="flex items-center gap-s text-sm text-paragraph-03 font-semibold">
						<Picto
							icon="circle"
							className="fill-accent-500 opacity-80"
							style={{ width: "10px" }}
						/>
						<span>{t("calendar.absencesDates")}</span>
					</span>
				)}
			</div>
		</div>
	);
};

export default HomeCalendar;
