import React, { useCallback, useContext, useState } from "react";
import { t } from "i18next";
import SuccessEmail from "../../assets/illustrations/email.svg";
import { InfoMessage, Button, Title } from "@zolteam/react-ras-library";
import MessagerieService from "../../services/MessagerieService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ChatsList from "../../components/molecules/List/List";
import Message, { IMessage } from "../../components/molecules/Messages/Message";
import { AsyncChatMessages } from "../../components/molecules/Messages/ChatMessages";
import { useNavigate, useParams } from "react-router-dom";
import {
	NotificationsContext,
	NotificationsContextType,
} from "../../contexts/NotificationsContext/NotificationsContext";
import SVG from "../../components/atoms/SVG/SVG";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import Loader from "../../components/atoms/Loader/Loader";

interface IDataMessageList {
	messages: IMessage[];
	total: number;
}

export interface IMessageDetail {
	answers: [
		{
			type: string;
			createdAt: string;
			comment: string;
			reaction: string;
		},
		{
			type: string;
			createdAt: string;
			comment: string;
		}
	];
	files: [
		{
			uuid: string;
			name: string;
			extension: string;
			link: string;
		}
	];
	messageSource: {
		title: string;
		description: string;
		createdAt: string;
	};
}

interface IMessagesStatus {
	readed: number;
	unreaded: number;
	total: number;
}

const Messagerie: React.FC = () => {
	const navigate = useNavigate();
	const { messageId: paramMessageId } = useParams();
	const isInitied = React.useRef(false);
	const queryClient = useQueryClient();
	const previousStatus = React.useRef<IMessagesStatus>({
		readed: -1,
		unreaded: -1,
		total: -1,
	});

	const [CurrentChat, setCurrentChat] = useState<IMessage>();

	const { setNumberMessage } =
		useContext<NotificationsContextType>(NotificationsContext); // filtrer isWatched pour avoir le nombre de message non lu

	const messagesStatus = (messages: IMessage[]) => {
		let status = {
			readed: 0,
			unreaded: 0,
			total: 0,
		};

		messages.forEach((message) => {
			const { type, isWatched, isAnswered, status: msgStatus } = message;
			if (
				(type === "news" && isWatched) ||
				(type === "flow" && isAnswered) ||
				msgStatus === "archive"
			) {
				status.readed++;
			} else {
				status.unreaded++;
			}
			status.total++;
		});

		genDataLayer(status);
		setNumberMessage(status.unreaded);
		return status;
	};

	const hasStatusChanged = (status: any) => {
		const previous = previousStatus.current;
		if (!previous) {
			return true;
		}

		if (
			previous.readed !== status.readed ||
			previous.unreaded !== status.unreaded ||
			previous.total !== status.total
		) {
			return true;
		}
		return false;
	};

	const genDataLayer = (status) => {
		if (!hasStatusChanged(status)) {
			return;
		}
		previousStatus.current = status;

		pushDataLayer({
			dataLayer: {
				event: "messagerie__voir_messagerie",
				messages: status.total,
				massages_traites: status.readed,
				massages_non_traites: status.unreaded,
			},
		});
	};

	const handleItemClick = useCallback(
		(messagesChat: IMessage) => {
			if (!messagesChat) return;
			if (CurrentChat?.messageId === messagesChat.messageId) return;
			pushDataLayer({
				dataLayer: {
					event: "messagerie__voir_message",
					message_informatif:
						messagesChat.type === "news" ? "yes" : "no",
					status: messagesChat.isAnswered ? "traité" : "en attente",
					first_open: messagesChat.isWatched ? "no" : "yes",
				},
			});
			window.history.replaceState(
				null,
				document.title,
				"/messagerie/" + messagesChat.messageId
			);
			!messagesChat.isWatched &&
				passMessageToWatched(messagesChat.messageId);
			setCurrentChat(messagesChat);
		},
		[CurrentChat]
	);

	const { data, isLoading, error } = useQuery<IDataMessageList>(
		["messagerie"],
		() => MessagerieService.getMessages(),
		{
			onSuccess: (data) => {
				messagesStatus(data.messages);
				if (
					!CurrentChat &&
					!isInitied.current &&
					(window.innerWidth >= 900 || paramMessageId)
				) {
					let message = data.messages[0];
					if (paramMessageId) {
						let found = data.messages.find((message) => {
							return (
								message.messageId === parseInt(paramMessageId)
							);
						});
						if (found) {
							message = found;
						}
					}
					handleItemClick(message);
				}
				isInitied.current = true;
			},
		}
	);

	if (error)
		return (
			<InfoMessage color="error" withIcon>
				{t("messaging.loadingError")}
			</InfoMessage>
		);

	const passMessageToWatched = (messageId: number) => {
		return MessagerieService.getPassMessageToWatch(messageId.toString());
	};

	const handleSubmitForm = (
		values: { message: string },
		reaction: string
	) => {
		if (!CurrentChat) return;

		pushDataLayer({
			dataLayer: {
				event: "messagerie__message_envoye",
				reponse: values.message?.length > 0 ? "longue" : "courte",
			},
		});

		const answer = {
			type: CurrentChat.type,
			reaction: reaction,
			comment: values.message,
		};
		MessagerieService.postMessageAnswer(
			CurrentChat.messageId,
			CurrentChat.recipientId,
			answer
		).then(() =>
			queryClient.refetchQueries([
				"ChatMessages",
				CurrentChat.messageId.toString(),
			])
		);
	};

	const hasMessage = () => {
		return data && data.total >= 0;
	};

	if (isLoading) return <Loader />;

	if (!hasMessage())
		return (
			<div className="flex flex-col items-center mt-xl">
				<InfoMessage withIcon>{t("messaging.noMessage")}</InfoMessage>
				<SVG src={SuccessEmail} />
			</div>
		);

	return (
		<div className="flex min-h-[400px] max-h-[75svh] h-full">
			<div
				className={`flex flex-col ${CurrentChat && "hidden"} ${
					!CurrentChat && "w-full"
				} 2md:flex`}
			>
				<Title tag="span" size="heading02" color="black">
					{t("messaging.title")}
				</Title>
				<ChatsList
					className="pr-2"
					backgroundImage={SuccessEmail}
					noDataMessage={t("messaging.noMessage")}
					items={data?.messages ?? []}
					isLoading={isLoading}
					children={Message}
					onItemClick={handleItemClick}
					itemIdKey="messageId"
					activeItemId={CurrentChat?.messageId ?? null}
				/>
				<Button
					type="button"
					color="primary"
					className="ml-auto mt-m 2md:mr-auto 2md:ml-0"
					onClick={() =>
						navigate("/contact-agencies", {
							state: { from: "messaging" },
						})
					}
				>
					{t("messaging.contactAgency")}
				</Button>
			</div>
			{CurrentChat?.messageId && (
				<AsyncChatMessages
					chatId={CurrentChat?.messageId}
					isLoading={isLoading}
					statusTag={CurrentChat?.status}
					handleSubmitForm={handleSubmitForm}
					messageType={CurrentChat?.type ?? ""}
					handleClose={() => {
						window.history.replaceState(
							null,
							document.title,
							"/messagerie"
						);
						setCurrentChat(undefined);
					}}
					queryFn={() =>
						MessagerieService.getMessageDetail(
							CurrentChat.messageId
						)
					}
					queryKey={[
						"ChatMessages",
						CurrentChat.messageId.toString(),
					]}
				/>
			)}
		</div>
	);
};

export default Messagerie;
