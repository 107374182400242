export const offersLocationName = (pathname: string) => {
	if (pathname === "/offers") {
		return "Listes des offres d'emploi";
	}
	if (pathname === "/offers/favorites") {
		return "Liste offres favorites";
	}
	if (pathname === "/offers/apply") {
		return "Liste des candidatures";
	}
};
