import React from "react";
import { t } from "i18next";
import ValidationCode, {
	IValidationCodeProps,
} from "../ValidationCode/ValidationCode";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

interface IDocumentSign {
	sign: IValidationCodeProps;
	handleDownload: () => void;
	handleSign: (code: string) => Promise<any>;
	onCancel: () => void;
}
const DocumentSign: React.FC<IDocumentSign> = (props: IDocumentSign) => {
	const { sign, handleDownload, handleSign, onCancel } = props;
	const [ShowMessage, setShowMessage] = React.useState(true);

	React.useEffect(() => {
		// used to prevent the Zol modal from removing the page scroll if the DocumentSign is not unmounted properly
		return () => {
			setShowMessage(false);
		};
	}, []);

	return (
		<>
			<ConfirmModal
				title={"Avant de signer"}
				isOpen={ShowMessage}
				onCancel={() => {
					setShowMessage(false);
					onCancel();
				}}
				onClose={() => setShowMessage(false)}
				onConfirm={() => setShowMessage(false)}
				confirmText={t("PDFViewer.signModal.confirm")}
			>
				<p className="mb-5">{t("PDFViewer.signModal.title")}</p>
				<div className="[&>div>p>span]:bg-red-500 [&>div>p>span]:p-2 [&>div>p>span]:leading-[0]">
					<ol>
						<li>
							<b>{t("PDFViewer.signModal.step1")}</b>
							<p>{t("PDFViewer.signModal.step1Text")}</p>
						</li>

						<li className="mt-4">
							<b>{t("PDFViewer.signModal.step2")}</b>
							<p>{t("PDFViewer.signModal.step2Text")}</p>
						</li>
					</ol>
				</div>
			</ConfirmModal>
			<ValidationCode
				codeLength={3}
				{...sign}
				successModal={{
					text: t("PDFViewer.signSuccess"),
					btnText: t("PDFViewer.download"),
					btnOnClick: handleDownload,
					...sign.successModal,
				}}
				onSubmit={handleSign}
			/>
		</>
	);
};

export default DocumentSign;
