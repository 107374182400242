import api from "./api";
import TokenService from "./TokenService";

interface ILoginOptions {
	isRegister?: boolean;
	isOnboarding?: boolean;
	isInitied?: boolean;
}

class AuthServiceClass {
	login(username: string, password: string, options: ILoginOptions = {}) {
		return api
			.post("/api/login_check", {
				username,
				password,
			})
			.then((response) => {
				if (response.data.token) {
					TokenService.setUserTokens(response.data, {
						isRegister: options.isRegister,
						isOnboarding: options.isOnboarding,
						initied:
							options.isInitied ??
							(!options.isRegister && !options.isOnboarding),
					});
				}
				return response.data;
			});
	}

	logout() {
		TokenService.removeUser();
	}

	register(username: string, password: string) {
		return api.post("/auth/signup", {
			username,
			password,
		});
	}
	getCurrentUser() {
		return TokenService.getUserTokens();
	}

	initialize(uniqueId: string) {
		return api.post("/api/security/forgot-password", {
			uniqueId,
		});
	}
}

const AuthService = new AuthServiceClass();
export default AuthService;
