import React from "react";
import NotificationCard, {
	INotificationCardProps,
} from "../NotificationCard/NotificationCard";

interface INotificationsBox {
	title?: string;
	notifications?: INotificationCardProps[];
	icon?: React.ReactNode | boolean;
	origin?: "Notifications" | "Il y a du nouveau"; // used for GTM
}

const NotificationsBox: React.FC<INotificationsBox> = ({
	notifications = [],
	icon,
	origin,
}) => {
	if (!notifications?.length) return null;
	return (
		<div className="h-full overflow-auto relative w-[calc(100%+0.5rem)] left-[-0.5rem]">
			<div className="flex flex-col gap-s overflow-y-auto h-full max-h-[80%]">
				{notifications?.map((notification, index) => (
					<NotificationCard
						key={index}
						icon={icon}
						origin={origin}
						{...notification}
					/>
				))}
			</div>
		</div>
	);
};

export default NotificationsBox;
