import React, { useEffect, useReducer, useContext } from "react";

// Reducers
import appReducer from "./appReducer";

const AppStateContext = React.createContext({});
const AppDispatchContext = React.createContext({});

const initStore = {};

interface IAppProvider {
	children: any;
}

const AppProvider = ({ children }: IAppProvider) => {
	const [state, dispatch] = useReducer(appReducer, initStore);

	useEffect(() => {
		//   if you want a persistant context in localstorage, add it here
	}, [state]);

	return (
		<AppStateContext.Provider value={state}>
			<AppDispatchContext.Provider value={dispatch}>
				{children}
			</AppDispatchContext.Provider>
		</AppStateContext.Provider>
	);
};

const useAppContext = () => [
	useContext(AppStateContext),
	useContext(AppDispatchContext),
];

export { AppProvider, AppStateContext, AppDispatchContext, useAppContext };
