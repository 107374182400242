/* eslint-disable max-len */
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Text,
	Title,
	BackLink,
	Button,
	InfoMessage,
} from "@zolteam/react-ras-library";

// Templates
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";

// Forms
import { RetrieveLoginForm } from "../../forms/RetrieveLoginForm/RetrieveLoginForm";

// SVG
import SuccessPhone from "../../assets/illustrations/successPhone.svg";
import SuccessEmail from "../../assets/illustrations/email.svg";

import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/regex";
import RetrieveIdService from "../../services/RetrieveIdService";
import SVG from "../../components/atoms/SVG/SVG";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const RetrieveLogin = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [success, setSuccess] = useState(true);
	const [retrieveLoginValue, setRetrieveLoginValue] = useState("");
	const [loading, setLoading] = useState(false);

	const handleBackNavigation = () => {
		navigate("/login");
	};
	const handleSubmitForm = (value: {
		retrieveLogin?: string;
		gtag?: string;
	}) => {
		pushDataLayer({
			dataLayer: {
				event: value.gtag ?? "connexion__envoi_identifiant",
			},
		});

		let type = "";
		setSuccess(false);
		setRetrieveLoginValue(value.retrieveLogin ?? retrieveLoginValue);
		PHONE_REGEX.test(value.retrieveLogin ?? retrieveLoginValue)
			? (type = "phoneNumber")
			: (type = "email");

		RetrieveIdService.postRetrieveId({
			value: value.retrieveLogin ?? retrieveLoginValue,
			type,
		}).catch((err) => {
			console.error(err.message);
			setLoading(false);
		});
	};

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" lineHeight="m" size="heading01" className="pt-m">
				{t("retrieveLogin.title")}
			</Title>
			{success && (
				<>
					<div>
						<InfoMessage color="primary" withIcon>
							{t("retrieveLogin.text")}
						</InfoMessage>
					</div>
					<RetrieveLoginForm
						emailOrPhone={state?.email ?? state?.phoneNumber ?? ""}
						handleSubmitForm={handleSubmitForm}
						load={loading}
					/>
				</>
			)}
			{!success && EMAIL_REGEX.test(retrieveLoginValue) ? (
				<>
					<InfoMessage color="success" withIcon>
						<Trans
							i18nKey="retrieveLogin.emailSend"
							values={{
								email: retrieveLoginValue,
							}}
							components={[<strong>strong</strong>]}
						/>
					</InfoMessage>
					<SVG src={SuccessEmail} className="w-full" />
				</>
			) : !success && PHONE_REGEX.test(retrieveLoginValue) ? (
				<>
					<InfoMessage color="success" withIcon>
						<Trans
							i18nKey="retrieveLogin.smsSend"
							values={{
								phone: retrieveLoginValue,
							}}
							components={[<strong>strong</strong>]}
						/>
					</InfoMessage>
					<SVG src={SuccessPhone} className="w-full" />
				</>
			) : (
				""
			)}
			{!success && (
				<>
					<div className="flex flex-wrap gap-4 mt-xl mb-xs">
						<Button
							size="m"
							className="min-w-[200px] border-2 w-full !py-s md:w-40 border-solid border-primary-500 hover:!text-white hover:!bg-primary-500"
							type="button"
							color="transparentPrimary"
							onClick={(e) => {
								handleSubmitForm({
									gtag: "connexion__renvoi_identifiant",
								});
							}}
						>
							{PHONE_REGEX.test(retrieveLoginValue)
								? t("retrieveLogin.resendSms")
								: t("retrieveLogin.resend")}
						</Button>
						<Button
							size="m"
							className="min-w-[200px] w-full  md:w-auto"
							type="button"
							color="primary"
							onClick={() => {
								navigate("/login");
							}}
						>
							{t("auth.login.login")}
						</Button>
					</div>
					<div className="mt-xl flex flex-wrap gap-x-1 items-center">
						<Text
							tag="span"
							size="paragraph01"
							lineHeight="s"
							color="black"
						>
							{t("retrieveLogin.contactAgency")}
						</Text>
						<Button
							type="button"
							color="transparentPrimary"
							onClick={() => {
								navigate("/contact-agencies");
							}}
						>
							<Trans
								i18nKey={"retrieveLogin.contactAgencyLink"}
							/>
						</Button>
					</div>
				</>
			)}
		</AuthLayout>
	);
};

export default RetrieveLogin;
