import React, { PropsWithChildren } from "react";
import { Picto } from "@zolteam/react-ras-library";
import { DefaultTFuncReturn } from "i18next";

interface IHeaderLinkProps extends PropsWithChildren {
	icon?: string;
	text?: string | React.ReactNode | DefaultTFuncReturn;
	onClick?: () => void;
}

const HeaderLink: React.FC<IHeaderLinkProps> = ({
	icon,
	text,
	children,
	onClick,
}) => {
	return (
		<button
			onClick={onClick}
			className="w-full flex items-center gap-4 p-2 text-neutral-600 hover:bg-neutral-100 rounded rounded-s duration-100"
		>
			{!!icon?.length && <Picto icon={icon} />}
			{children ?? text ?? "headerlink"}
		</button>
	);
};

export default HeaderLink;
