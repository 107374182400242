import * as Yup from "yup";
import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/regex";

export const RetrieveLoginFormInitValues = {
	retrieveLogin: "",
};

export const RetrieveLoginFormValidation = (t) =>
	Yup.object().shape({
		retrieveLogin: Yup.string()
			.required(t("retrieveLogin.error.required"))
			.test(
				"emailOrPhone",
				t("retrieveLogin.error.shouldBePhoneOrMail"),
				(value) => {
					const isValidEmail = EMAIL_REGEX.test(value);
					const isValidPhone = PHONE_REGEX.test(value);
					if (!isValidEmail && !isValidPhone) {
						return false;
					}
					return true;
				}
			),
	});
