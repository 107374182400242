import { t } from "i18next";
import Select from "../../components/molecules/Select/Select";
import { InfoMessage, SelectAsync } from "@zolteam/react-ras-library";
import React from "react";
import { IFilters } from "../../contexts/FiltersContext";
import SectorsServices from "../../services/SectorsServices";
import { useQueries } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import { ISector } from "../../views/Offers/Offers";
import { DEFAULT_ALL_JOB_TYPES } from "../../services/AnnouncementService";
import LocationService from "../../services/LocationService";

interface IOffersFiltersFormProps {
	formikProps: any;
	values: IFilters;
	isSubmitting: boolean;
	activeFilterCount: number;
	resetFilters: () => IFilters;
}

export interface ISelectOption {
	label: string;
	value: string;
}

const OffersFiltersForm = (props: IOffersFiltersFormProps) => {
	const [userSectors, allSectors, allJobTypes] = useQueries({
		queries: [
			{
				queryKey: ["userSectors"],
				retry: false,
			},

			{
				queryKey: ["allSectors"],
				queryFn: () => SectorsServices.getSectors(),
			},
			{
				queryKey: ["offersJobTypes"],
				queryFn: () =>
					Promise.resolve({ jobTypes: DEFAULT_ALL_JOB_TYPES }),
			},
		],
	});

	if (userSectors.isLoading || allSectors.isLoading || allJobTypes.isLoading)
		return <Loader />;

	const allSectorsArray: ISelectOption[] = userSectors.data?.sectors.map(
		(item) => ({ label: item.name, value: item.id } || [])
	);

	if (allSectors.data) {
		allSectors.data.sectors.forEach((sector: ISector) => {
			if (
				!userSectors.data ||
				!userSectors.data.sectors.find(
					(s: ISector) => s.id === sector.id
				)
			) {
				allSectorsArray.push({
					label: sector.name,
					value: sector.id,
				});
			}
		});
	}

	const mapToSelectOptions = (data: ISector[]) => {
		let locationArrayOptions = data.map((item: ISector) => ({
			label: `${item.name}  (${item.postalCode})`,
			value: item.id,
		}));

		return locationArrayOptions;
	};
	const promiseLocationOptions = (inputValue: string) =>
		inputValue.length >= 3
			? LocationService.getSearchLocations(inputValue).then((res) =>
					mapToSelectOptions(res)
			  )
			: Promise.resolve([]);

	const sectors = () => allSectorsArray;

	const jobTypes = () =>
		allJobTypes.data?.jobTypes.map((jobType) => ({
			label: jobType.name,
			value: jobType.id,
		}));

	return (
		<>
			<p className="mb-4 font-medium">
				{t("offers.searchOffers.filters.locations")}
			</p>
			<InfoMessage color="primary" withIcon>
				{t("offers.searchOffers.filters.infos")}
			</InfoMessage>
			<div className="flex flex-col flex-wrap items-start mt-m [&>div]:w-full">
				<SelectAsync
					defaultOptions
					loadOptions={promiseLocationOptions}
					label={t(
						"offers.searchOffers.filters.locationsPlaceholder"
					)}
					value={props.values.location}
					searchIcon
					isSearchable
					isClearable
					onClear={() =>
						props.formikProps.setFieldValue("location", {})
					}
					onChange={(value) =>
						props.formikProps.setFieldValue("location", value)
					}
					className="[&>p:empty]:hidden"
					name="location"
					noOptionsMessage={({ inputValue }) => {
						if (!inputValue?.length)
							return t("onboarding.workplaces.selectPlaceholder");
						if (inputValue.length < 3)
							return t(
								"onboarding.workplaces.selectPlaceholderMinChar"
							);
						return t(
							"onboarding.workplaces.selectPlaceholderNoResult",
							{
								search: inputValue,
							}
						);
					}}
				/>
			</div>
			<hr className="my-5" />
			<p className="mb-4 font-medium">
				{t("offers.searchOffers.filters.sector")}
			</p>
			<Select
				options={sectors()}
				label={t("offers.searchOffers.filters.sector")}
				isMulti={true}
				closeMenuOnSelect={false}
				onChange={(val: string) => {
					props.formikProps.setFieldValue("sectors", val);
				}}
				value={props.values.sectors}
			/>
			<hr className="my-5" />
			<p className="mb-4 font-medium">
				{t("offers.searchOffers.filters.contractType")}
			</p>
			<Select
				options={jobTypes()}
				label={t("offers.searchOffers.filters.contractType")}
				isMulti={true}
				closeMenuOnSelect={false}
				onChange={(val: string) => {
					props.formikProps.setFieldValue("jobTypes", val);
				}}
				value={props.values.jobTypes}
			/>
		</>
	);
};

export default OffersFiltersForm;
