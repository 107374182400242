import React from "react";

import cn from "../../../utils/cn";
export interface IListItem {
	id: string;
	onClick?: () => void;
	disabled?: boolean;
	item?: any;
	children: (item: any) => React.ReactNode;
	isActive?: boolean;
}

const ListItem: React.FC<IListItem> = ({
	disabled,
	onClick,
	item,
	isActive,
	children,
}) => {
	return (
		<div
			className={
				!disabled ? "cursor-pointer" : "!cursor-not-allowed opacity-60"
			}
			tabIndex={0}
			onClick={!disabled ? onClick : () => {}}
		>
			<div
				className={cn([
					"flex w-full p-m rounded-xl justify-between items-center flex-wrap gap-m",
					isActive && "bg-neutral-150",
					!disabled && "hover:bg-neutral-100",
				])}
			>
				{children && children(item)}
			</div>
		</div>
	);
};

export default ListItem;
