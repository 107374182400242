class CookiesServiceClass {
	setCookies(preferences: string) {
		localStorage.setItem("cookies", preferences);
	}
	getCookies() {
		return localStorage.getItem("cookies");
	}
}

const CookiesService = new CookiesServiceClass();
export default CookiesService;
