import React from "react";
import { Select as ZolSelect } from "@zolteam/react-ras-library";
import "./Select.scss";
import { DefaultTFuncReturn } from "i18next";

export interface ISelectValue {
	value?: string;
	label?: string | DefaultTFuncReturn;
}

export interface ISelectProps {
	options?: ISelectValue[];
	value?: ISelectValue[] | string[] | string | any;
	isMulti?: boolean;
	onChange?: (data: ISelectValue[]) => void;
	name?: string;
	label?: string | DefaultTFuncReturn;
	className?: string;
	containerClassName?: string;
	disabled?: boolean;
}

export const convToSelectValue = (item) => ({
	...item,
	value: item.id,
	label: item.name,
});

const Select: React.FC<ISelectProps> = (props: any) => {
	// cette fonction permet de convertir les valeurs passées en props.value en objet
	// pour que le select puisse les afficher
	const initValues = (vals = []) => {
		if (!props.isMulti) return vals; // ne pas convertir en objet si le select n'est pas multi
		if (!props.options) return []; // ne pas convertir en objet si les options ne sont pas définies

		let options = props.options;
		let values: object[] = [];

		vals.forEach((val: ISelectValue) => {
			if (typeof val !== "object") {
				let option = options.find(
					(option: ISelectValue) =>
						option.value === val || option.label === val
				);
				if (option) values.push(option);
			} else if (val.value && val.label) {
				values.push(val);
			}
		});
		return values;
	};

	const [Selected, setSelected] = React.useState(
		initValues(props.value ?? [])
	);

	const handleChange = (selected: ISelectValue[]) => {
		setSelected(selected);
	};

	return (
		<ZolSelect
			onChange={handleChange}
			name="Période"
			label={"Selectionner une option"}
			menuPlacement="auto"
			{...props}
			value={initValues(props.value) ?? Selected}
		/>
	);
};

export default Select;
