import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { t } from "i18next";
import { Button } from "@zolteam/react-ras-library";

// Forms
import {
	RetrieveLoginFormInitValues,
	RetrieveLoginFormValidation,
} from "./validationForm";

// Components
import Field from "../../components/molecules/Field/Field";

// Utils
import cn from "../../utils/cn";

interface RetrieveLoginFormProps {
	load: boolean;
	handleSubmitForm: (
		values: { retrieveLogin: string },
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => void;
	emailOrPhone?: string;
}
export const RetrieveLoginInput = () => {
	return (
		<>
			<div>
				<Field
					type="text"
					name="retrieveLogin"
					label={t("retrieveLogin.inputLabel")}
					className="pt-l"
				/>
			</div>
		</>
	);
};

export const RetrieveLoginForm: React.FC<RetrieveLoginFormProps> = (
	props: RetrieveLoginFormProps
) => {
	const RetrieveLoginValueSchema = {
		...RetrieveLoginFormInitValues,
		retrieveLogin: props.emailOrPhone,
	};

	return (
		<Formik
			initialValues={RetrieveLoginValueSchema}
			validationSchema={RetrieveLoginFormValidation(t)}
			onSubmit={(values, { resetForm }) => {
				props.handleSubmitForm(values, resetForm);
			}}
		>
			{({ isValid, dirty }) => {
				const canSubmit = isValid && (dirty || props.emailOrPhone);
				return (
					<div className="pt-m pb-s">
						<Form id="form-password-recovery">
							<RetrieveLoginInput />
							<Button
								type="submit"
								isLoading={props.load}
								color={!canSubmit ? "grey" : "primary"}
								disabled={!canSubmit}
								size="m"
								className={cn([
									`w-full mt-xl`,
									!canSubmit && "text-neutral-500",
								])}
								data-testid="button-password-recovery"
							>
								{t("retrieveLogin.buttonLabel")}
							</Button>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};
