import React from "react";
import cn from "../../../utils/cn";

interface ProgressBarProps {
	bgcolor?: string;
	className?: string;
	completed: number;
}
export const ProgressBar: React.FC<ProgressBarProps> = (
	props: ProgressBarProps
) => {
	let val = props.completed;

	if (val > 100) {
		val = 100;
	} else if (val < 0) {
		val = 0;
	}

	const fillerStyles = {
		width: `${val}%`,
	};

	return (
		<div
			className={cn([
				"ProgressBar h-2 rounded bg-progress-bar my-m",
				props.className,
			])}
		>
			<div
				style={fillerStyles}
				className={`ProgressBar__progress h-full bg-rating-range-2 rounded duration-300`}
			></div>
		</div>
	);
};
