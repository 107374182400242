import { t } from "i18next";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	InfoMessage,
	BackLink,
	Title,
	Button,
	Text,
} from "@zolteam/react-ras-library";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import { ReactComponent as SuccessEmail } from "../../assets/illustrations/email.svg";
import SecurityService from "../../services/SecurityService";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const InitializeDone: React.FC = () => {
	const handleBackNavigation = () => {
		navigate("/initialize");
	};

	const navigate = useNavigate();

	const handleInitialize = () => {
		pushDataLayer({
			dataLayer: {
				event: "initialisation__renvoi_lien",
			},
		});
		SecurityService.resendResetPasswordLink(id).then(() => {
			navigate("/initialize-done", { state: { id: id } });
		});
	};

	// Get id from state from previous page in react
	const { state } = useLocation();

	// If no state or no id, redirect to initialize page
	if (!state || !state?.id) {
		window.location.assign("/initialize");
		return;
	}

	const { id } = state;

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h1" size="display02">
				{t("initialize.title")}
			</Title>
			<InfoMessage color="success" withIcon>
				{t("initialize.done.text")}
			</InfoMessage>
			<SuccessEmail className="w-full" />
			<Button
				size="m"
				className="mt-xl mb-xs border-2 w-full !py-s md:w-40 border-solid border-primary-500 hover:!text-white hover:!bg-primary-500"
				type="button"
				color="transparentPrimary"
				onClick={handleInitialize}
			>
				{t("initialize.done.resend")}
			</Button>
			<Text tag="span" className="flex mt-s flex-wrap gap-x-1 ">
				<span>{t("initialize.done.notReceived")}</span>
				<Button
					color="transparentPrimary"
					type="button"
					onClick={() => navigate("/contact-agency")}
				>
					{t("initialize.contactAgencyLink")}
				</Button>
			</Text>
		</AuthLayout>
	);
};

export default InitializeDone;
