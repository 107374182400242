import React, { useEffect, useState } from "react";
import { BackLink, InfoMessage, Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import { AgenciesForm } from "../../forms/AgenciesForm/AgenciesForm";
import { useNavigate } from "react-router-dom";

const ForgotLogin: React.FC = () => {
	const [time, setTime] = useState(300); // 300 secondes = 5 minutes
	const minutes = Math.floor(time / 60);
	const seconds = time % 60;
	const navigate = useNavigate();

	const handleBackNavigation = () => {
		navigate("/login");
	};

	let showTime = `${minutes.toString().padStart(2, "0")}:${seconds
		.toString()
		.padStart(2, "0")}`;

	useEffect(() => {
		let intervalId: NodeJS.Timer;

		if (time > 0) {
			intervalId = setInterval(() => {
				if (time === 1) {
					navigate("/login");
				}
				setTime((time) => time - 1);
			}, 1000);
		}

		return () => clearInterval(intervalId);
	}, [time, navigate]);

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" lineHeight="m" size="heading01" className="pt-m">
				{t("forgotId.title")}
			</Title>
			<div className="pt-m pb-xl">
				<InfoMessage color="primary" withIcon>
					{t("forgotId.infos", { time: showTime })}
				</InfoMessage>
			</div>
			<AgenciesForm type="forgotId" />
		</AuthLayout>
	);
};

export default ForgotLogin;
