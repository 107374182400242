import { useState } from "react";
import { getMobileOperatingSystem } from "../utils/mobileOS";
import { t } from "i18next";

const APP_ID = "com.ras.mobile";

interface IMobileAppBannerProps {
	daysHidden?: number;
	daysReminder?: number;
}

interface IMobileAppBannerState {
	lastHidden?: number;
	lastReminder?: number;
	isVisible: boolean;
}

// always Redirect to play store -> intent://${appHost}/${appDeepLink}/#Intent;scheme=${appScheme};package=${APP_ID};end
// redirect to the app if installed -> `myras://${appHost}/${appDeepLink}/#Intent;scheme=${appScheme};package=${APP_ID};end`

const getAppLink = (intented?) => {
	const mobileOs = getMobileOperatingSystem();

	if (!mobileOs) return null;

	let urlPath = window.location.pathname;
	let appHost = window.location.host;
	let appScheme = "https";
	let appDeepLink = urlPath !== "/" ? urlPath.replace("/", "") : "accueil";

	if (mobileOs === "Android")
		return `${
			intented ? "intent" : "myras"
		}://${appHost}/${appDeepLink}/#Intent;scheme=${appScheme};package=${APP_ID};end`;

	if (intented)
		return "itms://itunes.apple.com/fr/app/my-ras-emploi-et-int%C3%A9rim/id1474653896";
	return `myras://${appDeepLink}`;
};

export const handleAppDownload = (link?) => {
	const os = getMobileOperatingSystem();
	const appLink = getAppLink();
	if (!appLink) return;

	let clicked = Date.now(); // utilisé pour enregistrer le timestamp du clic
	let timeout = 1500;

	// on essaie d'ouvrir l'application

	// si l'application n'est pas installée, la page retournera une erreur qu'il est impossible de détecter
	// SOUS IOS, CELA OUVRE UNE POPUP "impossible d'ouvrir la page" qui bloque le script et empeche la redirection
	// -> on ouvre donc une autre modal demandant au user si il veut être redirigé vers le store

	// pour ANDROID on lance donc un timeout qui redirige par défaut vers le store au bout de 0.5 secondes
	// si l'application est installée, elle aura le temps de s'ouvrir avant la redirection

	// le code suivant sert a éviter que la redirection ne se fasse quand même
	// si l'utilisateur revient sur l'espace web après avoir téléchargé l'application

	window.location.href = appLink;

	// si le temps écoulé entre le clic et l'exécution du timeout est inférieur à 2 secondes
	// c'est que l'utilisateur est directement revenu sur l'espace web

	if (os === "iOS") {
		// ok bizarre avec le double setTimeout mais ça marche 💀
		const tm = setTimeout(() => {
			setTimeout(() => {
				let resp = window.confirm(
					t("mobileAppBanner.redirectToStore").toString()
				);
				if (resp) {
					// on redirige vers le store
					window.location.href = getAppLink(true) ?? "";
				}
			}, 500);
		}, timeout);
		window.addEventListener("pagehide", () => {
			clearTimeout(tm);
		});
	} else {
		window.location.replace(appLink);
		setTimeout(() => {
			if (Date.now() - clicked < timeout * 2) {
				// on redirige vers le store
				window.location.href = getAppLink(true) ?? "";
			}
		}, timeout);
	}
};

export const useMobileAppBanner = (options?: IMobileAppBannerProps) => {
	const appLink = getAppLink();

	const getState = () => {
		const state = localStorage.getItem("mobileAppBanner");
		return state ? JSON.parse(state) : {};
	};

	const saveState = (state: object) => {
		const currentState = getState();
		localStorage.setItem(
			"mobileAppBanner",
			JSON.stringify({
				...currentState,
				...state,
			})
		);
	};

	const initVisibility = () => {
		const state = getState();
		const { daysHidden = 3, daysReminder = 15 } = options ?? {};
		const dayMs = 86400000;
		const now = new Date().getTime();

		if (state?.lastReminder)
			return now - state.lastReminder > dayMs * daysReminder;

		if (state?.lastHidden)
			return now - state.lastHidden > dayMs * daysHidden;

		let tempHidden = sessionStorage.getItem("mobileAppBanner");
		if (tempHidden) return false;

		return appLink !== "";
	};

	const [isBannerVisible, setIsBannerVisible] = useState<boolean>(
		initVisibility()
	);

	const [isTempBannerVisible, setIsTempBannerVisible] = useState<boolean>(
		sessionStorage.getItem("mobileAppBanner") !== "hidden" ?? false
	);

	const handleVisibilityChange = (state) => {
		saveState({ isVisible: state });
		setIsBannerVisible(state);
	};

	const showBanner = () => {
		handleVisibilityChange(appLink !== "");
	};

	const hideBanner = (fromClose?: boolean) => {
		const time = new Date().getTime();

		let state: IMobileAppBannerState = {
			isVisible: false,
			lastReminder: fromClose ? undefined : time,
			lastHidden: fromClose ? time : undefined,
		};
		saveState(state);
		setIsBannerVisible(state.isVisible);
	};

	const tempHideTopBanner = () => {
		sessionStorage.setItem("mobileAppBanner", "hidden");
		setIsTempBannerVisible(false);
		setIsBannerVisible(false);
	};

	return {
		appLink,
		isBannerVisible,
		showBanner,
		hideBanner,
		tempHideTopBanner,
		isTempBannerVisible,
		setIsTempBannerVisible,
	};
};
