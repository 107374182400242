import React, { Ref } from "react";
import { Formik, Form, useFormikContext, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { Button, InfoMessage, Picto } from "@zolteam/react-ras-library";

// Forms
import {
	IResetPasswordFormValues,
	ResetPasswordFormInitValues,
	ResetPasswordFormValidation,
} from "./validationForm";

import RandomNumPad from "../../components/atoms/RandomNumPad/RandomNumPad";
import PasswordField from "../../components/molecules/PasswordField/PasswordField";

export const ResetPasswordInput = () => {
	const { t } = useTranslation();
	const { values, setValues, setFieldTouched } =
		useFormikContext<typeof ResetPasswordFormInitValues>();

	return (
		<>
			<div className={"relative text-primary-500"}>
				<PasswordField
					type="password"
					name="password"
					label={t("auth.login.password")}
					className={"pb-s pointer-events-none"}
					readOnly
				>
					{values.password?.length > 0 && (
						<Picto
							icon="delete"
							className={"text-neutral-400 cursor-pointer"}
							onClick={() => {
								setFieldTouched("password", true, false);
								setValues({
									password: "",
									passwordConfirmation: "",
								});
							}}
						/>
					)}
				</PasswordField>
				{values?.password?.length !== 6 && (
					<RandomNumPad fieldName="password" maxLength={6} />
				)}
				<PasswordField
					type="password"
					name="passwordConfirmation"
					label={t("resetPassword.passwordConfirm")}
					className="relative pointer-events-none pb-s mt-s"
					readOnly
				>
					{values.passwordConfirmation?.length > 0 && (
						<Picto
							icon="delete"
							className={"text-neutral-400 cursor-pointer"}
							onClick={() => {
								setFieldTouched("passwordConfirmation", true);
								setValues({
									...values,
									passwordConfirmation: "",
								});
							}}
						/>
					)}
				</PasswordField>
				{values?.password?.length === 6 && (
					<RandomNumPad
						fieldName="passwordConfirmation"
						maxLength={6}
						onChange={(e, value) => {
							if (value.length === 6)
								setFieldTouched(
									"passwordConfirmation",
									true,
									false
								);
						}}
					/>
				)}
			</div>
		</>
	);
};

export const ResetPasswordForm: React.FC<IResetPasswordForm> = ({
	error,
	...props
}: IResetPasswordForm) => {
	const { t } = useTranslation();

	const ResetPasswordValueSchema = {
		...ResetPasswordFormInitValues,
	};
	const initFormPassword =
		props.formRef !== undefined
			? {
					validateOnChange: true,
					validate: (values) => {
						props.validateStep && props.validateStep(values);
					},
					innerRef: props.formRef,
			  }
			: {};
	return (
		<Formik
			initialValues={ResetPasswordValueSchema}
			validationSchema={ResetPasswordFormValidation(t)}
			onSubmit={(values, { resetForm }) => {
				return props.handleSubmitForm(values, resetForm);
			}}
			{...initFormPassword}
		>
			{({ isValid, dirty, errors }) => {
				return (
					<div className={"pt-xl px-0 pb-s"}>
						<Form id="form-password-recovery">
							<div className={"pb-l"}>
								<InfoMessage color="primary" withIcon>
									{t("resetPassword.infoPassword")}
								</InfoMessage>
							</div>

							<ResetPasswordInput />
							{!!error?.length && (
								<InfoMessage color="error" className="mt-3">
									{error &&
									t(`API_ERRORS.${error}`) !==
										`API_ERRORS.${error}`
										? t(`API_ERRORS.${error}`)
										: t("API_ERRORS.default")}
								</InfoMessage>
							)}
							{!props.formRef && (
								<Button
									type="submit"
									isLoading={props.load}
									color={
										isValid && dirty ? "primary" : "grey"
									}
									disabled={!(isValid && dirty)}
									size="m"
									className={"w-full mt-l"}
									data-testid="button-password-recovery"
								>
									{t("resetPassword.buttonLabel")}
								</Button>
							)}
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};

interface IResetPasswordForm {
	load: boolean;
	error?: string;
	handleSubmitForm: (
		value: IResetPasswordFormValues,
		resetForm: () => void
	) => void;
	formRef?: Ref<FormikProps<any>> | undefined;
	validateStep?: (values: {}) => void;
}
