import TagManager from "react-gtm-module/dist/TagManager";
import { BASE_PAGE_TITLE } from "../constants/constants";

export const resetDataLayer = () => {
	if (!window["dataLayer"]) window["dataLayer"] = [];

	window["dataLayer"].push(function () {
		this.reset();
	});
};

export const pushDataLayer = (data: any) => {
	if (!window["dataLayer"]) window["dataLayer"] = [];

	resetDataLayer();
	TagManager.dataLayer(data);
};

const getPageTitleDocTitle = () => {
	const reg = new RegExp(`${BASE_PAGE_TITLE}(\\s*\\|\\s*)?`, "gm");
	return document.title.replace(reg, "")?.trim();
};

export const getPageTitle = () => {
	const defaultTitle = getPageTitleDocTitle();
	const title = {
		title: document["Custom__PageTitle"] ?? defaultTitle,
		type: document["Custom__PageType"] ?? defaultTitle,
	};

	return title;
};

export const setPageTitle = (type: string, title: string) => {
	const name = type ?? title;

	document["Custom__PageTitle"] = title;
	document["Custom__PageType"] = name;
	document.title = `${BASE_PAGE_TITLE}${type ? ` | ${name}` : ""}`;

	return getPageTitle();
};
