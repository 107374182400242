import React, { useEffect } from "react";
import AgenciesService from "../../services/AgenciesService";
import { Title, InfoMessage, Picto } from "@zolteam/react-ras-library";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import RadioListItem from "../../components/molecules/RadioListItem/RadioListItem";
import { IStepComponentProps } from "../../components/templates/InitLayout/InitLayout";
import { t } from "i18next";
import { Form, Formik } from "formik";
import SessionService from "../../services/SessionService";
import { toast } from "react-toastify";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import { Trans } from "react-i18next";
import { OnboardingRedirect } from "./Onboarding";

const VIRTUAL_AGENCY_NAME = "VAA";

const OnboardingReferenceAgency: React.FC<IStepComponentProps> = ({
	validateStep,
	isStepValid,
	isActive,
	formRef,
}) => {
	const [SelectedAgency, setSelectedAgency] = React.useState({
		id: "",
		name: "",
	});

	useEffect(() => {
		if (!isStepValid && isActive && SelectedAgency.id) {
			validateStep && validateStep({ agency: SelectedAgency });
		}
	}, [isStepValid, validateStep, isActive, SelectedAgency]);

	const setAgency = (agency) => {
		setSelectedAgency(agency);
		if (agency.id) validateStep && validateStep({ agency });
	};

	const fetchAgencies = () =>
		AgenciesService.getMyAgencies().then((resp) => {
			if (true || !resp.agencies.length) {
				validateStep && validateStep({ agency: null });
			}
			return resp.agencies;
		});

	const submitForm = () => {
		const user = SessionService.getUser();

		pushDataLayer({
			dataLayer: {
				event: "inscription__choix_agence",
			},
		});

		return AgenciesService.postUserReferenceAgency(SelectedAgency.id)
			.then((resp) => {
				if (user.phoneNumber !== null) {
					OnboardingRedirect();
				} else {
					return resp;
				}
			})
			.catch((e) => {
				toast.error(t("promiseToast.error").toString());
				throw new Error(t("API_ERRORS.sendError").toString());
			});
	};

	const { isLoading, error, data } = useQuery(
		["onboarding", "agencies"],
		fetchAgencies,
		{
			refetchOnWindowFocus: false,
			enabled: isActive,
			onSuccess: (data) => {
				if (data?.length === 1) setSelectedAgency(data[0]);
				return data;
			},
		}
	);

	if (isLoading) return <AgenciesLoad />;

	if (error || !data?.length) return <AgenciesError />;

	if (
		(data?.lenth && data[0]?.name === VIRTUAL_AGENCY_NAME) ||
		SelectedAgency.name === VIRTUAL_AGENCY_NAME
	)
		return (
			<AttributingAgency
				onSubmit={() => submitForm()}
				formRef={formRef}
			/>
		);

	return (
		<div className="flex flex-col h-full overflow-hidden">
			<Title tag="h1" className="!leading-7 mt-4">
				{t("onboarding.agency.title")}
			</Title>
			<p className="my-6">{t("onboarding.agency.text")}</p>
			<Formik
				onSubmit={() => submitForm()}
				initialValues={{
					mainAgency: "",
				}}
				innerRef={formRef}
			>
				{() => {
					if (error || !data?.length)
						return (
							<InfoMessage withIcon color="error">
								{t("onboarding.agency.loadingError")}
							</InfoMessage>
						);

					return (
						<Form>
							<div className="flex flex-col h-full max-h-[55vh] gap-2 overflow-auto pr-1">
								{data?.map((agency) => (
									<RadioListItem
										className="!uppercase"
										radio
										isSelected={
											SelectedAgency.id === agency.id
										}
										onClick={() => setAgency(agency)}
										key={agency.id}
										name={agency.name}
										item={{
											value: agency.id,
											label:
												agency.name +
												" (" +
												agency.locations[0]
													?.postalCode +
												")",
										}}
									/>
								))}
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

const AgenciesLoad: React.FC = () => (
	<div className="flex flex-col h-full overflow-hidden">
		<Title tag="h1" className="!leading-7 mt-4">
			{t("onboarding.agency.stepTitle")}
		</Title>
		<Loader />
	</div>
);

const AgenciesError: React.FC = () => (
	<div className="flex flex-col h-full overflow-hidden">
		<Title tag="h1" className="!leading-7 mt-4">
			{t("onboarding.agency.stepTitle")}
		</Title>
		<InfoMessage withIcon color="error">
			{t("onboarding.agency.loadingError")}
		</InfoMessage>
	</div>
);

interface IAttributingAgencyProps {
	formRef: any;
	onSubmit: () => void;
}
const AttributingAgency: React.FC<IAttributingAgencyProps> = ({
	formRef,
	onSubmit,
}) => {
	return (
		<Formik
			onSubmit={onSubmit}
			initialValues={{
				mainAgency: "",
			}}
			innerRef={formRef}
		>
			{() => {
				return (
					<div className="flex flex-col h-full overflow-hidden">
						<Title tag="h1" className="!leading-7 mt-4 mb-8">
							{t("onboarding.agency.attributing")}
						</Title>
						<InfoMessage withIcon color="primary">
							<Trans
								i18nKey={"onboarding.agency.attributingText"}
							/>
						</InfoMessage>
						<div className="flex items-center gap-4 mt-6">
							<div className="p-1 rounded-full bg-success-500">
								<Picto
									icon="checkCircle"
									className="w-5 h-5 text-white"
								/>
							</div>
							<p className="text-lg font-bold text-neutral-500">
								{t("onboarding.agency.attributingThanks")}
							</p>
						</div>
					</div>
				);
			}}
		</Formik>
	);
};

export default OnboardingReferenceAgency;
