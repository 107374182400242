import api from "./api";

export interface IDocsProofType {
	acceptedRefusedRule: number;
	attachmentsRule: number;
	effectiveDateRule: number;
	expirationDateRule: number;
	id: number;
	isArchived: boolean;
	isRegular: boolean;
	isVisibleMobile: boolean;
	name: string;
	patterns: any[]; // No infos about this field in the API documentation
	permanentRule: number;
	referenceRule: number;
}

class DocumentsServiceClass {
	getProofTypes = () =>
		api.get("/api-360/document-proof-types/list").then((resp) => resp.data);

	getProDocsProofTypes = () =>
		api
			.get("/api-360/professional-documents/document-proof-types")
			.then((response) => response.data);

	getAllProfessionalCommonDocuments = (id) =>
		api
			.get(
				`/api-360/professional-documents/document-proof-type/${id}/common-documents`
			)
			.then((response) => response.data);

	getAllProofsTypesForId = (id) =>
		api
			.get(`/api-360/document-proof-type/${id}/common-documents/list`)
			.then((response) => response.data);

	getAllUserDocuments = (id) =>
		api
			.get(`/api-360/worker/document-proof-type/${id}/documents/list`)
			.then((response) => response.data);

	uploadDocument = (data) =>
		api.post("/api-360/worker/add-document", data, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

	deleteDocument = (id) =>
		api.delete(`/api-360/worker/delete-document/${id}`);

	deleteDocumentAttachment = (id) =>
		api.delete(`/api-360/worker/delete-document-attachment/${id}`);
}

const DocumentsService = new DocumentsServiceClass();
export default DocumentsService;
