import { t } from "i18next";
import moment from "moment";
import React from "react";
import TimeOffStatus from "../../atoms/TimeOffStatus/TimeOffStatus";

interface IAbsenceProps {
	absence: IAbsence;
}

export interface IAbsence {
	id: number;
	reasonName: string;
	startDate: string;
	finishDate: string;
	startTime: string;
	finishTime: string;
	status: string;
	files: any[];
	isAllDay?: boolean;
}

const Absence: React.FC<IAbsenceProps> = ({ absence }) => {
	const startDate = moment(absence.startDate).format(
		t("dates.format").toString()
	);
	const endDate = moment(absence.finishDate).format(
		t("dates.format").toString()
	);
	const startTime = moment(absence.startTime).format(
		t("dates.formatHours").toString()
	);
	const finishTime = moment(absence.finishTime).format(
		t("dates.formatHours").toString()
	);

	const checkDate = (_startTime: string, _finishTime: string) =>
		(_startTime === "01:00" && _finishTime === "01:00") ||
		_startTime === "Invalid date" ||
		_finishTime === "Invalid date";

	return (
		<div className="flex flex-row items-center w-full justify-between  gap-m max-w-[500px]">
			<div className="flex flex-col">
				<b>{absence.reasonName}</b>
				<span>
					{checkDate(startTime, finishTime)
						? t("dates.fromTo", {
								from: startDate,
								to: endDate,
						  })
						: t("dates.fromTo", {
								from: startDate + " " + startTime,
								to: endDate + " " + finishTime,
						  })}
				</span>
			</div>
			<TimeOffStatus status={absence.status as any} />
		</div>
	);
};

export default Absence;
