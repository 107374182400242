import React from "react";
import { BackLink, Title, Text, Button } from "@zolteam/react-ras-library";
import { t } from "i18next";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import { useLocation, useNavigate } from "react-router-dom";
import Illustration from "../../assets/illustrations/userSearch.svg";
import SVG from "../../components/atoms/SVG/SVG";

const SignUpExistingUser: React.FC = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const handleBackNavigation = () => {
		navigate("/signup", { state: state });
	};

	const loginRedirect = () => {
		navigate("/login", { state: { ...state, isOnboarding: true } });
	};

	const loginRetrieveRedirect = () => {
		navigate("/login-retrieve", {
			state: { ...state },
		});
	};

	if (!state?.email) {
		navigate("/signup");
		return null;
	}

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" size="heading01" className="!leading-9 my-8">
				{t("signup.existingUser.title")}
			</Title>

			<Text tag="span" className="flex flex-wrap mt-s mb-l gap-x-1">
				{t("signup.existingUser.text")}
				<br />
				<span className="flex flex-wrap gap-x-1">
					{t("auth.login.idForgot")}
					<button
						onClick={loginRetrieveRedirect}
						className="font-bold text-primary-500"
					>
						{t("retrieveLogin.title")}
					</button>
				</span>
			</Text>
			<SVG src={Illustration} className="max-w-full" />
			<Button
				color="primary"
				className="mt-6"
				type="button"
				onClick={loginRedirect}
			>
				{t("auth.login.login")}
			</Button>
		</AuthLayout>
	);
};

export default SignUpExistingUser;
