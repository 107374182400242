import * as Yup from "yup";

export interface IResetPasswordFormValues {
	password: string;
	passwordConfirmation: string;
	activationCode?: string;
}

export const ResetPasswordFormInitValues: IResetPasswordFormValues = {
	password: "",
	passwordConfirmation: "",
	activationCode: "",
};

export const ResetPasswordFormValidation = (t) =>
	Yup.object().shape({
		password: Yup.string()
			.test(
				"len",
				t("auth.error.passwordValid"),
				(val) => !!val && val.length === 6
			)
			.required(t("auth.error.passwordRequired")),
		passwordConfirmation: Yup.string()
			.test(
				"len",
				t("auth.error.passwordValid"),
				(val) => !!val && val.length === 6
			)
			.oneOf(
				[Yup.ref("password"), "", undefined],
				t("auth.error.passwordMismatch")
			),
	});
