import React from "react";
import { t } from "i18next";
import AnnouncementService, {
	DEFAULT_JOB_TYPES,
} from "../../services/AnnouncementService";
import Offer from "../../components/molecules/Offer/Offer";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import SectorsServices from "../../services/SectorsServices";
import Loader from "../../components/atoms/Loader/Loader";
import OffersFiltersForm from "../../forms/FiltersForm/OffersFiltersForm";
import ListLayout from "../../components/templates/ListLayout/ListLayout";
import { useParams } from "react-router-dom";
import OfferLayout from "../../components/templates/OfferLayout/OfferLayout";
import OffersForm from "../../forms/OffersForm/OffersForm";
import { IFilters } from "../../contexts/FiltersContext";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

export interface ISector {
	id: string | number;
	name: string;
	mainQualification?: boolean;
}

interface IFilterForm extends IFilters {
	orderByDesc: boolean;
	jobTypes: { label: string; value: number }[];
	sectors: { label: string; value: number }[];
	location: { label: ""; value: "" };
	searchInput: "";
}

const Offers: React.FC = () => {
	const params = useParams();
	const queryClient = useQueryClient();

	const [userSectors, userJobTypes] = useQueries({
		queries: [
			{
				queryKey: ["userSectors"],
				queryFn: () => SectorsServices.getUserSectors(),
			},

			{
				queryKey: ["userOffersJobTypes"],
				queryFn: () => Promise.resolve({ jobTypes: DEFAULT_JOB_TYPES }),
			},
		],
	});

	if (userSectors.isLoading || userJobTypes.isLoading) return <Loader />;

	const userSectorsFilter = () => {
		let sectors = userSectors.data?.sectors || [];
		return sectors
			.filter((sector: ISector) => sector.mainQualification === true)
			.map((sector: ISector) => ({
				label: sector.name,
				value: sector.id,
			}));
	};

	const userJobTypesFilter = () =>
		(userJobTypes.data?.jobTypes || []).map((jobType) => ({
			label: jobType.name,
			value: jobType.id,
		}));

	const DEFAULT_FILTERS = {
		orderByDesc: true,
		jobTypes: [...userJobTypesFilter()],
		sectors: [...userSectorsFilter()],
		location: { label: "", value: "" },
		searchInput: "",
		page: 1,
		limit: 20,
	};

	let criteriaFilter = {};
	const fetchAnnouncements = (filters: typeof DEFAULT_FILTERS) => {
		let sectors = filters.sectors.map(
			(sector: { label: string; value: string }) => sector.label
		);
		let jobTypes = filters.jobTypes.map(
			(jobType: { label: string; value: number }) => jobType.label
		);
		if (
			JSON.stringify(filters) === JSON.stringify(DEFAULT_FILTERS) &&
			filters.searchInput.length <= 0
		) {
			pushDataLayer({
				dataLayer: {
					event: "liste_offres__predefinie",
					secteurs: sectors,
					types_de_contrats: jobTypes,
				},
			});
		}
		let newFilters = {};
		if (filters.searchInput.length >= 3) {
			newFilters = { searchInput: filters.searchInput };
		} else {
			newFilters = {
				locationId: filters.location?.value
					? filters.location.value
					: undefined,
				masterMobileQualifications: filters.sectors.map(
					(sector: { label: string; value: string }) => sector.label
				),
				contractTypes: filters.jobTypes.map(
					(jobType: { label: string; value: number }) => jobType.label
				),
			};
		}

		criteriaFilter = {
			criteria: {
				...newFilters,
				hasApplied: false,
			},
			page: filters.page ?? 1,
			limit: filters.limit ?? 100,
		};

		return AnnouncementService.getAnnouncement(criteriaFilter).then(
			(resp) => {
				return {
					...resp,
					items: resp.announcements,
					total: resp.totalResult,
					hasMore: !resp.isLastPage,
					nextPage: filters.page + 1,
				};
			}
		);
	};

	const countFilters = (filters: IFilters) => {
		let count = 0;
		if (filters.searchInput) {
			count++;
			return count;
		}
		if (filters.location?.label?.length > 0) count += 1;
		if (filters.jobTypes?.length > 0) count += filters.jobTypes.length;
		if (filters.sectors?.length > 0) count += filters.sectors.length;
		return count;
	};

	const onApplyFilter = (values: IFilterForm) => {
		let sectors = values.sectors.map((sector) => sector.label);
		let jobTypes = values.jobTypes.map((jobType) => jobType.label);
		pushDataLayer({
			dataLayer: {
				event: "liste_offres__modifie",
				lieux: values.location?.label || "",
				secteurs: sectors,
				types_de_contrats: jobTypes,
			},
		});
		queryClient.resetQueries(["announcements"]);
	};

	const onCloseFilter = () => {
		pushDataLayer({
			dataLayer: {
				event: "filtres_offres__retour_liste",
			},
		});
	};

	return (
		<ListLayout
			title={t("offers.searchOffers.title").toString()}
			query={{
				key: ["announcements"],
				fn: fetchAnnouncements,
				opts: { refetchOnWindowFocus: false },
			}}
			noDataMessage={t("offers.noOffers")}
			activeItemId={params.id ?? null}
			baseUrl="/offers"
			navigation={() => <OffersForm queryKey={["announcements"]} />}
			filters={{
				values: DEFAULT_FILTERS,
				count: countFilters,
				modalForm: OffersFiltersForm,
				gtmEvent: "liste_offres__voir_filtres",
				onApply: (filters) => onApplyFilter(filters as IFilterForm),
				onClose: onCloseFilter,
			}}
			viewer={{
				render: ({ item, closeViewer }) => {
					return (
						<OfferLayout
							item={item}
							onBack={closeViewer}
							onBackText={t("offers.searchOffers.offersList")}
						/>
					);
				},
				position: "absolute",
			}}
			scrollParentId="App"
			showResultsCount={true}
		>
			{(item) => <Offer {...item} />}
		</ListLayout>
	);
};

export default Offers;
