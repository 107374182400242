import { Badge, Button, Modal, Picto, Title } from "@zolteam/react-ras-library";
import React, { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { FiltersContext, IFilters } from "../../../contexts/FiltersContext";
import { Form, Formik } from "formik";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";

interface IFilterButtonProps {
	queryKey?: string[];
	modalForm?: React.FC<any>;
	gtmEvent?: string;
	onApply?: (values: any) => void;
	onClose?: () => void;
	height?: string;
}

interface IFilterModalProps extends IFilterButtonProps {
	onClose: () => void;
	filters: any;
}

const FilterButton: React.FC<IFilterButtonProps> = ({
	queryKey,
	modalForm,
	gtmEvent,
	onApply,
	onClose,
	height,
}) => {
	const { filters, activeFilterCount, showFiltersModal, hideFiltersModal } =
		useContext(FiltersContext);

	const onCloseFilter = () => {
		onClose && onClose();
		hideFiltersModal();
	};
	return (
		<div>
			<Button
				type="button"
				color="transparent"
				className="!text-primary-500 !p-2 hover:!bg-neutral-200 ml-auto gap-s"
				onClick={() => {
					if (gtmEvent)
						pushDataLayer({
							dataLayer: {
								event: gtmEvent,
							},
						});
					showFiltersModal();
				}}
			>
				<Picto
					icon="filters"
					className="[&>g]:!text-primary-500 !mr-0"
				/>
				<span>{t("global.filters")}</span>
				{activeFilterCount > 0 && (
					<Badge
						content={activeFilterCount}
						color="accent"
						className={"[&>span]:min-w-[14px]"}
					/>
				)}
			</Button>

			<FilterModal
				onClose={onCloseFilter}
				filters={filters}
				queryKey={queryKey}
				modalForm={modalForm}
				onApply={onApply}
				height={height}
			/>
		</div>
	);
};

const FilterModal: React.FC<IFilterModalProps> = ({
	onClose,
	queryKey,
	modalForm: ModalForm,
	onApply,
	height,
}) => {
	const {
		showModal,
		hideFiltersModal,
		resetFilters,
		getFilters,
		setFilter,
		activeFilterCount,
		countFilters,
		isButtonVisible,
	} = useContext(FiltersContext);
	const queryClient = useQueryClient();

	const applyFilters = (values: IFilters) => {
		onApply && onApply(values);
		setFilter(values);
		hideFiltersModal();
		if (queryKey) queryClient.removeQueries({ queryKey: queryKey });
	};

	const onResetFilters = (formikCtx) => {
		let defaultFilters: IFilters = resetFilters();
		for (let filterName in defaultFilters) {
			formikCtx.setFieldValue(filterName, defaultFilters[filterName]);
		}
	};

	if (!isButtonVisible) return null;

	return (
		<Modal
			onClose={onClose}
			isDisplayed={showModal}
			className={`!min-w-[90vw] !max-h-[80vh] sm:!min-w-[580px] overflow-auto [&>div:first-child]:z-50 [&>div:first-child]:relative ${height}`}
		>
			<div className="w-full text-start mt-[-32px]">
				<Title
					tag="h2"
					size="heading01"
					className="mb-4 !pointer-events-none"
				>
					{t("global.filters")}
				</Title>
				<div className="3xl:mt-xxl">
					<Formik
						initialValues={getFilters()}
						onSubmit={applyFilters}
					>
						{({
							isSubmitting,
							errors,
							touched,
							values,
							resetForm,
							...formikProps
						}) => {
							const filtersCount = countFilters(values);
							return (
								<Form>
									{ModalForm && (
										<ModalForm
											values={values}
											isSubmitting={isSubmitting}
											formikProps={formikProps}
											activeFilterCount={
												activeFilterCount
											}
											resetFilters={resetFilters}
										/>
									)}
									<hr className="my-5" />
									<div className="flex justify-end flex-wrap gap-s">
										{filtersCount > 0 && (
											<Button
												type="button"
												color="primary"
												outline
												className="mr-3"
												onClick={() =>
													onResetFilters(formikProps)
												}
											>
												{t("global.reset")} (
												{filtersCount})
											</Button>
										)}
										<Button
											type="submit"
											color="primary"
											disabled={isSubmitting}
										>
											{t("global.apply")}
										</Button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</Modal>
	);
};

export default FilterButton;
