import React from "react";
import { useTranslation } from "react-i18next";

// Flags icon
// flags can be found here: https://purecatamphetamine.github.io/country-flag-icons/3x2/index.html
import US from "./assets/flags/US.svg";
import FR from "./assets/flags/FR.svg";

import "./LanguageSwitcher.scss";
import SVG from "../../components/atoms/SVG/SVG";

interface ILanguage {
	code: string; // language code ex: en-EN, fr-FR, fr-CA
	title: string; // displayed text
	icon: string; // svg icon
}

interface ILanguageSwitcher {
	handleChange?: (code: string) => void; // callback function, reload page if not specified
}

const Trads: ILanguage[] = [
	{
		code: "en-EN",
		title: "English",
		icon: US,
	},
	{
		code: "fr-FR",
		title: "Français",
		icon: FR,
	},
];

const LanguageSwitcher: React.FC = ({ handleChange }: ILanguageSwitcher) => {
	const { i18n } = useTranslation();

	function handleClick(code: string) {
		i18n.changeLanguage(code);
		if (handleChange) handleChange(code);
		else window.location.reload();
	}

	return (
		<div className="locales-menu-cont">
			{Trads.map((lang) => {
				return (
					<div
						className="locales-item text-black dark:text-white"
						key={lang.code}
					>
						<a
							href={"#" + lang.code}
							onClick={() => handleClick(lang.code)}
						>
							<span className={"flag-icon mr-2"}>
								<SVG src={lang.icon} />
							</span>
							<span>{lang.title}</span>
						</a>
					</div>
				);
			})}
		</div>
	);
};

export default LanguageSwitcher;
