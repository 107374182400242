import React from "react";
import "./Loader.scss";
import { DefaultTFuncReturn, t } from "i18next";
import cn from "../../../utils/cn";

interface ILoader {
	text?: string | boolean | DefaultTFuncReturn;
	progress?: number;
	className?: string;
	theme?: "primary" | "ras" | "ocean" | "rainbow";
}

const Loader: React.FC<ILoader> = ({
	text,
	progress,
	theme = "ocean",
	...props
}: ILoader) => {
	const genWaves = () => {
		let waves = Array(theme === "rainbow" ? 7 : 4)
			.fill({})
			.map((wave, i) => {
				return (
					<path
						key={i}
						fillOpacity="0.7"
						fill="#000"
						style={{
							animationDelay: `${i + Math.random()}s`,
						}}
						d="
				M0,192
				C220,100,440,100,660,192
				C880,290,1100,290,1320,192
				L1320 800
				L0 800
				"
					/>
				);
			});

		return waves;
	};

	return (
		<div
			className={cn([
				"Loader text-center relative overflow-hidden",
				theme,
				props.className,
			])}
		>
			<div
				className="Logo relative flex items-center justify-center overflow-hidden bg-primary-50 mt-10"
				data-value={progress}
			>
				{/* <span className="logo-fill"></span> */}
				<svg
					viewBox="0 0 1320 800"
					className="relative duration-500"
					style={{
						top: 110 - (progress ?? 70) + "%",
					}}
				>
					{genWaves()}
				</svg>
			</div>
			{text !== false && (
				<p className="text mx-auto mt-2">
					{text ??
						t("global.loading") +
							(progress && progress >= 0
								? ` - ${progress.toFixed(0)}%`
								: "")}
				</p>
			)}
		</div>
	);
};

export default Loader;
