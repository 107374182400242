import React, { MouseEventHandler } from "react";
import cn from "../../../utils/cn";
import "./BurgerMenuButton.scss";

interface IBurgerMenuButton {
	isActive?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const BurgerMenuButton: React.FC<IBurgerMenuButton> = ({
	isActive,
	onClick,
}) => {
	return (
		<button
			className={cn([
				"burgerMenuButton flex flex-col gap-xs sm:hidden !outline-none focus-visible:!outline-none",
				isActive && "active",
			])}
			onClick={onClick}
		>
			<div
				className={
					"pointer-events-none bg-neutral-300 h-[0.15rem] rounded-16 w-7"
				}
			/>
			<div
				className={
					"pointer-events-none bg-neutral-300 h-[0.15rem] rounded-16 w-7"
				}
			/>
			<div
				className={
					"pointer-events-none bg-neutral-300 h-[0.15rem] rounded-16 w-7"
				}
			/>
		</button>
	);
};

export default BurgerMenuButton;
