import React from "react";
import { Button, SelectAsync } from "@zolteam/react-ras-library";
import { Form, Formik } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import { promiseToast } from "../../toastify/toastify";
import { IPersonalInformationsCategory } from "./PersonalInformations";
import * as Yup from "yup";
import { noAccents } from "../../utils/noAccents";
import SessionService from "../../services/SessionService";
import moment from "moment/moment";
import formatDate from "../../utils/formatDate";
import DatePickerField from "../../components/molecules/DatePickerField/DatePickerField";

const validationSchema = Yup.object().shape({
	bornCity: Yup.string().required(t("personalInfos.errors.required")),
	nativeCountry: Yup.object()
		.shape({ name: Yup.string(), id: Yup.string() })
		.required(t("personalInfos.errors.required")),
	nationality: Yup.object()
		.shape({ name: Yup.string(), id: Yup.string() })
		.required(t("personalInfos.errors.required")),
});

const IdentityInformations: React.FC<IPersonalInformationsCategory> = ({
	infos,
	disabled,
	refetchInfos,
}) => {
	const status = SessionService.getUser().twStatus;
	const handleSubmit = (values: any) => {
		const prom = ProfileService.putProfileIdentity({
			bornCity: values.bornCity,
			nationalityId: values.nationality.id,
			nativeCountryId: values.nativeCountry.id,
			dateOfBirth: formatDate(values.formattedDateOfBirth),
		});

		promiseToast(prom);

		return prom;
	};

	const loadNationalities = (searchTerm: string) => {
		return ProfileService.getNationalities().then((resp) => {
			// filter items that name contains `inputValue`
			return resp.filter((i) =>
				noAccents(i.name.toLowerCase()).includes(
					noAccents(searchTerm.toLowerCase())
				)
			);
		});
	};

	const loadCountries = (searchTerm: string) => {
		return ProfileService.getCountries().then((resp) => {
			// filter items that name contains `inputValue`
			return resp.filter((i) =>
				noAccents(i.name.toLowerCase()).includes(
					noAccents(searchTerm.toLowerCase())
				)
			);
		});
	};

	return (
		<div className={cn([disabled && "cursor-not-allowed"])}>
			<Formik
				initialValues={{
					...infos,
				}}
				onSubmit={(values, { resetForm }) =>
					handleSubmit(values).then(() => {
						resetForm();
						refetchInfos();
					})
				}
				validationSchema={validationSchema}
			>
				{({
					values,
					setFieldValue,
					dirty,
					isSubmitting,
					setValues,
				}) => {
					const isDisabled = disabled || isSubmitting;

					return (
						<Form className="relative flex flex-col items-start justify-start gap-4">
							<div
								className={cn([
									"flex flex-col gap-2 sm:w-auto w-full",
									isDisabled &&
										"pointer-events-none opacity-70",
								])}
							>
								<b className="">
									{t("personalInfos.identity.title")}
								</b>
								<div className="flex gap-2">
									<Button
										className={cn([
											"!border-solid !border-2",
											values.sex === "female" &&
												"!border-primary-500",
										])}
										outline={values.sex !== "female"}
										color={
											values.sex === "female"
												? "primary"
												: "grey"
										}
										type="button"
										onClick={() => {
											setFieldValue("sex", "female");
										}}
									>
										{t("personalInfos.identity.women")}
									</Button>
									<Button
										className={cn([
											"!border-solid !border-2",
											values.sex === "male" &&
												"!border-primary-500",
										])}
										outline={values.sex !== "male"}
										color={
											values.sex === "male"
												? "primary"
												: "grey"
										}
										type="button"
										onClick={() => {
											setFieldValue("sex", "male");
										}}
									>
										{t("personalInfos.identity.men")}
									</Button>
								</div>
								<div className="flex flex-col gap-2">
									<div className="flex flex-col flex-wrap gap-2 md:flex-row">
										<Field
											label={t("global.firstName")}
											name="firstName"
											type="text"
											disabled
											className="md:min-w-[300px]"
										/>
										<Field
											label={t("global.lastName")}
											name="lastName"
											type="text"
											disabled
											className="md:min-w-[300px]"
										/>
										<div>
											<DatePickerField
												label={t(
													"personalInfos.identity.birthDate"
												)}
												name={"formattedDateOfBirth"}
												onChange={(val) => {
													setValues({
														...values,
														startDate: val,
														formattedDateOfBirth:
															moment(val).format(
																t(
																	"dates.format"
																)
															),
													});
												}}
												disabled={status !== "waiting"}
												value={values.startDate}
												pickerProps={{
													minDate: moment()
														.subtract(70, "years")
														.toDate(),
													maxDate: moment()
														.subtract(18, "years")
														.toDate(),
												}}
											/>
										</div>
									</div>
									<div className="flex flex-wrap gap-2 flex-col md:flex-row md:[&>*]:min-w-[300px]">
										<Field
											label={t(
												"personalInfos.identity.birthTown"
											)}
											type="text"
											name="bornCity"
											maxLength={30}
										/>
										<SelectAsync
											defaultOptions
											loadOptions={loadCountries}
											label={t(
												"personalInfos.identity.nativeCountry"
											)}
											value={values.nativeCountry}
											getOptionLabel={(option) =>
												option.name
											}
											getOptionValue={(option) =>
												option.id
											}
											searchIcon={true}
											isSearchable={true}
											onChange={(value) => {
												setFieldValue(
													"nativeCountry",
													value
												);
											}}
											name="nativeCountry"
										/>
										<SelectAsync
											defaultOptions
											loadOptions={loadNationalities}
											label={t(
												"personalInfos.identity.nationality"
											)}
											value={values.nationality}
											getOptionLabel={(option) =>
												option.name
											}
											getOptionValue={(option) =>
												option.id
											}
											searchIcon={true}
											isSearchable={true}
											onChange={(value) => {
												setFieldValue(
													"nationality",
													value
												);
											}}
											name="nationality"
										/>
									</div>
								</div>
							</div>
							{dirty && (
								<div className="w-full text-center">
									<Button
										type="submit"
										color={dirty ? "primary" : "grey"}
										disabled={!dirty}
										className="w-[250px] max-w-full"
										isLoading={isSubmitting}
									>
										{t("global.save")}
									</Button>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default IdentityInformations;
