import React, { useState } from "react";
import { Button, Title } from "@zolteam/react-ras-library";
import { DEFAULT_AVAILABILITIES_API_BODY } from "../../forms/AvailabilitiesForm/AvailabilitiesFormConst";
import { FormikProps } from "formik/dist/types";
import AvailabilitiesService from "../../services/AvailabilitiesService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { t } from "i18next";
import Loader from "../../components/atoms/Loader/Loader";
import AvailabilitiesForm from "../../forms/AvailabilitiesForm/AvailabilitiesForm";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

// function to check if one of the availabilities is selected
const isOneSelected = (availabilities) => {
	let isOneSelected = false;
	Object.keys(availabilities).forEach((day) => {
		Object.keys(availabilities[day]).forEach((timeSlot) => {
			if (availabilities[day][timeSlot]) {
				isOneSelected = true;
			}
		});
	});
	return isOneSelected;
};

const Availabilities: React.FC = () => {
	const formRef = React.createRef<FormikProps<any>>();
	const hasFetched = React.useRef(false);
	const [State, setState] = useState({
		availabilities: DEFAULT_AVAILABILITIES_API_BODY,
		fetched: {},
		isValid: false,
		isSending: false,
		hasChanged: false,
	});
	const queryClient = useQueryClient();

	const handleSubmit = (availabilities) => {
		setState({ ...State, isSending: true });
		return AvailabilitiesService.putAvailabilities(availabilities)
			.then((response) => {
				setState({
					...State,
					isSending: false,
					hasChanged: false,
				});
				toast.success(t("availabilities.updateSuccess"));
				queryClient.invalidateQueries(["availabilities"]);
			})
			.catch((error) => {
				setState({
					...State,
					isSending: false,
				});
				toast.error(t("global.error"));
				console.error(error);
			});
	};

	const fetchAvailabilities = () =>
		AvailabilitiesService.getAvailabilities().then((response) => {
			hasFetched.current = true;
			setState({
				...State,
				fetched: response,
				isValid: isOneSelected(response),
			});
			return response;
		});

	const { isLoading, error, data } = useQuery(
		["availabilities"],
		fetchAvailabilities,
		{
			refetchOnWindowFocus: false,
			enabled: hasFetched.current === false,
		}
	);

	if (error) return <div>{t("availabilities.loadingError")}</div>;

	if (isLoading) return <Loader />;

	return (
		<>
			<Title tag="h2" className="pb-xl !leading-7">
				{t("availabilities.title")}
			</Title>
			{isLoading ? (
				<div className="w-full flex items-center flex-col h-full">
					<Loader />
				</div>
			) : (
				<>
					<AvailabilitiesForm
						onChange={(availabilities) => {
							setState({
								...State,
								isValid: isOneSelected(availabilities),
								hasChanged: true,
								availabilities: availabilities,
							});
						}}
						handleSubmit={handleSubmit}
						formRef={formRef}
						availabilities={data}
					/>
					{State.hasChanged && (
						<Button
							type="submit"
							color={State.isValid ? "primary" : "grey"}
							disabled={!State.isValid ? true : false}
							size="m"
							className={`mt-8`}
							data-testid="availabilities"
							onClick={() => {
								pushDataLayer({
									dataLayer: {
										event: "disponibilites__modifiee",
									},
								});
								formRef.current?.submitForm();
							}}
							isLoading={State.isSending}
						>
							{t("global.save")}
						</Button>
					)}
				</>
			)}
		</>
	);
};

export default Availabilities;
