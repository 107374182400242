import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { Title, Text, Button, ToggleSwitch } from "@zolteam/react-ras-library";
import { Trans } from "react-i18next";
import CookiesService from "../../services/CookiesService";

const Cookies = () => {
	const [isToggle, setIsToggle] = useState(false);
	const preference = CookiesService.getCookies();

	useEffect(() => {
		preference === "acceptAll" ? setIsToggle(true) : setIsToggle(false);
	}, [preference]);

	const handleRegisterCookies = (preferenceClick: string) => {
		preferenceClick === "acceptAll"
			? setIsToggle(true)
			: setIsToggle(false);
		CookiesService.setCookies(preferenceClick);
	};
	return (
		<>
			<Title tag="h1" lineHeight="l" size="heading01">
				{t("cookies.title")}
			</Title>
			<Text tag="p" size="paragraph01" className="mt-l mb-s">
				<Trans i18nKey="cookies.description" />
			</Text>
			<hr />
			<Text tag="p" size="heading03" className="mt-l" fontWeight="bold">
				{t("cookies.functionalTitle")}
			</Text>
			<Text tag="p" size="paragraph01" className="mb-s">
				{t("cookies.functionalDescription")}
			</Text>
			<hr />
			<div className="flex">
				<Text
					tag="p"
					size="heading-03"
					className="mt-m"
					fontWeight="bold"
				>
					{t("cookies.analyticTitle")}
				</Text>
				<ToggleSwitch
					id="toggle1"
					name="toggle1"
					className="mt-m ml-m"
					onChange={() => {
						setIsToggle(!isToggle);
					}}
					toggled={isToggle}
				/>
			</div>
			<Text tag="p" size="paragraph01">
				{t("cookies.analyticDescription")}
			</Text>
			<div className="flex mt-s">
				<div className="flex flex-col pr-s">
					<Text tag="span" size="paragraph01" fontWeight="bold">
						{t("cookies.cookie")}
					</Text>

					<Text tag="span" size="paragraph01" fontWeight="bold">
						{t("cookies.duration")}
					</Text>
					<Text tag="span" size="paragraph01" fontWeight="bold">
						{t("cookies.cookieDescription")}
					</Text>
				</div>
				<div className="flex flex-col">
					<Text tag="p" size="paragraph01">
						{t("cookies.analyticCookies")}
					</Text>
					<Text tag="p" size="paragraph01">
						{t("cookies.analyticDuration")}
					</Text>
					<Text tag="p" size="paragraph01">
						{t("cookies.analyticCookieDescription")}
					</Text>
				</div>
			</div>
			<div className="flex mt-s">
				<div className="flex flex-col pr-s">
					<Text tag="span" size="paragraph01" fontWeight="bold">
						{t("cookies.cookie")}
					</Text>

					<Text tag="span" size="paragraph01" fontWeight="bold">
						{t("cookies.duration")}
					</Text>
					<Text tag="span" size="paragraph01" fontWeight="bold">
						{t("cookies.cookieDescription")}
					</Text>
				</div>
				<div className="flex flex-col mb-s">
					<Text tag="p" size="paragraph01">
						{t("cookies.analyticCookies2")}
					</Text>
					<Text tag="p" size="paragraph01">
						{t("cookies.analyticDuration")}
					</Text>
					<Text tag="p" size="paragraph01">
						{t("cookies.analyticCookieDescription2")}
					</Text>
				</div>
			</div>
			<hr />
			<Text tag="p" size="heading03" className="mt-m" fontWeight="bold">
				{t("cookies.performanceTitle")}
			</Text>
			<Text tag="p" size="paragraph01">
				{t("cookies.performanceDescription")}
			</Text>
			<div className="flex flex-wrap gap-s justify-end mt-l">
				<Button
					type="button"
					color="primary"
					outline={!isToggle}
					onClick={() => handleRegisterCookies("acceptAll")}
				>
					{t("cookies.acceptAll")}
				</Button>
				<Button
					type="button"
					color="primary"
					outline={isToggle}
					onClick={() => handleRegisterCookies("rejectAll")}
				>
					{t("cookies.rejectAll")}
				</Button>
				<Button
					type="button"
					color="primary"
					outline
					onClick={() =>
						handleRegisterCookies(
							isToggle ? "acceptAll" : "rejectAll"
						)
					}
				>
					{t("cookies.registerPreferences")}
				</Button>
			</div>
		</>
	);
};

export default Cookies;
