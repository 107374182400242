import React from "react";
import { Picto, Badge } from "@zolteam/react-ras-library";

// Styles
import "./PictoNotification.scss";

interface IPictoNotification {
	icon: string;
	number: number;
	padColor: string;
	padBorderColor?: string;
	customIconSize: {
		width?: string;
		height?: string;
	};
}

const PictoNotification = React.forwardRef(
	(
		{
			icon = "notification",
			number = 0,
			padColor = "primary",
			padBorderColor = "",
			customIconSize = {},
		}: IPictoNotification,
		ref
	) => (
		<div ref={ref} className={"pictoNotification"}>
			<Picto
				className={"pictoNotificationIcon"}
				icon={icon}
				style={customIconSize}
			/>
			{number > 0 || number < 0 ? (
				<Badge
					className={"pictoNotificationBadge"}
					content={number}
					color={padColor}
					borderColor={padBorderColor}
				/>
			) : null}
		</div>
	)
);

export default PictoNotification;
