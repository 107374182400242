import React from "react";
import { Text } from "@zolteam/react-ras-library";

interface ITimeSlotsProps {
	label: string;
	subLabel: string;
	picto?: React.ReactNode;
	children?: React.ReactNode;
}
const TimeSlots: React.FC<ITimeSlotsProps> = (props: ITimeSlotsProps) => {
	return (
		<div>
			<div className="flex  ">
				{props.picto && props.picto}

				<Text tag="span" className="px-s">
					{props.label}
				</Text>
				<Text tag="span" className="!text-neutral-500">
					{props.subLabel}
				</Text>
			</div>
			<div>{props.children}</div>
		</div>
	);
};

export default TimeSlots;
