import api from "./api";

class JobTypesServiceClass {
	getJAllJobTypes() {
		return api
			.get("/api-360/job-types/list")
			.then((response) => response.data);
	}

	getUserJobTypes() {
		return api
			.get("/api-360/job-types/worker")
			.then((response) => response.data);
	}

	postJobTypes(jobTypes) {
		return api
			.post("/api-360/job-types/edit", jobTypes)
			.then((response) => response.data);
	}
}

const JobTypesService = new JobTypesServiceClass();
export default JobTypesService;
