import * as Yup from "yup";
import { DATE_REGEX } from "../../utils/regex";

export const RegisterFormInitValues = {
	lastName: "",
	dateOfBirth: "",
};

export const RegisterFormValidation = (t, init: string) =>
	init === "dateOfBirth"
		? Yup.object().shape({
				dateOfBirth: Yup.string()
					// test if birthdate is valid
					.test(
						"dateOfBirth",
						t("initRegister.error.birthDate"),
						(value) => (value ? DATE_REGEX.test(value) : true)
					)
					// test if birthdate is not in the future
					.test(
						"dateOfBirth",
						t("initRegister.error.futureBirthDate"),
						(value) =>
							value
								? new Date(
										value.split("/").reverse().join("/") // reformate the date to US format
								  ) <= new Date(Date.now())
								: true
					)
					.required(t("initRegister.error.birthDateRequired")),
		  })
		: Yup.object().shape({
				lastName: Yup.string()
					// test if lastName is at least 2 characters long
					.test("len", t("initRegister.error.lastName"), (val) =>
						val && val.length >= 2 ? true : false
					)
					// test if lastName is only composed of letters
					.test(
						"lastName",
						t("initRegister.error.lastName"),
						(value) =>
							value ? /^[a-zA-Z\-\s]+$/.test(value) : true
					)

					.required(t("initRegister.error.lastNameRequired")),
		  });
