import React from "react";
import SVG from "../../components/atoms/SVG/SVG";
import Sunrise from "../../assets/icons/sunrise.svg";
import Sun from "../../assets/icons/sun.svg";
import Moon from "../../assets/icons/moon.svg";
import i18n from "../../i18n/i18n";
import { IDaysSlots } from "./AvailabilitiesForm";

export const DEFAULT_AVAILABILITIES_API_BODY: IDaysSlots = {
	monday: {
		morning: false,
		afternoon: false,
		night: false,
	},
	tuesday: {
		morning: false,
		afternoon: false,
		night: false,
	},
	wednesday: {
		morning: false,
		afternoon: false,
		night: false,
	},
	thursday: {
		morning: false,
		afternoon: false,
		night: false,
	},
	friday: {
		morning: false,
		afternoon: false,
		night: false,
	},
	saturday: {
		morning: false,
		afternoon: false,
		night: false,
	},
	sunday: {
		morning: false,
		afternoon: false,
		night: false,
	},
};

// "i18n.t" is needed to translate the labels instead of just "t" because this file is not in a component
// and therefore the translation is not automatically done
export const TIMES_OF_DAY = [
	{
		value: "morning",
		label: i18n.t("availabilities.weekDaysSelector.morning"),
		subLabel: i18n.t("availabilities.weekDaysSelector.morningSlot"),
		picto: <SVG src={Sunrise} />,
	},
	{
		value: "afternoon",
		label: i18n.t("availabilities.weekDaysSelector.afternoon"),
		subLabel: i18n.t("availabilities.weekDaysSelector.afternoonSlot"),
		picto: <SVG src={Sun} />,
	},
	{
		value: "night",
		label: i18n.t("availabilities.weekDaysSelector.night"),
		subLabel: i18n.t("availabilities.weekDaysSelector.nightSlot"),
		picto: <SVG src={Moon} />,
	},
];
