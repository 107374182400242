import React from "react";
import { Select } from "@zolteam/react-ras-library";
import { t } from "i18next";
import { Formik } from "formik";
import AgenciesService from "../../services/AgenciesService";
import SuccessPassword from "../../assets/illustrations/password.svg";
import ContactAgencies from "../../assets/illustrations/contact.svg";
import "./Agencies.scss";
import AgenciesCard from "../../components/molecules/AgenciesCard/AgenciesCard";
import SVG from "../../components/atoms/SVG/SVG";
import SessionService from "../../services/SessionService";
import { useQuery } from "@tanstack/react-query";

export interface IAgency {
	id: string;
	locations: [{ name: string; postalCode: string }];
	name: string;
	phone: string;
	street: string;
	city: string;
	postalCode: string;
}

const ForgotLoginInitValues = {
	id: "",
	locations: [{ name: "", postalCode: "" }],
	name: "",
	phone: "",
	street: "",
};

interface IAgenciesFormProps {
	type: string;
	from?: string;
}

export const AgenciesForm: React.FC<IAgenciesFormProps> = ({ type, from }) => {
	const userInfos = SessionService.getUser();

	const fetchAgencies = () => {
		return from && userInfos.agencies?.length
			? Promise.resolve(userInfos.agencies)
			: AgenciesService.getAgencies();
	};

	const { data } = useQuery(["agencies"], () => fetchAgencies());

	return (
		<Formik initialValues={ForgotLoginInitValues} onSubmit={() => {}}>
			{({ values, setValues }) => {
				return (
					<>
						<Select
							options={data}
							onChange={(option: IAgency) => {
								setValues({
									...values,
									...option,
									locations: option.locations || [
										{
											name: option.city,
											postalCode: option.postalCode,
										},
									],
								});
							}}
							name="agencyId"
							label={
								!values.name
									? t("forgotId.selectAgency")
									: t("forgotId.agency")
							}
							value={
								data && values.name
									? data.find(
											(option) =>
												option.name === values.name
									  )
									: null
							}
							getOptionLabel={(option: IAgency) => option.name}
							getOptionValue={(option: IAgency) => option.id}
						/>
						{values.name ? (
							<AgenciesCard values={values} />
						) : type === "forgotId" ? (
							<SVG
								src={SuccessPassword}
								className="w-full !h-[30vh] mt-l min-h-[200px]"
							/>
						) : (
							<SVG
								src={ContactAgencies}
								className="w-full !h-[30vh] min-h-[200px] mt-xl"
							/>
						)}
					</>
				);
			}}
		</Formik>
	);
};
