import React from "react";
import { ToastContainer } from "react-toastify";

import "./App.scss";
import "react-toastify/dist/ReactToastify.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Routes
import { IRoute, privateRoutes, publicRoutes } from "./routes";

import { AppProvider } from "./store/AppContext";
import TokenService from "./services/TokenService";

import { AdminLayout } from "./components/templates/AdminLayout/AdminLayout";
import { AdminLayoutContextProvider } from "./contexts/AdminLayoutContext/AdminLayoutContext";
import PageWrapper from "./components/templates/PageWrapper/PageWrapper";
import IdleModal from "./components/atoms/IdleModal/IdleModal";
import OfflineModal from "./components/atoms/OfflineModal/OfflineModal";
import { useSettings } from "./contexts/SettingsContext/SettingsContext";

const App: React.FC = () => {
	const { token, initied, isOnboarding } = TokenService.getUserTokens();
	const { isDev, getSettings } = useSettings();

	const genRoute = (route: IRoute, Wrapper?, parent?) => {
		if (!route.path) return null;

		if (
			route.disabled ||
			(!isDev && route.devOnly) ||
			(route.devFeatureName &&
				getSettings(route.devFeatureName, true) !== true)
		)
			return null;

		if (typeof route.path === "string") {
			if (Wrapper)
				return (
					<Route
						key={route.path}
						{...route}
						path={route.path}
						element={<Wrapper route={route} parentRoute={parent} />}
					/>
				);
			return <Route key={route.path} {...route} path={route.path} />;
		}

		if (Array.isArray(route.path)) {
			return route.path.map((path) =>
				Wrapper ? (
					<Route
						key={path}
						{...route}
						path={path}
						element={<Wrapper route={route} parentRoute={parent} />}
					/>
				) : (
					<Route key={path} path={path} element={route.element} />
				)
			);
		}
	};

	return (
		<AppProvider>
			<div
				id="App"
				className={`App text-black dark:text-white dark:bg-neutral-800 overflow-auto`}
			>
				<OfflineModal />
				<Router>
					{!token?.length || !initied || isOnboarding ? (
						<Routes>
							{publicRoutes.map((route) =>
								genRoute(route, PageWrapper)
							)}
						</Routes>
					) : (
						<AdminLayoutContextProvider>
							<IdleModal />
							<AdminLayout>
								<Routes>
									{privateRoutes.map((route) => {
										if (route.childrens?.length)
											return route.childrens.map(
												(child) =>
													genRoute(
														child,
														PageWrapper,
														route
													)
											);
										return genRoute(route, PageWrapper);
									})}
								</Routes>
							</AdminLayout>
						</AdminLayoutContextProvider>
					)}
				</Router>
				<ToastContainer
					hideProgressBar
					pauseOnHover={false}
					closeOnClick
					limit={3}
				/>
			</div>
		</AppProvider>
	);
};

export default App;
