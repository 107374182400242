import React, { createContext, useState } from "react";

export interface AdminLayoutContextType {
	sideBox: React.ReactNode | boolean;
	toggleSideBox: () => void;
	setSideBox: (sideBox: React.ReactNode | boolean) => void;
	scrollable: boolean;
	setScrollable: (scrollable: boolean) => void;
}

export interface AdminLayoutCtxProps {
	children: React.ReactNode;
}

export const AdminLayoutContext = createContext<AdminLayoutContextType>({
	sideBox: false,
	toggleSideBox: () => {},
	setSideBox: () => {},
	scrollable: true,
	setScrollable: () => {},
});

export const AdminLayoutContextProvider: React.FC<AdminLayoutCtxProps> = ({
	children,
}) => {
	const [SideBox, setSideBox] = useState<React.ReactNode | boolean>(false);
	const [Scrollable, setScrollable] = useState<boolean>(true);

	const toggleSideBox = () => {
		setSideBox(!SideBox);
	};

	const value = {
		sideBox: SideBox,
		toggleSideBox,
		setSideBox,
		scrollable: Scrollable,
		setScrollable,
	};

	return (
		<AdminLayoutContext.Provider value={value}>
			{children}
		</AdminLayoutContext.Provider>
	);
};
