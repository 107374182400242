import * as Yup from "yup";
import { DATE_REGEX } from "../../../utils/regex";
import { IDocsProofType } from "../../../services/DocumentsService";

export interface IAddedDoc {
	type: string;
	effectiveDate: string;
	expirationDate: string;
	reference: string;
	files?: any[];
}

export const AddDocValidationInitialValues: IAddedDoc = {
	type: "",
	effectiveDate: "", // should have a value,
	expirationDate: "", // should have a value,
	files: [], // files must have at least one file
	reference: "",
};

export const AddDocValidationSchema = (t, type: IDocsProofType | null) => {
	const res = Yup.object().shape({
		type: Yup.object().required("Type is required"),

		effectiveDate:
			type?.effectiveDateRule === 2
				? Yup.string()
						// test if birthdate is valid
						.test(
							"effectiveDate",
							t("dates.errors.invalid"),
							(value) => (value ? DATE_REGEX.test(value) : true)
						)
						// test if birthdate is not in the future
						.test(
							"effectiveDate",
							t("dates.errors.futureDate"),
							(value) =>
								value
									? new Date(
											value.split("/").reverse().join("/") // reformate the date to US format
									  ) <= new Date()
									: true
						)
						.required(t("dates.errors.required"))
				: Yup.string().nullable(),

		expirationDate:
			type?.expirationDateRule === 2
				? Yup.string()
						// test if expirationDate is valid and not inferior to effectiveDate of the form
						.test(
							"expirationDate",
							t("dates.errors.invalid"),
							(value) => (value ? DATE_REGEX.test(value) : true)
						)
						// test if expirationDate is not inferior at current date
						.test(
							"expirationDate",
							t("dates.errors.inferiorDate"),
							(value) =>
								value
									? new Date(
											value.split("/").reverse().join("/") // reformate the date to US format
									  ) >= new Date()
									: true
						)
						.required(t("dates.errors.required"))
				: Yup.string().nullable(),

		reference:
			type?.referenceRule === 2
				? Yup.string().required(t("addDocModal.errors.emptyReference"))
				: Yup.string().nullable(),

		files: type?.attachmentsRule
			? Yup.array().min(1, "At least one file is required")
			: Yup.array().nullable(),
	});

	return res;
};
