import React from "react";
import { Button, Picto } from "@zolteam/react-ras-library";
import { t } from "i18next";
import moment from "moment";
export interface IContract {
	id: string;
	isSignedByWorker: boolean;
	isCancelled: boolean;
	jobTitle: string;
	clientName: string;
	startDate: string;
	finishDate: string;
}

const Contract: React.FC<IContract> = (props) => {
	const { jobTitle, startDate, finishDate, clientName, isSignedByWorker } =
		props;

	return (
		<>
			<div className="flex flex-col sm:flex-row items-start sm:items-center gap-0 sm:gap-xl">
				<div className="min-w-[20vw]">
					<div className="flex flex-col  gap-s">
						<div className="flex gap-s items-center">
							{!isSignedByWorker && (
								<Picto
									icon="circle"
									className="text-accent-500"
								/>
							)}
							<h2 className={"font-bold !leading-4 text-lg "}>
								{jobTitle}
							</h2>
						</div>
						<p className="text-sm text-neutral-600">
							{`Du ${moment(startDate).format(
								"DD-MM-YYYY"
							)} au ${moment(finishDate).format("DD-MM-YYYY")}`}
						</p>
					</div>
				</div>
				<div className="text-xs mt-2 sm:mt-0 sm:text-sm">
					{clientName}
				</div>
			</div>
			<div>
				<Button
					type="button"
					color={isSignedByWorker ? "transparent" : "primary"}
					className="ml-auto w-[15px] h-[25px] lg:h-full lg:w-full"
				>
					{isSignedByWorker ? (
						<Picto
							icon={"eye"}
							style={{ width: "24px", height: "24px" }}
							className={
								isSignedByWorker
									? "[&>g]:!text-primary-500 !mr-0"
									: ""
							}
						/>
					) : (
						<span className="">{t("contracts.signBtn")}</span>
					)}
				</Button>
			</div>
		</>
	);
};

export default Contract;
