import React from "react";
import { InfoMessage, Button, Picto } from "@zolteam/react-ras-library";
import { Formik, Form } from "formik";
import Field from "../../components/molecules/Field/Field";
import {
	BankInfosFormInitValues,
	BankInfosFormValidation,
} from "./bankInfosValidation";
import { t } from "i18next";
import BankInfosService from "../../services/BankInfosService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ConfirmModal from "../../components/molecules/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import Loader from "../../components/atoms/Loader/Loader";
import FileImporter from "../../components/molecules/FileImporter/FileImporter";
import { urlToArrayBuffer } from "../../utils/blob";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const BankInfosForm: React.FC = () => {
	const formRef = React.useRef(null);
	const [ShowDeleteModal, setShowDeleteModal] = React.useState(false);

	const [IsSending, setIsSending] = React.useState(false);
	const queryClient = useQueryClient();

	const submitForm = (values) => {
		setIsSending(true);

		const datas = {
			iban: values.iban.replace(/\s/g, ""),
			bic: values.bic.replace(/\s/g, ""),
			rib: values.file,
		};

		const prom = BankInfosService.update(datas).then(
			() => {
				pushDataLayer({
					dataLayer: {
						event: "info_bancaires__ajout_reussi",
					},
				});
				setIsSending(false);
				queryClient.resetQueries(["bankInfos"]);
			},
			(err) => {
				setIsSending(false);
				throw err;
			}
		);
		toast.promise(prom, {
			pending: t("bankInfos.updatePending"),
			success: t("bankInfos.updateSuccess").toString(),
			error: {
				render: (err) => {
					let error = err?.data?.response?.data?.message;
					if (error) return t("bankInfos.error." + error);
					else return t("bankInfos.error.updateError");
				},
			},
		});
		return prom;
	};

	const hasBankInfos = (values) => {
		if (!values) return false;
		return (
			values.bic?.length > 0 ||
			values.iban?.length > 0 ||
			values.rib?.uuid?.length > 0 ||
			values.file?.byteLength > 0
		);
	};

	const removeBankInfos = (setValues, resetForm) => {
		const prom = BankInfosService.delete().then(() => {
			queryClient.resetQueries(["bankInfos"]);
			resetForm({
				values: BankInfosFormInitValues,
			});
			setValues(BankInfosFormInitValues);
			setShowDeleteModal(false);
		});
		toast.promise(prom, {
			pending: t("bankInfos.deletePending"),
			success: t("bankInfos.deleteSuccess").toString(),
			error: t("bankInfos.deleteError"),
		});
		return prom;
	};

	const fetchBanInfos = () =>
		BankInfosService.fetch().then((resp) => {
			let values = {
				...resp,
				extension: resp.rib?.name?.split(".")?.pop(),
			};

			if (resp.rib?.link)
				return urlToArrayBuffer(resp.rib.link).then((res) => {
					values.file = res;
					return values;
				});

			return resp;
		});

	const { isLoading, data, error } = useQuery(["bankInfos"], fetchBanInfos, {
		refetchOnWindowFocus: false,
	});

	if (isLoading) return <Loader />;

	return (
		<Formik
			initialValues={{ ...BankInfosFormInitValues, ...data }}
			validationSchema={BankInfosFormValidation(t)}
			onSubmit={(values) => submitForm(values)}
			validateOnMount={true}
			innerRef={formRef}
		>
			{({ values, setValues, isValid, dirty, resetForm }) => {
				return (
					<Form className="flex flex-col h-full">
						{ShowDeleteModal && (
							<ConfirmModal
								title={t("bankInfos.deleteInfosTitle")}
								text={t("bankInfos.deleteConfirm")}
								confirmText={t(
									"global.confirmDelete"
								).toString()}
								isOpen={ShowDeleteModal}
								onClose={() => setShowDeleteModal(false)}
								onConfirm={() =>
									removeBankInfos(setValues, resetForm)
								}
							/>
						)}
						<div className="flex flex-col justify-start gap-m">
							<div>
								<InfoMessage color="primary" withIcon>
									{t("bankInfos.infoMessage")}
								</InfoMessage>
							</div>
							<FileImporter
								name="rib"
								fileName={"RIB." + values.extension}
								disabled={data?.isPaymentPeriod}
								file={values.file}
								accept=".pdf,.png,.jpg,.jpeg"
								onChange={(rib) => {
									setValues({
										...values,
										rib: rib,
										file: rib,
									});
								}}
								onCompress={(percent) => {
									setValues({
										...values,
										compressing:
											percent < 100 ? true : false,
									});
								}}
								onRemove={() => {
									setValues({
										...values,
										file: new ArrayBuffer(0),
										rib: {
											folder: "",
											link: "",
											name: "",
											uuid: "",
										},
									});
								}}
							/>
							<Field
								type="text"
								name="iban"
								label={t("bankInfos.IBAN")}
								className={
									values.compressing || data.isPaymentPeriod
										? "opacity-70 pointer-events-none !cursor-not-allowed"
										: ""
								}
								readOnly={
									values.compressing || data?.iban?.length > 0
								}
							/>
							<Field
								type="text"
								name="bic"
								label={t("bankInfos.BIC")}
								className={
									values.compressing || data.isPaymentPeriod
										? "opacity-70 pointer-events-none !cursor-not-allowed"
										: ""
								}
								readOnly={
									values.compressing || data?.bic?.length > 0
								}
							/>
						</div>

						<div className="flex  mt-m justify-between items-center !text-neutral-400">
							<Button
								type="button"
								color="transparent"
								disabled={
									values.compressing ||
									data?.isPaymentPeriod ||
									!hasBankInfos(values)
								}
								className={
									values.compressing ||
									data?.isPaymentPeriod ||
									!hasBankInfos(values)
										? "!text-neutral-400"
										: "!text-primary-500"
								}
								onClick={() => setShowDeleteModal(true)}
							>
								<Picto
									icon="trash"
									className={
										values.compressing ||
										data?.isPaymentPeriod ||
										!hasBankInfos(values)
											? "!stroke-neutral-400"
											: "!stroke-primary-500"
									}
								/>
								<p className="mt-1">
									{t("bankInfos.deleteInfos")}
								</p>
							</Button>

							{dirty && (
								<Button
									type="submit"
									isLoading={IsSending}
									disabled={!isValid || IsSending}
									color={isValid ? "primary" : "grey"}
								>
									{t("global.save")}
								</Button>
							)}
						</div>

						{(error !== null || data.isPaymentPeriod) && (
							<div className="max-w-[400px]">
								<InfoMessage
									className="mt-auto"
									color="warning"
									withIcon
								>
									{t("bankInfos.error.isPaymentPeriod")}
								</InfoMessage>
							</div>
						)}
					</Form>
				);
			}}
		</Formik>
	);
};

export default BankInfosForm;
