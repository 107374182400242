import api from "./api";

export interface IBankInfos {
	rib: string;
	iban: string;
	bic: string;
}

class BankInfosServiceClass {
	fetch() {
		return api
			.get("api-360/bank-information")
			.then((response) => response.data);
	}

	update(data: IBankInfos) {
		return api
			.post("api-360/bank-information/upload", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => response.data);
	}

	delete() {
		return api.delete("api-360/bank-information");
	}
}

const BankInfosService = new BankInfosServiceClass();
export default BankInfosService;
