import React, { useContext, useRef } from "react";
import { Form, Formik } from "formik";
import InputSearch from "../../components/molecules/InputSearch/InputSearch";
import { t } from "i18next";
import { FiltersContext } from "../../contexts/FiltersContext";
import { useQueryClient } from "@tanstack/react-query";
import { InfoMessage } from "@zolteam/react-ras-library";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

interface IOffersFormProps {
	queryKey?: string[];
}

const OffersForm: React.FC<IOffersFormProps> = ({ queryKey }) => {
	const { setFilter } = useContext(FiltersContext);
	const queryClient = useQueryClient();
	const timeoutId = useRef<any>(null);
	const promiseOptions = (inputValue: string) => {
		if (timeoutId.current) clearTimeout(timeoutId.current);
		timeoutId.current = setTimeout(() => {
			pushDataLayer({
				dataLayer: {
					event: "liste_offres__recherche",
					lieux: inputValue,
				},
			});
		}, 3500);
		if ((queryKey && inputValue.length >= 2) || inputValue.length === 0) {
			queryClient.removeQueries(queryKey);
		}
		setFilter("searchInput", inputValue);
	};
	return (
		<Formik initialValues={{ search: "" }} onSubmit={() => {}}>
			<Form>
				<div className="flex flex-col w-fudll justify-betwdeen itedms-center gap-m">
					<div className="[&>div]:mt-0">
						<InfoMessage withIcon>
							{t("offers.filtersMessage")}
						</InfoMessage>
					</div>
					<InputSearch
						label={t("offers.searchOffers.label")}
						name="search"
						type="text"
						className="max-w-[400px] min-w-[200px]"
						promiseOptions={promiseOptions}
					/>
				</div>
			</Form>
		</Formik>
	);
};

export default OffersForm;
