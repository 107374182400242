import React, { useContext } from "react";
import { BoxContent } from "@zolteam/react-ras-library";

import OutsideClickHandler from "react-outside-click-handler";
// Components
import Menu from "../../organisms/Menu/Menu";
import Header from "../../organisms/Header/Header";

// Styles
import "./AdminLayout.scss";

import { IRoute, menuRoutes } from "../../../routes";
import {
	AdminLayoutContext,
	AdminLayoutContextType,
} from "../../../contexts/AdminLayoutContext/AdminLayoutContext";
import cn from "../../../utils/cn";
import Footer from "../../organisms/Footer/Footer";

interface IAdminLayout {
	children: React.ReactNode;
}

export const AdminLayout: React.FC<IAdminLayout> = ({ children }) => {
	const [IsMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

	const { sideBox, scrollable } =
		useContext<AdminLayoutContextType>(AdminLayoutContext);

	React.useLayoutEffect(() => {
		const handleResize = () => {
			if (IsMenuOpen) setIsMenuOpen(false);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [IsMenuOpen]);

	const handleItemClick = (item: IRoute, hasParent?: IRoute) => {
		if (!hasParent) setIsMenuOpen(false);
	};

	const handleMenuToggle = () => {
		setIsMenuOpen(!IsMenuOpen);
	};

	return (
		<div className={cn(["AdminLayout", IsMenuOpen && "menu-open"])}>
			<div className={"layout_header"}>
				<Header toggleMenu={handleMenuToggle} isMenuOpen={IsMenuOpen} />
			</div>
			<div
				className={cn([
					"layout-container",
					scrollable === false && "overflow-hidden",
				])}
			>
				<div className={"menu z-0"}>
					<OutsideClickHandler
						useCapture={true}
						onOutsideClick={(e) => {
							let target = e.target;
							if (
								target?.className?.indexOf &&
								target.className.indexOf("burgerMenuButton") >=
									0
							)
								return;
							setIsMenuOpen(false);
						}}
					>
						<Menu
							items={menuRoutes}
							onItemClick={handleItemClick}
							isOpen={IsMenuOpen}
						/>
					</OutsideClickHandler>
				</div>
				<BoxContent
					boxShadow
					borderTopLeft
					borderTopRight={sideBox !== false}
					backgroundColor="white"
					className={cn([
						"boxContent min-w-[90svw] sm:min-w-0 duration-500 z-1",
						IsMenuOpen && "min-w-[100%] ",
					])}
				>
					{children}
					<Footer />
				</BoxContent>
				{sideBox !== false && (
					<BoxContent
						boxShadow
						borderTopLeft
						backgroundColor="white"
						className={"boxContent sideBox lg:!block ml-6"}
					>
						{sideBox}
					</BoxContent>
				)}
			</div>
		</div>
	);
};
