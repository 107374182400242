import React, { useState } from "react";
import TokenService from "../../services/TokenService";
import Login from "../Login/Login";
import { Title, InfoMessage } from "@zolteam/react-ras-library";
import ContractsIcon from "../../assets/illustrations/contract.svg";
import PayslipsIcon from "../../assets/illustrations/payslips.svg";
import MessageIcon from "../../assets/illustrations/email.svg";

import SearchIcon from "../../assets/illustrations/search.svg";
import TruckPicto from "../../assets/icons/truck.svg";
import IDCardPicto from "../../assets/illustrations/IDCard.svg";
import proofOfAddressPicto from "../../assets/illustrations/cvs.svg";
import AdvancePaymentIcon from "../../assets/illustrations/acompteEuro.svg";

import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import SessionService from "../../services/SessionService";
import HomeTile from "../../components/molecules/HomeTile/HomeTile";
import DownloadAppButton from "../../components/atoms/DownloadAppButton/DownloadAppButton";
import SVG from "../../components/atoms/SVG/SVG";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import { Trans } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import DocumentsService from "../../services/DocumentsService";
import moment from "moment";
import { addMonths, parseISO } from "date-fns";
import HappyNewYear from "../../components/atoms/HappyNewYear/HappyNewYear";

import cn from "../../utils/cn";

interface IDocIdentity {
	expirationDate: string;
	commonDocumentName: string;
}

const DriversMessage: React.FC = (props) => {
	return (
		<div className="flex flex-wrap items-center xs:flex-nowrap sm:flex-nowrap lg:flex-wrap xl:flex-nowrap gap-m">
			<div className="flex items-center gap-m">
				<SVG src={TruckPicto} className="min-w-[30px]" />
				<div className="leading-4 text-neutral-500">
					{t("home.driverText")}
					<br />
					{t("home.driverText2")}
				</div>
			</div>
			<DownloadAppButton origin="accueil" />
		</div>
	);
};

const Welcome: React.FC = (props) => {
	const [isExpired, setIsExpired] = useState(false);
	const [willExpire, setWillExpire] = useState(false);
	const [dateExpired, setDateExpired] = useState("");
	const [error, setError] = useState("");
	const user = SessionService.getUser();
	const identityDocs = 1;

	useQuery(
		["getWorkerDocuments"],
		() => DocumentsService.getAllUserDocuments(identityDocs),
		{
			onSuccess: (data) => {
				return data?.unifiedTempWorkerDocument
					?.filter((doc: IDocIdentity) => {
						return (
							doc.commonDocumentName ===
							t("identityDocs.residencePermit.name")
						);
					})
					.map((doc: IDocIdentity) => {
						let docExpiration = doc.expirationDate;
						if (!docExpiration) return doc;
						const today = new Date();
						const todayMinusTwoMonths = addMonths(today, 2);
						setIsExpired(parseISO(docExpiration) < today);
						setWillExpire(
							parseISO(docExpiration) < todayMinusTwoMonths
						);
						setDateExpired(
							moment(docExpiration).format(
								t("dates.formatWithMonth")?.toString()
							)
						);
						return doc;
					});
			},
			onError: (err) => setError(t(err?.response?.data?.message)),
		}
	);

	return (
		<>
			<Title tag="h1" className="!leading-7 mb-4">
				{t("global.welcomeHome")}, {user.firstName}&nbsp;
				{user.lastName}&nbsp;!
			</Title>
			<HappyNewYear />
			{error && (
				<InfoMessage color="error" withIcon>
					{error}
				</InfoMessage>
			)}
			{(willExpire || isExpired) && (
				<div className="mb-m">
					<InfoMessage color="error" withIcon>
						{isExpired ? (
							<Trans
								i18nKey={"identityDocs.residencePermit.expired"}
							/>
						) : (
							<Trans
								i18nKey={
									"identityDocs.residencePermit.infoMessage"
								}
								values={{
									dateExpired,
								}}
							/>
						)}
					</InfoMessage>
				</div>
			)}
		</>
	);
};

interface IHomeTile {
	title?: string;
	text?: string;
	image?: any;
	imageClassName?: string;
	className?: string;
	onClick?: () => void;
	children?: React.ReactNode;
}

const Home: React.FC = (props) => {
	const { token, initied } = TokenService.getUserTokens();
	const navigate = useNavigate();

	const handleTileClick = (tile) => {
		pushDataLayer({
			dataLayer: {
				event: "menu_central",
				item: tile.title,
			},
		});

		if (tile.onClick) tile.onClick();
	};

	const tiles: IHomeTile[] = [
		{
			title: t("contracts.tileTitle"),
			text: t("contracts.description"),
			image: ContractsIcon,
			onClick: () => navigate("/contracts"),
		},
		{
			title: t("payslips.title"),
			text: t("payslips.description"),
			image: PayslipsIcon,
			onClick: () => navigate("/payslips"),
		},
		{
			title: t("advancePayment.tileTitle"),
			text: t("advancePayment.description"),
			image: AdvancePaymentIcon,
			imageClassName: "!-right-10 -bottom-14",
			onClick: () => navigate("/advance-payment"),
		},
		{
			title: t("absences.title"),
			text: t("absences.description"),
			image: MessageIcon,
			onClick: () => navigate("/absences"),
		},
		{
			title: t("personalInfos.tileTitle"),
			text: t("personalInfos.myInfosDescription"),
			image: IDCardPicto,
			onClick: () => navigate("/personal-informations"),
		},
		{
			title: t("personalInfos.myDocsTileTitle"),
			text: t("personalInfos.myDocsDescription"),
			image: proofOfAddressPicto,
			onClick: () => navigate("/identity-docs"),
		},
	];

	const OfferTile = {
		title: t("offers.findIt"),
		text: t("offers.discover"),
		image: SearchIcon,
		onClick: () => navigate("/offers"),
	};

	if (!token?.length || !initied) return <Login />;

	return (
		<div className="Home flex flex-col items-center mx-auto w-full max-w-[700px]">
			<Welcome />
			<div className="flex w-full">
				<HomeTile
					className="w-full text-xl text-white bg-primary-600 hover:bg-primary-800 "
					{...OfferTile}
					onClick={() =>
						handleTileClick({
							...OfferTile,
							title: "Offres d'emploi",
						})
					}
				>
					<p
						color="white"
						className={`w-full xs:w-fit
							py-1 px-4 mt-4
							!leading-4 font-semibold
							text-primary-500 text-sm bg-white text-center
							rounded-40 border-2 border-transparent
							hover:!border-white hover:!bg-primary-800 hover:text-white duration-500`}
					>
						{t("offers.applyNow")}
					</p>
				</HomeTile>
			</div>
			<div className="flex flex-wrap w-full my-12 gap-l">
				{tiles.map((tile, index) => {
					return (
						<HomeTile
							key={index}
							{...tile}
							onClick={() => handleTileClick(tile)}
							imageClassName={cn([
								"w-48 h-48 -bottom-16 !-right-14",
								tile.imageClassName,
							])}
						/>
					);
				})}
			</div>
			<DriversMessage />
		</div>
	);
};

export default Home;
