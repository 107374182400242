import React, { useState } from "react";
import { t } from "i18next";
import { Text, InfoMessage } from "@zolteam/react-ras-library";
import Field from "../../components/molecules/Field/Field";
import { Form, Formik } from "formik";
import Spinner from "../../components/atoms/Spinner/Spinner";
import cn from "../../utils/cn";
import { promiseToast } from "../../toastify/toastify";
import CountDown from "../../components/atoms/CountDown/CountDown";
import { Trans } from "react-i18next";
import RegisterService from "../../services/RegisterService";
import SessionService from "../../services/SessionService";
import { OnboardingRedirect } from "./Onboarding";

interface IProps {
	phoneNumber: string;
}

const OnboardingPhoneVerification: React.FC<IProps> = ({ phoneNumber }) => {
	const { uniqueId } = SessionService.getUser();
	const [resendTimer, setResendTimer] = useState(false);
	const [sendingState, setSendingState] = useState({
		loading: false,
		error: "",
	});

	const reSendCode = () => {
		setSendingState({
			loading: true,
			error: "",
		});
		const sendProm = RegisterService.sendMediaSecurityToken({
			uniqueId,
			phone: { value: phoneNumber },
		}).catch((err) => {
			setSendingState({
				loading: false,
				error: err.response.data.message,
			});
			throw err;
		});
		promiseToast(
			sendProm,
			{
				pending: t("initRegister.codeSend").toString(),
			},
			{
				toastId: "sendPhoneCode",
			}
		);
		setResendTimer(true);
		return sendProm;
	};

	const checkVerificationCode = (code) => {
		setSendingState({ loading: true, error: "" });
		const prom = RegisterService.putVerifySecurityToken({
			uniqueId,
			securityToken: code,
			phone: { value: phoneNumber },
		}).then(
			(res) => {
				setSendingState({ loading: false, error: "" });
				OnboardingRedirect();
				return res;
			},
			(err) => {
				setSendingState({
					loading: false,
					error: err.response.data.message,
				});
				throw err;
			}
		);
		promiseToast(prom);
		return prom;
	};

	return (
		<>
			<Text tag="p" size="paragraph01" className="mb-8">
				<Trans
					i18nKey="registerCode.phoneInfo"
					values={{
						phone: phoneNumber,
					}}
				/>
				<br />
				{t("signup.emailVerification.notReceived")}&nbsp;
				<button
					className={cn([
						"text-primary-500 font-bold",
						resendTimer && "opacity-70",
					])}
					disabled={resendTimer}
					onClick={resendTimer ? undefined : reSendCode}
				>
					{t("signup.emailVerification.resend")}
					{resendTimer && (
						<CountDown
							time={3}
							text={(time) => ` (${time})`}
							handleEnd={() => setResendTimer(false)}
						/>
					)}
				</button>
			</Text>
			<Formik
				initialValues={{
					activationCode: "",
				}}
				validateOnChange
				validateOnBlur={false}
				onSubmit={() => {
					// prop is mendatory but the submission is triggered by the validation
					// when the code reaches 4 characters
				}}
				validate={({ activationCode }) => {
					if (activationCode?.length === 4) {
						checkVerificationCode(activationCode);
					}
				}}
			>
				{() => {
					const isLoading = sendingState.loading;
					return (
						<Form>
							<div className="flex items-center justify-center gap-4">
								<Field
									name="activationCode"
									className={cn([
										"w-[200px] [&>div]:!rounded-2xl",
										"[&>*>input]:!pl-[1.2rem] [&>*>input]:!tracking-[0.4rem] [&>*>input]:!p-[10px] [&>*>input]:!text-center [&>*>input]:!text-2xl",
										isLoading &&
											"[&>*>input]:!text-neutral-500",
									])}
									type="text"
									label=""
									maxLength={4}
									readOnly={isLoading}
								/>
								{isLoading && <Spinner />}
							</div>
						</Form>
					);
				}}
			</Formik>
			{!!sendingState.error?.length && (
				<div className="[&>div]:!mt-4 [&>div]:w-full ">
					<InfoMessage withIcon color="error">
						{sendingState.error &&
							t(`API_ERRORS.${sendingState.error}`)}
					</InfoMessage>
				</div>
			)}
		</>
	);
};

export default OnboardingPhoneVerification;
