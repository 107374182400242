import { t } from "i18next";

interface ISalaryFormat {
	[key: string]: string;
}

const getShortSalaryType = (salaryType: string) => {
	const salaryTypeMap: ISalaryFormat = {
		Annuel: "/ an",
		Horaire: "/ heure",
		Mensuel: "/ mois",
	};

	return salaryTypeMap[salaryType] || "";
};
const getSalaryFormat = (
	amount: number,
	salary_min: number,
	salary_max: number,
	salary_type: string
) => {
	if (amount === 0) {
		return "";
	}

	if (amount > 0) {
		return t("offers.searchOffers.salaryBrut", {
			salary: amount,
			salaryType: getShortSalaryType(salary_type),
		});
	}

	if (salary_min > 0 && salary_max) {
		return t("offers.searchOffers.salaryRange", {
			salaryMin: salary_min,
			salaryMax: salary_max,
			salaryType: getShortSalaryType(salary_type),
		});
	}

	if (!salary_min && salary_max) {
		return t("offers.searchOffers.salaryMax", {
			salaryMax: salary_max,
			salaryType: getShortSalaryType(salary_type),
		});
	}

	if (!salary_max && salary_min) {
		return t("offers.searchOffers.salaryMin", {
			salaryMin: salary_min,
			salaryType: getShortSalaryType(salary_type),
		});
	}

	return "";
};

export default getSalaryFormat;
