import api from "./api";

class RetrieveIdServiceClass {
	postRetrieveId({ value, type }: { value: string; type: string }) {
		return api.post("/api-360/worker/public/retrieve-id", { value, type });
	}
}

const RetrieveIdService = new RetrieveIdServiceClass();
export default RetrieveIdService;
