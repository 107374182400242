import React, { useState } from "react";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import { BackLink, InfoMessage, Title } from "@zolteam/react-ras-library";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ResetPasswordForm } from "../../forms/ResetPasswordForm/ResetPasswordForm";
import RegisterService from "../../services/RegisterService";
import AuthService from "../../services/AuthService";
import { promiseToast } from "../../toastify/toastify";
import LoginIdService from "../../services/LoginIdService";
import SessionService from "../../services/SessionService";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const SignUpPassword: React.FC = () => {
	const { state } = useLocation();
	const navigate = useNavigate();

	const [queryState, setQueryState] = useState<any>({
		isLoading: false,
		success: false,
		error: "",
	});

	const handleSubmitForm = ({
		password,
	}: {
		password: string;
		activationCode?: string;
	}) => {
		const { uniqueId, activationCode } = state;

		pushDataLayer({
			dataLayer: {
				event: "inscription__valider_mdp",
			},
		});

		setQueryState({
			isLoading: true,
			success: false,
			error: "",
		});
		let prom = new Promise((resolve, reject) => {
			return RegisterService.postRegisterPassword(
				password,
				uniqueId,
				activationCode
			)
				.then(
					(res) => {
						return AuthService.login(uniqueId, password, {
							isOnboarding: true,
						}).then(
							(resp) => {
								let prom = new Promise((resolve, reject) => {
									LoginIdService.setId(uniqueId);

									SessionService.fetchMe().then((res) => {
										SessionService.setUser(res);

										navigate("/onboarding", {
											state: {
												...state,
												...resp,
												isOnboarding: true,
											},
										});

										resolve(res);
									}, reject);
								});
								return prom;
							},
							(err) => {
								console.error(err);
							}
						);
					},
					(err) => {
						const error = t(
							"API_ERRORS." + err.response.data.message
						).toString();
						setQueryState({
							isLoading: false,
							success: false,
							error: error,
						});
						reject(new Error(error));
						throw err;
					}
				)
				.then(
					(res) => {
						setQueryState({
							isLoading: false,
							success: true,
							error: "",
						});
						resolve(res);
					},
					(err) => {
						setQueryState({
							isLoading: false,
							success: false,
							error: t(
								"API_ERRORS." + err.response.data.message
							).toString(),
						});
						reject(err);
					}
				);
		});
		promiseToast(prom);
		return prom;
	};

	if (!state?.uniqueId) {
		navigate("/signup");
		return;
	}

	const handleBackNavigation = () => navigate("/signup", { state: state });

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" size="heading01" className="!leading-9 mt-8">
				{t("passwordRegister.password")}
			</Title>
			<ResetPasswordForm
				load={queryState.isLoading}
				handleSubmitForm={handleSubmitForm}
			/>
			{!!queryState.error?.length && (
				<div className="[&>div]:w-full">
					<InfoMessage color="error" withIcon>
						{queryState.error}
					</InfoMessage>
				</div>
			)}
		</AuthLayout>
	);
};

export default SignUpPassword;
