import api from "./api";

class AvailabilitiesServiceClass {
	getAvailabilities() {
		return api.get("/api-360/worker/availabilities").then((response) => {
			return response.data;
		});
	}

	putAvailabilities(availabilities) {
		return api
			.put("/api-360/worker/availabilities/update", availabilities)
			.then((response) => {
				return response.data;
			});
	}
}

const AvailabilitiesService = new AvailabilitiesServiceClass();
export default AvailabilitiesService;
