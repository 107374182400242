import React, { useEffect, useCallback } from "react";
import { BackLink, Button, Picto, Tooltip } from "@zolteam/react-ras-library";
import ZoomPlus from "./assets/zoom_plus.svg";
import ZoomMinus from "./assets/zoom_minus.svg";
import { t } from "i18next";
import SVG from "../../atoms/SVG/SVG";
import cn from "../../../utils/cn";
import "./ViewerNavigation.scss";

interface IViewerNavigationProps {
	isLoading: boolean;
	Zoom: number;
	onZoom: (zoom: number) => void;
	download: boolean;
	onDownload: () => void;
	onBack: () => void;
	disabled: boolean;
	className?: string;
	// the event is passed as an argument not in a callback
	scrollEvent: any;
}

const zoomConfig = {
	min: 0.1,
	max: 4,
	step: 0.1,
};

const ViewerNavigation: React.FC<IViewerNavigationProps> = ({
	isLoading,
	Zoom = 1,
	onZoom,
	download,
	onDownload,
	onBack,
	disabled = false,
	className,
	scrollEvent,
}) => {
	// function to handle the zoom
	const handleZoom = useCallback(
		(type: string) => {
			if (type === "in" && Zoom < zoomConfig.max) {
				onZoom(parseFloat((Zoom + zoomConfig.step).toFixed(2)));
			} else if (type === "out" && Zoom > zoomConfig.min) {
				onZoom(parseFloat((Zoom - zoomConfig.step).toFixed(2)));
			}
		},
		[Zoom, onZoom]
	);

	useEffect(() => {
		if (!scrollEvent) return;
		if (scrollEvent.altKey) {
			scrollEvent.preventDefault();
			handleZoom(scrollEvent.deltaY > 0 ? "in" : "out");
		}
	}, [scrollEvent, handleZoom]);

	return (
		<div
			className={cn([
				"ViewerNavigation",
				"flex justify-between items-center p-l",
				className,
			])}
		>
			<BackLink
				className="[&>*]:!text-primary-500 [&>span]:!pr-2"
				onClick={onBack}
			>
				{t("global.back")}
			</BackLink>

			<div className="flex gap-m ">
				<Button
					className="zoomBtn"
					color="transparent"
					type="button"
					disabled={isLoading || Zoom === zoomConfig.min}
					onClick={() => handleZoom("out")}
				>
					<SVG src={ZoomMinus} className="!h-5" />
				</Button>
				<Tooltip
					content={t("PDFViewer.resetZoom")}
					placement={"bottom"}
				>
					<Button
						color="transparent"
						type="button"
						className="!text-neutral-500 min-w-[90px]"
						onClick={() => onZoom(1)}
						disabled={isLoading}
					>
						{t("PDFViewer.zoom")}: {Math.round(Zoom * 100)}%
					</Button>
				</Tooltip>
				<Button
					className="zoomBtn"
					color="transparent"
					type="button"
					onClick={() => handleZoom("in")}
					disabled={isLoading || Zoom === zoomConfig.max}
				>
					<SVG src={ZoomPlus} className="!h-5" />
				</Button>
			</div>
			{!isLoading && download && !disabled ? (
				<Button
					type="button"
					color="transparent"
					className="!text-primary-500 ml-2"
					onClick={onDownload}
				>
					<Picto
						icon="download"
						className="[&>g]:!text-primary-500 !mr-0 xs:!mr-2"
					/>
					<span className="hidden xs:block">
						{t("global.download")}
					</span>
				</Button>
			) : (
				<span></span>
			)}
		</div>
	);
};

export default ViewerNavigation;
