import React from "react";
import BgImage from "../../assets/illustrations/error.svg";
import "./404.scss";
import { DefaultTFuncReturn, t } from "i18next";
import SVG from "../../components/atoms/SVG/SVG";
import cn from "../../utils/cn";

interface IPage404Props {
	className?: string;
	text?: string | DefaultTFuncReturn;
}

const Page404: React.FC<IPage404Props> = ({
	className,
	text = t("errors.404.message"),
}) => {
	return (
		<div
			className={cn([
				"Page404 flex flex-col overflow-hidden relative",
				className,
			])}
		>
			<div className="text relative z-1">
				<h1 className="text-4xl font-bold">
					<span className="text-primary-500">
						{t("errors.404.oups")}
					</span>{" "}
					{t("errors.404.title")}
				</h1>
				<p className="text-xl">{text}</p>
			</div>
			<div className="overflow-hidden relative h-full">
				<SVG
					src={BgImage}
					className="absolute max-w-full h-full bgImg bottom-0 right-[-1svw] z-0 "
				/>
			</div>
		</div>
	);
};

export default Page404;
