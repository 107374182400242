import React from "react";
import appicon from "./appIcon.png";
import {
	handleAppDownload,
	useMobileAppBanner,
} from "../../../hooks/useMobileAppBanner";
import { t } from "i18next";
import { Modal, Button, Picto } from "@zolteam/react-ras-library";

const MobileAppModal: React.FC = () => {
	const { isBannerVisible, appLink, hideBanner, isTempBannerVisible } =
		useMobileAppBanner();

	const handleHideBanner = (fromClose?: boolean) => {
		hideBanner(fromClose);
	};

	if (!isBannerVisible && appLink && isTempBannerVisible) {
		return <MobileAppBanner />;
	}
	if (!isBannerVisible || !appLink) return null;

	return (
		<Modal
			isDisplayed={true}
			onClose={() => handleHideBanner(true)}
			className="!block !min-w-[90%] md:!min-w-[500px]"
			title={""}
		>
			<div className={`block w-full`}>
				<img
					src={appicon}
					alt="banner"
					className="w-auto min-h-[100px] max-h-[15vh] mx-auto "
				/>
				<h1 className="font-bold my-5 text-xl">
					{t("mobileAppBanner.bannerTitle")}
				</h1>
				<div className="flex gap-m items-center flex-col">
					<div className="flex flex-col gap-xs">
						<p className="text-neutral-600 leading-4 mr-3 font-semibold">
							{t("mobileAppBanner.bannerText")}
						</p>
					</div>
				</div>
				<Button
					color="primary"
					className="mt-8"
					type="button"
					onClick={() => {
						handleHideBanner();
						if (!appLink) return;
						handleAppDownload();
					}}
				>
					<span className="!text-[1.2rem]">
						{t("global.download")}
					</span>
				</Button>
			</div>
		</Modal>
	);
};

export const MobileAppBanner: React.FC = () => {
	const { appLink, tempHideTopBanner, isTempBannerVisible } =
		useMobileAppBanner();

	const handleHideBanner = (fromDownload) => {
		tempHideTopBanner();
		if (fromDownload && appLink) {
			handleAppDownload();
		}
	};

	if (!isTempBannerVisible || !appLink) return null;

	return (
		<div
			className={`
			sticky top-0
			w-full p-4
			z-50  bg-white
			outline outline-2 outline-neutral-200`}
		>
			<div
				className={`flex justify-between relative items-end flex-col sm:flex-row`}
			>
				<div className="flex gap-m items-center">
					<img src={appicon} alt="banner" className="max-w-[64px]" />
					<div className="flex flex-col gap-xs">
						<p className="font-bold">
							{t("mobileAppBanner.bannerTitle")}
						</p>
						<p className="text-neutral-600 leading-4 mr-3">
							{t("mobileAppBanner.bannerText")}
						</p>
					</div>
				</div>
				<div className="w-full mt-m sm:w-auto sm:mt-0">
					<Button
						color="primary"
						type="button"
						className="!px-3 !py-1 w-full"
						onClick={() => {
							handleHideBanner(true);
						}}
					>
						{t("global.download")}
					</Button>
				</div>
			</div>
			<Button
				color="transparent"
				type="button"
				className="absolute right-1 top-4"
				onClick={() => handleHideBanner(false)}
			>
				<Picto icon="close" />
			</Button>
		</div>
	);
};

export default MobileAppModal;
