import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { HelpFormInitValues, HelpFormValidation } from "./HelpFormValidation";
import { t } from "i18next";
import { Button, Title, InfoMessage } from "@zolteam/react-ras-library";
import HelpInput from "./HelpInput";
import SessionService from "../../services/SessionService";
import AgenciesService from "../../services/AgenciesService";
import TokenService from "../../services/TokenService";
import { ISelectValue } from "../../components/molecules/Select/Select";
import HelpService from "../../services/HelpService";
import { useQueries } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";

export interface IHelpFormProps {
	handleSubmitForm: (
		values: {
			firstName: string;
			lastName: string;
			email: string;
			phone: string;
			agency: { label: string; value: string };
			topic: { label: string; value: string };
			problem: string;
		},
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => Promise<any>;
}

export const HelpForm: React.FC<IHelpFormProps> = (props: IHelpFormProps) => {
	const parseList = (list: any) => {
		let parsedList: ISelectValue[] = [];
		list.forEach((item: any) => {
			parsedList.push({
				label: item.name,
				value: item.id,
			});
		});
		return parsedList;
	};

	const getInitValues = () => {
		const user = SessionService.getUser();
		const token = TokenService.getLocalAccessToken();
		const values = { ...HelpFormInitValues };
		if (!token || !user) return values;

		values.firstName = user.firstName;
		values.lastName = user.lastName;
		if (user.email) {
			values.email = user.email;
		}
		if (user.phoneNumber) {
			values.phone = user.phoneNumber;
		}

		return values;
	};

	const fetchAgencies = () => {
		const user = SessionService.getUser();
		const token = TokenService.getLocalAccessToken();

		if (user && token) {
			const list = parseList(user.agencies);
			return Promise.resolve(list);
		} else if (!token) {
			return AgenciesService.getAgencies().then((response) =>
				parseList(response)
			);
		}
	};

	const fetchTopics = () => {
		return HelpService.getTopics().then((response) => {
			let translation = response.assistanceTopics.map((item: any) => {
				return {
					name: t(`help.traduction.${item.name}`),
					id: item.id,
				};
			});
			return parseList(translation);
		});
	};

	const [
		{ data: agencies, isLoading: isLoadingAgencies },
		{ data: topics, isLoading: isLoadingTopics },
	] = useQueries({
		queries: [
			{
				queryKey: ["helpform", "agencies"],
				queryFn: () => fetchAgencies(),
			},
			{
				queryKey: ["helpform", "topics"],
				queryFn: () => fetchTopics(),
			},
		],
	});

	return (
		<div className="flex flex-col w-full text-left">
			<Title tag="h2" className="mb-3">
				{t("help.title")}
			</Title>
			<InfoMessage color="primary" withIcon>
				{t("help.description")}
			</InfoMessage>
			{isLoadingAgencies || isLoadingTopics ? (
				<Loader />
			) : (
				<Formik
					initialValues={{
						...getInitValues(),
						topic: null,
						agency: agencies?.length === 1 ? agencies[0] : null,
					}}
					validationSchema={HelpFormValidation(t)}
					onSubmit={(values, { resetForm, setFieldError }) => {
						return props
							.handleSubmitForm(values, resetForm)
							.catch((error) => setFieldError("error", error));
					}}
				>
					{({ values, errors, isSubmitting }) => {
						const token = TokenService.getLocalAccessToken();

						return (
							<div
								className={` flex flex-col h-full ${
									token ? "" : "items-center mt-xs"
								}`}
							>
								<Form
									className={
										"max-w-[500px] w-full items-center"
									}
								>
									<HelpInput
										isLogged={token}
										isEmail={!!values.email?.length}
										isPhone={!!values.phone?.length}
										agencies={agencies ?? []}
										topics={topics ?? []}
									/>

									<div className="flex flex-col justify-end gap-m">
										{!!errors.error?.length && (
											<InfoMessage color="error" withIcon>
												{errors.error}
											</InfoMessage>
										)}
										<Button
											type="submit"
											className={"w-full align-right"}
											color="primary"
											isLoading={isSubmitting}
										>
											{t("help.submit")}
										</Button>
									</div>
								</Form>
							</div>
						);
					}}
				</Formik>
			)}
		</div>
	);
};

export default HelpForm;
