import api from "./api";
class SectorsServicesClass {
	getSectors() {
		return api.get("/api-360/sector/available-sectors").then((response) => {
			return response.data;
		});
	}

	getUserSectors() {
		return api.get("/api-360/sector").then((response) => {
			return response.data;
		});
	}

	postUserSectors(sectorIds: any) {
		return api.post("/api-360/sector", { sectorIds });
	}
}

const SectorsServices = new SectorsServicesClass();
export default SectorsServices;
