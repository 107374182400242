import React from "react";
import { useNavigate } from "react-router-dom";
import { Popover } from "@zolteam/react-ras-library";
import DisconnectButton from "../../atoms/DisconnectButton/DisconnectButton";
import { t } from "i18next";
// Styles
import "./HeaderProfile.scss";
import HeaderLink from "../../atoms/HeaderLink/HeaderLink";
import Avatar from "../../atoms/Avatar/Avatar";

interface IHeaderProfileProps {
	ref?: any;
}

const HeaderProfile: React.FC<IHeaderProfileProps> = ({ ref }) => {
	const navigate = useNavigate();

	return (
		<div ref={ref} className={"headerProfile !cursor-default"}>
			<Popover
				placement="bottom"
				clickInside={true}
				content={
					<>
						<HeaderLink
							text={t("personalInfos.title")}
							onClick={() => navigate("/personal-informations")}
						/>
						<HeaderLink
							text={t("personalInfos.myDocs")}
							onClick={() => navigate("/identity-docs")}
						/>
						<HeaderLink
							icon="settings"
							text={t("settings.title")}
							onClick={() => navigate("/settings")}
						/>
						<hr className="my-4" />
						<DisconnectButton />
					</>
				}
			>
				<Avatar className="cursor-pointer" />
			</Popover>
		</div>
	);
};

export default HeaderProfile;
