import api from "./api";

class AgenciesServiceClass {
	getAgencies() {
		return api.get("/api-360/public/agencies").then((response) => {
			return response.data;
		});
	}

	getMyAgencies() {
		return api
			.get("/api-360/worker/reference-agency/list")
			.then((response) => {
				return response.data;
			});
	}

	postUserReferenceAgency = (agencyId) =>
		api.post("/api-360/worker/reference-agency/add", { agencyId });
}

const AgenciesService = new AgenciesServiceClass();
export default AgenciesService;
