import React, { Ref, useContext, useEffect } from "react";
import { Formik, Form, useFormikContext, FormikProps } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { InfoMessage, Title } from "@zolteam/react-ras-library";

// Forms
import {
	RegisterFormInitValues,
	RegisterFormValidation,
} from "./validationForm";

// Component
import Field from "../../components/molecules/Field/Field";

import { useNavigate, useParams } from "react-router-dom";
import { ICurStep } from "../../components/templates/InitLayout/InitLayout";
import formatDate from "../../utils/formatDate";
import {
	StepsContext,
	StepsContextType,
} from "../../contexts/StepsContext/StepsContext";
import DatePickerField from "../../components/molecules/DatePickerField/DatePickerField";

interface IRegisterInputProps {
	init: string;
}

interface IRegisterFormProps extends ICurStep {
	load: boolean;
	handleSubmitForm: (
		values: {
			token: string;
			propertyToCheck: string;
			value: string;
		},
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => Promise<any>;
	isMobile: boolean;
	init: string;
	error: string;
	setError: (error: string) => void;
	tries: number;
	nextStep: () => void;
	setStepsContext: (context: any) => void;
	stepsContext: any;

	formRef:
		| Ref<FormikProps<{ lastName: string; dateOfBirth: string }>>
		| undefined;
}

export const RegisterInput: React.FC<IRegisterInputProps> = (
	props: IRegisterInputProps
) => {
	const { t } = useTranslation();
	const ctx = useFormikContext<{ lastName: string; dateOfBirth: string }>();

	if (props.init === "lastName")
		return (
			<div className={`relative w-full lg:w-3/4 xl:1/2`}>
				<Field
					name="lastName"
					label={t("initRegister.name")}
					type="text"
				/>
			</div>
		);
	return (
		<div className={`relative w-full lg:w-3/4 xl:1/2`}>
			<DatePickerField
				name="dateOfBirth"
				label={t("initRegister.birthDate")}
				value={ctx.values.dateOfBirth}
				onChange={(val: Date) => {
					ctx.setFieldValue(
						"dateOfBirth",
						val.toLocaleDateString("fr")
					);
				}}
				pickerProps={{
					maxDate: new Date(),
				}}
			/>
		</div>
	);
};

export const RegisterForm: React.FC<IRegisterFormProps> = (
	props: IRegisterFormProps
) => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const RegisterValueSchema = {
		...RegisterFormInitValues,
	};
	let propertyToCheck = props.init;
	let token = params.token ?? "";

	useEffect(() => {
		if (props.tries === 0) {
			navigate("/contact-agencies");
		}
	}, [navigate, props.tries]);

	const { setStepsContext } = useContext<StepsContextType>(StepsContext);

	return (
		<div>
			<Title tag="h2" lineHeight="m" size="heading01">
				{props.init === "lastName"
					? t("initRegister.nameTitle")
					: t("initRegister.birthDateTitle")}
			</Title>
			<Formik
				initialValues={RegisterValueSchema}
				validationSchema={RegisterFormValidation(t, props.init)}
				onSubmit={(values, { resetForm }) => {
					let value =
						props.init === "lastName"
							? values.lastName
							: formatDate(values.dateOfBirth);
					return props
						.handleSubmitForm(
							{ token, propertyToCheck, value },
							resetForm
						)
						.then((res) => {
							setStepsContext({
								...props.stepsContext,
								validationCode: res.validationCode,
								uniqueId: res.uniqueId,
							});
						})
						.catch((err) => {
							props.setError(err);
							throw err;
						});
				}}
				validate={(values) => {
					props.validateStep && props.validateStep(values);
				}}
				innerRef={props.formRef}
			>
				{() => {
					return (
						<div className="pt-m ">
							<Form className={`${props.isMobile ? "" : "flex"}`}>
								<RegisterInput init={props.init} />
								{props.isMobile && props.error?.length > 0 && (
									<div className="mb-m">
										<InfoMessage color="error" withIcon>
											{props.init === "lastName"
												? t(
														"auth.error.api.registerName"
												  )
												: t(
														"auth.error.api.registerBirthDate"
												  )}
											<Trans
												i18nKey={
													props.tries !== 1
														? "auth.error.api.loginStrong"
														: "auth.error.api.loginStrong_one"
												}
												values={{
													tries: props.tries,
												}}
											/>
										</InfoMessage>
									</div>
								)}
							</Form>
							{!props.isMobile && props.error?.length > 0 && (
								<div className="mb-m">
									<InfoMessage color="error" withIcon>
										{props.init === "lastName"
											? t("auth.error.api.registerName")
											: t(
													"auth.error.api.registerBirthDate"
											  )}
										<Trans
											i18nKey={
												props.tries !== 1
													? "auth.error.api.loginStrong"
													: "auth.error.api.loginStrong_one"
											}
											values={{
												tries: props.tries,
											}}
										/>
									</InfoMessage>
								</div>
							)}
						</div>
					);
				}}
			</Formik>
		</div>
	);
};
