import React, { ReactElement, useCallback, useState } from "react";
import "./Waves.scss";
import cn from "../../../utils/cn";
import DefaultCurve from "./DefaultCurve";

interface ISVGWaves {
	segments?: number; // number of "waves", 4 by default
	amplitude?: number; // amplitude of the waves, 2.5 by default
	animated?: boolean; // animate waves - true by default
	purpleOnly?: boolean; // display only the purple border - false by default
}

interface IWavesProp extends ISVGWaves {
	title?: string | ReactElement;
	text?: string | ReactElement;
	className?: string;
	children?: any;
}

const DEFAULT_SEGMENTS_NBR = 4;
const DEFAULT_AMPLITUDE = 2;
const DEFAULT_ANIMATION = true;
const DEFAULT_STOKE_WIDTH = 12; // define the thickness for the "blue/orange/purple" border
const DEFAULT_SPEED = 0.002;

const AnimatedWaves: React.FC<IWavesProp> = (props: IWavesProp) => {
	return (
		<div
			className={cn([
				"AnimatedWaves flex text-white h-full fixed right-0 max-w-[5vw] md:max-w-[35vw] duration-500 delay-0 ease-in-out",
				props.className,
			])}
		>
			<SVGWaves
				segments={props.segments ?? DEFAULT_SEGMENTS_NBR} // 4 by default
				amplitude={props.amplitude ?? DEFAULT_AMPLITUDE} // 2.5 by default
				animated={props.animated ?? DEFAULT_ANIMATION}
				purpleOnly={props.purpleOnly ?? false}
			/>
			<div className="inner-cont md:flex flex-col justify-between h-full z-1 md:p-[3vw] opacity-0 md:opacity-100">
				{props.children}
			</div>
		</div>
	);
};

export interface ICurve {
	// Main point of the curve
	y: number;
	x: number;

	// Control point for the curve
	// see: https://developer.mozilla.org/fr/docs/Web/SVG/Tutorial/Paths#curveto for more infos
	c_x: number;
	c_y: number;

	dir: number; // direction of the wave (left: -1, right: 1)
	angle: number; // used for the movement, the main point of the wave is going around a circle to create the movement

	// Used to save the "center“ of the wave before animation
	initValues?: ICurve | undefined;
}

interface IPath {
	stroke?: string;
	fill?: string;
	path: string;
}

export const SVGWaves: React.FC<ISVGWaves> = (props: ISVGWaves) => {
	const [Paths, setPaths] = useState<IPath[]>([]);
	const [Curves, setCurves] = useState<ICurve[]>([]); // default points for curve if genCurves take too much time to init

	function getRandomDirection() {
		let amplitude = 1;
		let half = amplitude / 2;
		let min = 1 - half;
		let max = 1 + half;

		return (max - min) * Math.random() + min;
	}

	// Curves generation before init
	const genCurves = useCallback(() => {
		let curves: ICurve[] = [...DefaultCurve];
		let x = -140;
		let y = 1300;
		let amplitude = // define the amplitude for the waves depending on the window height
			(window.innerHeight * (props.amplitude ?? DEFAULT_AMPLITUDE)) / 100; // divide by 100 to get a good ratio
		let x_amp = amplitude;
		let y_amp = amplitude;

		let segmentsNbr = props.segments ?? DEFAULT_SEGMENTS_NBR;
		let y_step = window.innerHeight / segmentsNbr; // define the height of each wave depending on the screen height

		for (let i = 0; i < segmentsNbr; i++) {
			let dir =
				getRandomDirection() > 1 //&& i < 1 && i !== segmentsNbr - 1 // always arc left for the first and the last one
					? 1
					: -1;
			let n_x = parseFloat((x * getRandomDirection()).toFixed(3)); // init the new x point

			let vals = {
				x: n_x + x_amp * dir,
				y: y + y_amp * dir,
				c_x: n_x - x_amp * dir,
				c_y: y - y_amp * dir + y_step,
				dir, // random direction for the wave
				angle: Math.random() * Math.PI, // random angle for the start
				initValues: undefined,
			};
			let curve: ICurve = { ...vals, initValues: vals };
			curves.push(curve);

			y = y + y_step * 2;
			x = n_x;
		}
		return curves;
	}, [props.segments, props.amplitude]);

	const curvesAnim = useCallback(() => {
		let amplitude = (props.amplitude ?? DEFAULT_AMPLITUDE) * 10;
		let n_curves: ICurve[] = Curves.map((cur, b) => {
			let n_cur = { ...cur };
			n_cur.x =
				(n_cur.initValues?.x || n_cur.x) + // if there is no initial value, use the current x position
				amplitude * Math.cos(n_cur.angle); // move the wave main point around the circle, the amplitude is the radius of the circle
			n_cur.y =
				(n_cur.initValues?.y || n_cur.y) +
				amplitude * Math.sin(n_cur.angle); // move the wave control point depending on the amplitude
			n_cur.c_x =
				(n_cur.initValues?.c_x ?? n_cur.c_x) +
				amplitude * Math.cos(n_cur.angle);
			n_cur.c_y =
				(n_cur.initValues?.c_y ?? n_cur.c_y) +
				amplitude * Math.sin(n_cur.angle);

			n_cur.angle += DEFAULT_SPEED * n_cur.dir; // legacy value with good results

			return n_cur;
		});
		// define the new curves paths
		setCurves(n_curves);
	}, [Curves, setCurves, props]);

	// Transform the curves to an SVG path
	const genPath = useCallback(() => {
		let x = 0;
		let y = -150;
		let path = `M${x} ${y}`; // move to the start of the curve

		Curves.forEach((curve) => {
			// define the curve with the shorthand CurveTo: https://developer.mozilla.org/fr/docs/Web/SVG/Tutorial/Paths#shorthand_curveto
			path += ` S${curve.x.toFixed(3)}, ${curve.y.toFixed(3)}
						${curve.c_x.toFixed(3)},${curve.c_y.toFixed(3)}`;
		});

		// close the path by going arround all right borders of the screen to create the purple background;
		// L: LineTo
		// Z: close the path
		path += ` L ${window.innerWidth} ${window.innerHeight}
			L ${window.innerWidth} -150
			L0 -150 Z`;

		return path;
	}, [Curves]);

	React.useLayoutEffect(() => {
		setCurves(genCurves());
	}, [genCurves]);

	// Waves and path init
	React.useLayoutEffect(() => {
		let path = genPath(); // gen the main path
		if (props.purpleOnly)
			setPaths([
				{
					path: path,
					stroke: "url(#purple_only)",
					fill: "url(#purple_only)",
				},
			]);
		else
			setPaths([
				{ path: path, stroke: "url(#blue)" }, // define all the paths + colors for each one
				{ path: path, stroke: "url(#orange)" },
				{ path: path, stroke: "url(#purple_rev)" },
				{ path: path, stroke: "url(#purple)", fill: "url(#purple)" },
			]);

		// request the start of the animation if not disabled
		if (props.animated !== false) requestAnimationFrame(curvesAnim);
	}, [genPath, curvesAnim, genCurves, props]);

	// handle Resize useEffect
	React.useEffect(() => {
		function handleResize() {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				let last = Curves[Curves.length - 1];
				if (last.y < window.innerHeight) setCurves(genCurves());
			}, 100);
		}

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [Curves, genCurves]);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={50} // legacy value with good results
			height={1000} // legacy value with good results
			style={{
				zIndex: 1,
				position: "relative",
				display: "block",
			}}
			bbox={"0 0 50 1800"}
			overflow={"visible"}
		>
			<linearGradient id="blue" x1="0%" y1="-10%" x2="0%" y2="100%">
				<stop offset="0%" stopColor="#69C5B9" />
				<stop offset="50%" stopColor="#264297" />
			</linearGradient>
			<linearGradient id="orange" x1="0%" y1="-10%" x2="0%" y2="100%">
				<stop offset="0%" stopColor="#FF9A54" />
				<stop offset="50%" stopColor="#F95B03" />
			</linearGradient>
			<linearGradient id="purple" x1="0%" y1="-10%" x2="0%" y2="100%">
				<stop offset="0%" stopColor="#400F4D" />
				<stop offset="50%" stopColor="#751B8D" />
			</linearGradient>
			<linearGradient id="purple_rev" x1="0%" y1="100%" x2="0%" y2="100%">
				<stop offset="0%" stopColor="#751B8D" />
				<stop offset="50%" stopColor="#5a146d" />
			</linearGradient>
			<linearGradient id="purple_only" x1="0%" y1="0%" x2="100%" y2="0%">
				<stop offset="2%" stopColor="#400F4D" />
				<stop offset="30%" stopColor="#751B8D" />
			</linearGradient>

			{Paths.map((a, b: number) => {
				return (
					<path
						key={b}
						d={a.path}
						stroke={a.stroke}
						strokeWidth={DEFAULT_STOKE_WIDTH}
						fill={a.fill}
						style={{
							transform: `translateX(${
								(DEFAULT_STOKE_WIDTH - 2) * b // the translation need to be smaller that the strokeWidth to avoid bad pixels
							}px)`,
						}}
					/>
				);
			})}
		</svg>
	);
};

export default AnimatedWaves;
