import React from "react";
import { SelectAsync, Button } from "@zolteam/react-ras-library";
import { Form, Formik } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import { promiseToast } from "../../toastify/toastify";
import { IPersonalInformationsCategory } from "./PersonalInformations";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../components/atoms/Spinner/Spinner";

const FamilySituationInformations: React.FC<IPersonalInformationsCategory> = ({
	infos,
	disabled,
	refetchInfos,
}) => {
	const handleSubmit = (values: any) => {
		const prom = ProfileService.putProfileFamilySituation({
			familySituation: values.familySituationOption
				? values.familySituationOption.id
				: values.familySituation,
			childrenQty: parseInt(values.childrenQty),
		});

		promiseToast(prom);

		return prom;
	};

	const fetchOptions = () => ProfileService.getFamilySituationList();

	const getSelectedOption = (values: any) => {
		if (!values || !infos.familySituation) return null;
		return values.find((v: any) => v.name === infos.familySituation);
	};

	const { isLoading, data } = useQuery(["familySituationList"], fetchOptions);

	if (isLoading)
		return (
			<div>
				<b>{t("personalInfos.situation.title")}</b>
				<Spinner />;
			</div>
		);

	return (
		<div className={cn([disabled && "cursor-not-allowed"])}>
			<Formik
				initialValues={{
					...infos,
					familySituationOption: getSelectedOption(data),
				}}
				onSubmit={(values, { resetForm }) =>
					handleSubmit(values).then(() => {
						resetForm();
						refetchInfos();
					})
				}
			>
				{({ values, setValues, dirty, isSubmitting }) => {
					const isDisabled = disabled || isSubmitting;
					return (
						<Form className="relative flex flex-col items-start justify-start gap-4">
							<div
								className={cn([
									"flex flex-col gap-2 w-full",
									isDisabled &&
										"pointer-events-none opacity-70",
								])}
							>
								<b>{t("personalInfos.situation.title")}</b>
								<div className="flex flex-col gap-2 md:flex-row [&>*]:min-w-[200px]">
									<SelectAsync
										defaultOptions
										loadOptions={fetchOptions}
										inputValue={
											!values.familySituationOption
												? values.familySituation
												: ""
										}
										label={t(
											"personalInfos.situation.label"
										)}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										value={values.familySituationOption}
										name="familySituationOption"
										onChange={(value) => {
											setValues({
												...values,
												familySituationOption: value,
												familySituation: value.name,
											});
										}}
									/>
									<Field
										label={t(
											"personalInfos.situation.childs"
										)}
										name="childrenQty"
										type="number"
										min="0"
										max="10"
									/>
								</div>
							</div>
							{dirty && (
								<div className="w-full text-center">
									<Button
										type="submit"
										color={dirty ? "primary" : "grey"}
										disabled={!dirty}
										className="w-[250px] max-w-full"
										isLoading={isSubmitting}
									>
										{t("global.save")}
									</Button>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default FamilySituationInformations;
