import { t } from "i18next";
import React from "react";
import cn from "../../../utils/cn";

interface ITimesOffProps {
	status: "approved" | "autoApproved" | "pending" | "rejected";
	className?: string;
}

export const TIME_OFF_STATUS = {
	approved: "approved",
	autoApproved: "autoApproved",
	pending: "pending",
	rejected: "rejected",
};

const TimeOffStatus: React.FC<ITimesOffProps> = ({ status, className }) => {
	const genBadgeStyle = () => {
		if (
			[TIME_OFF_STATUS.approved, TIME_OFF_STATUS.autoApproved].includes(
				status
			)
		)
			return {
				backgroundColor: "#CCE8E4",
				color: "#326961",
			};
		if (status === TIME_OFF_STATUS.pending)
			return {
				backgroundColor: "#DCCAE0",
				color: "#5F1673",
			};
		return {
			backgroundColor: "#FFD9D9",
			color: "#E55151",
		};
	};

	return (
		<div
			className={cn([
				"px-1 rounded flex justify-self-end items-center",
				className,
			])}
			style={genBadgeStyle()}
		>
			{t(`absences.status.${status}`)}
		</div>
	);
};

export default TimeOffStatus;
