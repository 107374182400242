import React from "react";
import HelpForm from "../../forms/HelpForm/HelpForm";
import { getPageTitle, pushDataLayer } from "../../GoogleTagManager/gtm";
import HelpService from "../../services/HelpService";
import { detect } from "detect-browser";
import { promiseToast } from "../../toastify/toastify";
import { t } from "i18next";

interface IBrowser {
	name: string;
	version: string;
	os: string;
	type: string;
}

const Help: React.FC = () => {
	const browser: IBrowser | any = detect();
	const { name, version, os, type } = browser;

	const handleSubmitHelpForm = (values, reset) => {
		let valuesToSend = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			phone: values.phone,
			agencyId: values.agency.value,
			topicId: values.topic.value,
			problem: values.problem,
			osVersion: os,
			phoneModel: `${type} - ${name}`,
			appVersion: version,
		};

		pushDataLayer({
			dataLayer: {
				event: "demande_aide_envoyee",
				pageUrl: window.location.href,
				pageTitle: getPageTitle().title,
				rubrique_aide: values.topicId?.label ?? "Contacter mon agence",
			},
		});
		const prom = HelpService.postHelpRequest(valuesToSend).then(() =>
			reset()
		);
		promiseToast(prom, {
			success: t("help.success"),
		});
		return prom;
	};

	return (
		<div className="flex flex-col h-full">
			<HelpForm handleSubmitForm={handleSubmitHelpForm} />
		</div>
	);
};

export default Help;
