import React, { useContext } from "react";
import { Title, InfoMessage } from "@zolteam/react-ras-library";
import SectorTile, {
	ISector,
} from "../../components/molecules/SectorTile/SectorTile";
import SectorsServices from "../../services/SectorsServices";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import { IStepComponentProps } from "../../components/templates/InitLayout/InitLayout";
import { Form, Formik } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import cn from "../../utils/cn";
import { toast } from "react-toastify";
import { StepsContext } from "../../contexts/StepsContext/StepsContext";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const OnboardingSectors: React.FC<IStepComponentProps> = ({
	isActive,
	...props
}) => {
	const { setContextValue } = useContext(StepsContext);
	const { validateStep, unvalidateStep } = props;
	const maxSelected = 5;

	const fetchUserSectors = () => SectorsServices.getUserSectors();

	const { isLoading, data } = useQuery(
		["onboarding", "selected", "sectors"],
		fetchUserSectors,
		{
			refetchOnWindowFocus: false,
			enabled: isActive,
			onSuccess: (data) => {
				if (data?.sectors?.length) {
					validateStep &&
						validateStep({
							sectors: data?.sectors,
						}).then((resp) => {
							setContextValue("sectors", data.sectors);
						});
				}
				return data;
			},
		}
	);

	return (
		<div>
			<Title tag="h2" className="mb-8 !leading-7">
				{t("onboarding.sectors.title")}
			</Title>

			{isLoading ? (
				<Loader />
			) : (
				<Formik
					initialValues={{
						sectors: [...(data?.sectors || [])],
					}}
					onSubmit={(values) => {
						pushDataLayer({
							dataLayer: {
								event: "inscription__choix_secteurs_activites",
							},
						});
						return SectorsServices.postUserSectors(
							values.sectors.map((a) => a.id)
						).then(
							(resp) => {
								setContextValue("sectors", values.sectors);
								return resp;
							},
							(e) => {
								toast.error(t("promiseToast.error").toString());
								throw new Error(
									t("API_ERRORS.sendError").toString()
								);
							}
						);
					}}
					validate={(values) => {
						if (!values.sectors?.length) {
							return unvalidateStep && unvalidateStep();
						}
						return (
							validateStep &&
							validateStep({ sectors: values.sectors })
						);
					}}
					innerRef={props.formRef}
				>
					{({ values, setFieldValue }) => {
						if (isLoading) return <Loader />;
						return (
							<Form>
								<SectorsModal
									maxSelected={maxSelected}
									selected={values.sectors}
									setSelected={(sectors) => {
										setFieldValue("sectors", sectors);
									}}
								/>
							</Form>
						);
					}}
				</Formik>
			)}
		</div>
	);
};

interface ISectorsModalProps {
	maxSelected: number;
	selected: ISector[];
	setSelected: (sectors: ISector[]) => void;
}

const SectorsModal: React.FC<ISectorsModalProps> = ({
	maxSelected = 5,
	selected,
	setSelected,
}) => {
	const handleSelectSector = (sector: ISector) => {
		if (selected.find((sect) => sect.id === sector.id)) {
			setSelected(selected.filter((sect) => sect.id !== sector.id));
		} else {
			if (selected.length >= maxSelected) return;
			setSelected([...selected, sector]);
		}
	};

	const fetch = () =>
		SectorsServices.getSectors().then((resp) => resp?.sectors || []);

	const { isLoading, data, error } = useQuery(
		["onboarding", "sectors"],
		fetch,
		{
			refetchOnWindowFocus: false,
		}
	);

	if (isLoading) return <Loader />;

	if (error || !data?.length)
		return (
			<div className="[&>div]:w-full">
				<InfoMessage withIcon color="error">
					{t("onboarding.sectors.loadingError")}
				</InfoMessage>
			</div>
		);

	const hasReachedMax = selected.length >= maxSelected;

	return (
		<>
			<div className="[&>div]:mb-3">
				<InfoMessage
					withIcon
					color={
						selected?.length < maxSelected ? "primary" : "warning"
					}
					className="!mb-4"
				>
					{selected?.length < maxSelected ? (
						<Trans
							i18nKey="onboarding.sectors.remainingSectors"
							values={{
								count: selected?.length
									? maxSelected - selected?.length
									: 0,
								max: maxSelected,
							}}
						/>
					) : (
						t("onboarding.sectors.maxSelectedSectors", {
							max: maxSelected,
						})
					)}
				</InfoMessage>
			</div>
			<div className="flex flex-wrap gap-4 mt-3 [&>div]:!w-[1/4] mb-8">
				{data?.map((sector) => {
					const isSelected = selected?.find(
						(s) => s.id === sector.id
					);

					return (
						<SectorTile
							key={sector.id}
							sector={sector}
							isSelected={isSelected}
							className={cn([
								!isSelected &&
									hasReachedMax &&
									"opacity-50 cursor-no-drop pointer-events-none",
							])}
							onClick={() => handleSelectSector(sector)}
						/>
					);
				})}
			</div>
		</>
	);
};
export default OnboardingSectors;
