import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import Field from "../../components/molecules/Field/Field";
import {
	InfoMessage,
	Button,
	SelectAsync,
	Popover,
	Picto,
	ToggleSwitch,
} from "@zolteam/react-ras-library";
import { t } from "i18next";
import {
	AbsencesFormInitValues,
	AbsencesFormValidation,
} from "./AbsencesFormValidation";
import AbsencesService from "../../services/AbsencesService";
import { IAbsence } from "../../components/molecules/Absence/Absence";
import FileImporter from "../../components/molecules/FileImporter/FileImporter";
import Select from "../../components/molecules/Select/Select";
import DatePicker from "../../components/molecules/DatePicker/DatePicker";
import cn from "../../utils/cn";
import moment from "moment";
import { urlToArrayBuffer } from "../../utils/blob";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";

export interface IAbsencesFormProps {
	onClose?: () => void;
	isOpen: boolean;
	absence: IAbsence;
	isApproved: boolean;
	onSubmit: (values: any, formik: FormikHelpers<any>) => void;
}

export interface IAbsencesReasons {
	id: string;
	name: string;
	value: string;
	label: string;
}

const genHoursList = () => {
	let hoursList: object[] = [];
	let minutes = ["00", "30"];
	for (let hours = 0; hours < 24; hours++) {
		for (let isHalf = 0; isHalf < 2; isHalf++) {
			let val = `${hours < 10 ? "0" + hours : hours}:${minutes[isHalf]}`;
			hoursList.push({
				value: val,
				label: val,
			});
		}
	}
	return hoursList;
};

const HOURS_LIST = genHoursList();

const MIN_DATE = moment().add(3, "days").toDate();

const ModalDatePicker: React.FC<any> = ({
	value,
	onChange,
	minDate,
	maxDate,
}: any) => {
	return (
		<DatePicker
			disabled={true}
			showYearDropdown
			showMonthDropdown
			dropdownMode="select"
			inline
			selected={value}
			minDate={minDate ?? MIN_DATE}
			maxDate={maxDate}
			onChange={(val: Date) => {
				onChange(val);
			}}
		/>
	);
};

export const AbsencesForm: React.FC<IAbsencesFormProps> = ({
	absence,
	isApproved,
	onClose,
	onSubmit,
}) => {
	const TEMP_DISABLED_EDIT = isApproved || (!isApproved && absence.id !== 0); // TODO: need to be removed when backend is ready

	const genFilesField = ({ values, setFieldValue }) => {
		const files = values?.files ?? [];
		const filesInputs: React.ReactNode[] = [];

		if ((TEMP_DISABLED_EDIT || isApproved) && files.length === 0)
			return filesInputs;

		for (let i = 0; i < files.length + 1 && i < 3; i++) {
			filesInputs.push(
				<FileImporter
					className="w-full"
					key={`File-${i}-${files[i]?.fileName ?? "empty"}`}
					label="Ajouter un justificatif"
					name={files[i]?.fileName ?? files[i]?.name ?? ""}
					file={files[i]?.file ?? files[i]?.link ?? null}
					fileName={files[i]?.fileName}
					accept="image/jpeg, image/jpg, image/png, image/bmp, image/pjpeg"
					onChange={(file, fileName, raw) => {
						let newFiles = [...files];
						newFiles[i] = {
							file,
							fileName,
							raw,
						};
						setFieldValue(`files`, newFiles);
					}}
					onRemove={() => {
						let newFiles = [...files];
						newFiles.splice(i, 1);
						setFieldValue(`files`, newFiles);
					}}
					disabled={TEMP_DISABLED_EDIT || isApproved}
					isInModal={true}
				/>
			);
			if (TEMP_DISABLED_EDIT || (isApproved && i >= files.length - 1))
				return filesInputs;
		}

		return filesInputs;
	};

	const fetchReasons = (setFieldValue) => {
		const prom = AbsencesService.getReasons().then(
			({ commonTimeOffReasons }) => {
				if (!commonTimeOffReasons) return [];
				return commonTimeOffReasons.map((reason) => {
					if (
						absence.id &&
						absence.reasonName === reason.name &&
						setFieldValue
					)
						setFieldValue("commonTimeOffReasonId", reason.id);
					return {
						value: reason.id,
						label: reason.name,
					};
				});
			}
		);

		return prom;
	};

	const fetchFiles = async () => {
		if (!absence.id || !absence.files.length) return Promise.resolve([]);
		let filesProms = absence.files.map((file) =>
			urlToArrayBuffer(file.link).then((res) => ({
				file: res,
				fileName: file.name,
			}))
		);

		let prom = Promise.allSettled(filesProms).then((res) => {
			return res.map((file: any) => file.value);
		});
		return prom;
	};

	const { isInitialLoading, data } = useQuery(
		["AbsenceFiles", absence.id],
		fetchFiles,
		{
			enabled: absence.id && absence.files.length > 0 ? true : false,
			retry: 1,
		}
	);

	if (isInitialLoading) return <Loader />;

	return (
		<Formik
			initialValues={{
				...AbsencesFormInitValues,
				...absence,
				files: data ?? absence.files ?? [],
			}}
			validationSchema={AbsencesFormValidation(t)}
			onSubmit={(values, formikHelper) => {
				onSubmit && onSubmit(values, formikHelper);
			}}
		>
			{({ values, setValues, setFieldValue, touched, errors }) => {
				const checkValues = () => {
					if (absence.id && !isApproved) return false;
					if (!absence.id || touched) {
						return (
							values.reasonName &&
							values.startDate &&
							values.finishDate &&
							(values.isAllDay ||
								(!values.isAllDay &&
									values.startTime !== "" &&
									values.finishTime !== ""))
						);
					}
					return false;
				};

				return (
					<Form
						className={cn([
							"flex flex-col h-full gap-m",
							TEMP_DISABLED_EDIT && "cursor-not-allowed",
						])}
					>
						{((!isApproved && !TEMP_DISABLED_EDIT) ||
							values.files.length > 0) && (
							<div className="flex flex-col w-full gap-m">
								{genFilesField({
									values,
									setFieldValue,
								})}
							</div>
						)}
						{!TEMP_DISABLED_EDIT && !isApproved && (
							<div className="[&>div]:!mt-0 [&>div]:w-full">
								<InfoMessage
									withIcon
									color={
										values.files.length < 3
											? "primary"
											: "warning"
									}
								>
									{t("absences.form.remainingFiles", {
										count: 3 - values.files.length,
									})}
								</InfoMessage>
							</div>
						)}
						<SelectAsync
							defaultOptions
							promiseOptions={() => fetchReasons(setFieldValue)}
							className={`flex [&>p]:hidden
									${TEMP_DISABLED_EDIT || isApproved ? "pointer-events-none" : ""}`}
							label={t("absences.form.reason")}
							name="reasonName"
							menuPlacement="bottom"
							menuPosition="absolute"
							onChange={(val) => {
								setFieldValue("reasonName", val.label);
								setFieldValue(
									"commonTimeOffReasonId",
									val.value
								);
							}}
							value={
								values.reasonName
									? {
											value: values.reasonName,
											label: values.reasonName,
									  }
									: null
							}
							disabled={TEMP_DISABLED_EDIT}
						/>
						<div>
							<div className="flex flex-col justify-between sm:flex-row gap-m">
								<Field
									name="formattedStartDate"
									label={t("absences.form.startDate")}
									type="text"
									className={cn([
										"w-full flex",
										(TEMP_DISABLED_EDIT || isApproved) &&
											"!pointer-events-none opacity-70",
									])}
									containerClassName="w-full"
								>
									<div
										className={cn([
											"absolute top-2 right-2 [& button svg]:!m-0",
											(TEMP_DISABLED_EDIT ||
												isApproved) &&
												"pointer-events-none opacity-70",
										])}
									>
										<Popover
											key={Date.now().toString()}
											content={
												<ModalDatePicker
													value={
														values.startDate
															? new Date(
																	values.startDate
															  )
															: MIN_DATE
													}
													onChange={(val) => {
														let vals = {
															...values,
															startDate: val,
															formattedStartDate:
																moment(
																	val
																).format(
																	"DD/MM/YYYY"
																),
														};
														if (
															val >
															values.finishDate
														) {
															vals.finishDate =
																val;
															vals.formattedFinishDate =
																moment(
																	val
																).format(
																	"DD/MM/YYYY"
																);
														}

														setValues(vals);
													}}
												/>
											}
										>
											<Button color="white" type="button">
												<Picto
													icon="calendar"
													className="!m-0"
												/>
											</Button>
										</Popover>
									</div>
								</Field>
								<Field
									name="formattedFinishDate"
									label={t("absences.form.endDate")}
									type="text"
									className={cn([
										"w-full",
										(TEMP_DISABLED_EDIT || isApproved) &&
											"pointer-events-none opacity-70",
									])}
									containerClassName="w-full"
								>
									<div
										className={cn([
											"absolute top-2 right-2 [& button svg]:!m-0",
											(TEMP_DISABLED_EDIT ||
												isApproved) &&
												"pointer-events-none  opacity-70",
										])}
									>
										<Popover
											key={Date.now().toString()}
											content={
												<ModalDatePicker
													value={
														values.finishDate
															? new Date(
																	values.finishDate
															  )
															: MIN_DATE
													}
													minDate={
														values.startDate
															? new Date(
																	values.startDate
															  )
															: MIN_DATE
													}
													onChange={(val) => {
														setValues({
															...values,
															finishDate: val,
															formattedFinishDate:
																moment(
																	val
																).format(
																	"DD/MM/YYYY"
																),
														});
													}}
												/>
											}
										>
											<Button color="white" type="button">
												<Picto
													icon="calendar"
													className="!m-0"
												/>
											</Button>
										</Popover>
									</div>
								</Field>
							</div>
							{errors.dateError && (
								<div className="[&>div]:w-full mt-2">
									<InfoMessage withIcon color="error">
										{errors.dateError}
									</InfoMessage>
								</div>
							)}
						</div>
						<div className="flex flex-row">
							<ToggleSwitch
								id="allDay"
								name="isAllDay"
								onChange={() => {
									setFieldValue("isAllDay", !values.isAllDay);
								}}
								toggled={values.isAllDay}
								className={
									isApproved ? "cursor-not-allowed" : ""
								}
								disabled={TEMP_DISABLED_EDIT || isApproved}
							>
								<span
									className={cn([
										"pl-2",
										(TEMP_DISABLED_EDIT || isApproved) &&
											"opacity-50",
									])}
								>
									{t("absences.form.toggleAllDay")}
								</span>
							</ToggleSwitch>
						</div>
						{!values.isAllDay && (
							<div className="flex flex-col sm:flex-row gap-m">
								<Select
									options={HOURS_LIST}
									label={t("absences.form.startHourText")}
									value={
										values.startTime && {
											value: values.startTime,
											label: values.startTime,
										}
									}
									className={cn([
										"w-full [&>p]:hidden",
										isApproved && " pointer-events-none",
									])}
									onChange={(val: any) => {
										setFieldValue("startTime", val.label);
									}}
									disabled={isApproved}
								/>
								<Select
									options={HOURS_LIST}
									label={t("absences.form.endHourText")}
									className={cn([
										"w-full [&>p]:hidden",
										isApproved && " pointer-events-none",
									])}
									value={
										values.finishTime && {
											value: values.finishTime,
											label: values.finishTime,
										}
									}
									disabled={isApproved}
									onChange={(val: any) => {
										setFieldValue("finishTime", val.label);
									}}
								/>
							</div>
						)}
						{!isApproved ? (
							<>
								<hr className="my-2" />
								<div className="flex flex-col justify-between mt-auto sm:flex-row gap-m">
									<Button
										color="white"
										type="button"
										onClick={onClose}
										className="w-full sm:w-1/2"
									>
										{t("absences.form.cancel")}
									</Button>

									<Button
										color="primary"
										type="submit"
										disabled={!checkValues() ? true : false}
										className="w-full sm:w-1/2"
									>
										{!absence.id
											? t("absences.form.submit")
											: t("absences.form.submitEdit")}
									</Button>
								</div>
								{absence.id > 0 && !isApproved && (
									<InfoMessage color="warning" withIcon>
										{t("absences.form.disabledEdit")}
									</InfoMessage>
								)}
							</>
						) : (
							<InfoMessage color="primary" withIcon>
								{t("absences.form.approvedMessage")}
							</InfoMessage>
						)}
					</Form>
				);
			}}
		</Formik>
	);
};
