import { t } from "i18next";
import React, { useEffect } from "react";

interface ICountDownProps {
	time?: number;
	text?: string | ((time: number) => string);
	handleEnd: () => void;
}

const CountDown: React.FC<ICountDownProps> = ({ time, text, handleEnd }) => {
	const [Time, setTime] = React.useState(time ?? 15);

	useEffect(() => {
		let intervalId: NodeJS.Timer;

		if (Time > 0) {
			intervalId = setInterval(() => {
				if (Time === 1) {
					handleEnd();
				}
				setTime((time) => time - 1);
			}, 1000);
		}

		return () => clearInterval(intervalId);
	}, [Time, handleEnd]);

	const generateText = () => {
		if (typeof text === "string") return text;
		if (typeof text === "function") return text(Time);
		return t("countdown.seconds", { count: Time });
	};

	return generateText();
};

export default CountDown;
