import React, { createContext, useState } from "react";

export type IFilters = {
	[key: string]: any;
};

interface IFiltersContextType {
	filters: IFilters;
	showModal: boolean;
	activeFilterCount: number;
	countFilters: (filters: IFilters) => number;
	setFilters: (filters: any) => void;
	setFilter: (key_or_values: any, value?: any) => void;
	getFilters: (key?: string) => any;
	resetFilters: () => IFilters;
	showFiltersModal: () => void;
	hideFiltersModal: () => void;
	showFilterButton: () => void;
	hideFilterButton: () => void;
	isButtonVisible: boolean;
}

export const FiltersContext = createContext<IFiltersContextType>({
	filters: {},
	showModal: false,
	activeFilterCount: 0,
	setFilters: () => {},
	setFilter: () => {},
	getFilters: () => {},
	resetFilters: () => ({}),
	showFiltersModal: () => {},
	hideFiltersModal: () => {},
	showFilterButton: () => {},
	hideFilterButton: () => {},
	isButtonVisible: false,
	countFilters: () => 0,
});

export const FiltersProvider = ({
	children,
	filters,
	countFilters = (filters) => 0,
}) => {
	const [showModal, setShowModal] = useState(false);
	const [Filters, setFilters] = useState({
		...filters,
	});
	const [showButton, setShowButton] = useState(false);

	const showFiltersModal = () => {
		setShowModal(true);
	};

	const hideFiltersModal = () => {
		setShowModal(false);
	};

	const setFilter = (key_or_values, value?) => {
		if (typeof key_or_values === "string" && typeof value !== "undefined") {
			setFilters((prevFilters) => ({
				...prevFilters,
				[key_or_values]: value,
			}));
		} else if (typeof key_or_values === "object") {
			setFilters((prevFilters) => ({
				...prevFilters,
				...key_or_values,
			}));
		}
	};

	const getFilters = (key: string | undefined) => {
		if (key) return Filters[key];
		return Filters;
	};

	const resetFilters = () => {
		setFilters({ ...filters });
		return filters;
	};

	const values = {
		filters: Filters,
		showModal,
		activeFilterCount: countFilters(Filters),
		countFilters,
		getFilters,
		setFilters,
		setFilter,
		showFiltersModal,
		hideFiltersModal,
		resetFilters,
		showFilterButton: () => setShowButton(true),
		hideFilterButton: () => setShowButton(false),
		isButtonVisible: showButton,
	};

	return (
		<FiltersContext.Provider value={values}>
			{children}
		</FiltersContext.Provider>
	);
};
