import React, { Ref } from "react";
import { Formik, Form, useFormikContext, FormikProps } from "formik";
import {
	RegisterPhoneNumberFormInitValues,
	RegisterPhoneFormValidation,
} from "./RegisterEmailPhoneValidationForm";
import { t } from "i18next";
import Field from "../../components/molecules/Field/Field";

interface IRegisterPhoneFormProps {
	handleSubmitForm: (
		values: { phoneNumber: string },
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => void;

	phoneNumber?: string;
	formRef?: Ref<FormikProps<any>> | undefined;
	validateStep?: (values: {}) => void;
	isActive?: boolean;
	isLoading?: boolean;
}

const RegisterPhoneInput = (props) => {
	const { setFieldTouched } = useFormikContext();

	React.useEffect(() => {
		if (props.phoneNumber) setFieldTouched("phoneNumber", true, true);
	}, [setFieldTouched, props.phoneNumber]);

	return (
		<div>
			<Field
				name={"phoneNumber"}
				label={t("registerPhone.phone")}
				type="text"
				disabled={props.isLoading}
			/>
		</div>
	);
};

export const RegisterPhoneForm: React.FC<IRegisterPhoneFormProps> = (
	props: IRegisterPhoneFormProps
) => {
	const RegisterPhoneNumberValueSchema = props.phoneNumber
		? {
				phoneNumber: props.phoneNumber,
		  }
		: {
				...RegisterPhoneNumberFormInitValues,
		  };
	return (
		<Formik
			initialValues={RegisterPhoneNumberValueSchema}
			validationSchema={RegisterPhoneFormValidation(t)}
			onSubmit={(values, { resetForm }) => {
				return props.handleSubmitForm(values, resetForm);
			}}
			validateOnChange={true}
			validate={(values: typeof RegisterPhoneNumberValueSchema) => {
				props.validateStep && props.validateStep(values);
			}}
			innerRef={props.formRef}
		>
			<Form>
				<RegisterPhoneInput
					phoneNumber={props.phoneNumber}
					isActive={props.isActive}
					isLoading={props.isLoading}
				/>
			</Form>
		</Formik>
	);
};
