import React from "react";
import { InfoMessage, Title } from "@zolteam/react-ras-library";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import moment from "moment";
import IdentityInformations from "./IdentityInformations";
import AddressInformations from "./AddressInformations";
import SocialSecurityInformations from "./SocialSecurityInformations";
import ContactInformations from "./ContactInformations";
import FamilySituationInformations from "./FamilySituationInformations";
import AvatarEdit, {
	RemoveAvatarButton,
} from "../../components/molecules/AvatarEdit/AvatarEdit";

export const initialPersonalInformationsValues = {
	sex: "",
	firstName: "",
	lastName: "",
	dateOfBirth: "",
	bornCity: "",
	nativeCountry: "",
	nationality: "",

	ssn: "",

	addressStreet: "",
	addressBuilding: "",
	addressPostalCode: "",
	addressCity: "",
	addressCountry: "",

	phoneNumber: "",
	initialPhoneNumber: "",
	email: "",
	initialEmail: "",

	familySituation: "",
	childrenQty: 0,
};

export interface IPersonalInformationsCategory {
	infos: any;
	disabled: boolean;
	refetchInfos: () => void;
}

const IS_READ_ONLY = false;

const PersonalInformations: React.FC = () => {
	const queryClient = useQueryClient();

	const fetch = () =>
		ProfileService.getProfile().then((resp) => {
			return {
				...resp,
				dateOfBirth: new Date(resp.dateOfBirth),
				formattedDateOfBirth: resp.dateOfBirth
					? moment(resp.dateOfBirth).format(t("dates.format"))
					: "",
				initialPhoneNumber: resp.phoneNumber,
				phoneNumber: resp.phoneNumber,
				initialEmail: resp.email,
				email: resp.email,
				childrenQty: resp.childrenQty || "0",
				mainLocation: "",
			};
		});

	const handleRefresh = () => {
		queryClient.resetQueries(["profile", "personalInformations"]);
	};

	const { isLoading, data } = useQuery(
		["profile", "personalInformations"],
		fetch,
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	const values = isLoading
		? {}
		: {
				...initialPersonalInformationsValues,
				...data,
		  };

	return (
		<div className={cn([IS_READ_ONLY && "cursor-not-allowed"])}>
			<Title tag="h1" className="!leading-7 mb-6">
				{t("personalInfos.title")}
			</Title>
			{IS_READ_ONLY && (
				<div className="mb-4">
					<InfoMessage color="warning" withIcon>
						{t("personalInfos.readOnly")}
					</InfoMessage>
				</div>
			)}

			{isLoading ? (
				<Loader />
			) : (
				<div
					className={cn([
						"relative flex flex-col gap-6 w-full",
						IS_READ_ONLY && "pointer-events-none opacity-70",
					])}
				>
					<div className="flex flex-col items-center justify-center w-full gap-4 p-4 sm:flex-row sm:justify-start">
						<AvatarEdit />
						<RemoveAvatarButton />
					</div>
					<IdentityInformations
						infos={values}
						disabled={IS_READ_ONLY}
						refetchInfos={handleRefresh}
					/>
					<hr />
					<SocialSecurityInformations
						infos={values}
						disabled={IS_READ_ONLY}
						refetchInfos={handleRefresh}
					/>
					<hr />
					<ContactInformations
						infos={values}
						disabled={IS_READ_ONLY}
						refetchInfos={handleRefresh}
					/>
					<hr />
					<AddressInformations
						infos={values}
						disabled={IS_READ_ONLY}
						refetchInfos={handleRefresh}
					/>
					<hr />
					<FamilySituationInformations
						infos={values}
						disabled={IS_READ_ONLY}
						refetchInfos={handleRefresh}
					/>
				</div>
			)}
		</div>
	);
};

export default PersonalInformations;
