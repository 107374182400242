import Overseas from "../../../assets/illustrations/overseas.svg";
import Logistique from "../../../assets/illustrations/logistique.svg";
import BTP from "../../../assets/illustrations/btp.svg";
import TransportVoyageurs from "../../../assets/illustrations/transportVoyageur.svg";
import Industrie from "../../../assets/illustrations/industrie.svg";
import Tertiaire from "../../../assets/illustrations/tertiaire.svg";

import Truck from "../../../assets/illustrations/truck.svg";
import RetailGrandeDistribution from "../../../assets/illustrations/retailGrandeDistribution.svg";
import EspacesVerts from "../../../assets/illustrations/espacesVerts.svg";
import Securite from "../../../assets/illustrations/securite.svg";
import Medical from "../../../assets/illustrations/medical.svg";
import hotelerie from "../../../assets/illustrations/hotelerie.svg";
import sport from "../../../assets/illustrations/sport.svg";
import RetailPremiumLuxe from "../../../assets/illustrations/retailPremiumLuxe.svg";
import recycleTruck from "../../../assets/illustrations/recycleTruck.svg";
import { t } from "i18next";

export interface ISectorInfos {
	id: number;
	picto: any;
	label: string;
	bottomOffset: string;
}

export const SectorsPictos: ISectorInfos[] = [
	{
		id: 1,
		picto: Overseas,
		label: t("onboarding.sectors.sector1"),
		bottomOffset: "-50px",
	},
	{
		id: 2,
		picto: TransportVoyageurs,
		label: t("onboarding.sectors.sector2"),
		bottomOffset: "-40px",
	},
	{
		id: 3,
		picto: Industrie,
		label: t("onboarding.sectors.sector3"),
		bottomOffset: "-15px",
	},
	{
		id: 4,
		picto: Logistique,
		label: t("onboarding.sectors.sector4"),
		bottomOffset: "-30px",
	},
	{
		id: 5,
		picto: Tertiaire,
		label: t("onboarding.sectors.sector5"),
		bottomOffset: "-15px",
	},
	{
		id: 6,
		picto: Truck,
		label: t("onboarding.sectors.sector6"),
		bottomOffset: "-40px",
	},
	{
		id: 7,
		picto: RetailGrandeDistribution,
		label: t("onboarding.sectors.sector7"),
		bottomOffset: "-35px",
	},
	{
		id: 8,
		picto: BTP,
		label: t("onboarding.sectors.sector8"),
		bottomOffset: "-50px",
	},
	{
		id: 9,
		picto: EspacesVerts,
		label: t("onboarding.sectors.sector9"),
		bottomOffset: "-40px",
	},
	{
		id: 10,
		picto: Securite,
		label: t("onboarding.sectors.sector10"),
		bottomOffset: "-40px",
	},
	{
		id: 11,
		picto: Medical,
		label: t("onboarding.sectors.sector11"),
		bottomOffset: "-40px",
	},
	{
		id: 12,
		picto: hotelerie,
		label: t("onboarding.sectors.sector12"),
		bottomOffset: "-40px",
	},
	{
		id: 13,
		picto: sport,
		label: t("onboarding.sectors.sector13"),
		bottomOffset: "-40px",
	},
	{
		id: 14,
		picto: RetailPremiumLuxe,
		label: t("onboarding.sectors.sector14"),
		bottomOffset: "-40px",
	},
	{
		id: 15,
		picto: recycleTruck,
		label: t("onboarding.sectors.sector15"),
		bottomOffset: "-10px",
	},
	{
		id: 16,
		picto: Medical,
		label: t("onboarding.sectors.sector16"),
		bottomOffset: "-40px",
	},
];
