import React, { InputHTMLAttributes, useRef } from "react";
import { Button, InfoMessage, Input, Picto } from "@zolteam/react-ras-library";
import "./ValidationCode.scss";

import { Form, Formik } from "formik";
import { DefaultTFuncReturn, t } from "i18next";

export interface IValidationSuccessProps {
	text?: string | DefaultTFuncReturn; // text to display in the success modal
	btnText?: string | DefaultTFuncReturn; // text of the success button
	btnIcon?: string; // icon of the success button
	btnOnClick?: () => void; // function to call when the success button is clicked
	render?: () => React.ReactNode; // used to render a custom component
	display?: boolean; // used to hide the modal if false
}

export interface IValidationCodeProps {
	onSubmit: (code: string) => Promise<any>; // function to call when the form is submitted
	codeLength?: number; // length of the code
	title?: string; // title of the form
	successModal?: IValidationSuccessProps; // props of the success modal
	type?: string; // type of the input
}

/**
 * Component to display a form to validate a code
 * @param {IValidationCodeProps} props
 * @returns {React.FC<IValidationCodeProps>}
 * @constructor
 * @example
 * <ValidationCode
 * 	onSubmit={async (code) => {
 * 		// do something
 * 	}}
 * 	codeLength={3}
 * 	title="Enter the code"
 * 	successModal={{
 * 		text: "Success",
 * 		btnText: "Ok",
 * 		btnIcon: "check",
 * 		btnOnClick: () => {
 * 			// do something
 * 		},
 * 	}}
 * />
 */

const ValidationCode: React.FC<IValidationCodeProps> = (props) => {
	const { onSubmit, codeLength = 3, successModal = null } = props;
	const formRef = useRef<any>(null);
	const innerFormRef = useRef<any>(null);
	const [Error, setError] = React.useState("");
	const [IsValid, setIsValid] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	React.useLayoutEffect(() => {
		if (!innerFormRef.current) return;
		const inputs = innerFormRef.current.querySelectorAll("input");
		Array.from(inputs).forEach((input: any, b: number) => {
			// used to move to handle the focus of the inputs when the user delete a value
			input.addEventListener("keydown", (e: any) => {
				const form = formRef.current;
				if (!form || form.isSubmitting) return;
				if (e.keyCode === 8) {
					// if delete key is pressed
					if (b !== 0 && input.value === "") {
						inputs[b - 1].focus();
					}
				}
			});

			// used to submit the form when the all inputs are filled
			input.addEventListener("keyup", (e: any) => {
				// concetenate the value of the inputs

				const form = formRef.current;
				if (!form) return;

				// prevent the user to enter more than one character
				if (e.target.value.length > 1) {
					form.setFieldValue(`num${b + 1}`, e.target.value[1]);
				}

				// concatenate the values of the inputs
				let vals = Array.from(inputs)
					.map((a: any) => a.value)
					.join("");

				if (
					vals.length === codeLength &&
					!form.isSubmitting &&
					!form.isValidating
				) {
					form.submitForm();
				}
			});
		});

		return () => {
			// remove the event listeners
			Array.from(inputs).forEach((input: any) => {
				input.removeEventListener("keydown", () => {});
				input.removeEventListener("keyup", () => {});
			});
		};
	}, [codeLength]);

	// generate the initial values of the form depending on the code length
	const genInitValues = () => {
		let vals: { [key: string]: string } = {};
		for (let i = 0; i < codeLength; i++) {
			vals[`num${i + 1}`] = "";
		}
		return vals;
	};

	// generate the fields of the form depending on the code length
	const genFields = (isSubmitting: boolean) => {
		let fields: any = [];
		let inputConfig: InputHTMLAttributes<{}> = {};
		let type = props.type ?? "number";

		if (type === "number") {
			inputConfig.min = 0;
			inputConfig.max = 9;
		}

		for (let i = 0; i < codeLength; i++) {
			fields.push(
				<Input
					label=""
					key={`num${i + 1}`}
					name={`num${i + 1}`}
					type={type}
					readOnly={isSubmitting}
					autoComplete="off"
					maxLength={1}
					{...inputConfig}
					className="ValidationCode__input"
					onPictoClick={() => {}}
				/>
			);
		}
		return fields;
	};

	// if the code is valid, display the success modal
	if (IsValid) {
		// if the user has choser to hide the success modal, return null
		if (successModal?.display === false) return null;
		return (
			<div className="ValidationCode flex flex-col items-center gap-m fixed bottom-10 right-0 shaddow-2xl bg-white p-4 px-6 z-2 rounded-2xl rounded-e-none">
				{
					// if the user has passed a custom render function, use it
					successModal?.render ? (
						successModal.render()
					) : (
						<>
							<InfoMessage withIcon color="success">
								{successModal?.text ??
									t("ValidationCode.successText")}
							</InfoMessage>
							<Button
								type="button"
								color="primary"
								onClick={successModal?.btnOnClick ?? null}
							>
								{successModal?.btnIcon ? (
									<Picto icon={successModal?.btnIcon} />
								) : null}
								{successModal?.btnText ??
									t("ValidationCode.successBtnText")}
							</Button>
						</>
					)
				}
			</div>
		);
	}

	const handleSubmit = (values: {}) => {
		// concat the values
		let code = Array.from(Object.values(values)).join("");
		setError("");
		if (onSubmit && !isLoading) {
			setIsLoading(true);
			return onSubmit(code).then(
				() => {
					setIsValid(true);
					setIsLoading(false);
				},
				(e) => {
					setError(e.toString());
					setIsLoading(false);
				}
			);
		}
	};

	return (
		<div className="ValidationCode flex flex-col items-center gap-m fixed bottom-10 right-0 bg-white p-4 px-6 z-2 rounded-2xl rounded-e-none">
			<div className="font-bold">
				{t("ValidationCode.title", {
					count: codeLength,
				})}
			</div>
			<Formik
				initialValues={genInitValues()}
				onSubmit={handleSubmit}
				validate={() => {
					let form = formRef.current;
					if (!form) return;
					let fields = innerFormRef.current.querySelectorAll("input");

					// focus the first empty field
					for (let i = 0; i < fields.length; i++) {
						if (fields[i].value === "") {
							form.setFieldTouched(fields[i].name, true, false);
							fields[i].focus();
							return;
						}
					}
				}}
				validateOnBlur={false}
				validateOnMount={false}
				innerRef={formRef}
			>
				{({ isSubmitting }) => (
					<Form className="flex gap-l" ref={innerFormRef}>
						{genFields(isSubmitting)}
					</Form>
				)}
			</Formik>
			{isLoading && (
				<p className="text-primary-500">{t("global.loading")}...</p>
			)}
			{Error && (
				<div className="max-w-[300px]">
					<InfoMessage withIcon color="error">
						{Error}
					</InfoMessage>
				</div>
			)}
		</div>
	);
};

export default ValidationCode;
