import api from "./api";

class CVServiceClass {
	getCV = () =>
		api.get("/api-360/cv").then((response) => {
			return response.data;
		});

	uploadCV = (cvs: any[]) => {
		const formData = new FormData();
		cvs.forEach((cv, index) => {
			formData.append(`cvs[${index}]`, cv.file, cv.fileName);
		});
		let prom = api.post("/api-360/cv/upload", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return prom;
	};

	deleteCV = (id: string) =>
		api.delete(`/api-360/cv/delete/${id}`).then((response) => {
			return response.data;
		});
}

const CVService = new CVServiceClass();
export default CVService;
