import * as Yup from "yup";
import LoginIdService from "../../services/LoginIdService";

export const LoginFormInitValues = {
	id: LoginIdService.getId() ?? "",
	password: "",
};

export const LoginFormValidation = (t) =>
	Yup.object().shape({
		id: Yup.string().required(t("auth.error.idRequired")),
		password: Yup.string().required(t("auth.error.passwordRequired")),
	});
