import React from "react";
import Wave from "./assets/Wave.png";
import "./Waves.scss";
import cn from "../../../utils/cn";

interface IWavesProp {
	className?: string;
	children?: any;
}

const Waves: React.FC<IWavesProp> = (props) => {
	return (
		<div
			className={cn(["Waves h-full", props.className])}
			style={{
				backgroundImage: "url(" + Wave + ")",
				backgroundSize: "cover",
				backgroundPositionX: "right",
				backgroundPositionY: "center",
				backgroundRepeat: "no-repeat",
			}}
		>
			<div className="waveWrapper h-full w-[80%] p-[3vw] text-white relative">
				{props.children}
			</div>
		</div>
	);
};

export default Waves;
