import api from "./api";

class MessagerieServiceClass {
	getMessages() {
		return api
			.post("api-360/messages/list", {
				sorting: "createdAt",
				orderBy: "DESC",
			})
			.then((response) => {
				return response.data;
			});
	}
	getMessageDetail(id) {
		return api.get(`api-360/message/${id}/detail`).then((response) => {
			return response.data;
		});
	}

	postMessageAnswer(id, recipientId, answer) {
		return api
			.post(`api-360/message/${id}/answer/${recipientId}`, answer)
			.then((response) => {
				return response.data;
			});
	}

	getPassMessageToWatch(id: string) {
		return api.get(`api-360/message/${id}/watch`).then((response) => {
			return response.data;
		});
	}
}
const MessagerieService = new MessagerieServiceClass();

export default MessagerieService;
