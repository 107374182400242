import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

// eslint-disable-next-line
import { Picto } from "@zolteam/react-ras-library";

// Components
import HeaderProfile from "../../molecules/HeaderProfile/HeaderProfile";
import PictoNotification from "../../molecules/PictoNotification/PictoNotification";

// Utils
import cn from "../../../utils/cn";
import { t } from "i18next";

// Style
import "./Header.scss";
import BurgerMenuButton from "../../atoms/BurgerMenuButton/BurgerMenuButton";
import {
	NotificationsContext,
	NotificationsContextType,
} from "../../../contexts/NotificationsContext/NotificationsContext";
import ContactMyAgencies from "../../../views/ContactAgencies/ContactMyAgencies";
import Notifications from "../../../views/Notifications/Notifications";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";

const Header = (props) => {
	const navigate = useNavigate();
	const { numberMessage } =
		useContext<NotificationsContextType>(NotificationsContext);

	return (
		<div className={"header"} data-testid="header">
			<div className={"logoWrapper w-[130px] sm:w-auto flex gap-m"}>
				<div className="block sm:hidden">
					<BurgerMenuButton
						onClick={props.toggleMenu}
						isActive={props.isMenuOpen}
					/>
				</div>
				<button
					onClick={() => {
						pushDataLayer({
							dataLayer: {
								event: "header",
								item: "logo",
							},
						});
						navigate("/");
					}}
				>
					<Picto className={"logo"} icon="logoMyRas" />
				</button>
			</div>
			<div className={"actionsWrapper"}>
				{/* <BackLink onClick={() => {}}>Voir toutes les offres</BackLink> */}
				<ul className={"headerList"}>
					<li className={"headerItem !mx-0 sm:!mx-s"}>
						<ContactMyAgencies />
					</li>

					<li className={"headerItem !mx-0 sm:!mx-s"}>
						<Notifications />
					</li>

					<li className={"headerItem !mx-0 sm:!mx-s"}>
						<NavLink
							to={"/messagerie"}
							className={cn([
								"headerLink",
								"flex gap-s items-center",
							])}
							onClick={() => {
								pushDataLayer({
									dataLayer: {
										event: "header",
										item: "Mes messages",
									},
								});
							}}
						>
							<PictoNotification
								icon="messageSquare"
								number={numberMessage}
								padColor="accent"
								customIconSize={{ width: "1.25rem" }}
							/>
							<p className="hidden sm:block">
								{t("header.messages")}
							</p>
						</NavLink>
					</li>

					<li className={"headerItem !ml-0"}>
						<HeaderProfile />
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Header;
