import React from "react";
import Select from "../../molecules/Select/Select";
import { Form, Formik } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import DatePickerField from "../../molecules/DatePickerField/DatePickerField";
import moment from "moment";
import { useQueries } from "@tanstack/react-query";
import DocumentsService from "../../../services/DocumentsService";
import FileImporter from "../../molecules/FileImporter/FileImporter";
import Loader from "../../atoms/Loader/Loader";
import { Button, InfoMessage, Title } from "@zolteam/react-ras-library";
import {
	AddDocValidationInitialValues,
	AddDocValidationSchema,
} from "./AddDocValidationSchema";
import Field from "../../molecules/Field/Field";
import cn from "../../../utils/cn";
import { useNavigate } from "react-router-dom";
import { urlToArrayBuffer } from "../../../utils/blob";
import { PROXY_URL } from "../../../constants/constants";
import ConfirmModal from "../../molecules/ConfirmModal/ConfirmModal";
import Spinner from "../../atoms/Spinner/Spinner";

interface IAddDocModalProps {
	type: any;
	subType?: any;
	maxFiles?: number;
	handleSubmit: (values: any) => Promise<any>;
	closeModal: () => void;
	fetchedDatas?: any;
	initialValues?: any;
	isPersonalDoc?: boolean;
	isDocTypeLocked?: (docType: any) => boolean;
	lockedDocMessage?: string | ((docType: any) => string | React.ReactNode);
}

interface IRemoveCallback {
	callback: () => void;
	name: string;
}

const AddDocModal: React.FC<IAddDocModalProps> = ({
	type,
	subType,
	maxFiles = 3,
	handleSubmit,
	closeModal,
	fetchedDatas,
	initialValues,
	isPersonalDoc,
	isDocTypeLocked,
	lockedDocMessage,
}) => {
	const navigate = useNavigate();
	const [isSending, setIsSending] = React.useState(false);
	const [Type, setType] = React.useState<any>(subType ?? null);
	const [DeleteDocCallback, setDeleteDocCallback] =
		React.useState<IRemoveCallback | null>(null);

	const apiRoute = isPersonalDoc
		? DocumentsService.getAllProofsTypesForId
		: DocumentsService.getAllProfessionalCommonDocuments;

	const fetchOptions = async () =>
		apiRoute(type.id).then((res) => {
			const docTypes = res?.commonDocuments;
			if (!docTypes.length) return [];

			const types = docTypes
				.map((docType) => {
					const hasExistingDoc =
						fetchedDatas?.find(
							(existingDoc) =>
								existingDoc.commonDocumentId === docType.id
						) ?? false;

					return {
						...docType,
						label: docType.name,
						value: docType.id,
						hasExistingDoc: hasExistingDoc,
					};
				})
				.filter((a) => a && !a.hasExistingDoc)
				.sort((a, b) => a.label.localeCompare(b.label));

			const initType = types.find(
				(type) =>
					type.id === initialValues?.type?.id ||
					type.id === initialValues?.commonDocumentId
			);

			if (initType) setType(initType);
			else if (initialValues?.type)
				setType({
					...initialValues.type,
					label: initialValues.type.name,
					value: initialValues.type.id,
				});

			return types;
		});

	const genFilesField = ({ values, setFieldValue }, isLocked: boolean) => {
		const files = values?.files ?? [];
		const filesInputs: React.ReactNode[] = [];

		for (let i = 0; i < files.length + 1 && i < maxFiles; i++) {
			if (isLocked && i === files.length) break;
			filesInputs.push(
				<FileImporter
					className="w-full"
					key={`File-${i}-${files[i]?.fileName ?? "empty"}`}
					label={t("addDocModal.addFile")}
					name={files[i]?.fileName ?? files[i]?.name ?? ""}
					file={files[i]?.file ?? files[i]?.link ?? null}
					fileName={files[i]?.fileName}
					accept={
						type.acceptedFilesType ??
						"image/png, image/jpg, image/jpeg, application/pdf"
					}
					onChange={(file, fileName, raw) => {
						let newFiles = [...files];
						newFiles[i] = {
							file,
							fileName,
							raw,
						};
						setFieldValue(`files`, newFiles);
					}}
					onRemove={
						!isLocked
							? () => {
									setDeleteDocCallback({
										callback: () => {
											let newFiles = [...files];
											newFiles.splice(i, 1);
											setFieldValue(`files`, newFiles);
										},
										name: files[i]?.fileName,
									});
							  }
							: undefined
					}
					isInModal={true}
				/>
			);
		}

		return filesInputs;
	};

	const fetchFiles = async () => {
		if (!initialValues?.files) return Promise.resolve([]);

		const proms = initialValues.files.map((file) => {
			return urlToArrayBuffer(PROXY_URL + file.link).then((blob) => {
				return {
					...file,
					file: blob,
					fileName: file.name,
					link: file.link,
					raw: new File([blob], file.name, {
						type: file.type,
					}),
				};
			});
		});
		return Promise.allSettled(proms).then((resp) => {
			return resp.filter((res) => res.value).map((res) => res.value);
		});
	};

	const [
		{ isLoading: isLoadingTypes, data },
		{ isLoading: isLoadingFiles, data: fetchedFiles },
	] = useQueries({
		queries: [
			{ queryKey: ["getDocTypes", type?.id], queryFn: fetchOptions },
			{
				queryKey: [
					"getDocFiles",
					initialValues?.type?.id,
					initialValues?.id,
				],
				queryFn: fetchFiles,
				enabled: !!initialValues?.files,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				refetchOnReconnect: false,
			},
		],
	});

	const isLoading =
		isLoadingTypes || (isLoadingFiles && initialValues?.files);

	if (isLoading) return <Loader />;

	let initialType = initialValues?.type
		? data.find(
				(type) =>
					type.id === initialValues?.type.id ||
					type.id === initialValues?.commonDocumentId
		  )
		: null;

	if (!initialType && initialValues?.type)
		initialType = {
			...initialValues.type,
			label: initialValues.type.name,
			value: initialValues.type.id,
			typeNotFound: true,
		};

	return (
		<div>
			<Formik
				initialValues={{
					...AddDocValidationInitialValues,
					...initialValues,
					files: fetchedFiles ?? [],
					type: subType ?? initialType ?? null,
				}}
				validationSchema={AddDocValidationSchema(t, Type)}
				onSubmit={(values, formikHelper) => {
					setIsSending(true);
					handleSubmit(values).then(
						() => {
							setIsSending(false);
							closeModal();
						},
						() => {
							setIsSending(false);
						}
					);
				}}
			>
				{({ values, setFieldValue, isValid, isSubmitting }) => {
					const showField = (fieldName) =>
						values.type.typeNotFound ||
						(values.type && values.type[`${fieldName}`]);

					const nbrFiles = values?.files?.length ?? 0;
					const checkValues = () => {
						if (!values.type) return false;
						if (values.type.typeNotFound) return true;
						if (showField("effectiveDateRule")) {
							if (!values.effectiveDate) return false;
						}
						if (showField("expirationDateRule")) {
							if (!values.expirationDate) return false;
						}
						if (showField("referenceRule")) {
							if (!values.reference) return false;
						}
						if (showField("attachmentsRule")) {
							if (nbrFiles < 1) return false;
						}
						return true;
					};

					const isTypeLocked = isDocTypeLocked?.(values.type);

					return (
						<Form className="flex flex-col gap-m">
							{isLoading && <Spinner />}
							{!isLoading && !!data.length && !subType && (
								<div
									className={cn([
										"flex flex-col w-full gap-m",
									])}
								>
									{isTypeLocked && (
										<InfoMessage withIcon color="error">
											{lockedDocMessage ? (
												typeof lockedDocMessage ===
												"function" ? (
													lockedDocMessage(
														values.type
													)
												) : (
													lockedDocMessage
												)
											) : (
												<button
													className="text-left"
													onClick={() => {
														navigate(
															"/contact-agencies",
															{
																state: {
																	from: "add-document-modal",
																},
															}
														);
													}}
													disabled={isSubmitting}
												>
													{t("docs.locked")}
												</button>
											)}
										</InfoMessage>
									)}
									{!isTypeLocked &&
										values.type?.hasExistingDoc && (
											<InfoMessage
												color="warning"
												withIcon
											>
												{t("docs.sameTypeDocExist")}
											</InfoMessage>
										)}
									<Select
										name="type"
										label={t("addDocModal.type")}
										options={data}
										value={values.type}
										menuPlacement="bottom"
										onChange={(data) => {
											setType(data);
											setFieldValue("type", data);
										}}
										disabled={
											(isTypeLocked &&
												type?.hasExistingDoc) ||
											!!initialValues?.files ||
											isSubmitting
										}
									/>
								</div>
							)}

							{values.type &&
								(initialValues?.files || !isTypeLocked) && (
									<>
										{showField("effectiveDateRule") && (
											<DatePickerField
												name="effectiveDate"
												label={t(
													"addDocModal.effectiveDate"
												)}
												value={values.effectiveDate}
												onChange={(val: Date) => {
													let date = moment(
														val
													).format(
														t(
															"dates.format"
														).toString()
													);
													setFieldValue(
														"effectiveDate",
														date
													);
												}}
												pickerProps={{
													maxDate: new Date(),
												}}
												disabled={
													isTypeLocked || isSubmitting
												}
											/>
										)}
										{showField("expirationDateRule") && (
											<DatePickerField
												name="expirationDate"
												label={t(
													"addDocModal.expirationDate"
												)}
												value={values.expirationDate}
												onChange={(val: Date) => {
													let date = moment(
														val
													).format(
														t(
															"dates.format"
														).toString()
													);
													setFieldValue(
														"expirationDate",
														date
													);
												}}
												pickerProps={{
													minDate: new Date(),
												}}
												disabled={
													isTypeLocked || isSubmitting
												}
											/>
										)}
										{showField("referenceRule") && (
											<Field
												name="reference"
												type="text"
												label={t(
													"addDocModal.reference"
												)}
												value={values.reference}
												onChange={(e) => {
													setFieldValue(
														"reference",
														e.target.value
													);
												}}
												disabled={
													isTypeLocked || isSubmitting
												}
											/>
										)}
										{showField("attachmentsRule") && (
											<>
												<Title
													tag="h3"
													size="paragraph01"
													className="!-mb-1 mt-3 !leading-3"
												>
													{t(
														"addDocModal.attachments"
													)}
												</Title>
												<div className="flex flex-col w-full gap-m">
													{genFilesField(
														{
															values,
															setFieldValue,
														},
														isTypeLocked ||
															isSubmitting
															? true
															: false
													)}
												</div>
											</>
										)}
										{!isTypeLocked && (
											<InfoMessage
												color={
													nbrFiles === maxFiles
														? "warning"
														: "primary"
												}
												withIcon
											>
												{t(
													nbrFiles < maxFiles
														? "docs.remainingFiles"
														: "docs.maxFiles",
													{
														count:
															maxFiles - nbrFiles,
													}
												)}
											</InfoMessage>
										)}
									</>
								)}
							{!isTypeLocked && (
								<Button
									type="submit"
									color={
										isValid && checkValues()
											? "primary"
											: "grey"
									}
									disabled={!(isValid && checkValues())}
									className="mt-8"
									isLoading={isSending}
								>
									{t("global.save")}
								</Button>
							)}
							<ConfirmModal
								isOpen={!!DeleteDocCallback}
								onClose={() => setDeleteDocCallback(null)}
								title={t(
									"addDocModal.confirmAttachmentDeleteTitle"
								)}
								onConfirm={() => {
									if (DeleteDocCallback)
										DeleteDocCallback.callback();
									setDeleteDocCallback(null);
								}}
								size="s"
							>
								<Trans
									i18nKey="addDocModal.confirmAttachmentDeleteText"
									values={{
										name: DeleteDocCallback?.name,
									}}
								/>
							</ConfirmModal>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default AddDocModal;
