import React from "react";
import { Button, SelectAsync } from "@zolteam/react-ras-library";
import { Form, Formik } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import { promiseToast } from "../../toastify/toastify";
import { IPersonalInformationsCategory } from "./PersonalInformations";
import { noAccents } from "../../utils/noAccents";
import * as Yup from "yup";
import LocationService from "../../services/LocationService";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../components/atoms/Spinner/Spinner";

const COUNTRY_NAME_FRANCE = "France";

const validationSchema = Yup.object().shape({
	addressStreet: Yup.string().required(t("personalInfos.errors.required")),
	addressCity: Yup.string(),

	addressPostalCode: Yup.string()
		.max(5, t("personalInfos.errors.notValidZipcode"))
		.min(5, t("personalInfos.errors.notValidZipcode")),
	addressCountry: Yup.object().shape({
		name: Yup.string(),
		id: Yup.string(),
	}),
	mainLocation: Yup.object()
		.shape({
			id: Yup.string(),
			name: Yup.string(),
			postalCode: Yup.string(),
		})
		.nullable(),
});

const AddressInformations: React.FC<IPersonalInformationsCategory> = ({
	infos,
	disabled,
	refetchInfos,
}) => {
	const loadCountries = (searchTerm: string) => {
		return ProfileService.getCountries().then((resp) => {
			// filter items that name contains `inputValue`
			return resp.filter((i) =>
				noAccents(i.name.toLowerCase()).includes(
					noAccents(searchTerm.toLowerCase())
				)
			);
		});
	};

	const handleSubmit = (values: any) => {
		const isCountryFrance =
			values?.addressCountry?.name === COUNTRY_NAME_FRANCE;

		const data = {
			...values,
			addressCountryId: values?.addressCountry?.id,
			addressPostalCode: isCountryFrance
				? null
				: parseInt(values?.addressPostalCode, 10),
			addressCity: isCountryFrance ? null : values?.addressCity,
			mainLocation: isCountryFrance ? values?.mainLocation?.id : null,
		};
		const prom = ProfileService.putPostalAddress(data);

		promiseToast(prom);

		return prom;
	};

	const fetchUserLocation = () =>
		LocationService.getUserLocations().then((resp) => {
			const mainLocation = {
				id: 0,
				name: "",
				...resp?.mainLocation,
				postalCode: resp?.mainLocation?.postalCode ?? "00000",
			};
			return mainLocation;
		});

	const { isLoading, data } = useQuery(
		["profile", "personalInformations", "locations"],
		fetchUserLocation,
		{
			refetchOnWindowFocus: false,
		}
	);

	if (isLoading)
		return (
			<div>
				<b>{t("personalInfos.address.title")}</b>
				<Spinner className="mt-4" />
			</div>
		);

	return (
		<div className={cn([disabled && "cursor-not-allowed"])}>
			<Formik
				initialValues={{
					...infos,
					mainLocation: data,
				}}
				onSubmit={(values, { resetForm }) =>
					handleSubmit(values).then(() => {
						resetForm();
						refetchInfos();
					})
				}
				validationSchema={validationSchema}
			>
				{({
					values,
					dirty,
					isSubmitting,
					setFieldValue,
					touched,
					...props
				}) => {
					const isDisabled = disabled || isSubmitting;
					return (
						<Form className="relative flex flex-col items-start justify-start gap-4">
							<div
								className={cn([
									"flex flex-col gap-2 w-full sm:w-auto",
									isDisabled &&
										"pointer-events-none opacity-70",
								])}
							>
								<b>{t("personalInfos.address.title")}</b>
								<div className="flex flex-col gap-2">
									<div className="flex flex-wrap flex-col md:flex-row gap-2 md:[&>*]:min-w-[400px]">
										<Field
											className="w-full md:w-auto"
											label={t(
												"personalInfos.address.label"
											)}
											name="addressStreet"
											type="text"
											maxLength={30}
										/>
										<Field
											className="w-full md:w-auto"
											label={t(
												"personalInfos.address.complement"
											)}
											name="addressBuilding"
											type="text"
											maxLength={30}
										/>
									</div>
									<div className="flex flex-wrap flex-col md:flex-row gap-2 md:[&>*]:min-w-[400px]">
										{values?.addressCountry?.name ===
										COUNTRY_NAME_FRANCE ? (
											<MainLocationSelect
												values={values}
												setFieldValue={setFieldValue}
												disabled={isDisabled}
											/>
										) : (
											<>
												<Field
													label={t(
														"personalInfos.address.city"
													)}
													name="addressCity"
													type="text"
												/>
												<Field
													label={t(
														"personalInfos.address.postalCode"
													)}
													name="addressPostalCode"
													type="text"
												/>
											</>
										)}
										<SelectAsync
											defaultOptions
											loadOptions={loadCountries}
											label={t(
												"personalInfos.address.country"
											)}
											value={values.addressCountry}
											getOptionLabel={(option) =>
												option.name
											}
											getOptionValue={(option) =>
												option.id
											}
											searchIcon={true}
											isSearchable={true}
											onChange={(value) => {
												setFieldValue(
													"addressCountry",
													value
												);
											}}
											className="[&>p:empty]:hidden"
											name="addressCountry"
										/>
									</div>
								</div>
							</div>
							{dirty && (
								<div className="w-full text-center">
									<Button
										type="submit"
										color={dirty ? "primary" : "grey"}
										disabled={!dirty}
										className="w-[250px] max-w-full"
										isLoading={isSubmitting}
									>
										{t("global.save")}
									</Button>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

const MainLocationSelect: React.FC<any> = ({ values, setFieldValue }) => {
	const formatLabel = (option: any) => {
		return `${option.name} ${
			option.postalCode && option.postalCode !== "00000"
				? `(${option.postalCode})`
				: ""
		}`;
	};

	const handleSearch = (searchTerm: string) =>
		LocationService.getSearchLocations(searchTerm);

	return (
		<SelectAsync
			defaultOptions
			loadOptions={handleSearch}
			label={t("personalInfos.address.cityPostalCode")}
			value={values.mainLocation}
			getOptionLabel={formatLabel}
			getOptionValue={(option) => option.id}
			searchIcon={true}
			isSearchable={true}
			onChange={(value) => setFieldValue("mainLocation", value)}
			name="mainLocation"
		/>
	);
};

export default AddressInformations;
