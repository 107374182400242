import React from "react";
import ReactDatePicker, { DatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

registerLocale("fr", fr);

const DatePicker: React.FC<DatePickerProps> = (props) => {
	return (
		<ReactDatePicker
			{...props}
			locale="fr"
			dateFormat="dd/MM/yyyy"
			className={"DatePicker " + props.className}
		/>
	);
};

export default DatePicker;
