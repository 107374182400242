import api from "./api";
interface ISearchOptions {
	searchByIdsSector?: number[];
	limit?: number;
	page?: number;
}

class QualificationsServiceClass {
	getUserQualifications() {
		return api
			.get("/api-360/qualification/user-selected-qualifications")
			.then((response) => {
				return response.data;
			});
	}

	postUserQualifications(qualifications: any) {
		return api
			.post(
				"/api-360/qualification/link-user-qualifications",
				qualifications
			)
			.then((response) => {
				return response.data;
			});
	}

	postSearchedQualifications = (searchByName, options?: ISearchOptions) => {
		return api
			.post("/api-360/qualification/available-qualifications", {
				searchByName,
				page: 1,
				limit: 20,
				...(options ? options : {}),
			})
			.then((response) => {
				return response.data;
			});
	};
}

const QualificationsService = new QualificationsServiceClass();
export default QualificationsService;
