import React from "react";
import { Picto } from "@zolteam/react-ras-library";
import { DefaultTFuncReturn } from "i18next";
import { useNavigate } from "react-router-dom";
import MessageIcon from "./assets/message.svg";
import ContractIcon from "./assets/contracts.svg";
import PayslipsIcon from "./assets/payslips.svg";
import SVG from "../../atoms/SVG/SVG";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";

export interface INotificationCardProps {
	title?: string | DefaultTFuncReturn;
	text?: string | DefaultTFuncReturn;
	infos?: string | DefaultTFuncReturn;
	isNew?: boolean;
	type?: "contract" | "payslip" | "mission" | "absence" | "message";
	icon?: React.ReactNode | boolean;
	link?: string | DefaultTFuncReturn;
	additionalInfos?: string | DefaultTFuncReturn;
	id?: string;
	origin?: "Notifications" | "Il y a du nouveau"; // used for GTM
}

const NotificationCard: React.FC<INotificationCardProps> = ({
	title,
	text,
	infos,
	additionalInfos,
	isNew,
	icon,
	type,
	id,
	link = undefined,
	origin = "Unknown",
}) => {
	const navigate = useNavigate();
	const getIcon = () => {
		if (icon === false) return null;

		let useIcon = MessageIcon;

		if (type === "contract") useIcon = ContractIcon;
		if (type === "payslip") useIcon = PayslipsIcon;
		return (
			<SVG
				src={useIcon}
				className={"absolute -bottom-2 -right-2 w-full h-full"}
			/>
		);
	};

	const genDataLayerEvent = () => {
		if (type === "contract") return "contrat__signer";
		if (type === "payslip") return "paie__voir_bulletin";
		return false;
	};

	const handleClicked = () => {
		pushDataLayer({
			dataLayer: {
				event: "notification_ouverture",
				item: title,
				clic_position: origin,
			},
		});

		const event = genDataLayerEvent();
		if (event)
			pushDataLayer({
				dataLayer: {
					event,
					parcours: origin,
				},
			});
		navigate(
			`${type === "contract" ? `/contracts/${id}` : `/payslips/${id}`}`,
			{
				state: {
					pathname: origin,
					fromNotification: true,
				},
			}
		);
	};

	return (
		<div
			className={`flex group hover:bg-neutral-100 rounded-8 p-2 py-2 duration-300 w-full flex-wrap justify-end`}
		>
			<button className="flex flex-col w-full" onClick={handleClicked}>
				<span className="flex items-start gap-m w-full">
					{icon !== false && (
						<span className="relative img min-w-[3.4rem] min-h-[3.4rem] w-[3.4rem] h-[3.4rem] aspect-square bg-neutral-100 group-hover:bg-white rounded-8 flex items-center justify-center duration-300 overflow-hidden ">
							{getIcon()}
						</span>
					)}
					<span className="flex flex-col overflow-hidden w-full text-left">
						<span className="flex items-center gap-xs">
							{isNew && (
								<Picto
									icon="circle"
									style={{
										width: "8px",
										height: "8px",
										minWidth: "8px",
									}}
									className="text-accent-500"
								/>
							)}
							<p className="text-paragraph-01 text-black font-bold truncate">
								{title}
							</p>
						</span>
						{text && (
							<p className="text-paragraph-02 text-black leading-4 font-semibold line-clamp-2">
								{text}
							</p>
						)}
						{infos && (
							<p className="text-paragraph-03 capitalize truncate">
								{infos}
							</p>
						)}
						{additionalInfos && (
							<p className="text-neutral-500 text-paragraph-03 leading-3">
								{additionalInfos}
							</p>
						)}
					</span>
					<span className="flex self-center">
						{link && (
							<span className="w-[60px] h-[20px] !text-paragraph-03 bg-primary-500 rounded-[2rem] text-white font-bold flex items-center justify-center empty:hidden">
								{link}
							</span>
						)}
					</span>
				</span>
			</button>
		</div>
	);
};

export default NotificationCard;
