import React, { useState, useRef, useContext, useEffect } from "react";
import {
	Title,
	InfoMessage,
	Button,
	ModalV2,
	SelectAsync,
} from "@zolteam/react-ras-library";

import { ISector } from "../../components/molecules/SectorTile/SectorTile";
import { useQueries } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import { IStepComponentProps } from "../../components/templates/InitLayout/InitLayout";
import { Form, Formik } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import QualificationsService from "../../services/QualificationsService";
import RadioListItem from "../../components/molecules/RadioListItem/RadioListItem";
import { ISelectValue } from "../../components/molecules/Select/Select";
import { StepsContext } from "../../contexts/StepsContext/StepsContext";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import Spinner from "../../components/atoms/Spinner/Spinner";

const convToSelectValue = (job) => ({
	...job,
	value: job.id,
	label: job.name,
});

const OnboardingJobs: React.FC<IStepComponentProps> = ({
	isActive,
	unvalidateStep,
	validateStep,
	...props
}) => {
	const {
		submitStep,
		isStepValid,
		customNextCallback,
		setCustomNextCallback,
	} = props;
	const [Jobs, setJobs] = useState<ISector[]>([]);
	const JobsRef = useRef<ISector[]>(Jobs);
	const maxSelected = 5;
	const searchTM = useRef<any>(-1);
	const [SearchOptions, setSearchOptions] = useState<any>([]);
	const [IsMainJobModalDisplayed, setIsMainJobModalDisplayed] =
		useState(false);
	const [MainJob, setMainJob] = useState<any>(null);

	const { sectors: userSectors } = useContext(StepsContext);

	useEffect(() => {
		if (isActive) {
			if (!isStepValid && JobsRef.current?.length) {
				validateStep && validateStep({ Jobs: JobsRef.current });
			}
			!customNextCallback &&
				setCustomNextCallback &&
				setCustomNextCallback(() => {
					const jobs = JobsRef.current;
					if (jobs.length === 1) return submitStep();
					setIsMainJobModalDisplayed(true);
				});
		}
	}, [
		isActive,
		isStepValid,
		validateStep,
		customNextCallback,
		setCustomNextCallback,
		submitStep,
	]);

	const handleSelect = (option) => {
		let newJobs: any = [];
		if (Jobs.includes(option)) {
			newJobs =
				Jobs.filter((selectedSector) => selectedSector !== option) ??
				[];
			if (MainJob?.value === option.value) setMainJob(null);
		} else {
			if (Jobs.length >= maxSelected) return;
			if (!Jobs.length) setMainJob(option);
			newJobs = [...Jobs, option];
		}
		JobsRef.current = newJobs;
		if (newJobs.length) validateStep && validateStep({ Jobs: newJobs });
		else unvalidateStep && unvalidateStep();

		setJobs(newJobs);
	};

	const fetchOptions = (inputValue: string | null, sectors: ISector[]) => {
		return QualificationsService.postSearchedQualifications(
			inputValue || null,
			{
				searchByIdsSector: sectors.map((sector) => sector.id),
				limit: 1000,
			}
		).then(
			(resp) => {
				const items = resp.items
					.map(convToSelectValue)
					.sort((a, b) => a.label.localeCompare(b.label));
				setSearchOptions(items);
				return items;
			},
			(e) => {
				toast.error(t("onboarding.jobs.jobsLoadingError"));
			}
		);
	};

	const handleSearch = (searchTerm, sectors) => {
		clearTimeout(searchTM.current);
		const term = searchTerm?.length > 2 ? searchTerm : null;
		const prom = new Promise((resolve) => {
			searchTM.current = setTimeout(() => {
				fetchOptions(term, sectors).then((options) => {
					resolve(options);
					return options;
				});
			}, 800);
		});

		return prom;
	};

	const fetchUserQualifications = () =>
		QualificationsService.getUserQualifications();

	const [userJobs, initialSearch] = useQueries({
		queries: [
			{
				queryKey: ["onboarding", "selected", "jobs"],
				queryFn: fetchUserQualifications,

				refetchOnWindowFocus: false,
				refetchOnMount: false,
				enabled: isActive,
				onSuccess: (data) => {
					const jobs = [
						data.mainQualification,
						...(data.secondaryQualifications ?? []),
					]
						.filter((a) => a)
						.map(convToSelectValue);

					if (jobs?.length) {
						validateStep && validateStep({ Jobs: jobs });
						JobsRef.current = jobs;
						if (data.mainQualification)
							setMainJob(
								convToSelectValue(data.mainQualification)
							);
						else if (data.secondaryQualifications?.length) {
							setMainJob(
								convToSelectValue(
									data.secondaryQualifications[0]
								)
							);
						}
						setJobs(jobs);
					}
					return data;
				},
			},
			{
				queryKey: ["onboarding", "initialSearch", userSectors],
				queryFn: () => fetchOptions(null, userSectors),
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				enabled: isActive,
				onSuccess: (data) => {
					setSearchOptions(data);
					return data;
				},
			},
		],
	});

	const isLoading = userJobs.isLoading || initialSearch.isLoading;

	return (
		<div>
			<Title tag="h2" className="mb-8 !leading-7">
				{t("onboarding.jobs.title")}
			</Title>

			{isLoading ? (
				<Loader />
			) : (
				<Formik
					initialValues={{
						sectors: userSectors || [],
					}}
					onSubmit={(values) => {
						pushDataLayer({
							dataLayer: {
								event: "inscription__choix_metiers",
							},
						});
						return QualificationsService.postUserQualifications({
							mainQualification: MainJob?.id
								? { id: MainJob.id }
								: null,
							secondaryQualifications: Jobs.map((a) => ({
								id: a.id,
							})),
						}).catch((e) => {
							toast.error(t("promiseToast.error").toString());
							throw new Error(
								t("API_ERRORS.sendError").toString()
							);
						});
					}}
					innerRef={props.formRef}
				>
					{({ values, setFieldValue }) => {
						if (isLoading) return <Loader />;
						return (
							<Form>
								<div className="flex flex-col gap-l">
									<InfoMessage
										withIcon
										color={
											Jobs?.length < maxSelected
												? "primary"
												: "warning"
										}
									>
										{Jobs?.length < maxSelected ? (
											<Trans
												i18nKey="onboarding.jobs.remainingJobs"
												values={{
													count: Jobs?.length
														? maxSelected -
														  Jobs?.length
														: 0,
													max: maxSelected,
												}}
											/>
										) : (
											t("onboarding.jobs.maxSelected", {
												max: maxSelected,
											})
										)}
									</InfoMessage>

									<div className="flex gap-3">
										<SelectAsync
											className="hover:[&>div>div]:!bg-white [&>div>div]:!min-w-[300px] max-w-full"
											loadOptions={(searchTerm) =>
												handleSearch(
													searchTerm,
													values.sectors
												)
											}
											defaultOptions={SearchOptions}
											onChange={(option) => {
												if (values.inputValue?.length) {
													setFieldValue(
														"inputValue",
														values.inputValue
													);
												}
												handleSelect(option);
											}}
											filterOption={(option) =>
												!Jobs.find(
													(job: any) =>
														job.value ===
														option.value
												)
											}
											onInputChange={(value) => {
												setFieldValue(
													"inputValue",
													value
												);
											}}
											inputValue={values.inputValue}
											value={values.inputValue}
											isSearchable={true}
											type="text"
											name="inputValue"
											label={t(
												"onboarding.jobs.searchLabel"
											)}
											maxLength={50}
											loadingMessage={() => {
												return (
													<Spinner
														text={t(
															"global.optionLoading"
														)}
													/>
												);
											}}
											noOptionsMessage={({
												inputValue,
											}) =>
												!inputValue?.length
													? t(
															"onboarding.jobs.selectPlaceholder"
													  )
													: t(
															"onboarding.jobs.selectPlaceholderNoResult",
															{
																search: inputValue,
															}
													  )
											}
										/>
									</div>
									<div className="flex flex-col gap-2">
										{Jobs.map((job) => {
											const item = job as ISelectValue;
											return (
												<RadioListItem
													key={item.value}
													item={item}
													className="bg-white"
													onRemove={() => {
														handleSelect(job);
													}}
												/>
											);
										})}
									</div>
								</div>

								<ModalV2
									onClose={() =>
										setIsMainJobModalDisplayed(false)
									}
									isDisplayed={
										isActive && IsMainJobModalDisplayed
									}
									title={t(
										"onboarding.jobs.mainJobModalTitle"
									)}
									size="m"
								>
									<MainJobModal
										jobs={Jobs}
										selected={MainJob}
										onChange={(job) => {
											setMainJob(job);
										}}
										onClose={() =>
											setIsMainJobModalDisplayed(false)
										}
										onConfirm={() => {
											pushDataLayer({
												dataLayer: {
													event: "inscription__choix_metier_principal",
												},
											});
											setIsMainJobModalDisplayed(false);
											submitStep();
										}}
									/>
								</ModalV2>
							</Form>
						);
					}}
				</Formik>
			)}
		</div>
	);
};

const MainJobModal = ({ jobs, selected, onChange, onClose, onConfirm }) => {
	return (
		<div>
			<p className="mt-4">{t("onboarding.jobs.mainJobModalText")}</p>
			<div className="flex flex-col gap-2 mt-8">
				{jobs.map((job) => {
					return (
						<RadioListItem
							key={job.value}
							item={job}
							name={job.label}
							onClick={() => onChange(job)}
							radio
							isSelected={selected?.value === job.value}
						/>
					);
				})}
			</div>
			<div className="flex gap-4 mt-6">
				<Button color="primary" outline type="button" onClick={onClose}>
					{t("onboarding.jobs.modifyJobs")}
				</Button>
				<Button
					color={selected ? "primary" : "grey"}
					type="button"
					onClick={onConfirm}
					disabled={!selected}
				>
					{t("onboarding.jobs.confirmMainJob")}
				</Button>
			</div>
		</div>
	);
};

export default OnboardingJobs;
