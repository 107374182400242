import React from "react";
import { useQueries } from "@tanstack/react-query";
import { InfoMessage } from "@zolteam/react-ras-library";
import { t } from "i18next";
import TreatNotifications from "../../../services/NotificationsService";
import SVG from "../../atoms/SVG/SVG";
import Spinner from "../../atoms/Spinner/Spinner";
import NotificationsBox from "../NotificationsBox/NotificationsBox";
import ContractsService from "../../../services/ContractsService";
import PayslipService from "../../../services/PayslipService";
import SuccessEmail from "../../../assets/illustrations/email.svg";
interface IWhatsNew {
	icon?: boolean;
	noDataComponent?: React.ReactNode;
	title?: (count: number) => React.ReactNode;
	origin?: "Notifications" | "Il y a du nouveau"; // used for GTM
}
const WhatsNew: React.FC<IWhatsNew> = (props) => {
	const { icon = true, title, noDataComponent } = props;

	const filters = {
		months: [],
		years: [],
		agencies: [],
		sorting: "name",
		orderBy: "DESC",
		page: 1,
		limit: 30,
	};

	const [contractsQuery, payslipsQuery] = useQueries({
		queries: [
			{
				queryKey: ["contracts"],
				queryFn: () =>
					ContractsService.getContracts({
						sorting: "startDate",
					}),
			},

			{
				queryKey: ["payslips"],
				queryFn: () => PayslipService.getPayslips(filters),
			},
		],
	});

	const isLoading = payslipsQuery.isLoading || contractsQuery.isLoading;
	const hasError = payslipsQuery.error || contractsQuery.error;

	const resultNotifications = TreatNotifications(
		contractsQuery.data?.items,
		payslipsQuery.data?.items
	);

	return (
		<div className="relative w-[calc(100%+0.5rem)]">
			{isLoading && (
				<div className="absolute w-full h-full bg-white opacity-90 flex items-center justify-center">
					<Spinner
						text={t("notifications.loading")}
						className=" !opacity-100"
					/>
				</div>
			)}
			{hasError || !resultNotifications.length ? (
				<>
					{hasError && (
						<div className="[&>div]:w-full">
							<InfoMessage color="error" withIcon>
								{contractsQuery?.error && (
									<div>
										{t("notifications.errorContracts")}
									</div>
								)}
								{payslipsQuery?.error && (
									<div>
										{t("notifications.errorPayslips")}
									</div>
								)}
							</InfoMessage>
						</div>
					)}
					{noDataComponent ?? (
						<div className="mt-xl flex flex-col items-center">
							<InfoMessage withIcon>
								{t("notifications.noNotification")}
							</InfoMessage>
							<SVG src={SuccessEmail} />
						</div>
					)}
				</>
			) : (
				<>
					{title ? (
						title(resultNotifications.length)
					) : (
						<p className="mb-4 text-paragraph-01 font-bold">
							{t("notifications.new")}
						</p>
					)}
					<NotificationsBox
						notifications={resultNotifications}
						icon={icon}
						origin={props.origin}
					/>
				</>
			)}
		</div>
	);
};

export default WhatsNew;
