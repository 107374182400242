import React, { useEffect } from "react";

import AdvancePaymentService from "../../services/AdvancePaymentService";

import ListLayout from "../../components/templates/ListLayout/ListLayout";
import AdvancePaymentIcon from "../../assets/illustrations/acompteEuro.svg";
import AdvancePayment from "../../components/molecules/AdvancePayment/AdvancePayment";
import { Button, InfoMessage, ModalV2 } from "@zolteam/react-ras-library";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { AdvancePaymentForm } from "../../forms/AdvancePaymentForm/AdvancePaymentForm";
import { promiseToast } from "../../toastify/toastify";
import { toast } from "react-toastify";
import { t } from "i18next";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import moment from "moment";

const AdvancesPayment: React.FC = () => {
	const formRef = React.useRef<any>(null);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const isInitialMount = React.useRef(true);
	const queryClient = useQueryClient();

	const fetchHistory = () =>
		AdvancePaymentService.getAdvancePayments().then((data) => data.items);

	const fetchAgencies = () => AdvancePaymentService.allowAdvancePayment();

	const handleOpenModal = () => {
		pushDataLayer({
			dataLayer: {
				event: "demander_acompte",
			},
		});
		setIsModalOpen(true);
	};

	const handleSubmit = (values) => {
		const requestValues = {
			...values,
			desiredPaymentDate: moment(
				values.desiredPaymentDate,
				t("dates.format")
			).format(t("dates.apiFormatLight")),
			agencyId: values.agency?.value,
			requestedAmount: values.isMaximumAdvanceRequested
				? null
				: parseFloat(values.requestedAmount),
		};

		pushDataLayer({
			dataLayer: {
				event: "acompte__envoyer_demande",
				montant: values.isMaximumAdvanceRequested
					? "totalité"
					: "défini",
			},
		});
		const prom = AdvancePaymentService.postAdvancePayment(
			requestValues
		).then(
			(resp) => {
				pushDataLayer({
					dataLayer: {
						event: "demande_acompte_envoyee",
					},
				});
				setIsModalOpen(false);
				queryClient.invalidateQueries(["advancePayments"]);
				return resp;
			},
			(e) => {
				if (e?.response?.data?.message) {
					toast.dismiss("advancePayment");
					toast.error(
						t("advancePayment.errors." + e.response.data.message)
					);
				}
				throw e;
			}
		);

		promiseToast(
			prom,
			{
				success: t(
					"advancePayment.advancePaymentRequest.submitValidation"
				),
				error: t(
					"advancePayment.advancePaymentRequest.submitErrorMessage"
				),
			},
			{ toastId: "advancePayment" }
		);

		return prom;
	};

	const [
		{
			isLoading: isLoadingAgencies,
			data: contractsOnAgencies,
			error: agenciesError,
			isFetched: isAgenciesFetched,
		},
		{
			isLoading: isLoadingHistory,
			data: historyData,
			isFetched: isHistoryFetched,
		},
	] = useQueries({
		queries: [
			{
				queryKey: ["advancePayments", "allowAdvancePayment"],
				queryFn: fetchAgencies,
				retry: 0,
				refetchOnWindowFocus: false,
			},
			{
				queryKey: ["advancePayments", "history"],
				queryFn: fetchHistory,
				retry: 0,
				refetchOnWindowFocus: false,
			},
		],
	});

	const isDisabledWeek = (agenciesError as any)?.response?.status === 403;

	const isGenericAgencyError =
		(agenciesError as any)?.response?.status === 422;

	const canRequestAdvancePaymentThisWeek =
		contractsOnAgencies?.agencies?.some(
			(agency) => !agency.hasAlreadyRequestedAdvancePaymentThisWeek
		);

	const genInfoMessage = () => {
		if (isDisabledWeek) return t("advancePayment.infoMessageDisabledWeek");

		if (isGenericAgencyError) return t("advancePayment.cantAskAdvance");

		if (!contractsOnAgencies?.agencies?.length)
			return t("advancePayment.infoMessageDontHaveContract");

		if (!canRequestAdvancePaymentThisWeek)
			return t("advancePayment.infoMessageHaveContractCantAskAdvance");

		return t("advancePayment.infoMessageHaveContractCanAskAdvance");
	};

	const isAddButtonDisabled =
		isGenericAgencyError ||
		isDisabledWeek ||
		!contractsOnAgencies?.agencies?.length ||
		!canRequestAdvancePaymentThisWeek;

	useEffect(() => {
		if (isInitialMount.current && isAgenciesFetched && isHistoryFetched) {
			isInitialMount.current = false;
			pushDataLayer({
				dataLayer: {
					event: "acompte__voir_demandes",
					contrat_en_cours: !!contractsOnAgencies?.agencies?.length
						? "yes"
						: "no",
					demande_en_cours: canRequestAdvancePaymentThisWeek
						? "no"
						: "yes",
				},
			});
		}
	}, [
		isHistoryFetched,
		isAgenciesFetched,
		historyData,
		canRequestAdvancePaymentThisWeek,
		contractsOnAgencies,
	]);

	const infoMessage = genInfoMessage();

	return (
		<div className="flex flex-col h-full">
			<ListLayout
				title={t("advancePayment.title")}
				backgroundImage={AdvancePaymentIcon}
				noDataMessage={infoMessage}
				filters={false}
				pagination={false}
				items={historyData}
				isLoading={isLoadingHistory}
			>
				{(item) => <AdvancePayment item={item} />}
			</ListLayout>
			<div className="sticky bottom-0 flex flex-row flex-wrap items-center justify-end gap-4 pt-2 pb-4 bg-white">
				{!isLoadingAgencies && !!historyData?.length && (
					<div className="flex w-full [&>div]:mt-0 justify-end max-w-[500px] ml-auto">
						<InfoMessage color="primary" withIcon>
							{infoMessage}
						</InfoMessage>
					</div>
				)}
				<Button
					color={isAddButtonDisabled ? "grey" : "primary"}
					size="m"
					type="button"
					className="w-full sm:w-auto"
					onClick={handleOpenModal}
					isLoading={isLoadingAgencies || isLoadingHistory}
					disabled={isAddButtonDisabled}
				>
					{t("advancePayment.buttonLabel")}
				</Button>
			</div>
			<ModalV2
				title={t("advancePayment.advancePaymentRequest.title")}
				isDisplayed={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				size="s"
			>
				<AdvancePaymentForm
					innerRef={formRef}
					onCancel={() => {
						setIsModalOpen(false);
					}}
					onSubmit={handleSubmit}
				/>
			</ModalV2>
		</div>
	);
};
export default AdvancesPayment;
