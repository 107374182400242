/* eslint-disable max-len */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Text, Title, Button } from "@zolteam/react-ras-library";

// Templates
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";

// Forms
import { LoginForm } from "../../forms/LoginForm/LoginForm";
import { useFormikContext } from "formik";

// Services
import AuthService from "../../services/AuthService";
import LoginIdService from "../../services/LoginIdService";
import SessionService from "../../services/SessionService";

import { pushDataLayer } from "../../GoogleTagManager/gtm";
import TokenService from "../../services/TokenService";

interface ICredentials {
	id: string;
	password: string;
}

interface ILogin {
	title?: string;
	noInitMessage?: boolean;
	noSignUpMessage?: boolean;
	children?: React.ReactNode;
	resetForm?: (
		nextState?: Partial<typeof useFormikContext> | undefined
	) => void;
}

const Login: React.FC<ILogin> = (props) => {
	const [loginState, setLoginState] = useState({
		isLoading: false,
		error: "",
	});

	const { state } = useLocation();

	const navigate = useNavigate();

	const handleLogin = (
		{ id, password }: ICredentials,
		resetForm: ILogin["resetForm"]
	) => {
		setLoginState({
			isLoading: true,
			error: "",
		});
		return AuthService.login(id.toUpperCase(), password, {
			isOnboarding: state?.isOnboarding,
		}).then(
			(res) => {
				let prom = new Promise((resolve, reject) => {
					LoginIdService.setId(id);

					SessionService.fetchMe().then((res) => {
						pushDataLayer({
							dataLayer: {
								event: "connexion",
							},
						});

						SessionService.setUser(res);

						if (
							state?.isOnboarding ||
							SessionService.needOnboarding()
						) {
							TokenService.setOnboarding();
							navigate("/onboarding", {
								state: { ...state, isOnboarding: true },
							});
						} else window.location.replace("/");
						resolve(res);
					}, reject);
				});
				return prom;
			},
			(err) => {
				setLoginState({
					isLoading: false,
					error: err.message,
				});
				throw err;
			}
		);
	};

	return (
		<AuthLayout>
			<Title tag="h1" size="display02">
				{props.title ?? t("auth.title")}
			</Title>
			{props.children}
			{!props.noInitMessage && (
				<Text tag="span" className="flex flex-wrap mt-s gap-x-1 ">
					<span>{t("auth.firstLog")}</span>
					<Button
						color="transparentPrimary"
						type="button"
						onClick={() => {
							pushDataLayer({
								dataLayer: {
									event: "initialisation_du_compte",
								},
							});

							navigate("/initialize");
						}}
					>
						{t("auth.initAccount")}
					</Button>
				</Text>
			)}
			{!props.noSignUpMessage && (
				<Text tag="span" className="flex flex-wrap mt-1 gap-x-1 ">
					<span>{t("signup.title")}</span>
					<Button
						color="transparentPrimary"
						type="button"
						onClick={() => {
							pushDataLayer({
								dataLayer: {
									event: "s_inscrire",
								},
							});
							navigate("/signup");
						}}
					>
						{t("signup.link")}
					</Button>
				</Text>
			)}
			<LoginForm
				load={loginState.isLoading}
				error={loginState.error}
				handleSubmitForm={handleLogin}
			/>
		</AuthLayout>
	);
};

export default Login;
