import React, { useContext } from "react";
import RegisterService from "../../services/RegisterService";
import RegisterInitService from "../../services/RegisterInitService";
import SessionService from "../../services/SessionService";
import LoginIdService from "../../services/LoginIdService";
import TokenService from "../../services/TokenService";
import { RegisterPhoneForm } from "../../forms/RegisterForm/RegisterPhoneForm";
import { useQuery } from "@tanstack/react-query";
import { Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import {
	StepsContext,
	StepsContextType,
} from "../../contexts/StepsContext/StepsContext";
import { RegisterCodeForm } from "../../forms/RegisterForm/RegisterCodeForm";
import Loader from "../../components/atoms/Loader/Loader";
import { promiseToast } from "../../toastify/toastify";
import { IRegisterStepProps } from "./Register";

interface IRegisterFormValues {
	lastName: string;
	firstName: string;
	phoneNumber?: string;
	email: string;
	consent: boolean;
}

const RegisterPhone: React.FC<IRegisterStepProps> = (props) => {
	const { uniqueId } = useContext<StepsContextType>(StepsContext);
	const [UserData, setUserData] = React.useState<IRegisterFormValues>({
		lastName: "",
		firstName: "",
		phoneNumber: "",
		email: "",
		consent: false,
	});
	const [IsPopperOpen, setIsPopperOpen] = React.useState(false);
	const [CodeError, setCodeError] = React.useState("");

	const formContext = props.formRef?.current;

	// PromiseCallbacks is used to resolve or reject the promises returned by handleSubmitRegisterForm + handleSubmitCodeForm
	// it also prevents the user to submit the form and go to the next step if the code is not valid
	const [PromiseCallbacks, setPromiseCallbacks] = React.useState({
		isCodeValid: false,
		resolve: (value) => {},
		reject: (err) => {},
	});

	const fetchInfos = () =>
		SessionService.fetchMe().then((res) => {
			LoginIdService.setId(res.uniqueId);
			SessionService.setUser(res);
			TokenService.initAccount();
		});

	const handleRedirect = () => {
		window.location.assign("/");
	};

	const handleSubmitRegisterForm = (values: { phoneNumber: string }) => {
		if (!formContext) {
			setCodeError("form context is undefined");
			return;
		}
		RegisterInitService.setValue("phoneNumber", values.phoneNumber);
		setCodeError("");

		let prom = new Promise((resolve, reject) => {
			setPromiseCallbacks({ ...PromiseCallbacks, resolve, reject });
			setUserData({
				...UserData,
				phoneNumber: values.phoneNumber,
			});
			if (
				formContext.values.phoneNumber !==
				formContext.initialValues.phoneNumber
			)
				return sendCode(values.phoneNumber).then(() => {
					if (!values.phoneNumber) resolve(true);
					else setIsPopperOpen(true);
				});
			else resolve(true);
		});

		prom.then(() => {
			props.validateStep(true);
			props.setIsLoading(true);
			let infosProm = fetchInfos().then(() => {
				handleRedirect();
			});
			promiseToast(
				infosProm,
				{
					success: t("initRegister.success"),
				},
				{
					toastId: "fetchInfos",
				}
			);
			return infosProm;
		});
		return prom;
	};

	const sendCode = (phone) => {
		props.setIsLoading(true);
		let sendProm = RegisterService.sendMediaSecurityToken({
			uniqueId,
			phone: { value: phone ? phone : null },
		}).then(
			(resp) => {
				props.setIsLoading(false);
				return resp;
			},
			(err) => {
				const errorMessage = t(
					`API_ERRORS.${err.response?.data?.message}`
				);
				setCodeError(errorMessage);
				props.setError({
					message: errorMessage,
				});
				props.setIsLoading(false);
				throw err;
			}
		);
		if (phone)
			promiseToast(
				sendProm,
				{
					pending: t("initRegister.codeSend").toString(),
				},
				{
					toastId: "sendPhoneCode",
				}
			);
		return sendProm;
	};

	const handleSubmitCodeForm = (values: { activationCode: string }) => {
		return RegisterService.putVerifySecurityToken({
			uniqueId,
			securityToken: values.activationCode,
			phone: { value: UserData.phoneNumber },
		}).then(
			(res) => {
				formContext.initialValues.phoneNumber = UserData.phoneNumber;
				props.setError({});
				setIsPopperOpen(false);
				setPromiseCallbacks({ ...PromiseCallbacks, isCodeValid: true });
				return PromiseCallbacks.resolve(res);
			},
			(err) => {
				let errorMessage = t(
					"API_ERRORS." + (err.response?.data?.message ?? "default")
				);
				setCodeError(errorMessage);
				props.setError({ message: errorMessage });
				setPromiseCallbacks({
					...PromiseCallbacks,
					isCodeValid: false,
				});
				props.setIsLoading(false);
			}
		);
	};

	const fetchPhoneNumber = () => {
		return RegisterService.getUserRegistration().then(
			(res) => {
				setUserData(res);
				return res.phoneNumber;
			},
			(err) => {
				console.error(err.message);
			}
		);
	};

	const { isLoading: stepLoad, data } = useQuery(
		["registerPhone"],
		fetchPhoneNumber,
		{
			enabled: props.isActive,
			refetchOnWindowFocus: false,
			onSettled: () => {
				props.validateStep && props.validateStep({});
			},
		}
	);
	if (stepLoad) return <Loader />;

	return (
		<div>
			<Title
				tag="h2"
				lineHeight="m"
				size="heading01"
				className={"pb-xxl"}
			>
				{data ? t("registerPhone.title2") : t("registerPhone.title")}
			</Title>
			<RegisterPhoneForm
				handleSubmitForm={handleSubmitRegisterForm}
				phoneNumber={data ?? UserData.phoneNumber}
				formRef={props.formRef}
				validateStep={props.validateStep}
				isActive={props.isActive}
				isLoading={props.isLoading}
			/>
			{IsPopperOpen && (
				<RegisterCodeForm
					error={CodeError}
					handleResendCode={() => sendCode(UserData.phoneNumber)}
					handleSubmitForm={handleSubmitCodeForm}
					phone={UserData.phoneNumber}
					isLoading={props.isLoading}
					onClose={() => {
						setIsPopperOpen(false);
						if (!PromiseCallbacks.isCodeValid)
							props.setIsLoading(false);
					}}
				/>
			)}
		</div>
	);
};
export default RegisterPhone;
