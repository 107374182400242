import api from "./api";

class LocationServiceClass {
	getSearchLocations(searchPattern: string) {
		if (searchPattern.length < 3) {
			return Promise.resolve([]);
		}
		return api
			.post("/api-360/locations/available/search", {
				searchPattern: searchPattern,
				limit: 30,
			})
			.then((response) => response.data);
	}

	postUserLocations = ({ locations, mainLocation }) =>
		api.post("/api-360/location", { locations, mainLocation });

	getUserLocations = () =>
		api.get("/api-360/location").then((resp) => resp.data);
}

const LocationService = new LocationServiceClass();
export default LocationService;
