import React from "react";
import { Button, ModalV2, Text, InfoMessage } from "@zolteam/react-ras-library";
import { Form, Formik } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import { promiseToast } from "../../toastify/toastify";
import { IPersonalInformationsCategory } from "./PersonalInformations";
import RegisterService from "../../services/RegisterService";
import SessionService from "../../services/SessionService";
import { Trans } from "react-i18next";
import CountDown from "../../components/atoms/CountDown/CountDown";
import Spinner from "../../components/atoms/Spinner/Spinner";
import * as Yup from "yup";
import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/regex";
import { useQuery } from "@tanstack/react-query";
import ProfileService from "../../services/ProfileService";

const ContactValidation = () =>
	Yup.object().shape({
		phoneNumber: Yup.string()
			.nullable()
			.test("phone", t("registerPhone.error.shouldBePhone"), (value) => {
				if (!value) return true;
				return PHONE_REGEX.test(value);
			}),
		email: Yup.string()
			.nullable()
			.test("email", t("registerEmail.error.shouldBeMail"), (value) => {
				if (!value) return true;
				return EMAIL_REGEX.test(value);
			}),
	});

const ContactInformations: React.FC<IPersonalInformationsCategory> = ({
	infos,
	disabled,
	refetchInfos,
}) => {
	const [sendingState, setSendingState] = React.useState({
		loading: false,
		error: "",
		type: "",
	});
	const user = SessionService.getUser();

	const sendVerificationCode = (type: string, value: string) => {
		setSendingState({ loading: false, error: "", type: type });
		const prom = RegisterService.sendMediaSecurityToken({
			uniqueId: user.uniqueId,
			[type]: {
				value,
			},
		}).catch((err) => {
			setSendingState({
				loading: true,
				error: err.response.data.message,
				type: "",
			});

			throw err;
		});
		promiseToast(prom);
		return prom;
	};

	const handleSubmit = (values: any, formCtx) => {
		return sendVerificationCode(
			values.emailCheck ? "email" : "phone",
			values.emailCheck ? values.email : values.phoneNumber
		).catch(() => {
			formCtx.setFieldValue("emailCheck", false);
			refetchInfos();
		});
	};

	const { isLoading, data: contactVerification } = useQuery(
		["contactVerification"],
		() => {
			return ProfileService.getParams();
		}
	);
	if (isLoading)
		return (
			<>
				<b>{t("personalInfos.contact.title")}</b>
				<Spinner />
			</>
		);

	return (
		<div className={cn([disabled && "cursor-not-allowed"])}>
			<Formik
				initialValues={{
					...infos,
				}}
				validationSchema={ContactValidation()}
				onSubmit={(values, ctx) => handleSubmit(values, ctx)}
			>
				{({ values, setFieldValue, isSubmitting, errors }) => {
					const isDisabled = disabled || isSubmitting;
					const hasPhoneNumber =
						values.phoneNumber &&
						values.phoneNumber === values.initialPhoneNumber &&
						values.phoneNumber;
					const hasEmail =
						values.email &&
						values.email === values.initialEmail &&
						values.email;
					return (
						<Form className="relative flex flex-col items-start justify-start gap-4">
							<div
								className={cn([
									"flex flex-col gap-2 sm:w-auto w-full",
									isDisabled &&
										"pointer-events-none opacity-70",
								])}
							>
								<b>{t("personalInfos.contact.title")}</b>
								<div className="flex flex-col gap-2 mb-4">
									<div className="flex flex-wrap xs:flex-nowrap w-full gap-2 [&>*]:w-full">
										<Field
											label={t(
												"personalInfos.contact.phoneNumber"
											)}
											name="phoneNumber"
											type="text"
											readOnly={hasPhoneNumber}
											disabled={hasPhoneNumber}
											maxLength={10}
										/>
										{values.phoneNumber && (
											<Button
												color={
													hasPhoneNumber ||
													(values.phoneNumber &&
														!errors.phoneNumber)
														? "primary"
														: "grey"
												}
												type={
													!values.phoneNumber &&
													values.phoneNumber !==
														values.initialPhoneNumber
														? "button"
														: "submit"
												}
												onClick={() => {
													hasPhoneNumber
														? setFieldValue(
																"phoneNumber",
																""
														  )
														: setFieldValue(
																"phoneCheck",
																true
														  );
												}}
												className="!w-fit"
												size="s"
											>
												{values.phoneNumber ===
												values.initialPhoneNumber
													? t("global.modify")
													: t("global.validate")}
											</Button>
										)}
									</div>
									{contactVerification?.isPhoneVerified ? (
										<InfoMessage color="success" withIcon>
											{t(
												"personalInfos.contact.phoneVerified"
											)}
										</InfoMessage>
									) : (
										<InfoMessage color="warning" withIcon>
											<button
												type={"submit"}
												onClick={() =>
													setFieldValue(
														"phoneCheck",
														true
													)
												}
											>
												{t(
													"personalInfos.contact.phoneNotVerified"
												)}
											</button>
										</InfoMessage>
									)}
								</div>
								<div className="flex flex-col gap-2">
									<div className="flex flex-wrap xs:flex-nowrap w-full gap-2 [&>*]:w-full">
										<Field
											label={t(
												"personalInfos.contact.mail"
											)}
											name="email"
											type="text"
											readOnly={hasEmail}
											disabled={hasEmail}
										/>
										{values.email && (
											<Button
												color={
													hasEmail ||
													(values.email &&
														!errors.email)
														? "primary"
														: "grey"
												}
												type={
													!values.email &&
													values.email !==
														values.initialEmail
														? "button"
														: "submit"
												}
												onClick={() => {
													hasEmail
														? setFieldValue(
																"email",
																""
														  )
														: setFieldValue(
																"emailCheck",
																true
														  );
												}}
												className="!w-fit"
												size="s"
											>
												{values.email ===
												values.initialEmail
													? t("global.modify")
													: t("global.validate")}
											</Button>
										)}
									</div>
									{contactVerification?.isEmailVerified ? (
										<InfoMessage color="success" withIcon>
											{t(
												"personalInfos.contact.emailVerified"
											)}
										</InfoMessage>
									) : (
										<InfoMessage color="warning" withIcon>
											<button
												type={"submit"}
												onClick={() =>
													setFieldValue(
														"phoneCheck",
														true
													)
												}
											>
												{t(
													"personalInfos.contact.emailNotVerified"
												)}
											</button>
										</InfoMessage>
									)}
								</div>
							</div>
							{values.emailCheck && !sendingState.loading && (
								<ConfirmContactModal
									newValue={values.email}
									oldValue={values.initialEmail}
									setFieldValue={setFieldValue}
									onConfirmed={() => {
										refetchInfos();
										setFieldValue("emailCheck", false);
									}}
									onClose={() => {
										setFieldValue("emailCheck", false);
									}}
									handleResend={() =>
										sendVerificationCode(
											"email",
											values.email
										)
									}
									type="email"
								/>
							)}
							{values.phoneCheck && !sendingState.loading && (
								<ConfirmContactModal
									newValue={values.phoneNumber}
									oldValue={values.initialPhoneNumber}
									setFieldValue={setFieldValue}
									onConfirmed={() => {
										refetchInfos();
										setFieldValue("phoneCheck", false);
									}}
									onClose={() => {
										setFieldValue("phoneCheck", false);
									}}
									handleResend={() =>
										sendVerificationCode(
											"phone",
											values.phoneNumber
										)
									}
									type="phone"
								/>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

interface IConfirmContactModalProps {
	newValue: string;
	oldValue: string;
	onConfirmed?: () => void;
	onClose?: () => void;
	handleResend: () => Promise<any>;
	type?: "email" | "phone";
	setFieldValue: (field: string, value: any) => void;
}

const ConfirmContactModal: React.FC<IConfirmContactModalProps> = ({
	newValue,
	oldValue,
	onConfirmed,
	onClose,
	handleResend,
	type,
	setFieldValue,
}) => {
	const user = SessionService.getUser();

	const [sendingState, setSendingState] = React.useState({
		loading: false,
		error: "",
	});
	const [resendTimer, setResendTimer] = React.useState(false);

	const reSendCode = () => {
		const prom = handleResend().catch((err) => {
			setSendingState({
				loading: false,
				error: err.response.data.message,
			});
			throw err;
		});
		promiseToast(prom);
		setResendTimer(true);
		return prom;
	};

	const checkVerificationCode = (code: string) => {
		setSendingState({ loading: true, error: "" });
		const prom = RegisterService.putVerifySecurityToken({
			uniqueId: user.uniqueId,
			securityToken: code,
			[type]: {
				value: newValue,
			},
		}).then(
			(res) => {
				setSendingState({ loading: false, error: "" });
				onConfirmed && onConfirmed();
				return res;
			},
			(err) => {
				setSendingState({
					loading: false,
					error: err.response.data.message,
				});
				setFieldValue(
					type === "email" ? "email" : "phoneNumber",
					oldValue
				);
				throw err;
			}
		);
		promiseToast(prom);
		return prom;
	};

	return (
		<ModalV2
			isDisplayed={true}
			onClose={onClose}
			title={t("signup.emailVerification.title")}
			size="s"
		>
			<Text tag="p" size="paragraph01" className="mb-8">
				<Trans
					i18nKey={
						type === "email"
							? "registerCode.mailInfo"
							: "registerCode.phoneInfo"
					}
					values={{
						email: newValue,
						phone: newValue,
					}}
				/>
				<br />
				{t("signup.emailVerification.notReceived")}&nbsp;
				<button
					className={cn([
						"text-primary-500 font-bold",
						resendTimer && "opacity-70",
					])}
					disabled={resendTimer}
					onClick={resendTimer ? undefined : reSendCode}
				>
					{t("signup.emailVerification.resend")}
					{resendTimer && (
						<CountDown
							time={30}
							text={(time) => ` (${time})`}
							handleEnd={() => setResendTimer(false)}
						/>
					)}
				</button>
			</Text>
			<Formik
				initialValues={{
					activationCode: "",
				}}
				validateOnChange
				validateOnBlur={false}
				onSubmit={() => {
					// prop is mendatory but the submission is triggered by the validation
					// when the code reaches 4 characters
				}}
				validate={({ activationCode }) => {
					if (activationCode?.length === 4) {
						checkVerificationCode(activationCode);
					}
				}}
			>
				{() => {
					const isLoading = sendingState.loading;
					return (
						<Form>
							<div className="flex items-center gap-4">
								<Field
									name="activationCode"
									className={cn([
										"w-[200px] [&>div]:!rounded-2xl",
										"[&>*>input]:!pl-[1.2rem] [&>*>input]:!tracking-[0.4rem] [&>*>input]:!p-[10px] [&>*>input]:!text-center [&>*>input]:!text-2xl",
										isLoading &&
											"[&>*>input]:!text-neutral-500",
									])}
									type="text"
									label=""
									maxLength={4}
									readOnly={isLoading}
								/>
								{isLoading && <Spinner />}
							</div>
						</Form>
					);
				}}
			</Formik>
		</ModalV2>
	);
};

export default ContactInformations;
