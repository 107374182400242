import React from "react";
import { Button } from "@zolteam/react-ras-library";
import SVG from "../../components/atoms/SVG/SVG";
import HomeCalendar from "../../components/molecules/HomeCalendar/HomeCalendar";
import WhatsNew from "../../components/molecules/WhatsNew/WhatsNew";
import SearchIcon from "../../assets/illustrations/search.svg";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const HomeSideBox: React.FC = () => {
	return (
		<div className="HomeSideBox flex flex-col">
			<HomeCalendar />
			<hr className="my-5" />
			<WhatsNew
				noDataComponent={<EmptyNotifictationsBox />}
				origin="Il y a du nouveau"
			/>
		</div>
	);
};

const EmptyNotifictationsBox: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className="mt-6 flex flex-col items-center justify-center gap-l h-full w-full text-paragraph-01 text-center font-semibold">
			<SVG src={SearchIcon} className="w-full" />
			<Button
				color="primary"
				type="button"
				onClick={() => navigate("/offers")}
			>
				{t("offers.browse")}
			</Button>
			<div>{t("offers.callToAction")}</div>
		</div>
	);
};
export default HomeSideBox;
