import React from "react";

import AdvancePaymentService from "../../services/AdvancePaymentService";

import { Button } from "@zolteam/react-ras-library";
import { Toggle } from "../../components/atoms/Toggle/Toggle";
import { Form, Formik } from "formik";
import Field from "../../components/molecules/Field/Field";
import AgencySelect from "../../components/molecules/AgencySelect/AgencySelect";
import * as Yup from "yup";
import { t } from "i18next";
import DatePickerField from "../../components/molecules/DatePickerField/DatePickerField";
import moment from "moment";

const AdvancePaymentFormValidation = () =>
	Yup.object().shape({
		agency: Yup.object().required(t("formValidation.requiredField")),
		isMaximumAdvanceRequested: Yup.boolean().required(
			t("formValidation.requiredField")
		),
		requestedAmount: Yup.number().when("isMaximumAdvanceRequested", {
			is: (val) => val === false,
			then: () =>
				Yup.number().required(t("formValidation.requiredField")).min(1),
			otherwise: () => Yup.number().nullable(),
		}),
		desiredPaymentDate: Yup.string().required(
			t("formValidation.requiredField")
		),
	});

interface IAdvancesPaymentProps {
	innerRef: React.MutableRefObject<any>;
	onCancel: () => void;
	onSubmit: (values) => void;
}

const getNextDay = (dayNumber) => {
	const date = new Date();
	const day = date.getDay();
	const diff = dayNumber - day;
	date.setDate(date.getDate() + diff);
	// if today is friday, return next friday
	date.setDate(date.getDate() + (diff === 0 ? 7 : 0));
	return date;
};

const isDay = (date, dayNumber) => date.getDay() === dayNumber;

export const AdvancePaymentForm: React.FC<IAdvancesPaymentProps> = ({
	innerRef,
	onCancel,
	onSubmit,
}) => {
	const [Agencies, setAgencies] = React.useState<any>([]);
	const fetchAgencies = () =>
		AdvancePaymentService.allowAdvancePayment().then((resp) => {
			const filtered = resp?.agencies?.filter(
				(agency) => !agency.hasAlreadyRequestedAdvancePaymentThisWeek
			);
			setAgencies(filtered);
			return { agencies: filtered };
		});

	return (
		<Formik
			initialValues={{
				requestedAmount: "",
				isMaximumAdvanceRequested: false,
				agency: undefined,
				desiredPaymentDate: moment(getNextDay(5)).format(
					t("dates.format")
				),
			}}
			validationSchema={AdvancePaymentFormValidation()}
			onSubmit={onSubmit}
			innerRef={innerRef}
			validateOnMount
		>
			{({ values, setFieldValue, isValid, isSubmitting, errors }) => {
				return (
					<Form className="flex flex-col gap-6">
						<AgencySelect
							isMulti={false}
							query={{
								key: ["allowAdvancePayment"],
								fn: () =>
									fetchAgencies().then((resp) => {
										if (resp?.agencies?.length === 1) {
											setFieldValue("agency", {
												value: resp.agencies[0].id,
												label: resp.agencies[0].name,
											});
										}
										return resp;
									}),
							}}
							onChange={(agency) => {
								setFieldValue("agency", agency);
							}}
							value={values.agency}
							label={t(
								"advancePayment.advancePaymentRequest.agency"
							)}
							disabled={Agencies?.length <= 1}
						/>
						<p>
							{t(
								"advancePayment.advancePaymentRequest.placeholderLabel"
							)}
						</p>
						<Toggle
							label={t(
								"advancePayment.advancePaymentRequest.switchLabel"
							)}
							name="isMaximumAdvanceRequested"
							onChange={(event) => {
								setFieldValue(
									"isMaximumAdvanceRequested",
									event.target.checked
								);
							}}
							checked={values.isMaximumAdvanceRequested}
						/>
						{!values.isMaximumAdvanceRequested && (
							<Field
								label={t("advancePayment.amount")}
								name="requestedAmount"
								placeholder={t(
									"advancePayment.amountPlaceholder"
								)}
								type="text"
								onChange={(event) => {
									if (isNaN(Number(event.target.value)))
										return;
									setFieldValue(
										"requestedAmount",
										event.target.value
									);
								}}
								maxLength={4}
							>
								<span className="absolute text-lg -translate-y-1/2 right-4 top-7 text-neutral-500">
									€
								</span>
							</Field>
						)}
						<DatePickerField
							name="desiredPaymentDate"
							value={values.desiredPaymentDate}
							label={t(
								"advancePayment.advancePaymentRequest.desiredPaymentDate"
							)}
							onChange={(date) => {
								setFieldValue(
									"desiredPaymentDate",
									moment(date).format(t("dates.format"))
								);
							}}
							pickerProps={{
								filterDate: (day) => isDay(day, 5),
								excludeDates: [
									isDay(new Date(), 5) && new Date(),
								],
								minDate: moment
									.max(moment(), moment(getNextDay(5)))
									.toDate(),
								maxDate: moment(getNextDay(5))
									.endOf("month")
									.add(7, "days")
									.toDate(),
							}}
						/>
						<div className="flex flex-row items-end justify-end gap-4 pt-6 mt-6 border-t border-neutral-100">
							<Button
								color="primary"
								type="submit"
								outline
								onClick={onCancel}
								disabled={isSubmitting}
							>
								{t("global.cancel")}
							</Button>
							<Button
								color="primary"
								type="submit"
								disabled={!isValid || isSubmitting}
								isLoading={isSubmitting}
							>
								{t("global.send")}
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
