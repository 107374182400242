import api from "./api";

export interface ISendAbsence {
	id?: number;
	commonTimeOffReasonId: number;
	startDate: string;
	finishDate: string;
	allDay: boolean;
	startTime: string;
	finishTime: string;
	attachments: any[];
}

class AbsencesServiceClass {
	getAbsences() {
		return api
			.get("/api-360/worker/time-off-request/list")
			.then((response) => {
				return response.data;
			});
	}

	updateAbsence(values) {
		return api
			.put(
				`/api-360/worker/time-off-request/update/${values.id}`,
				values,
				{
					method: "PUT",
					headers: {
						"Content-Type": "multipart/form-data",
						Accept: "*/*",
					},
				}
			)
			.then((response) => {
				return response.data;
			});
	}

	postAbsence(values) {
		return api
			.post("/api-360/worker/time-off-request/add", values, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				return response.data;
			});
	}

	deleteAbsence = (id) =>
		api.delete(`/api-360/worker/time-off-request/delete/${id}`);

	getReasons() {
		return api
			.get("/api-360/common/time-off-reason/list", {})
			.then((response) => {
				return response.data;
			});
	}
}

const AbsencesService = new AbsencesServiceClass();
export default AbsencesService;
