import api from "./api";

export const DEFAULT_ALL_JOB_TYPES = [
	{
		name: "CDD",
		id: 1,
	},
	{
		name: "CDI",
		id: 2,
	},
	{
		name: "Intérim",
		id: 3,
	},
	{
		name: "Stage",
		id: 4,
	},
	{
		name: "Apprentissage/Alternance",
		id: 5,
	},
];

export const DEFAULT_JOB_TYPES = [
	{
		name: "CDD",
		id: 1,
	},
	{
		name: "CDI",
		id: 2,
	},
	{
		name: "Intérim",
		id: 3,
	},
];

class AnnouncementServiceClass {
	getAnnouncement(filters) {
		return api
			.post("/api-360/announcement/search", {
				orderBy: ["publishedAt"],
				limit: 100,
				page: 1,
				orderByDesc: true,
				...filters,
			})
			.then((response) => {
				return response.data;
			});
	}

	getFavorites() {
		return api.get("/api-360/announcement/favorites").then((response) => {
			return response.data;
		});
	}

	getApplyAnnouncement() {
		return api.get("/api-360/announcement/apply").then((response) => {
			return response.data;
		});
	}

	postAnnouncement(announcement) {
		return api
			.post("/api-360/announcement/to-apply-or-add-favored", announcement)
			.then((response) => {
				return response.data;
			});
	}
}

const AnnouncementService = new AnnouncementServiceClass();
export default AnnouncementService;
