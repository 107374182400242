import api from "./api";

class ProfileServiceClass {
	/**
	 * Get the profile completion
	 * @returns {Promise<any>}
	 */
	getProfileCompletion() {
		return api.get("/api-360/profile/completion").then((resp) => resp.data);
	}

	/**
	 * Get nationalities list
	 * @returns {Promise<any>}
	 */
	getNationalities() {
		return api.get("/api-360/nationalities/list").then((resp) => resp.data);
	}

	/**
	 * Get profile info
	 * @returns {Promise<any>}
	 */
	getProfile() {
		return api
			.get("/api-360/profile/personal-information")
			.then((resp) => resp.data);
	}

	/**
	 * Update profile info
	 * @param {{sex: string, dateOfBirth: string, bornCity: string, nationalityId: number, nativeCountryId: number}} data
	 * @returns {Promise<any>}
	 */
	putProfileIdentity(data) {
		return api.put("/api-360/profile/identity/update", data);
	}

	/**
	 * Update profile ssn info
	 * @param {{sex: string, bornCity: string, nationalityId: number, nativeCountryId: number}} data
	 * @returns {Promise<any>}
	 */
	putProfileSocialSecurityNumber(data) {
		return api.put("/api-360/profile/social-security-number/update", data);
	}

	/**
	 * Get countries list
	 * @returns {Promise<any>}
	 */
	getCountries() {
		return api.get("/api-360/countries/list").then((resp) => resp.data);
	}

	/**
	 * Get family situation list
	 * @returns {Promise<any>}
	 */
	getFamilySituationList() {
		return api
			.get("/api-360/family-situations/list")
			.then((resp) => resp.data);
	}

	/**
	 * Update family situation list
	 * @param {{childrenQty: number, familySituation: number}} data
	 * @returns {Promise<any>}
	 */
	putProfileFamilySituation(data) {
		return api.put("/api-360/profile/family-situation/update", data);
	}

	/**
	 * Update postal address
	 * @param data
	 * @returns {Promise<any>}
	 */
	putPostalAddress(data) {
		return api.put("/api-360/profile/postal-address/update", data);
	}

	uploadAvatar(data) {
		return api.post(
			"/api-360/avatar/upload",
			{
				avatar: data,
			},
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Accept: "*/*",
				},
			}
		);
	}

	removeAvatar() {
		return api.delete("/api-360/avatar/delete");
	}

	getParams() {
		return api.get("/api-360/parameters").then((resp) => resp.data);
	}
}

const ProfileService = new ProfileServiceClass();
export default ProfileService;
