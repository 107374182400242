import React from "react";
import { ModalV2, Button } from "@zolteam/react-ras-library";
import { DefaultTFuncReturn, t } from "i18next";
import cn from "../../../utils/cn";

interface IConfirmModalProps {
	className?: string;
	isOpen: boolean;
	onConfirm: () => void;
	onCancel?: () => void;
	onClose: () => void;
	title: string | DefaultTFuncReturn;
	text?: string | DefaultTFuncReturn;
	cancelText?: string | DefaultTFuncReturn;
	confirmText?: string | DefaultTFuncReturn;
	children?: React.ReactNode;
	size?: "s" | "m" | "l";
}

const ConfirmModal: React.FC<IConfirmModalProps> = (
	props: IConfirmModalProps
) => {
	const { isOpen, onConfirm, onClose, onCancel } = props;
	const [isLoading, setIsLoading] = React.useState(false);

	const handleConfirm = () => {
		let res: any = onConfirm();
		setIsLoading(true);
		if (!res?.then) {
			res = new Promise((resolve) => resolve(res));
		}
		res.then(
			() => {
				setIsLoading(false);
				onClose();
			},
			() => {
				setIsLoading(false);
				onClose();
			}
		);
	};
	return (
		<ModalV2
			isDisplayed={isOpen}
			onClose={onClose}
			size={props.size ?? "m"}
			className={cn([
				"ConfirmModal !min-w-[90%] sm:!min-w-[70%] md:!min-w-0",
				props.className,
			])}
			title={props.title}
		>
			<div className="flex flex-col text-left gap-m">
				<div className="mb-5">{props.children ?? props.text}</div>
				<div className="flex justify-end gap-m">
					<Button
						type="button"
						color="primary"
						outline
						className="!text-primary-500"
						onClick={onCancel ?? onClose}
						disabled={isLoading}
					>
						{props.cancelText ?? t("global.cancel")}
					</Button>
					<Button
						type="button"
						color="primary"
						onClick={handleConfirm}
						isLoading={isLoading}
						className="confirmButton"
					>
						{props.confirmText ?? t("global.confirmDelete")}
					</Button>
				</div>
			</div>
		</ModalV2>
	);
};

export default ConfirmModal;
