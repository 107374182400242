export const getMobileOperatingSystem = () => {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Check for Android
	if (/android/i.test(userAgent)) return "Android";

	// Check for iOS
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";

	// If the OS is not explicitly detected, return null
	return null;
};
