import React from "react";
import { Button, Picto, Tag, Modal } from "@zolteam/react-ras-library";
import { DefaultTFuncReturn, t } from "i18next";
import cn from "../../../utils/cn";
import Drawer from "../Drawer/Drawer";
import ImageViewer from "../ImageViewer/ImageViewer";
import Loader from "../../atoms/Loader/Loader";
import imageCompression from "browser-image-compression";
import { blobToArrayBuffer } from "../../../utils/blob";
import PDFViewer from "../PDFViewer/PDFViewer";

interface IFileImporterProps {
	name: string;
	label?: string | DefaultTFuncReturn;
	file?: ArrayBuffer;
	fileName?: string;
	onChange: (file: ArrayBuffer, fileName: string, raw: any) => void;
	onRemove?: () => void;
	disabled?: boolean;
	accept?: string;
	className?: string;
	onCompress?: (value: number) => void;

	// cette prop sert a contourner le conflit lorsqu'une modal de Zol est imbriqué dans une autre
	// -> il faut alors passer isInModal={true} pour que le body ne redevienne pas scrollable
	//	  lorsque la modale imbriquée est fermée
	isInModal?: boolean;
}

const FileImporter = ({ file, ...props }: IFileImporterProps) => {
	const [FileName, setFileName] = React.useState<string>(
		props.fileName ?? ""
	);
	const [previewUrl, setPreviewUrl] = React.useState<string>("");
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [Compressing, setCompressing] = React.useState<number>(-1);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (!file) return;
		setFileName(file.name);
		setCompressing(-1);
		if (file.type.includes("image")) {
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				onProgress: (progress) => {
					setCompressing(progress);
					props.onCompress && props.onCompress(progress);
				},
			};
			imageCompression(file, options)
				.then(function (compressedFile) {
					blobToArrayBuffer(compressedFile).then((result: any) => {
						props.onChange &&
							props.onChange(result, file.name, file);
						setPreviewUrl(URL.createObjectURL(file));
						setCompressing(-1);
					});
				})
				.catch(function (error) {
					console.error(error.message);
					setCompressing(-1);
				});
		} else {
			blobToArrayBuffer(file).then((result: any) => {
				props.onChange && props.onChange(result, file.name, file);
				setPreviewUrl(URL.createObjectURL(file));
			});
		}
	};

	const closeModal = () => {
		if (props.isInModal) {
			// on remet le body en non scrollable si on est dans une modale imbriquée
			document.body.style.position = "fixed";
			document.body.style.top = `-${window.scrollY}px`;
			document.body.style.width = "100%";
		}
		setShowModal(false);
	};

	if (file?.byteLength) {
		return (
			<>
				<div
					className="cursor-pointer"
					onClick={() => {
						setPreviewUrl(URL.createObjectURL(new Blob([file])));
						setShowModal(true);
					}}
				>
					<Tag
						color="grey"
						outline
						size="m"
						className={cn([
							"flex justify-between w-full p-3 px-6 !border-neutral-300 !bg-white !border-2 text-paragraph-01",
							props.disabled &&
								"opacity-70 pointer-events-none !cursor-not-allowed",
						])}
					>
						<div className="leading-5 truncate whitespace-pre-wrap text-ellipsis line-clamp-2">
							{FileName}
						</div>
						{!!props.onRemove && (
							<Button
								type="button"
								color="transparent"
								disabled={props.disabled}
								className={`!p-1 !m-0 ${
									!props.onRemove &&
									"opacity-0 pointer-events-none"
								}`}
								onClick={(e) => {
									e.stopPropagation();
									setShowModal(false);
									props.onRemove && props.onRemove();
								}}
							>
								<Picto
									icon="trash"
									style={{
										width: "20px",
										height: "20px",
									}}
									className={cn([
										" !m-0 !p-0",
										props.disabled
											? "stroke-neutral-400 !cursor-not-allowed"
											: "stroke-primary-500",
									])}
								/>
							</Button>
						)}
					</Tag>
				</div>

				{showModal && (
					<Modal
						onClose={() => closeModal()}
						isDisplayed={showModal && previewUrl?.length > 0}
						className="h-[90vh] !min-w-0 w-[90vw] overflow-hidden"
					>
						<Loader />
						<Drawer
							isOpen={true}
							onClose={() => closeModal()}
							navigation={false}
						>
							{() => {
								if (FileName.match(/^.*\.(jpeg|jpg|png)$/))
									return (
										<ImageViewer
											downloadTitle={FileName}
											file={previewUrl}
											onBack={() => closeModal()}
										/>
									);
								else if (FileName.match(/^.*\.(pdf)$/)) {
									return (
										<PDFViewer
											downloadTitle={FileName}
											file={previewUrl}
											onBack={() => closeModal()}
										/>
									);
								}
							}}
						</Drawer>
					</Modal>
				)}
			</>
		);
	}

	return (
		<div className={cn(["relative", props.className])}>
			<Button
				type="button"
				color="primary"
				className="self-start w-full"
				icon="upload"
				isLoading={Compressing > -1}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{Compressing > -1 ? (
					<span>
						{t("compressor.progress", {
							percent: Compressing,
						})}
					</span>
				) : (
					<>
						<Picto icon="upload" />
						{props.label ?? t("files.import")}
						<input
							type="file"
							id="file-importer"
							onChange={handleFileChange}
							accept={props.accept ?? ".pdf"}
							disabled={props.disabled || Compressing > -1}
							className="absolute top-0 left-0 w-full h-full border border-red-600 opacity-0 cursor-pointer"
						/>
					</>
				)}
			</Button>
		</div>
	);
};

export default FileImporter;
