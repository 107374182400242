import { Button, Picto } from "@zolteam/react-ras-library";
import { t } from "i18next";
import React from "react";
import ConfirmModal from "../../molecules/ConfirmModal/ConfirmModal";
import TokenService from "../../../services/TokenService";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";

export const disconnect = () => {
	pushDataLayer({
		dataLayer: {
			event: "deconnexion_validee",
		},
	});
	TokenService.removeUser();
	sessionStorage.clear();
	window.location.assign("/");
};

const DisconnectButton: React.FC = () => {
	const [modal, setModal] = React.useState(false);

	return (
		<>
			<Button
				type="button"
				color="transparent"
				onClick={() => {
					pushDataLayer({
						dataLayer: {
							event: "deconnexion",
						},
					});
					setModal(true);
				}}
				className="!text-error-600 w-full"
			>
				<Picto icon="logout" className="[&>g]:!text-error-600" />
				{t("auth.logout.title")}
			</Button>
			<ConfirmModal
				className="z-[9999]"
				isOpen={modal}
				onConfirm={disconnect}
				onCancel={() => setModal(false)}
				onClose={() => setModal(false)}
				title={t("auth.logout.confirmTitle")}
				text={t("auth.logout.confirmText")}
				confirmText={t("auth.logout.confirmBtn")}
			/>
		</>
	);
};

export default DisconnectButton;
