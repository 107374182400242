import React from "react";
import "./DatePickerField.scss";
import Field from "../Field/Field";
import { Popover, Button, Picto } from "@zolteam/react-ras-library";
import DatePicker from "../DatePicker/DatePicker";
import { DefaultTReturn, TOptions } from "i18next";
import { DATE_REGEX } from "../../../utils/regex";
import { useMaskito } from "@maskito/react";
import { maskitoDateOptionsGenerator } from "@maskito/kit";
import { useFormikContext } from "formik";

interface IDatePickerFieldProps {
	label: string | DefaultTReturn<TOptions>;
	name: string;
	onChange: (value: any) => void;
	value: string;
	onFieldChange?: (value: any) => void;
	pickerProps?: {
		[key: string]: any;
	};
	disabled?: boolean;
	disableInput?: boolean;
}

const DatePickerField: React.FC<IDatePickerFieldProps> = ({
	label,
	name,
	onChange,
	value,
	pickerProps = {},
	disabled,
	disableInput,
	onFieldChange,
	...props
}) => {
	const [Value, setValue] = React.useState<Date>(new Date());

	const getCurDate = (date: string) => {
		if (!date?.length || !DATE_REGEX.test(date)) return Value;

		return new Date(date.split("/").reverse().join("/"));
	};

	//Masque pour l'input date
	const options = maskitoDateOptionsGenerator({
		mode: "dd/mm/yyyy",
		separator: "/",
	});
	const inputRef = useMaskito({ options });
	const formikContext = useFormikContext();

	const buttonRef = React.useRef<HTMLButtonElement>(null);

	return (
		<div className={`relative w-full`}>
			<Field
				innerRef={inputRef}
				name={name}
				label={label}
				type="text"
				disabled={disabled}
				onInput={(v: React.ChangeEvent<HTMLInputElement>) => {
					!disableInput &&
						formikContext.setFieldValue(name, v.target.value);
				}}
				readOnly={disableInput}
				onClick={(e: any) => {
					e.target.blur(); // remove focus from input
					if (buttonRef.current) {
						setTimeout(() => {
							// wait for blur to finish and then click the button to open the date picker
							buttonRef.current?.parentElement?.click();
						}, 10);
					}
				}}
			>
				<div className="absolute top-2 right-2 [& button svg]:!m-0">
					<Popover
						key={Date.now().toString()}
						disabled={disabled}
						content={
							<DatePicker
								showYearDropdown
								showMonthDropdown
								dropdownMode="select"
								inline
								selected={getCurDate(value)}
								{...pickerProps}
								onChange={(val: Date) => {
									setValue(val);
									onChange(val);
								}}
							/>
						}
					>
						<Button color="white" type="button" ref={buttonRef}>
							<Picto icon="calendar" className="!m-0" />
						</Button>
					</Popover>
				</div>
			</Field>
		</div>
	);
};

export default DatePickerField;
