import * as Yup from "yup";
import { IBAN_REGEX, BIC_REGEX } from "../../utils/regex";

export const BankInfosFormInitValues = {
	iban: "",
	bic: "",
	rib: {
		folder: "",
		link: "",
		name: "",
		uuid: "",
	},
	file: new ArrayBuffer(0),
};

export const BankInfosFormValidation = (t) =>
	Yup.object().shape({
		// test if file field arraybuffer is not empty or rib field is not empty
		file: Yup.mixed().test(
			"file",
			t("bankInfos.error.fileRequired"),
			(value, ctx) => {
				return value.byteLength || ctx.parent.rib?.uuid?.length;
			}
		),

		iban: Yup.string()
			// test iban length
			.test("iban", t("bankInfos.error.badIban"), (value) =>
				value ? IBAN_REGEX.test(value) : true
			)
			.required(t("bankInfos.error.ibanRequired")),
		bic: Yup.string()
			.test("bic", t("bankInfos.error.badBic"), (value) =>
				value ? BIC_REGEX.test(value) : true
			)
			.required(t("bankInfos.error.bicRequired")),
	});
