import { Button, InfoMessage, Picto, Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import { Trans } from "react-i18next";
import React, { useState } from "react";
import LocationInputForm from "../../forms/LocationInputForm/LocationInputForm";
import { Form, Formik } from "formik";
import LocationService from "../../services/LocationService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import ConfirmModal from "../../components/molecules/ConfirmModal/ConfirmModal";

interface ILocation {
	id: string;
	name: string;
	postalCode: string;
}
const Workplaces = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [location, setLocation] = React.useState<ILocation>();
	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery(["locations"], () =>
		LocationService.getUserLocations()
	);

	if (isLoading) return <Loader />;

	const mainLocation = data?.mainLocation;

	const handleDelete = (location: ILocation) => {
		setIsModalOpen(true);
		setLocation(location);
	};

	const handleSendLocation = (locationId: number) => {
		const newLocations = data.locations;
		sendLocation(newLocations, locationId);
	};

	const handleDeleteConfirm = () => {
		const newLocations = data.locations.filter(
			(dataLocation: ILocation) => dataLocation.id !== location?.id
		);
		sendLocation(newLocations);
	};

	const sendLocation = (newLocations: ILocation[], locationId?: number) => {
		const newLocationsId = newLocations?.map(
			(location: ILocation) => location.id
		);

		LocationService.postUserLocations({
			locations: locationId
				? [...newLocationsId, locationId]
				: newLocationsId,

			mainLocation: data.mainLocation.id,
		}).then(() => {
			queryClient.refetchQueries(["locations"]);
		});
	};

	return (
		<>
			<Title tag="h1">{t("profilePro.workplaces.title")}</Title>
			<div className=" lg:ml-xxl">
				<Title tag="h2">{t("profilePro.workplaces.workplace")}</Title>

				<div className="w-[25rem] py-m w-full">
					<InfoMessage color="primary" withIcon>
						<Trans i18nKey="profilePro.workplaces.infos" />
					</InfoMessage>
				</div>
				<div className="w-full lg:w-[30%] ">
					<Button
						type="button"
						color="grey"
						disabled={true}
						size="l"
						className="justify-start w-full mb-xl"
					>
						{mainLocation.name} ({mainLocation.postalCode})
					</Button>

					<Title tag="h2" className="mb-m">
						{t("profilePro.workplaces.otherWorkplaces")}
					</Title>
					<Formik
						initialValues={{ location: "" }}
						onSubmit={() => {}}
					>
						{({ values, resetForm, ...formikProps }) => {
							/*	if (values.location !== "") {
								handleSendLocation(values.location.value);
							}*/
							return (
								<Form>
									<LocationInputForm
										formikProps={formikProps}
										values={values}
										handleSendLocation={handleSendLocation}
										resetForm={resetForm}
										send={true}
									/>
									{data.locations.map(
										(location: ILocation) => {
											return (
												<Button
													key={location.id}
													type="button"
													color="grey"
													size="m"
													outline
													className="w-full mt-xl justify-between !pr-m"
													onClick={() =>
														handleDelete(location)
													}
												>
													{location.name} (
													{location.postalCode})
													<Picto
														icon="trash"
														className="w-5 h-5 [&>*]:text-primary-500"
													/>
												</Button>
											);
										}
									)}
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
			<ConfirmModal
				title={t("profilePro.workplaces.modalTitle")}
				isOpen={isModalOpen}
				confirmText={t("profilePro.workplaces.modalConfirm")}
				onClose={() => setIsModalOpen(false)}
				onConfirm={handleDeleteConfirm}
				className="w-[35%]"
			>
				<Trans
					i18nKey="profilePro.workplaces.modalText"
					values={{
						name: location?.name,
						postalCode: location?.postalCode,
					}}
				/>
			</ConfirmModal>
		</>
	);
};

export default Workplaces;
