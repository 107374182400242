import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { t } from "i18next";
import { Button, Picto, InfoMessage } from "@zolteam/react-ras-library";
import { useNavigate } from "react-router-dom";

// Forms
import { LoginFormInitValues, LoginFormValidation } from "./validationForm";

// Components
import RandomNumPad from "../../components/atoms/RandomNumPad/RandomNumPad";
import Field from "../../components/molecules/Field/Field";

import cn from "../../utils/cn";
import { Trans } from "react-i18next";
import LoginIdService from "../../services/LoginIdService";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import PasswordField from "../../components/molecules/PasswordField/PasswordField";

interface LoginFormProps {
	load: boolean;
	error: string;
	handleSubmitForm: (
		values: typeof LoginFormInitValues,
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => Promise<any>;
}
export const LoginInput: React.FC = () => {
	const navigate = useNavigate();
	const { setFieldValue, setFieldTouched, values } =
		useFormikContext<typeof LoginFormInitValues>();

	return (
		<>
			<div className="relative mb-xxl">
				<Field
					type="text"
					name="id"
					label={t("auth.login.id")}
					className={
						"mb-s [&>*>input]:uppercase [&>*>input::placeholder]:normal-case"
					}
					maxLength={7}
				>
					<Button
						color="transparentPrimary"
						size="s"
						type="button"
						className="absolute right-[1rem] top-[110%]"
						onClick={() => {
							pushDataLayer({
								dataLayer: {
									event: "connexion__identifiant_oublie",
									pageUrl: window.location.href,
									pageTitle: t("auth.title").toString(),
								},
							});

							navigate("/login-retrieve");
						}}
					>
						{t("auth.login.idForgot")}
					</Button>
				</Field>
			</div>
			<div className="relative mb-m flex flex-col">
				<PasswordField
					type="password"
					name="password"
					label={t("auth.login.password")}
					readOnly
					className="mb-s pointer-events-none"
				>
					<>
						{values.password?.length > 0 ? (
							<Picto
								icon="delete"
								className={cn([
									"text-neutral-400 cursor-pointer",
								])}
								onClick={() => {
									setFieldTouched("password", true, false);
									setFieldValue("password", "");
								}}
							/>
						) : null}
					</>
				</PasswordField>
				<Button
					color="transparentPrimary"
					size="s"
					type="button"
					className="ml-auto mr-m"
					onClick={() => {
						pushDataLayer({
							dataLayer: {
								event: "connexion__mot_de_passe_oublie",
							},
						});
						LoginIdService.setId(values.id);
						navigate("/password-retrieve");
					}}
				>
					{t("auth.login.passwordForgot")}
				</Button>
			</div>
		</>
	);
};

export const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
	const navigate = useNavigate();
	const LoginValueSchema = {
		...LoginFormInitValues,
	};

	let previousId = LoginIdService.getId();
	if (previousId) LoginValueSchema.id = previousId;

	return (
		<Formik
			initialValues={LoginValueSchema}
			validationSchema={LoginFormValidation(t)}
			onSubmit={(values, { resetForm, setFormikState }) => {
				props.handleSubmitForm(values, resetForm).catch((e) => {
					if (e.response?.status !== 401)
						setFormikState((state) => {
							return {
								...state,
								submitCount:
									state.submitCount > 0
										? state.submitCount - 1
										: 0,
							};
						});
					else {
						setFormikState((state) => {
							if (state.submitCount === 3) {
								navigate("/forgot-login");
								resetForm();
							}
							return {
								...state,
							};
						});
					}
				});
			}}
		>
			{(formContext) => {
				const { isValid, dirty, submitCount } = formContext;

				return (
					<div className="pt-xl pb-s">
						<Form>
							<LoginInput />
							{props.error?.length > 0 && (
								<div className=" mb-m   ">
									<InfoMessage color="error" withIcon>
										{t("auth.error.api.loginFirst")}
										<Trans
											i18nKey={
												submitCount !== 2
													? "auth.error.api.loginStrong"
													: "auth.error.api.loginStrong_one"
											}
											values={{
												tries: submitCount + 1,
											}}
										/>
										{t("auth.error.api.loginLast")}
									</InfoMessage>
								</div>
							)}
							<RandomNumPad fieldName="password" maxLength={6} />
							<Button
								type="submit"
								isLoading={props.load}
								color={!(isValid && dirty) ? "grey" : "primary"}
								disabled={!(isValid && dirty)}
								size="m"
								className={`w-full ${
									!(isValid && dirty)
										? "text-neutral-500"
										: ""
								}`}
								data-testid="login"
							>
								{t("auth.login.login")}
							</Button>
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};
