// function to retrieve a base64 file from a blob
export const blobToBase64 = (blob) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

export const blobToArrayBuffer = (blob) => {
	const reader = new FileReader();
	reader.readAsArrayBuffer(blob);
	return new Promise((resolve) => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

// function to retrieve a base64 file from a url
export const urlToBase64 = (url) =>
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => blobToBase64(blob));

export const urlToArrayBuffer = (url) =>
	fetch(url)
		.then((response) => response.arrayBuffer())
		.then((arrayBuffer) => arrayBuffer);
