import React from "react";
import { Title } from "@zolteam/react-ras-library";
import cn from "../../../utils/cn";

interface IPageHeadProps {
	title: string;
	children?: React.ReactNode;
	className?: string;
}

const PageHead: React.FC<IPageHeadProps> = (props) => {
	return (
		<div
			className={cn([
				"flex items-center justify-between flex-wrap gap-m",
				props.className,
			])}
		>
			<Title tag="h1" className={"!leading-7"}>
				{props.title}
			</Title>
			{props.children}
		</div>
	);
};

export default PageHead;
