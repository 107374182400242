import React from "react";
import { Title, ToggleSwitch } from "@zolteam/react-ras-library";
import { DefaultTFuncReturn, t } from "i18next";
import LanguageSwitcher from "../../i18n/LanguageSwitcher/LanguageSwitcher";
import DevFeaturesForm from "../../forms/DevFeaturesForm/DevFeaturesForm";
import { useSettings } from "../../contexts/SettingsContext/SettingsContext";

const Settings: React.FC = () => {
	const { isDev } = useSettings();

	const SettingsList = [
		{
			title: t("settings.language"),
			component: LanguageSwitcher,
		},

		{
			title: (
				<label className="flex gap-2 items-center leading-6 mt-4 mb-1">
					<ToggleSwitch
						name="devFeatures"
						onChange={() => {
							/*
							 **
							 ** La variable "root" est une varible globale qui
							 ** pointe vers l'element racine de l'application.
							 **
							 ** La fonction "dev" est une fonction qui permet
							 ** de permuter le mode développeur.
							 ** Elle est définie dans le fichier SettingsContext.tsx
							 **
							 */

							// @ts-ignore
							if (root.dev) root.dev();
						}}
						toggled={isDev}
						id={`devFeatures-switch`}
					/>
					<span>Fonctionnalitées des développeurs</span>
				</label>
			),
			component: DevFeaturesForm,
			dev: true,
		},
	];

	const refresh = () => {
		window.location.reload();
	};

	return (
		<div>
			<Title tag="h2" className="!mt-0">
				{t("settings.title")}
			</Title>

			<div className="settings-list flex gap-4 flex-col mt-4">
				{SettingsList.map((a, key) => {
					if (a.dev && !isDev) return false;
					return (
						<SettingSection
							key={key}
							{...a}
							handleRefresh={refresh}
						/>
					);
				})}
			</div>
		</div>
	);
};

interface ISettingSection {
	title: string | React.ReactNode | DefaultTFuncReturn;
	component: React.FC<any>;
	isDisabled?: boolean;
	props?: any;
	handleRefresh: () => void;
}

const SettingSection: React.FC<ISettingSection> = (props) => {
	const { title, isDisabled } = props;

	if (isDisabled) return false;

	return (
		<div>
			<Title tag="h3" size="heading03">
				{title}
			</Title>
			<props.component
				{...props?.props}
				handleRefresh={props.handleRefresh}
			/>
		</div>
	);
};

export default Settings;
