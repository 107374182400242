import React from "react";
import Waves from "../../molecules/AnimatedWaves/AnimatedWaves";
import { Picto } from "@zolteam/react-ras-library";
import QuestionMark from "../../../assets/QuestionMark.svg";
import "./AuthLayout.scss";
import SVG from "../../atoms/SVG/SVG";
import Logo from "../../../assets/myRAS.svg";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";

interface IAuthLayout {
	children?: any;
}

const AuthLayout: React.FC<IAuthLayout> = (props: IAuthLayout) => {
	const navigate = useNavigate();
	const datalayerPageTitle = "Connexion";

	const navigateHelp = () => {
		pushDataLayer({
			dataLayer: {
				event: "demande_aide",
				pageUrl: window.location.href,
				pageTitle: datalayerPageTitle,
			},
		});
		navigate("/help");
	};

	return (
		<div className="flex justify-between w-full h-full AuthLayout bg-neutral-50">
			<div className="main-cont w-full h-fit sm:w-10/12 md:w-6/12 lg:w-7/12 xl:w-8/12 px-[8%] pt-[3vh] md:pt-[8vh] duration-500 z-1">
				<div className="flex items-start justify-between w-full mb-5 md:hidden text-primary-500">
					<a className="flex items-center mt-1" href="/#">
						<Picto icon={"logoMyRas"} />
					</a>
					<button
						className="flex items-center"
						onClick={navigateHelp}
					>
						<SVG src={QuestionMark} />
					</button>
				</div>
				<div className="w-full xl:w-full max-w-[700px]">
					{props.children}
				</div>
			</div>
			<Waves className="z-0 sm:flex">
				<a className="self-end logo" href="/#">
					<SVG src={Logo} />
				</a>
				<div className="body">
					<h2 className="pb-4 title">
						<>
							<Trans i18nKey={"auth.layout.ourJob"} />
							&nbsp;!
						</>
					</h2>
					<div className="text">
						<Trans i18nKey={"auth.layout.secureSpace"} />
					</div>
				</div>
				<div className="self-end footer text-end">
					<button onClick={navigateHelp}>
						{t("global.needHelp")}
					</button>
				</div>
			</Waves>
		</div>
	);
};

export default AuthLayout;
