import React, { useState } from "react";
import { shuffle } from "lodash";
import { Text } from "@zolteam/react-ras-library";
import { useFormikContext } from "formik";
import { LoginFormInitValues } from "../../../forms/LoginForm/validationForm";

interface RandomNumPadProps {
	onChange?: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		inputValue: string
	) => void;
	fieldName: string;
	maxLength: number;
}
const RandomNumPad: React.FC<RandomNumPadProps> = (
	props: RandomNumPadProps
) => {
	const [randomArray] = useState(shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]));

	const { setFieldValue, values } =
		useFormikContext<typeof LoginFormInitValues>();

	return (
		<div className="RandomNumPad flex flex-wrap shrink pb-l">
			{randomArray.map((value, i) => (
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						if (
							values[props.fieldName]?.length <
							(props.maxLength ?? Infinity)
						)
							setFieldValue(
								props.fieldName,
								values[props.fieldName] + value
							);

						if (props.onChange)
							props.onChange(e, values[props.fieldName] + value);
					}}
					className={`${
						i !== 9 ? "flex-[1_0_30%]" : "w-[32.5%] mx-auto"
					}
					m-xs py-m px-0 bg-primary-50 rounded-[0.3125rem] transition-all duration-250 hover:bg-neutral-200 active:bg-neutral-300`}
					key={`numpad-${value}`}
					disabled={
						values[props.fieldName]?.length >= props.maxLength
					}
				>
					<Text
						tag="span"
						color="primary"
						fontWeight="bold"
						className="!text-heading-03 transition-all duration-250 hover:text-primary-800 active:text-primary-300"
					>
						{value}
					</Text>
				</button>
			))}
		</div>
	);
};

export default RandomNumPad;
