import React, { createContext, useState } from "react";

export interface StepsContextType {
	[key: string]: any;
	setContextValue: (key: string, value: any) => void;
	setStepsContext: (values: any) => void;
}

interface IStepsContextValues {
	[key: string]: any;
}

interface CtxProps {
	initValues?: IStepsContextValues;
	children: React.ReactNode;
}

const DEFAULT_VALUES = {
	setStepsContext: () => {},
	setContextValue: () => {},
};

export const StepsContext = createContext<StepsContextType>(DEFAULT_VALUES);

export const StepsContextProvider: React.FC<CtxProps> = ({
	children,
	initValues,
}) => {
	const [values, setValues] = useState(initValues ?? DEFAULT_VALUES);

	const saveValues = (new_values: any) => {
		setValues({
			...values,
			...new_values,
		});
	};

	const setContextValue = (key: string, value: any) => {
		setValues({
			...values,
			[key]: value,
		});
	};

	const contextValues = {
		...values,
		setStepsContext: saveValues,
		setContextValue,
	};

	return (
		<StepsContext.Provider value={contextValues}>
			{children}
		</StepsContext.Provider>
	);
};
