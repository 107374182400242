import React from "react";
import { Calendar as CalendarMain } from "@zolteam/react-ras-library";
import "./Calendar.scss";
import { IDate } from "../HomeCalendar/HomeCalendar";

interface ICalendar {
	modifiers?: {
		selected?:
			| Date[]
			| {
					from: Date;
					to: Date;
			  }[];
		absences?: IDate[];
		contracts?: IDate[];
		[dayPickerModifier: string]: any;
	};
	[dayPickerProps: string]: any;
}

const Calendar: React.FC<ICalendar> = (props) => {
	return (
		<div className="Calendar">
			<CalendarMain {...props} />
		</div>
	);
};

export default Calendar;
