import React from "react";
import { ModalV2 } from "@zolteam/react-ras-library";
import happyNewYear from "../../../assets/images/happy-new-year.png";

const HappyNewYear: React.FC = () => {
	const [isShowModal, setIsShowModal] = React.useState(true);
	const date = new Date();
	const dateNow = date.getTime();
	const dateNewYear = new Date("2024-01-01 00:00:00").getTime();
	const endOfTheYear = new Date("2024-01-31 23:59:59").getTime();
	const isHappyNewYear = dateNow >= dateNewYear && dateNow <= endOfTheYear;

	const onCloseModal = () => {
		setIsShowModal(false);
		localStorage.setItem("isHappyNewYearMessageDisplay", "true");
	};

	const isHappyNewYearMessageDisplay = localStorage.getItem(
		"isHappyNewYearMessageDisplay"
	);

	return isHappyNewYear && isHappyNewYearMessageDisplay !== "true" ? (
		<ModalV2
			className="max-w-[90vw] w-auto !min-w-[350px] [&>div:second-child]:pl-xs"
			size="s"
			isDisplayed={isShowModal}
			onClose={() => onCloseModal()}
		>
			<img
				src={happyNewYear}
				alt="happy new year"
				className={"w-full rounded-xl"}
			/>
		</ModalV2>
	) : null;
};

export default HappyNewYear;
