class LoginIdServiceClass {
	getId() {
		return localStorage.getItem("lastUserId");
	}

	setId(userId: string) {
		localStorage.setItem("lastUserId", userId);
	}

	remove() {
		localStorage.removeItem("lastUserId");
	}
}

const LoginIdService = new LoginIdServiceClass();

export default LoginIdService;
