import api from "./api";

interface IToken {
	token: string;
	refresh_token: string;
	initied?: boolean;
	isOnboarding?: boolean;
	issuedAt?: number;
	expiresAt?: number;
}

class TokenServiceClass {
	_validityInMinutes: number = 5;
	_isRefreshing: boolean = false;

	getLocalRefreshToken() {
		const user = JSON.parse(localStorage.getItem("userToken") ?? "{}");
		return user?.refresh_token;
	}

	getLocalAccessToken() {
		const user = JSON.parse(localStorage.getItem("userToken") ?? "{}");
		return user?.token;
	}

	updateLocalAccessToken(token: string) {
		let user = JSON.parse(localStorage.getItem("userToken") ?? "{}");
		user.token = token;
		this.setUserTokens(user, {});
	}

	getUserTokens() {
		return JSON.parse(localStorage.getItem("userToken") ?? "{}");
	}

	setUserTokens(token: IToken, options) {
		localStorage.setItem(
			"userToken",
			JSON.stringify({
				...token,
				...options,
				issuedAt: Date.now(),
				expiresAt: Date.now() + this._validityInMinutes * 60 * 1000,
			})
		);
	}

	initAccount() {
		let user = JSON.parse(localStorage.getItem("userToken") ?? "{}");
		user.initied = true;
		localStorage.setItem("userToken", JSON.stringify(user));
	}

	removeUser() {
		localStorage.removeItem("userToken");
	}

	refreshToken() {
		this._isRefreshing = true;
		const prom = api
			.post("/api/token/refresh", {
				refresh_token: this.getLocalRefreshToken(),
			})
			.then(
				(response) => {
					this._isRefreshing = false;
					const { token } = response.data;
					this.updateLocalAccessToken(token);
					return token;
				},
				(e) => {
					this._isRefreshing = false;

					return Promise.reject(e);
				}
			);

		return prom;
	}

	shouldRefreshToken() {
		const token = this.getUserTokens();

		if (this._isRefreshing || !token?.token) return false;

		const now = Date.now();

		return now > token.expiresAt;
	}

	setOnboarding(value?) {
		let user = JSON.parse(localStorage.getItem("userToken") ?? "{}");
		user.isOnboarding = value ?? true;
		if (value === false) user.initied = true;
		localStorage.setItem("userToken", JSON.stringify(user));
	}
}

const TokenService = new TokenServiceClass();

export default TokenService;
