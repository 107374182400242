import React, { useState, Ref, useContext } from "react";
import { t } from "i18next";
import RegisterService from "../../services/RegisterService";
import { InfoMessage } from "@zolteam/react-ras-library";
import { FormikProps } from "formik";
import {
	StepsContext,
	StepsContextType,
} from "../../contexts/StepsContext/StepsContext";
import AvailabilitiesService from "../../services/AvailabilitiesService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import AvailabilitiesForm, {
	IDaysSlots,
} from "../../forms/AvailabilitiesForm/AvailabilitiesForm";
import { DEFAULT_AVAILABILITIES_API_BODY } from "../../forms/AvailabilitiesForm/AvailabilitiesFormConst";

interface IRegisterAvailabilitiesProps {
	unvalidateStep: () => void;
	handleSubmitForm: (value: IDaysSlots, resetForm: () => void) => void;
	formRef?: Ref<FormikProps<any>> | undefined;
	validateStep?: () => void;
	stepsContext?: any;
	setStepsContext?: (context: any) => void;
	isActive?: boolean;
}

const RegisterAvailabilities: React.FC<IRegisterAvailabilitiesProps> = (
	props: IRegisterAvailabilitiesProps
) => {
	const queryClient = useQueryClient();

	const { availabilities, setStepsContext } =
		useContext<StepsContextType>(StepsContext);

	const [selectedTimesOfDay, setSelectedTimesOfDay] = useState<IDaysSlots>(
		availabilities ?? DEFAULT_AVAILABILITIES_API_BODY
	);

	const checkSelection = (times: IDaysSlots) => {
		// for each day slot, check if there is at least one time selected
		let isValid = false;
		Object.keys(times).forEach((day) => {
			Object.keys(times[day]).forEach((timeSlot) => {
				if (times[day][timeSlot]) {
					isValid = true;
				}
			});
		});
		return isValid;
	};

	const handleDayClick = (selected: IDaysSlots) => {
		setSelectedTimesOfDay(selected);

		if (checkSelection(selected)) {
			props.validateStep && props.validateStep();
		} else {
			props.unvalidateStep();
		}
	};

	const handleSubmit = () => {
		RegisterService.putAvailabilities(selectedTimesOfDay)
			.then((response) => {
				setStepsContext &&
					setStepsContext({
						availabilities: selectedTimesOfDay,
					});
				queryClient.invalidateQueries(["availabilities"]);
				queryClient.setQueriesData(
					["availabilities"],
					selectedTimesOfDay
				);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const fetchAvailabilities = () =>
		AvailabilitiesService.getAvailabilities().then((response) => {
			setStepsContext &&
				setStepsContext({
					availabilities: response,
				});
			props.validateStep && props.validateStep();

			return response;
		});

	const { isLoading, error, data } = useQuery(
		["availabilities"],
		fetchAvailabilities,
		{
			refetchOnWindowFocus: false,
			enabled: props.isActive,
		}
	);

	if (!props.isActive) return null;

	if (error) return <div>{t("availabilities.loadingError")}</div>;

	if (isLoading) return <Loader />;

	return (
		<>
			<div className="px-0 pb-xl">
				<InfoMessage color="primary" withIcon>
					{t("availabilities.infoMessage")}
				</InfoMessage>
			</div>
			<AvailabilitiesForm
				availabilities={data}
				onChange={handleDayClick}
				handleSubmit={handleSubmit}
				formRef={props.formRef}
			/>
		</>
	);
};

export default RegisterAvailabilities;
