import IDCardPicto from "../../../assets/illustrations/IDCard.svg";
import euroCardPicto from "../../../assets/illustrations/euroCard.svg";
import familyBookletPicto from "../../../assets/illustrations/familyBooklet.svg";
import healthCardPicto from "../../../assets/illustrations/healthCard.svg";
import healthInsuranceCertificatePicto from "../../../assets/illustrations/healthInsuranceCertificate.svg";
import medicalCertificatePicto from "../../../assets/illustrations/medicalCertificate.svg";
import passeportPicto from "../../../assets/illustrations/passeport.svg";
import proofOfAddressPicto from "../../../assets/illustrations/proofOfAddress.svg";

export const DocsTypesPictosList = [
	{ id: 105, picto: IDCardPicto },
	{ id: 51, picto: familyBookletPicto },
	{ id: 236, picto: proofOfAddressPicto },
	{ id: 249, picto: familyBookletPicto },
	{ id: 270, picto: passeportPicto },
	{ id: 312, picto: healthCardPicto },
	{ id: 313, picto: IDCardPicto },
	{ id: 309, picto: healthInsuranceCertificatePicto },
	{ id: 350, picto: medicalCertificatePicto },
	{ id: 663, picto: euroCardPicto },
];
