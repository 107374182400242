import React, { createContext, useState } from "react";

export interface NotificationsContextType {
	numberMessage: number;
	setNumberMessage: (numberMessage: number) => void;
}

interface CtxProps {
	children: React.ReactNode;
}

export const NotificationsContext = createContext<NotificationsContextType>({
	numberMessage: 0,
	setNumberMessage: () => {},
});

export const NotificationsContextProvider: React.FC<CtxProps> = ({
	children,
}) => {
	const [numberMessage, setNumberMessage] = useState<number>(0);

	const value = {
		numberMessage,
		setNumberMessage,
	};

	return (
		<NotificationsContext.Provider value={value}>
			{children}
		</NotificationsContext.Provider>
	);
};
