import React from "react";
import {
	ChatMessaging,
	Button,
	Title,
	BackLink,
	InfoMessage,
} from "@zolteam/react-ras-library";
import Field from "../Field/Field";
import { t } from "i18next";
import { Form, Formik } from "formik";
import Loader from "../../atoms/Loader/Loader";
import moment from "moment";
import SendingIcon from "../../../assets/icons/sending.svg";
import { useQuery } from "@tanstack/react-query";
import { IMessageDetail } from "../../../views/Messagerie/Messagerie";
import SVG from "../../atoms/SVG/SVG";
import cn from "../../../utils/cn";

interface IMessagerieChatProps {
	chatId: number;
	handleSubmitForm: (values: { message: string }, reaction: string) => void;
	handleClose?: () => void;
	messageType: string;
	isLoading?: boolean;
	messages?: IMessageDetail;
	statusTag: string;
}

interface IAsyncChatMessagesProps extends IMessagerieChatProps {
	queryKey: string[];
	queryFn: () => Promise<IMessageDetail>;
	isLoading?: boolean;
}

export const AsyncChatMessages: React.FC<IAsyncChatMessagesProps> = (props) => {
	const { chatId } = props;

	const { isLoading, data, error } = useQuery(props.queryKey, props.queryFn, {
		enabled: chatId > 0,
	});

	if (props.isLoading || isLoading)
		return (
			<div
				className={`flex flex-col h-full w-full 2md:border-l 2md:border-neutral-150 2md:ml-l pl-s`}
			>
				<Loader text={t("messaging.loading")} />
			</div>
		);

	if (error)
		return (
			<InfoMessage color="error" withIcon>
				{t("messaging.loadingError")}
			</InfoMessage>
		);

	if (!data) return null;

	return <ChatMessages {...props} messages={data} />;
};

const ChatMessages: React.FC<IMessagerieChatProps> = (props) => {
	const { messages, statusTag } = props;
	const [isActiveYes, setIsActiveYes] = React.useState(false);
	const [isActiveNo, setIsActiveNo] = React.useState(false);

	const isNews = props.messageType === "news";
	const isArchived =
		statusTag === "archive" || statusTag === "archive_manually";
	React.useEffect(() => {
		return () => {
			setIsActiveNo(false);
			setIsActiveYes(false);
		};
	}, [props.chatId]);

	if (!messages) return null;

	const showYesNoButtons = () => {
		return messages.answers.length <= 0 && props.messageType === "flow";
	};
	const showCommentField = () => {
		return (
			(messages.answers.length <= 0 && props.messageType === "flow") ||
			(messages.answers.length <= 0 && isNews)
		);
	};

	return (
		<div
			className={`flex flex-col w-full 2md:border-l 2md:border-neutral-150 2md:ml-l pl-s`}
		>
			<BackLink
				theme="primary"
				className="pb-s 2md:hidden"
				onClick={() => {
					props.handleClose && props.handleClose();
				}}
			>
				{t("global.back")}
			</BackLink>
			<div className="contents">
				<Title
					tag="span"
					size="heading02"
					color="black"
					className="!leading-7"
				>
					{messages.messageSource.title}
				</Title>
				<hr className="my-3" />
				<div className="flex flex-col w-full pr-m pt-m textColor overflow-auto">
					<ChatMessaging
						text={messages.messageSource.description}
						side="left"
						date={
							!messages.files?.length
								? moment(
										messages.messageSource.createdAt
								  ).format(t("dates.formatWithHour").toString())
								: ""
						}
					/>
					{messages.files.map((file: any, index) => (
						<ChatMessaging
							key={index}
							side="left"
							date={moment(
								messages.messageSource.createdAt
							).format(t("dates.formatWithHour")?.toString())}
						>
							<a
								href={file.link}
								target="_blank"
								rel="noreferrer"
								className="text-primary-500 underline decoration-primary-500"
							>
								{file.name}
							</a>
						</ChatMessaging>
					))}

					{messages.answers.map((answer: any, index) => (
						<div
							className="flex flex-col [&>div>div:first-child>p]:!text-primary-500"
							key={index}
						>
							{!isNews && (
								<ChatMessaging
									text={
										answer.reaction === "yes"
											? "Oui"
											: "Non"
									}
									side="right"
									date={
										answer.comment && !answer.comment.length
											? moment(answer.createdAt).format(
													t(
														"dates.formatWithHour"
													)?.toString()
											  )
											: ""
									}
								/>
							)}
							{answer.comment && answer.comment.length > 0 && (
								<ChatMessaging
									text={answer.comment}
									side="right"
									date={moment(answer.createdAt).format(
										t("dates.formatWithHour")?.toString()
									)}
								/>
							)}
						</div>
					))}
				</div>
			</div>

			{!isArchived && (
				<div className="flex flex-col justify-end mt-auto">
					{showYesNoButtons() && (
						<div className="flex gap-s justify-end flex-wrap ">
							<Button
								type="button"
								color="primary"
								outline={!isActiveYes}
								className={` ${
									isActiveYes
										? "border-2 border-solid border-transparent"
										: ""
								}`}
								onClick={() => {
									setIsActiveYes(!isActiveYes);
									setIsActiveNo(false);
								}}
							>
								Oui
							</Button>
							<Button
								type="button"
								color="primary"
								outline={!isActiveNo}
								className={` ${
									isActiveNo
										? "border-2 border-solid border-transparent"
										: ""
								}`}
								onClick={() => {
									setIsActiveNo(!isActiveNo);
									setIsActiveYes(false);
								}}
							>
								Non
							</Button>
						</div>
					)}
					{showCommentField() && (
						<Formik
							initialValues={{ message: "" }}
							validationSchema={""}
							onSubmit={(values) => {
								const reaction =
									isActiveYes || isNews ? "yes" : "no";
								props.handleSubmitForm(values, reaction);
							}}
						>
							{({ values }) => {
								let hasSelected = isActiveYes || isActiveNo;
								return (
									<Form>
										<Field
											name="message"
											label={t("messaging.comment")}
											type="text"
											className="pt-s"
											readOnly={
												isNews ? false : !hasSelected
											}
										>
											<button
												className="absolute top-[30%] right-0 mr-3 items-start justify-end [&>span]:pointer-events-none"
												type="submit"
												disabled={
													isNews
														? false
														: !hasSelected
												}
											>
												<SVG
													src={SendingIcon}
													className={cn([
														"w-8 h-8",
														hasSelected ||
														values.message?.length >
															0
															? "!fill-primary-500"
															: "!fill-neutral-300",
													])}
												/>
											</button>
										</Field>
									</Form>
								);
							}}
						</Formik>
					)}
				</div>
			)}
		</div>
	);
};

export default ChatMessages;
