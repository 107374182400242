import {
	Button,
	Checkbox,
	InfoMessage,
	Title,
	Picto,
	Text,
} from "@zolteam/react-ras-library";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import CVService from "../../services/CVService";
import ConfirmModal from "../../components/molecules/ConfirmModal/ConfirmModal";
import { Trans } from "react-i18next";
import { IOfferProps } from "../../components/templates/OfferLayout/OfferLayout";
import announcementService from "../../services/AnnouncementService";
import { ICV } from "../ProfilPro/CV";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import { offersLocationName } from "../../utils/offers";

interface IApply {
	offer: IOfferProps;
	onBack: () => void;
}

const Apply: React.FC<IApply> = (props) => {
	const [isChecked, setIsChecked] = React.useState(false);
	const [checkboxIndex, setCheckboxIndex] = React.useState("");
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [uuid, setUuid] = React.useState("");
	const [isSendCV, setIsSendCV] = React.useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	const isSend = isSendCV && !isModalOpen && uuid !== "";

	const fetchCV = () => CVService.getCV().then((res) => res.cvs);

	const { isLoading, data, error } = useQuery(["cvs"], () => fetchCV());

	useEffect(() => {
		if (data?.length === 1) {
			setIsChecked(true);
		}
	}, [data?.length]);

	if (isLoading) return <Loader />;
	if (error) return <InfoMessage type="error">{error}</InfoMessage>;

	const handleApply = () => {
		pushDataLayer({
			dataLayer: {
				event: "postuler__candidature_envoyee",
			},
		});
		setIsSendCV(true);
		setUuid(data[0]?.uuid);

		const announcement = {
			...props.offer,
			toApply: true,
			announcementId: props.offer.id,
			uuid: uuid,
		};
		announcementService.postAnnouncement(announcement);
	};

	const handleSendCV = () => {
		pushDataLayer({
			dataLayer: {
				event: "postuler__postuler_offre",
				previous_page: offersLocationName(location.pathname),
				nombre_cv_dispo: data?.length,
			},
		});
		isSend ? props.onBack() : setIsModalOpen(true);
	};

	return (
		<>
			<div className="flex flex-col items-center justify-center w-[300px] h-fit min-h-[200px] bg-neutral-100 p-m rounded">
				<Title tag="h2" size="heading03" className="!leading-8">
					{isSend
						? t("offers.searchOffers.applySuccess")
						: t("offers.searchOffers.interest")}
				</Title>
				{data?.length > 0 ? (
					isSend ? (
						<InfoMessage color="success" withIcon>
							<Trans i18nKey="offers.searchOffers.applySuccessText" />
						</InfoMessage>
					) : (
						<InfoMessage color="primary" withIcon>
							{t("offers.searchOffers.infoApply")}
						</InfoMessage>
					)
				) : (
					<InfoMessage color="warning" withIcon>
						{t("offers.searchOffers.infoAddCV")}
					</InfoMessage>
				)}
				{!isSend && (
					<>
						{data?.map((cv: ICV, index: number) => {
							let indexString = index.toString();
							return (
								<Checkbox
									key={index}
									id={indexString}
									value={
										checkboxIndex === indexString ||
										data?.length === 1
											? isChecked
											: false
									}
									onChange={() => {
										setIsChecked(!isChecked);
										setCheckboxIndex(indexString);
										setUuid(cv.uuid);
									}}
									label={cv.name}
									theme="primary"
									className="w-full p-l m-s bg-white rounded"
								/>
							);
						})}
						<button
							className="flex text-primary-500 self-start mt-s"
							onClick={() => {
								pushDataLayer({
									dataLayer: {
										event: "postuler__gerer_cv",
									},
								});
								navigate("/cv", {
									state: {
										redirectUrl: `${location.pathname}/${props.offer.id}`,
										pathname: offersLocationName(
											location.pathname
										),
									},
								});
							}}
						>
							<Picto
								icon="edit"
								style={{ width: "15px", height: "15px" }}
							/>
							<Text
								tag="span"
								size="paragraph01"
								color="primary"
								className="ml-s"
								fontWeight="bold"
							>
								{t("offers.searchOffers.editCV")}
							</Text>
						</button>
					</>
				)}
				{data?.length > 0 ? (
					<Button
						type="button"
						color="primary"
						className="mt-m"
						disabled={!isChecked}
						onClick={handleSendCV}
					>
						{isSend
							? t("offers.searchOffers.viewOffer")
							: t("offers.searchOffers.apply")}
					</Button>
				) : (
					<Button
						type="button"
						color="primary"
						className="mt-m"
						onClick={() =>
							navigate("/cv", {
								state: {
									redirectUrl: `${location.pathname}/${props.offer.id}`,
									pathname: offersLocationName(
										location.pathname
									),
								},
							})
						}
					>
						{t("offers.searchOffers.addCV")}
					</Button>
				)}
			</div>

			<ConfirmModal
				title={t("offers.modal.title")}
				isOpen={isModalOpen}
				confirmText={t("offers.modal.confirm")}
				onClose={() => setIsModalOpen(false)}
				onConfirm={handleApply}
				className="w-[35%]"
			>
				<Trans
					i18nKey="offers.modal.text"
					values={{ offerTitle: props.offer.title }}
				/>
			</ConfirmModal>
		</>
	);
};

export default Apply;
