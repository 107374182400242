import React from "react";
import { Title, Popover, InfoMessage } from "@zolteam/react-ras-library";
import { t } from "i18next";
import PictoNotification from "../../components/molecules/PictoNotification/PictoNotification";
import WhatsNew from "../../components/molecules/WhatsNew/WhatsNew";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const Notifications: React.FC = (props) => {
	return (
		<Popover
			placement="bottom"
			clickInside={true}
			content={
				<div className="font-normal max-h-[550px] overflow-x-hidden overflow-y-auto !min-w-[20rem] text-center p-m rounded-lg sm:rounded-[1.5rem] w-full">
					<Title tag="span" size="heading01" color="black">
						{t("notifications.title")}
					</Title>

					<WhatsNew
						icon={false}
						title={(count: number) => (
							<div className="mb-s">
								<InfoMessage
									color="warning"
									withIcon
									className="mb-m"
								>
									{t("notifications.notification", {
										count,
									})}
								</InfoMessage>
							</div>
						)}
						origin="Notifications"
					/>
				</div>
			}
		>
			<button
				onClick={() => {
					pushDataLayer({
						dataLayer: {
							event: "header",
							item: "Mes notifications",
						},
					});
				}}
				className="flex headerLink gap-s items-center"
			>
				<PictoNotification
					icon="notification"
					number={0}
					padColor="accent"
					customIconSize={{ width: "1.375rem" }}
				/>
				<p className="hidden sm:block">{t("header.notifications")}</p>
			</button>
		</Popover>
	);
};

export default Notifications;
