import React from "react";
import { Tag } from "@zolteam/react-ras-library";
import moment from "moment/moment";
import { t } from "i18next";

export interface IMessage {
	title: string;
	description?: string;
	agencyName: string;
	status: string;
	state?: string;
	createdAt: Date | string;
	type?: string;
	messageId: number;
	isWatched?: boolean;
	messageNumber?: number | null;
	recipientsCount?: number;
	recipientId?: number;
	isAnswered?: boolean;
}

const Message: React.FC<IMessage> = (props) => {
	const { title, createdAt, agencyName, isAnswered, status } = props;
	const getStatus = (type: string) => {
		if (type === "archive" || type === "archive_manually") {
			return {
				label: t("messaging.status.archived"),
				bgColor: "!bg-neutral-100",
				labelColor: "text-neutral-500",
			};
		}
		if (isAnswered) {
			return {
				label: t("messaging.status.treated"),
				bgColor: "!bg-success-100",
				labelColor: "text-success-600",
			};
		}

		return {
			label: t("messaging.status.waiting"),
			bgColor: "!bg-primary-50",
			labelColor: "text-primary-600",
		};
	};

	return (
		<div className="flex w-full gap-m">
			<div>
				<div className="font-bold">{title}</div>
				<span className="text-neutral-500">
					{createdAt &&
						moment(createdAt).format(
							t("dates.formatWithHour")?.toString()
						)}{" "}
					- {agencyName}
				</span>
			</div>
			<div className="flex items-center ml-auto">
				<Tag
					size="m"
					color="primary"
					className={` whitespace-nowrap ${
						getStatus(status).bgColor
					} ${getStatus(status).labelColor}`}
				>
					{getStatus(status).label}
				</Tag>
			</div>
		</div>
	);
};

export default Message;
