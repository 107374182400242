import React, { useContext, useEffect } from "react";
import { Title, BackLink, Tag } from "@zolteam/react-ras-library";
import { t } from "i18next";
import SVG from "../../atoms/SVG/SVG";
import EuroIcon from "../../../assets/icons/euro.svg";
import LocationIcon from "../../../assets/icons/map.svg";
import BriefcaseIcon from "../../../assets/icons/briefcase.svg";
import getSalaryFormat from "../../../utils/salaryFormat";
import { Parser } from "html-to-react";
import useFavorite from "../../../hooks/useFavorite";
import Apply from "../../../views/Offers/Apply";
import {
	AdminLayoutContext,
	AdminLayoutContextType,
} from "../../../contexts/AdminLayoutContext/AdminLayoutContext";
import Page404 from "../../../views/404/404";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";
import FavoriteButton from "../../molecules/FavoriteButton/FavoriteButton";

interface IOfferLayoutProps {
	item: IOfferProps;
	onBack: any;
	onBackText: string;
}

export interface IOfferProps {
	city: string;
	salary: {
		amount: number;
		salary_min: number;
		salary_max: number;
		salary_type: string;
	};
	title: string;
	agency: string;
	content: string;

	contractTypes: string;
	toApply: boolean;
	isFavored: boolean;
	id: string;
}

const OfferLayout: React.FC<IOfferLayoutProps> = (props) => {
	const {
		city,
		salary = { amount: 0, salary_min: 0, salary_max: 0, salary_type: "" },
		title,
		contractTypes,
		agency,
		content,
		toApply,
		isFavored,
		id,
	} = props.item;

	const { setScrollable } =
		useContext<AdminLayoutContextType>(AdminLayoutContext);

	useEffect(() => {
		setScrollable(false);
		return () => setScrollable(true);
	}, [setScrollable]);

	const htmlToReactParser = Parser();

	const { amount, salary_min, salary_max, salary_type } = salary;

	const salaryFormat = getSalaryFormat(
		amount,
		salary_min,
		salary_max,
		salary_type || ""
	);

	const { favorStatus, toggleFavorite } = useFavorite({
		items: props.item,
		isFavored,
		id,
	});

	useEffect(() => {
		pushDataLayer({
			dataLayer: {
				event: "offres__voir_offre",
				previous_page: props.onBackText,
			},
		});
	}, [props.onBackText]);

	return (
		<>
			<Title tag="h2">{t("offers.title")}</Title>
			<BackLink
				className="[&>*]:!text-primary-500 [&>span]:!pr-2 sticky top-0 z-10 bg-white"
				onClick={() => {
					pushDataLayer({
						dataLayer: {
							event: "offre__retour_liste",
						},
					});
					props.onBack();
				}}
			>
				{props.onBackText}
			</BackLink>
			{parseInt(id) === -1 ? (
				<div className="w-full h-full">
					<Page404
						className="w-full h-full"
						text={t("offers.offerNotFound")}
					/>
				</div>
			) : (
				<>
					<div className="flex flex-wrap gap-m md:gap-l w-full">
						<div
							className="pr-xl xl:pr-m"
							style={{
								flex: "1 1 600px",
							}}
						>
							<div className="flex justify-between items-center pt-m pb-s gap-s">
								<Title
									tag="h3"
									size="heading03"
									className="!leading-6"
								>
									{title}
								</Title>
								<FavoriteButton
									onClick={() => {
										!favorStatus &&
											pushDataLayer({
												dataLayer: {
													event: "liste_offres__favori",
												},
											});
										return toggleFavorite();
									}}
									isFavored={favorStatus}
									className="w-6 h-6"
								/>
							</div>
							<Tag color="primary" className="mr-s">
								{contractTypes}
							</Tag>
							{agency && (
								<Tag color="primary" outline>
									{agency}
								</Tag>
							)}
							<span className="flex pt-m font-bold items-center">
								<SVG src={LocationIcon} className="mr-s" />
								{city}
							</span>
							{salaryFormat && (
								<span className="flex pt-s font-bold items-center">
									<SVG
										src={EuroIcon}
										className="flex items-center mr-s"
									/>
									{salaryFormat}
								</span>
							)}
							<span className="flex pt-s font-bold items-center">
								<SVG
									src={BriefcaseIcon}
									className="flex items-center mr-s"
								/>

								{contractTypes}
							</span>
							<p className="bold pt-m font-bold">
								{t("offers.searchOffers.description")}
							</p>
							{htmlToReactParser.parse(content)}
						</div>
						{!toApply && (
							<Apply offer={props.item} onBack={props.onBack} />
						)}
					</div>
				</>
			)}
		</>
	);
};

export default OfferLayout;
