import React, { useLayoutEffect, useState } from "react";
import { BackLink } from "@zolteam/react-ras-library";
import { t } from "i18next";
import cn from "../../../utils/cn";
import "./Drawer.scss";
import { QueryFunction, QueryKey, useQuery } from "@tanstack/react-query";
import Loader from "../../atoms/Loader/Loader";

interface IDrawerProps {
	className?: string;
	isOpen?: boolean;
	onClose?: () => void;
	navigation?: React.FC<any> | undefined | false;
	children?: React.FC<any> | undefined;
	query?:
		| {
				key: QueryKey;
				fn: QueryFunction;
				options?: any;
		  }
		| undefined;
	position?: "fixed" | "absolute";
}

const Drawer: React.FC<IDrawerProps> = ({
	isOpen = false,
	onClose = () => {},
	className,
	query,
	position = "fixed",
	...props
}) => {
	const [IsOpen, setIsOpen] = useState(isOpen);
	const contRef = React.useRef(null);

	const handleBack = () => {
		setIsOpen(false);
		setTimeout(() => onClose(), 800);
	};

	useLayoutEffect(() => {
		setTimeout(() => {
			setIsOpen(isOpen);
		}, 0);
	}, [isOpen]);

	const { isLoading, data, error } = useQuery(
		query?.key ?? [
			"Drawer Fetch",
			query?.fn ? Date.now().toString() : "disabled",
		],
		query?.fn ?? (() => {}),
		{
			enabled: isOpen && query?.fn !== undefined,
			refetchOnWindowFocus: false,
			retry: false,
			initialData: null,
			...query?.options,
		}
	);

	if (!isOpen) return null;

	return (
		<div className={cn(["Drawer", position, IsOpen && "open", className])}>
			{props.navigation !== false && (
				<div className="Drawer_nav flex justify-between items-center p-l">
					<BackLink
						className="[&>*]:!text-primary-500 [&>span]:!pr-2"
						onClick={handleBack}
					>
						{t("global.back")}
					</BackLink>
					<div className="flex items-center gap-s">
						{props.navigation && props.navigation({ data })}
					</div>
				</div>
			)}
			{isLoading ? (
				<div className="Drawer__loader">
					<Loader />
				</div>
			) : (
				<>
					<div
						ref={contRef}
						className="Drawer__container h-full w-full flex flex-col overflow-auto"
					>
						{props.children &&
							props.children({
								data,
								error,
								onClose: handleBack,
							})}
					</div>
				</>
			)}
		</div>
	);
};

export default Drawer;
