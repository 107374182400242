import { t } from "i18next";
import MonthSelect from "../../components/molecules/MonthSelect/MonthSelect";
import Select from "../../components/molecules/Select/Select";
import AgencySelect from "../../components/molecules/AgencySelect/AgencySelect";
import { IFilters } from "../../contexts/FiltersContext";
import React from "react";
import SessionService from "../../services/SessionService";
import { Title } from "@zolteam/react-ras-library";

export interface IDocsFilters extends IFilters {
	months: any[];
	years: any[];
	agencies: any[];
	sorting: string;
	orderBy: string;
	page: number;
	limit: number;
}

export const DEFAULT_DOCS_FILTERS: IDocsFilters = {
	months: [],
	years: [],
	agencies: [],
	sorting: "name",
	orderBy: "DESC",
	page: 1,
	limit: 30,
};

interface IDocsFiltersFormProps {
	formikProps: any;
	values: IFilters;
	isSubmitting: boolean;
	activeFilterCount: number;
	resetFilters: () => IFilters;
}

export const countDocsFilters = (filters: IFilters) => {
	let count = 0;
	if (filters.months?.length + filters.years?.length > 0) count++;
	if (filters.agencies?.length > 0) count++;
	return count;
};

const DocsFiltersForm = (props: IDocsFiltersFormProps) => {
	const user = SessionService.getUser();
	const agencies = user?.agencies ?? [];

	const genYears = () => {
		const currentYear = new Date().getFullYear();
		const years: {
			label: string;
			value: number;
		}[] = [];
		for (let i = 2020; i <= currentYear; i++) {
			years.push({ label: i.toString(), value: i });
		}
		return years;
	};

	return (
		<>
			<Title tag="h3" size="heading03">
				{t("docs.filters.period")}
			</Title>
			<MonthSelect
				className="mb-3"
				onChange={(val) => {
					props.formikProps.setFieldValue("months", val);
				}}
				value={props.values.months}
			/>
			<Select
				options={genYears()}
				label="Année"
				isMulti={true}
				closeMenuOnSelect={false}
				onChange={(val) => {
					props.formikProps.setFieldValue("years", val);
				}}
				value={props.values.years}
			/>
			<hr className="my-5" />
			<Title tag="h3" size="heading03">
				{t("global.agency")}
			</Title>
			<AgencySelect
				onChange={(val) => {
					props.formikProps.setFieldValue("agencies", val);
				}}
				options={agencies}
				value={props.values.agencies}
			/>
		</>
	);
};

export default DocsFiltersForm;
