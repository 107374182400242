import React from "react";
import { Text } from "@zolteam/react-ras-library";

// Components
import MenuTab from "../../molecules/MenuTab/MenuTab";

// Styles
import "./Menu.scss";
import { useNavigate } from "react-router-dom";
import { IRoute } from "../../../routes";
import cn from "../../../utils/cn";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";
import { useSettings } from "../../../contexts/SettingsContext/SettingsContext";

interface IMenu {
	items: IRoute[];
	isOpen?: boolean;
	onItemClick?: (item: IRoute, hasParent?: IRoute) => void;
}

export const isRouteActive = (tab: IRoute) => {
	let path = window.location.pathname;
	if (typeof tab.path === "string" && path === tab.path) return true;
	if (Array.isArray(tab.path)) return tab.path.indexOf(path) !== -1;
	if (tab.childrens?.length) return tab.childrens.some(isRouteActive);
	return path === tab.path;
};

const Menu: React.FC<IMenu> = ({ items, isOpen, onItemClick }) => {
	const navigate = useNavigate();
	const { getSettings } = useSettings();

	const handlePageChange = (child: IRoute, hasParent?: IRoute) => {
		let path = child.path ?? "#";
		if (Array.isArray(child.path)) path = child.path[0];

		pushDataLayer({
			dataLayer: {
				event: "menu_lateral",
				categorie: hasParent?.name ?? child.name,
				sous_categorie: child.name,
			},
		});

		onItemClick && onItemClick(child, hasParent);
		navigate(path);
	};

	return (
		<nav className={cn(["Menu z-1 pl-s", isOpen && "open"])}>
			<div
				className={
					"hoverWrapper flex flex-col flex-wrap justify-between h-full w-full sm:w-[5.75rem] hover:w-[50vw]"
				}
			>
				<ul className={"tabWrapper"}>
					{items.map((item) => {
						if (
							item.disabled ||
							(item.devFeatureName &&
								!getSettings(item.devFeatureName, true))
						)
							return null;
						const isActive = isRouteActive(item);
						return (
							<MenuTab
								{...item}
								active={isActive}
								key={item.name}
								onClick={handlePageChange}
							>
								<Text
									tag="div"
									fontWeight="normal"
									size="paragraph02"
									className={cn([
										"hideOnHover !leading-4 opacity-0 sm:absolute md:relative translate-y-0 sm:-translate-y-1/2 top-[-10000px] md:translate-y-0 w-full",
										isActive &&
											"cursor-default !font-extrabold",
									])}
								>
									{item.name}
								</Text>
							</MenuTab>
						);
					})}
				</ul>
			</div>
		</nav>
	);
};

export default Menu;
