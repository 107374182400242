import React from "react";
import SVG from "../../atoms/SVG/SVG";
import { Picto } from "@zolteam/react-ras-library";
import DocumentPicto from "../../../assets/illustrations/document.svg";
import { DocsTypesPictosList } from "./DocTypeTilePictos";

import cn from "../../../utils/cn";
import { t } from "i18next";
interface IDocTypeTile {
	id?: number;
	name: string;
	picto?: string;
	onClick?: () => void;
	onRemove?: () => void;
	isValid?: boolean;
}

const DocTypeTile: React.FC<IDocTypeTile> = ({
	id,
	name,
	picto,
	onClick,
	onRemove,
	isValid,
}) => {
	const getPicto = (id: number) => {
		let pictoFound = DocsTypesPictosList.find((picto) => picto.id === id);

		if (!pictoFound) {
			if (picto) return picto;
			return DocumentPicto;
		}

		return pictoFound.picto;
	};

	return (
		<button
			onClick={onClick}
			className="relative group flex flex-col gap-m items-center text-center w-full xs:w-[45%] lg:w-[190px] sm:aspect-squafre bg-neutral-100 hover:bg-neutral-150 p-m rounded-xl  font-bold text-lg "
			style={{
				aspectRatio: "263/164",
			}}
		>
			<SVG
				src={getPicto(id || 0)}
				className="duration-300 opacity-60 group-hover:opacity-100"
			/>
			<p className={cn(["my-auto leading-6"])}>{name}</p>
			{onRemove && (
				<div
					onClick={(e) => {
						e.stopPropagation();
						onRemove();
					}}
					title={t("global.delete")}
					className="absolute p-2 duration-300 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 right-2 top-2 aspect-square"
				>
					<Picto icon="close" className="w-2 h-2 text-white" />
				</div>
			)}
			{isValid && (
				<div className="absolute rounded-full left-2 top-2 bg-success-500 p-xs">
					<Picto icon="check" className="w-4 h-4 text-white" />
				</div>
			)}
		</button>
	);
};

export default DocTypeTile;
