import React from "react";
import { ISector } from "../../../views/Offers/Offers";
import SectorTile from "../../molecules/SectorTile/SectorTile";

interface ISectorsCardProps {
	sectors: ISector[];
}
const SectorsCard: React.FC<ISectorsCardProps> = ({ sectors }) => {
	return (
		<div className="flex flex-wrap gap-10">
			{sectors.map((sector) => {
				return (
					<SectorTile
						sector={{ id: sector.id, name: sector.name }}
						key={sector.id}
						className={" [&_p]:!uppercase w-[180px] h-[160px] "}
						isSelected={true}
					/>
				);
			})}
		</div>
	);
};
export default SectorsCard;
