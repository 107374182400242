import React from "react";
import { ErrorMessage } from "formik";
import { InfoMessage, Input } from "@zolteam/react-ras-library";
import cn from "../../../utils/cn";
// Style
import style from "./Field.module.scss";
import { DefaultTFuncReturn } from "i18next";

const Field: React.FC<IField> = ({
	type,
	name = "",
	label = "",
	readOnly = false,
	className,
	children = null,
	maxLength,
	containerClassName,
	pictoName,
	disabled = false,
	...props
}) => (
	<div className={cn([style.inputContainer, containerClassName])}>
		<Input
			onPictoClick={() => {}} // used to fix unused prop error from zol component
			type={type}
			name={name}
			label={label}
			className={cn([
				style.input,
				className,
				disabled && "[&>*]:!bg-neutral-100 opacity-70",
			])}
			readOnly={readOnly}
			maxLength={maxLength}
			pictoName={pictoName}
			disabled={disabled}
			{...props}
		>
			<ErrorMessage name={name}>
				{(msg: string) => (
					<InfoMessage color="error" withIcon>
						{msg}
					</InfoMessage>
				)}
			</ErrorMessage>
		</Input>
		{children}
	</div>
);

export interface IField {
	type: string;
	name: string;
	readOnly?: boolean;
	label: string | DefaultTFuncReturn;
	className?: string;
	children?: any;
	maxLength?: number;
	containerClassName?: string;
	pictoName?: string;
	disabled?: boolean;
	[key: string]: any;
}

export default Field;
