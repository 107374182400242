import api from "./api";

class SessionServiceClass {
	fetchMe() {
		return api.get("/api-360/worker/me").then((response) => {
			return response.data;
		});
	}

	updateUser() {
		return this.fetchMe().then((user) => {
			this.setUser(user);
			return user;
		});
	}

	getAvatar() {
		const user = this.getUser();
		return user?.avatar ?? false;
	}

	setUser(user: object) {
		localStorage.setItem("userSession", JSON.stringify(user));
	}

	getUser() {
		return JSON.parse(localStorage.getItem("userSession") ?? "{}");
	}

	needOnboarding() {
		const user = this.getUser();
		return (
			user &&
			(!user.agencies?.length || user.statusMobile === "onboarding")
		);
	}
}

const SessionService = new SessionServiceClass();
export default SessionService;
