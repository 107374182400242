import api from "./api";

class HelpServiceClass {
	getTopics() {
		return api
			.get("/api-360/public/assistance/topics")
			.then((response) => response.data);
	}

	postHelpRequest(data: any) {
		return api
			.post("/api-360/public/assistance/send", data)
			.then((response) => response.data);
	}
}

const HelpService = new HelpServiceClass();
export default HelpService;
