import React, { useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";

import Field from "../../components/molecules/Field/Field";
import {
	InfoMessage,
	Text,
	Title,
	useModal,
	Modal,
	Button,
} from "@zolteam/react-ras-library";
import { t } from "i18next";
import {
	RegisterCodeFormInitValues,
	RegisterCodeFormValidation,
} from "./RegisterCodeFormValidation";
import "./RegisterCodeForm.scss";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CODE_LENGTH = 4;

interface IRegisterCodeFormProps {
	error: string;
	onClose?: () => void;
	handleSubmitForm: (
		values: {
			activationCode: string;
		},
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => void;
	handleResendCode?: () => Promise<any>;
	email?: string;
	phone?: string;
	isLoading?: boolean;
}

const RegisterCodeValueSchema = { ...RegisterCodeFormInitValues };

export const InputFormCode: React.FC<IRegisterCodeFormProps> = (
	props: IRegisterCodeFormProps
) => {
	const navigate = useNavigate();
	const { submitCount, handleReset } =
		useFormikContext<typeof RegisterCodeValueSchema>();

	useEffect(() => {
		if (submitCount === 3) {
			navigate("/contact-agencies", {
				state: {
					infos: t("registerCode.infos"),
				},
			});
			handleReset();
		}
	}, [navigate, submitCount, handleReset]);

	return (
		<div>
			<Field
				name="activationCode"
				label={""}
				type="text"
				className="registerCode text-xl"
				maxLength={CODE_LENGTH}
			/>
		</div>
	);
};
export const RegisterCodeForm: React.FC<IRegisterCodeFormProps> = (
	props: IRegisterCodeFormProps
) => {
	const [smallModal, toggleSmallModal] = useModal();
	const [Error, setError] = React.useState(props.error);

	const handleClose = () => {
		if (props.onClose) props.onClose();
		if (typeof toggleSmallModal !== "boolean") toggleSmallModal();
	};

	const handleResendCode = () => {
		if (props.handleResendCode) {
			props.handleResendCode().catch((err) => {
				setError(
					t(
						"API_ERRORS." +
							(err.response.data.message
								? err.response.data.message
								: "default")
					).toString()
				);
			});
		}
	};

	return (
		<Modal isDisplayed={!smallModal} onClose={handleClose} size="s">
			<Formik
				initialValues={RegisterCodeValueSchema}
				validationSchema={RegisterCodeFormValidation(t)}
				onSubmit={(values, { resetForm }) => {
					props.handleSubmitForm(values, resetForm);
				}}
				validate={(values) => {
					if (values.activationCode?.length === CODE_LENGTH) {
						props.handleSubmitForm(values, () => {});
					}
				}}
			>
				<Form>
					<Title tag="h1" lineHeight="l" size="heading01">
						{t("registerCode.title")}
					</Title>
					<div className="w-[25rem] py-m">
						<InfoMessage color="primary" withIcon>
							<Trans
								i18nKey={
									props.email
										? "registerCode.mailInfo"
										: "registerCode.phoneInfo"
								}
								values={{
									email: props.email,
									phone: props.phone,
								}}
							/>
						</InfoMessage>
					</div>

					<div className={" pb-xl"}>
						<div className="flex items-center justify-center">
							<Text
								tag="span"
								size="paragraph01"
								lineHeight="l"
								className="pr-s"
							>
								{t("registerCode.sendBack")}
							</Text>
							<Button
								color="transparent"
								type="button"
								onClick={handleResendCode}
								isLoading={props.isLoading}
							>
								<p className="text-primary-500 font-bold">
									{t("registerCode.sendBackCode")}
								</p>
							</Button>
						</div>
						{Error && (
							<InfoMessage color="error" withIcon>
								{Error}
							</InfoMessage>
						)}
					</div>
					<InputFormCode
						error={props.error}
						handleSubmitForm={props.handleSubmitForm}
					/>
					{props.error?.length > 0 && (
						<div className="w-[25rem] py-s">
							<InfoMessage color="error" withIcon>
								{props.error}
							</InfoMessage>
						</div>
					)}
				</Form>
			</Formik>
		</Modal>
	);
};
