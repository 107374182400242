import { ICurve } from "./AnimatedWaves";

const DefaultCurve: ICurve[] = [
	{
		x: -25.237,
		y: -28.88,
		c_x: 12.523,
		c_y: 166.21,
		dir: -1,
		angle: 2.69,
		initValues: {
			x: -25.23,
			y: -28.88,
			c_x: 12.52,
			c_y: 166.21,
			dir: -1,
			angle: 2.69,
		},
	},
	{
		x: 40,
		y: 400,
		c_x: 0,
		c_y: 500,
		dir: 1,
		angle: 0.61,
		initValues: {
			x: 40,
			y: 400,
			c_x: 0,
			c_y: 500,
			dir: 1,
			angle: 0.61,
		},
	},
	{
		x: -40,
		y: 638,
		c_x: -40.21,
		c_y: 757,
		dir: -1,
		angle: 2.24,
		initValues: {
			x: -40,
			y: 638,
			c_x: -40.21,
			c_y: 757,
			dir: -1,
			angle: 2.24,
		},
	},
	{
		x: -40,
		y: 915.12,
		c_x: 10,
		c_y: 1110.21,
		dir: 1,
		angle: 1.14,
		initValues: {
			x: -40,
			y: 915.12,
			c_x: 10,
			c_y: 1110.21,
			dir: 1,
			angle: 1.14,
		},
	},
];

export default DefaultCurve;
