import React from "react";
import imgProfile from "../../../assets/images/profile.png";
import cn from "../../../utils/cn";
import SessionService from "../../../services/SessionService";
import { useQuery } from "@tanstack/react-query";

interface IAvatarProps {
	src?: string;
	title?: string;
	className?: string;
	noDefault?: boolean;
}

const Avatar: React.FC<IAvatarProps> = ({
	src,
	title,
	className,
	noDefault,
}) => {
	const { firstName, lastName } = SessionService.getUser();

	const getImage = () => {
		const { link } = SessionService.getAvatar();

		const img = src ? src : link ? link : noDefault ? "" : imgProfile;

		return {
			link: img,
			hasImage: img?.length && img !== imgProfile,
		};
	};

	const { data } = useQuery(["avatar"], () => {
		return Promise.resolve(getImage());
	});

	if (noDefault && !data?.hasImage) return null;

	return (
		<img
			src={data?.link}
			alt={title ? title : `${firstName} ${lastName}`}
			className={cn([
				"image",
				!!data ? "rounded-full object-cover object-center" : "",
				className,
			])}
		/>
	);
};

export default Avatar;
