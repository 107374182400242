import React from "react";
import { Title, Popover, Picto, InfoMessage } from "@zolteam/react-ras-library";
import AgenciesCard, {
	IAgenciesCard,
} from "../../components/molecules/AgenciesCard/AgenciesCard";
import { t } from "i18next";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import { Trans } from "react-i18next";
import SessionService from "../../services/SessionService";

const ContactMyAgencies: React.FC = () => {
	const userAgencies = SessionService.getUser().agencies || [];

	const genContactAgencies = () => {
		if (!userAgencies.length)
			return (
				<InfoMessage color="error" withIcon>
					<Trans i18nKey={"agencies.loadingError"} />
				</InfoMessage>
			);
		if (userAgencies.length === 1 && userAgencies[0].name === "VAA")
			return (
				<div className="w-full text-center rounded agency-card bg-neutral-50 p-s my-m">
					<Title
						tag="h2"
						lineHeight="m"
						size="heading03"
						className="mt-0"
					>
						{t("agencies.virtualAgency")}
					</Title>
					<InfoMessage color="success" withIcon>
						<Trans i18nKey={"agencies.attributingText"} />
					</InfoMessage>
				</div>
			);
		if (userAgencies.length)
			return userAgencies.map((agency: IAgenciesCard) => {
				return (
					<AgenciesCard key={agency.id} {...agency} values={agency} />
				);
			});
	};
	return (
		<Popover
			placement="bottom"
			content={
				<div className="!min-w-[20rem] !max-w-[80%] h-[550px] mx-auto overflow-auto text-center p-m rounded-lg sm:rounded-[1.5rem]">
					<Title tag="span" size="heading01" color="black">
						{userAgencies.length > 1
							? t("agencies.agenciesTitle")
							: t("agencies.agencyTitle")}
					</Title>
					{genContactAgencies()}
				</div>
			}
		>
			<button
				onClick={() => {
					pushDataLayer({
						dataLayer: {
							event: "header",
							item: "Mes agences",
						},
					});
				}}
				className="flex items-center headerLink gap-s"
			>
				<Picto icon="contact" style={{ width: "24px" }} />
				<p className="hidden sm:block">
					{userAgencies.length > 1
						? t("header.contactAgencies")
						: t("header.contactAgency")}
				</p>
			</button>
		</Popover>
	);
};

export default ContactMyAgencies;
