import api from "./api";

class RegisterServiceClass {
	getIdentityCheck(token: string, origin: string) {
		return api
			.get(
				`/api-360/worker/public/init/identity-check/available-property/${token}/${origin}`
			)
			.then((response) => {
				return response.data;
			});
	}

	postIdentityValidate({
		token,
		propertyToCheck,
		value,
	}: {
		token: string;
		propertyToCheck: string;
		value: string;
	}) {
		return api
			.post("/api-360/worker/public/init/identity-check/validate", {
				token,
				propertyToCheck,
				value,
			})
			.then((response) => {
				return response.data;
			});
	}

	// Registration route to register a new user that automatically send confirmation code email
	postUserRegistration(newUser: {
		lastName: string;
		firstName: string;
		phoneNumber: string;
		email: string;
		consent: boolean;
	}) {
		return api
			.post("api-360/worker/public/registration", {
				...newUser,
				phoneNumber: !!newUser.phoneNumber?.length
					? newUser.phoneNumber
					: null,
			})
			.then((response) => {
				return response.data;
			});
	}

	getUserRegistration() {
		return api.get("api-360/worker/me").then((response) => {
			return response.data;
		});
	}

	/**
	 * PUT - Route to send media type and value to send a security token
	 * @param {string} [uniqueId]
	 * @param {{value: String}} [email]
	 * @param {{value: String}} [phoneNumber]
	 */
	sendMediaSecurityToken = (params) =>
		api.put(
			"api-360/worker/communications-media/security-token/send",
			params
		);

	/**
	 * PUT - Verify security token for specified media type
	 * @param {string} uniqueId
	 * @param {string} securityToken
	 * @param {{value: String}} [email]
	 * @param {{value: String}} [phoneNumber]
	 */
	putVerifySecurityToken = (params) =>
		api.put("api-360/worker/communications-media/verify", params);

	checkActivationCode(uniqueId: string, activationCode: string) {
		return api
			.get(`api-360/worker/public/${uniqueId}/activate/${activationCode}`)
			.then((response) => {
				return response.data;
			});
	}

	checkMailActivationToken(token: string) {
		return api
			.get(`api-360/worker/public/activation_code/${token}`)
			.then((response) => {
				return response.data;
			});
	}

	resendActivationCode(uniqueId: string) {
		return api
			.get(`api-360/worker/public/${uniqueId}/activation_code/resend`)
			.then((response) => {
				return response.data;
			});
	}

	postRegisterPassword(
		password: string,
		uniqueId: string,
		activationCode: string
	) {
		return api
			.post("/api-360/worker/public/registration/password", {
				password,
				uniqueId,
				activationCode,
			})
			.then((response) => {
				return response.data;
			});
	}

	getAvailabilities() {
		return api.get("/api-360/worker/availabilities").then((response) => {
			return response.data;
		});
	}

	putAvailabilities(availabilities) {
		return api
			.put("/api-360/worker/availabilities/update", availabilities)
			.then((response) => {
				return response.data;
			});
	}
}

const RegisterService = new RegisterServiceClass();
export default RegisterService;
