export const BASE_PAGE_TITLE = "myRAS";

export const ENV = process.env.REACT_APP_ENV ?? "dev";
export const IS_PROD = ENV === "production";

export const PROXY_URL = process.env.REACT_APP_PROXY_URL ?? "";
export const API_URL = process.env.REACT_APP_API_URL;
export const AWS_ENV = process.env.REACT_APP_AWS_ENV;
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const FRONT_URL = process.env.REACT_APP_DOMAIN_URL;

export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const DAYS_OF_THE_WEEK = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

export const ACCEPTED_FILES_TYPES =
	"application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png, image/jpg, image/jpeg";
