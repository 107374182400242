import React from "react";
import { Text } from "@zolteam/react-ras-library";
import { DAYS_OF_THE_WEEK } from "../../../constants/constants";
import { t } from "i18next";
interface IWeekDaysSelectorProps {
	onDayClick: (day: string, newState: boolean) => void;
	isSelected: (day: string) => boolean;
}
const WeekDaysSelector: React.FC<IWeekDaysSelectorProps> = (
	props: IWeekDaysSelectorProps
) => {
	return (
		<div className="flex flex-wrap mt-m gap-s">
			{DAYS_OF_THE_WEEK.map((day, index) => {
				let selected = props.isSelected(day);
				return (
					<button
						className={`
						rounded-full w-10 h-10 duration-300 ${
							selected
								? "bg-primary-500"
								: "bg-white border-2 border-primary-300"
						}
					`}
						key={day}
						onClick={() => {
							props.onDayClick(day, !selected);
						}}
						type={"button"}
					>
						<Text
							tag="span"
							className={`${selected ? "!text-white" : ""}`}
						>
							{t(`availabilities.weekDaysSelector.${day}`)}
						</Text>
					</button>
				);
			})}
		</div>
	);
};

export default WeekDaysSelector;
