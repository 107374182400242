import * as Yup from "yup";
import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/regex";

export const SignUpFormInitValues = {
	lastName: "",
	firstName: "",
	email: "",
	phoneNumber: "",
	consent: false,
};

export const SignUpFormValidation = (t) =>
	Yup.object().shape({
		lastName: Yup.string().required(t("register.error.lastNameRequired")),
		firstName: Yup.string().required(t("register.error.firstNameRequired")),
		email: Yup.string()
			.required(t("register.error.emailRequired"))
			.test("email", t("register.error.email"), (value) =>
				value ? EMAIL_REGEX.test(value) : true
			),
		phoneNumber: Yup.string()
			.nullable()
			.test("phoneNumber", t("register.error.phone"), (value) =>
				value ? PHONE_REGEX.test(value) : true
			),
		consent: Yup.boolean(),
	});
