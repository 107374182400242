import React, { useState } from "react";
import Avatar from "../../atoms/Avatar/Avatar";
import { t } from "i18next";
import { Button, Picto } from "@zolteam/react-ras-library";
import ProfileService from "../../../services/ProfileService";
import { promiseToast } from "../../../toastify/toastify";
import SessionService from "../../../services/SessionService";
import Spinner from "../../atoms/Spinner/Spinner";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import cn from "../../../utils/cn";
import { Trans } from "react-i18next";

interface IAvatarEditProps {}

const AvatarEdit: React.FC<IAvatarEditProps> = ({ ...props }) => {
	const [isSending, setIsSending] = useState(false);
	const queryClient = useQueryClient();

	const handleUpload = (evt) => {
		const [file] = evt.target.files;
		setIsSending(true);
		if (!file) return toast.error(t("global.error"));

		evt.target.value = null;

		const prom = ProfileService.uploadAvatar(file).then(
			(resp) => {
				return SessionService.updateUser().then((resp) => {
					queryClient.invalidateQueries(["avatar"]).then(() => {
						setIsSending(false);
					});
				});
			},
			() => {
				setIsSending(false);
			}
		);

		promiseToast(prom);

		return prom;
	};

	const { data } = useQuery(["avatar"]);

	const hasImage = data?.hasImage;
	return (
		<div
			className={cn([
				"relative w-32 h-32 duration-300 overflow-hidden border rounded-full shadow-inner cursor-pointer group",
				isSending ? "pointer-events-none" : "",
			])}
		>
			{isSending && (
				<Spinner className="absolute items-center justify-center w-full h-full bg-opacity-50 bg-neutral-100" />
			)}
			<Avatar
				noDefault={true}
				className="w-full h-full duration-300 group-hover:opacity-70"
			/>
			<div
				className={cn([
					"absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full gap-2 overflow-hidden text-sm text-white duration-300 rounded-full cursor-pointer",
					hasImage
						? "opacity-0 bg-neutral-100 group-hover:opacity-70 text-neutral-800"
						: "bg-neutral-100 [&>*]:text-neutral-700 group-hover:bg-neutral-200",
				])}
			>
				<Picto icon="upload" />
				{hasImage ? (
					t("global.modify")
				) : (
					<span className="leading-4 text-center ">
						<Trans i18nKey={"avatar.sendLabel"} />
					</span>
				)}
				<input
					type="file"
					accept="image/jpg, image/jpeg, image/png, image/bmp, image/pjpeg"
					className="absolute top-0 left-0 w-full h-full bg-red-500 rounded-full opacity-0 cursor-pointer"
					onChange={handleUpload}
				/>
			</div>
		</div>
	);
};

export const RemoveAvatarButton: React.FC = () => {
	const queryClient = useQueryClient();
	const [ShowModal, setShowModal] = useState(false);

	const handleRemove = () =>
		ProfileService.removeAvatar().then((resp) => {
			return SessionService.updateUser().then((resp) => {
				queryClient.invalidateQueries(["avatar"]);
			});
		});

	const { data } = useQuery(["avatar"]);

	if (!data?.hasImage) return null;

	return (
		<>
			<Button
				color="primary"
				type="button"
				outline
				onClick={() => setShowModal(true)}
			>
				{t("avatar.remove")}
			</Button>
			<ConfirmModal
				size="s"
				title={t("avatar.askRemove")}
				isOpen={ShowModal}
				onConfirm={handleRemove}
				onClose={() => setShowModal(false)}
			>
				{t("avatar.askRemoveText")}
			</ConfirmModal>
		</>
	);
};

export default AvatarEdit;
