import { Title, Textarea } from "@zolteam/react-ras-library";
import { t } from "i18next";
import Select, { ISelectValue } from "../../components/molecules/Select/Select";
import Field from "../../components/molecules/Field/Field";
import React from "react";
import { useFormikContext } from "formik";
import { HelpFormInitValues } from "./HelpFormValidation";

interface IHelpFormInputProps {
	isLogged: boolean;
	isEmail: boolean;
	isPhone: boolean;
	agencies: ISelectValue[];
	topics: ISelectValue[];
}

const HelpInput: React.FC<IHelpFormInputProps> = ({
	isLogged,
	isEmail,
	isPhone,
	agencies,
	topics,
}) => {
	const { values, setFieldValue } =
		useFormikContext<typeof HelpFormInitValues>();

	return (
		<>
			<div className={"flex flex-col w-full mb-4"}>
				<Title
					tag="h3"
					size="heading03"
					className="mt-4 mb-3 !leading-7"
				>
					{t("help.problemForm.title")}
				</Title>
				<Textarea
					name="problem"
					placeholder={t("help.problemForm.text")}
					className="flex w-full"
					autoScale={true}
					style={{ resize: "none", height: "240px" }}
				/>
				<Title tag="h3" size="heading03" className="my-3 !leading-7">
					{t("help.identityForm.title")}
				</Title>
				<div className="flex flex-col gap-m">
					<Field
						name="firstName"
						label={t("help.identityForm.firstName")}
						type="text"
						className={
							"w-full flex" +
							(isLogged ? "pointer-events-none" : "")
						}
						containerClassName="w-full"
					/>
					<Field
						name="lastName"
						label={t("help.identityForm.lastName")}
						type="text"
						className={
							"w-full flex" +
							(isLogged ? "pointer-events-none" : "")
						}
						containerClassName="w-full"
					/>
					<Field
						name="email"
						label={t("help.identityForm.email")}
						type="text"
						className={
							"w-full flex" +
							(isLogged && isEmail ? "pointer-events-none" : "")
						}
						containerClassName="w-full"
					/>
					<Field
						name="phone"
						label={t("help.identityForm.phone")}
						type="text"
						className={
							"w-full flex" +
							(isLogged && isPhone ? "pointer-events-none" : "")
						}
						containerClassName="w-full"
					/>
				</div>
				<Title
					tag="h3"
					size="heading03"
					className="mt-4 mb-3 !leading-7"
				>
					{t("help.agencyForm.title")}
				</Title>
				<Select
					name="agency"
					label={t("help.agencyForm.text")}
					options={agencies}
					className="flex w-full "
					containerClassName="w-full"
					value={values.agency}
					onChange={(val) => setFieldValue("agency", val)}
				/>

				<Title
					tag="h3"
					size="heading03"
					className="mt-4 mb-3 !leading-7"
				>
					{t("help.sectionForm.title")}
				</Title>
				<Select
					name="topic"
					label={t("help.sectionForm.text")}
					options={topics}
					className="flex w-full"
					containerClassName="w-full"
					value={values.topic}
					onChange={(val) => setFieldValue("topic", val)}
				/>
			</div>
		</>
	);
};

export default HelpInput;
