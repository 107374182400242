import React, { useEffect, useRef, useState } from "react";
import {
	Button,
	Title,
	ModalV2,
	InfoMessage,
} from "@zolteam/react-ras-library";
import Field from "../../components/molecules/Field/Field";
import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";
import { PHONE_REGEX } from "../../utils/regex";
import { IStepComponentProps } from "../../components/templates/InitLayout/InitLayout";
import OnboardingPhoneVerification from "./OnboardingPhoneVerification";
import RegisterService from "../../services/RegisterService";
import { promiseToast } from "../../toastify/toastify";
import SessionService from "../../services/SessionService";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import { OnboardingRedirect } from "./Onboarding";

const ContactValidation = (trad) =>
	Yup.object().shape({
		phoneNumber: Yup.string()
			.nullable()
			.test("phone", trad("onboarding.contact.validation"), (value) => {
				if (!value) return true;
				return PHONE_REGEX.test(value);
			}),
	});

const OnboardingContact: React.FC<IStepComponentProps> = ({
	validateStep,
	submitStep,
	unvalidateStep,
	isActive,
	setIsLoading,
	isLoading,
	curStep,
	customNextCallback,
	setCustomNextCallback,
}) => {
	const { uniqueId } = SessionService.getUser();
	const [NoPhoneConfirmModal, setNoPhoneConfirmModal] = useState(false);
	const [PhoneVerificationModal, setPhoneVerificationModal] = useState(false);
	const { isStepValid } = curStep;
	const formRef = useRef<any>(null);
	const [Error, setError] = useState<string | null>(null);

	useEffect(() => {
		const sendVerificationCode = (phoneNumber) => {
			if (!phoneNumber || !uniqueId) return Promise.reject(false);
			const prom = RegisterService.sendMediaSecurityToken({
				uniqueId,
				phone: { value: phoneNumber },
			});

			return prom;
		};

		const handleCodeSend = (phoneNumber) => {
			if (!isLoading) {
				setIsLoading(true);
				setError(null);
				const prom = sendVerificationCode(phoneNumber).then(
					(resp) => {
						setIsLoading(false);
						setPhoneVerificationModal(true);
					},
					(e) => {
						setError(t("onboarding.contact.sendCodeError"));
						setIsLoading(false);
						throw e;
					}
				);
				promiseToast(prom);
			}
		};

		if (isActive && !customNextCallback) {
			setCustomNextCallback &&
				setCustomNextCallback(() => {
					const form = formRef.current;

					if (!form) return;
					if (isStepValid && form.values?.phoneNumber?.length) {
						handleCodeSend(form.values?.phoneNumber);
						return;
					} else {
						pushDataLayer({
							dataLayer: {
								event: "inscription__contact",
								telephone: form.values?.phoneNumber?.length
									? "yes"
									: "no",
							},
						});
						setNoPhoneConfirmModal(true);
					}
				});
		}
	}, [
		isActive,
		uniqueId,
		customNextCallback,
		setCustomNextCallback,
		isStepValid,
		submitStep,
		formRef,
		isLoading,
		setIsLoading,
	]);

	if (!isActive) return null;

	return (
		<div>
			<Title tag="h1" className="!leading-7 mb-6">
				{t("onboarding.contact.title")}
			</Title>
			<Formik
				initialValues={{
					phoneNumber: "",
				}}
				validationSchema={ContactValidation(t)}
				validateOnMount
				validateOnBlur
				validate={(values) => {
					let isValid = true;
					if (
						values.phoneNumber?.length &&
						!PHONE_REGEX.test(values.phoneNumber)
					)
						isValid = false;
					if (isValid) validateStep && validateStep(values);
					else unvalidateStep && unvalidateStep();
				}}
				onSubmit={(values) => {
					return;
				}}
				innerRef={formRef}
			>
				{({ values }) => (
					<Form>
						<Field
							name="phoneNumber"
							type="text"
							label={`${t("onboarding.contact.phoneNumber")} (${t(
								"global.optionnal"
							)})`}
						/>
						<ModalV2
							isDisplayed={NoPhoneConfirmModal}
							onClose={() => setNoPhoneConfirmModal(false)}
							title={t("onboarding.contact.continueWithoutPhone")}
							size="s"
						>
							<NoPhoneConfirmModalBody
								onConfirm={() => {
									pushDataLayer({
										dataLayer: {
											event: "inscription__contact_popup",
											choix: "Continuer sans numéro",
										},
									});
									setNoPhoneConfirmModal(false);
									submitStep && submitStep();
									OnboardingRedirect();
								}}
								onCancel={() => {
									pushDataLayer({
										dataLayer: {
											event: "inscription__contact_popup",
											choix: "Ajouter un numéro",
										},
									});
									setNoPhoneConfirmModal(false);
								}}
							/>
						</ModalV2>
						<ModalV2
							isDisplayed={PhoneVerificationModal}
							onClose={() => setPhoneVerificationModal(false)}
							title={t("onboarding.contact.securityCode")}
							size="s"
						>
							<OnboardingPhoneVerification
								phoneNumber={values.phoneNumber}
							/>
						</ModalV2>
						{!!Error?.length && (
							<InfoMessage color="error" withIcon>
								{Error}
							</InfoMessage>
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
};

interface IConfirmModalProps {
	onConfirm: () => void;
	onCancel: () => void;
}

const NoPhoneConfirmModalBody: React.FC<IConfirmModalProps> = ({
	onConfirm,
	onCancel,
}) => {
	return (
		<>
			<p className="mt-6 mb-8">{t("onboarding.contact.noPhoneText")}</p>
			<div className="flex justify-between">
				<Button
					type="button"
					onClick={onCancel}
					color="primary"
					outline
				>
					{t("onboarding.contact.addPhoneBtn")}
				</Button>
				<Button type="button" color="primary" onClick={onConfirm}>
					{t("onboarding.contact.continueWithout")}
				</Button>
			</div>
		</>
	);
};

export default OnboardingContact;
