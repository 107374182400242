import React, { useState } from "react";
import { Button, Picto } from "@zolteam/react-ras-library";
import { File } from "react-pdf/dist/esm/types/pdfjs";
import cn from "../../../utils/cn";
import Spinner from "../../atoms/Spinner/Spinner";
import { DefaultTFuncReturn, t } from "i18next";
import { IDocument } from "../../templates/DocsLayout/DocsLayout";
import PDFThumbnail from "../../atoms/PDFThumbnail/PDFThumbnail";
import { ACCEPTED_FILES_TYPES } from "../../../constants/constants";

export interface IAddDocButton {
	onFileReaded: (
		files: any,
		onCompress?: (values: { progress: number }) => void
	) => Promise<any>;
	disabled?: boolean;

	text?: string | DefaultTFuncReturn;
	accept?: string;
	onClick?: () => void;
	className?: string;
	isLoading?: boolean;
}

interface IDocumentTile {
	document: IDocument;
	onClick?: () => void;
	onDelete?: (uuid: string) => void;
	disabled?: boolean;
	title?: string | ((doc: IDocument) => string) | DefaultTFuncReturn;
}

export const DocumentTile: React.FC<IDocumentTile> = ({
	document,
	onClick,
	onDelete,
	disabled,
	title,
}) => {
	const genPreview = (doc) => {
		if (!doc) return;
		const docName = doc.files ? doc.files[0]?.name : doc.name.toLowerCase();

		if (!docName) return;
		const usedDoc = document.files ? document.files[0] : doc;
		if (docName.match(/^.*\.(jpeg|jpg|png)$/))
			return (
				<img
					src={usedDoc.link}
					alt={docName}
					className="h-full rounded-8 max-h-full object-cover min-w-[100px]"
				/>
			);
		else if (docName.match(/^.*\.(pdf)$/))
			return <PDFThumbnail file={usedDoc.link} />;
	};

	// remove the extension from the name
	const genName = (doc: IDocument) => {
		if (!doc) return;

		let docName = doc.files ? doc.files[0]?.name : doc.name;

		if (typeof title === "string") return title;
		else if (typeof title === "function") return title(doc);

		if (!docName)
			return (
				<span className="block p-3 mt-6 text-white rounded bg-error-600 bg-opacity-80">
					{t("profilePro.proDocs.docError")}
				</span>
			);

		return docName.replace(/\.[^/.]+$/, "");
	};

	const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();
		if (onDelete) onDelete(document.uuid);
	};

	const name = genName(document)?.toString();

	return (
		<div
			className={cn([
				"flex gap-m bg-neutral-100 p-3 rounded-16 w-full 2xs:w-[260px] cursor-pointer",
				disabled && "opacity-50 pointer-events-none select-none",
			])}
			style={{
				aspectRatio: "263/164",
			}}
			onClick={onClick}
		>
			<div className="relative flex items-center justify-center rounded-4 h-full max-w-[40%]">
				{genPreview(document)}
			</div>

			<div className="flex flex-col justify-between w-full overflow-hidden">
				<p
					title={name}
					className="font-semibold leading-5 uppercase break-words truncate whitespace-break-spaces line-clamp-6"
				>
					{name}
				</p>

				<div className="flex flex-wrap justify-end mt-auto ">
					{document.files && document.files?.length > 1 && (
						<div className="flex items-center justify-center h-8 px-3 border-2 border-white bg-primary-500 rounded-2xl">
							<p className="text-sm font-semibold text-white">
								{t("profilePro.proDocs.files", {
									count: document.files?.length,
								})}
							</p>
						</div>
					)}
					<Button
						type="button"
						color="transparent"
						className="ml-auto w-[30px] [&>svg]:!mx-0 hover:!bg-neutral-200 !p-1"
						onClick={handleDelete}
					>
						<Picto
							icon="trash"
							className="[&>*]:!text-primary-500"
						/>
					</Button>
				</div>
			</div>
		</div>
	);
};

export const EmptyDocumentTile: React.FC<IAddDocButton> = ({
	onFileReaded,
	disabled,
	text,
	accept,
	onClick,
	className,
	isLoading,
}) => {
	const [hasFile, setHasFile] = useState<File | boolean>(false);
	const [Compression, setCompression] = useState(-1);

	return (
		<div
			className={cn([
				`relative
					flex flex-col gap-s
					items-center justify-center
					text-primary-500 w-full xs:w-[45%] lg:w-[190px]  bg-primary-50 text-center
					rounded-16 border-dashed border-2 border-primary-500 min-h-[164px]`,
				className,
				(disabled || hasFile) &&
					"opacity-70 border-solid pointer-events-none select-none",
			])}
			style={{
				aspectRatio: "263/164",
			}}
		>
			{!hasFile ? (
				<>
					{isLoading ? (
						<Spinner />
					) : (
						<Picto
							icon="plus"
							className="w-12 h-12 text-primary-500"
						/>
					)}
					<p className="font-semibold">{text}</p>
					{onClick ? (
						<button
							className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
							onClick={onClick}
						/>
					) : (
						<input
							disabled={disabled || hasFile}
							type="file"
							className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
							accept={accept ?? ACCEPTED_FILES_TYPES}
							onChange={(e) => {
								setHasFile(
									e.target.files ? e.target.files[0] : false
								);
								onFileReaded(
									e.target.files,
									(values: { progress: number }) => {
										setCompression(values.progress);
									}
								);
							}}
						/>
					)}
				</>
			) : (
				<>
					<Spinner
						text={
							<span>
								{t("compressor.progress", {
									percent: Compression,
								})}
							</span>
						}
					/>
				</>
			)}
		</div>
	);
};
