import React, { useContext } from "react";
import { IRoute } from "../../../routes";
import {
	AdminLayoutContextType,
	AdminLayoutContext,
} from "../../../contexts/AdminLayoutContext/AdminLayoutContext";
import cn from "../../../utils/cn";
import SessionService from "../../../services/SessionService";
import { pushDataLayer, setPageTitle } from "../../../GoogleTagManager/gtm";

interface IPageWrapperProps {
	route: IRoute;
	parentRoute?: IRoute;
}

interface IPrevPage {
	page: IRoute;
	parentRoute?: IRoute;
}

const PageWrapper: React.FC<IPageWrapperProps> = ({
	route: page,
	parentRoute,
}) => {
	const prevPage = React.useRef<IPrevPage>({
		page: {},
		parentRoute: {},
	});

	const { setSideBox, setScrollable } =
		useContext<AdminLayoutContextType>(AdminLayoutContext);

	sessionStorage.setItem("prevPage", JSON.stringify(prevPage.current ?? {}));
	React.useLayoutEffect(() => {
		if (prevPage.current?.page.path === page.path) return;

		const genName = () => {
			if (parentRoute?.name) return parentRoute.name;
			if (page.name) return page.name;
			return "";
		};

		const name = genName();

		setScrollable(page.scrollable !== false);
		setSideBox(page.sideElement ?? false);
		const { origin, pathname } = window.location;
		const onboarded = sessionStorage.getItem("onboardingFinish") === "true";
		pushDataLayer({
			dataLayer: {
				event: "virtualPageview",
				pageUrl: `${origin}${pathname}`,
				pageTitle: page.name,
				pageType: name,
				UserId: SessionService.getUser().uniqueId,
				isOnboarded: onboarded,
			},
		});

		setPageTitle(name, (page.name ?? "")?.toString());

		prevPage.current = { page, parentRoute };
	}, [setScrollable, setSideBox, page, parentRoute]);

	return (
		<div
			className={cn([
				"relative w-full h-full",
				page.scrollable === false && "overflow-hidden",
			])}
		>
			{page.element}
		</div>
	);
};

export default PageWrapper;
