import React from "react";
import InitLayout, {
	IStep,
} from "../../components/templates/InitLayout/InitLayout";

import WorkplaceIllu from "../../assets/illustrations/workplace.svg";
import ContactIllu from "../../assets/illustrations/contact.svg";
import OnboardingWorkplace from "./OnboardingWorkplace";
import OnboardingSectors from "./OnboardingSectors";
import OnboardingReferenceAgency from "./OnboardingReferenceAgency";
import OnboardingCV from "./OnboardingCV";
import OnboardingContact from "./OnboardingContact";
import { StepsContextProvider } from "../../contexts/StepsContext/StepsContext";
import SessionService from "../../services/SessionService";
import { t } from "i18next";
import OnboardingJobs from "./OnboardingJobs";
import TokenService from "../../services/TokenService";

export const OnboardingRedirect = () => {
	TokenService.setOnboarding(false);
	sessionStorage.setItem("onboardingFinish", "true");
	window.location.assign("/");
};
const Onboarding: React.FC = () => {
	const { firstName, phoneNumber } = SessionService.getUser();

	const steps: IStep[] = [
		{
			title: t("onboarding.workplaces.stepTitle"),
			name: "workplace",
			component: OnboardingWorkplace,
			backgroundImg: WorkplaceIllu,
			submitOnNext: true,
		},
		{
			title: t("onboarding.sectors.stepTitle"),
			name: "sectors",
			component: OnboardingSectors,
			backgroundImg: "",
			submitOnNext: true,
		},
		{
			title: t("onboarding.jobs.stepTitle"),
			name: "jobs",
			component: OnboardingJobs,
			backgroundImg: "",
			submitOnNext: true,
		},
		{
			title: t("onboarding.cv.stepTitle"),
			name: "cv",
			component: OnboardingCV,
			backgroundImg: "",
		},
		{
			title: t("onboarding.agency.stepTitle"),
			name: "referenceAgency",
			component: OnboardingReferenceAgency,
			backgroundImg: "",
			submitOnNext: true,
			enableBackNavigation: false,
		},
	];

	if (phoneNumber === null) {
		steps.push({
			title: t("onboarding.contact.stepTitle"),
			name: "contact",
			component: OnboardingContact,
			backgroundImg: ContactIllu,
		});
	}

	return (
		<StepsContextProvider
			initValues={{
				workplaces: {
					locations: [],
					mainLocation: null,
				},
				sectors: [],
				jobs: [],
				referenceAgency: null,
				contact: null,
			}}
		>
			<InitLayout
				allowBack={true}
				title={
					<>
						{t("onboarding.welcome")} {firstName}&nbsp;!
					</>
				}
				subtitle=""
				text={t("onboarding.text")}
				steps={steps}
			/>
		</StepsContextProvider>
	);
};

export default Onboarding;
