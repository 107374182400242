import React, { useState } from "react";
import { InfoMessage, ToggleSwitch } from "@zolteam/react-ras-library";
import { useSettings } from "../../contexts/SettingsContext/SettingsContext";

interface IFunctionSwitchProps {
	name: string;
	disabled?: boolean;
	label?: string;
	onChange?: (val: boolean) => void;
	toggled?: boolean;
}

const settingsList = [];

const DevFeaturesForm: React.FC = () => {
	const { isDev } = useSettings();

	if (!isDev) return null;

	return (
		<div className="[&>div]:mb-3">
			<InfoMessage color={"warning"} withIcon>
				Attention, ces fonctionnalités sont en cours de développement et
				peuvent ne pas être fonctionnelles.
			</InfoMessage>
			<div className="flex flex-col gap-2">
				{settingsList.length > 0 &&
					settingsList.map((a, key) => (
						<FunctionSwitch key={key} {...a} />
					))}
			</div>
		</div>
	);
};

const FunctionSwitch: React.FC<IFunctionSwitchProps> = ({
	name,
	label,
	disabled,
	toggled,
	onChange = () => {},
}) => {
	const { getSettings, setSetting } = useSettings();

	const [Toggled, setToggled] = useState(
		(getSettings(name) || toggled) ?? false
	);

	const handleChange = () => {
		const newState = !Toggled;
		setToggled(newState);
		setSetting(name, newState);
		onChange(newState);
	};

	return (
		<label className="flex items-center gap-2">
			<ToggleSwitch
				onChange={handleChange}
				disabled={disabled}
				toggled={Toggled}
				name={name}
				id={`devFeatures-switch-${name}`}
			/>
			<span>{label}</span>
		</label>
	);
};

export default DevFeaturesForm;
