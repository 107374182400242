import React, { Ref } from "react";
import { Formik, Form, useFormikContext, FormikProps } from "formik";
import {
	RegisterEmailFormInitValues,
	RegisterEmailFormValidation,
} from "./RegisterEmailPhoneValidationForm";
import { t } from "i18next";
import Field from "../../components/molecules/Field/Field";

interface IRegisterEmailFormProps {
	handleSubmitForm: (
		values: { email: string },
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => void;

	email?: string;
	formRef?: Ref<FormikProps<any>> | undefined;
	validateStep?: (values: {}) => void;
}

const RegisterMailInput = (props) => {
	const { setFieldTouched } = useFormikContext();

	React.useEffect(() => {
		if (props.email) setFieldTouched("email", true, true);
	}, [setFieldTouched, props.email]);

	return (
		<div>
			<Field
				name={"email"}
				label={t("registerEmail.email")}
				type="text"
			/>
		</div>
	);
};

export const RegisterEmailForm: React.FC<IRegisterEmailFormProps> = (
	props: IRegisterEmailFormProps
) => {
	const RegisterEmailValueSchema = props.email
		? {
				email: props.email,
		  }
		: {
				...RegisterEmailFormInitValues,
		  };

	return (
		<Formik
			initialValues={RegisterEmailValueSchema}
			validationSchema={RegisterEmailFormValidation(t)}
			onSubmit={(values, { resetForm }) => {
				return props.handleSubmitForm(values, resetForm);
			}}
			validateOnChange={true}
			validate={(values: typeof RegisterEmailValueSchema) => {
				props.validateStep && props.validateStep(values);
			}}
			innerRef={props.formRef}
		>
			<Form>
				<RegisterMailInput email={props.email} />
			</Form>
		</Formik>
	);
};
