export const getImageSize = (url, onLoaded?) => {
	const promise = new Promise((resolve, reject) => {
		let img = document.createElement("img");

		img.src = url;

		let poll = setInterval(function () {
			if (img.naturalWidth) {
				clearInterval(poll);
				resolve({
					width: img.naturalWidth,
					height: img.naturalHeight,
				});
			}
		}, 10);

		img.onload = function () {
			onLoaded && onLoaded(img);
		};

		img.onerror = function () {
			reject("Could not load image.");
		};
	});

	return promise;
};
