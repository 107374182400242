import React, { useState } from "react";
import { t } from "i18next";
import { Button, InfoMessage, Title, Picto } from "@zolteam/react-ras-library";
import { Trans } from "react-i18next";
import { Form, Formik } from "formik";
import { useQueries } from "@tanstack/react-query";
import SectorsServices from "../../services/SectorsServices";
import Loader from "../../components/atoms/Loader/Loader";
import ConfirmModal from "../../components/molecules/ConfirmModal/ConfirmModal";
import SectorsCard from "../../components/atoms/SectorsCard/SectorsCard";
import QualificationsService from "../../services/QualificationsService";

interface IQualification {
	id: string;
	name: string;
}
const MasterCategories = () => {
	const [userSectors, userQualifications] = useQueries({
		queries: [
			{
				queryKey: ["userSectors"],
				queryFn: () => SectorsServices.getUserSectors(),
			},
			{
				queryKey: ["userQualifications"],
				queryFn: () => QualificationsService.getUserQualifications(),
			},
		],
	});

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentJob, setCurrentJob] = useState<IQualification>({
		id: "",
		name: "",
	});

	if (userSectors.isLoading || userQualifications.isLoading)
		return <Loader />;

	const handleDelete = (name: string, id: string) => {
		setIsModalOpen(true);
		setCurrentJob({ name: name, id: id });
	};
	const handleConfirm = () => {
		const newQualifications =
			userQualifications.data.secondaryQualifications.filter(
				(job: IQualification) => job.id !== currentJob.id
			);
		QualificationsService.postUserQualifications({
			mainQualification: userQualifications.data.mainQualification,
			secondaryQualifications: newQualifications,
		});
	};

	return (
		<>
			<Title tag="h1">{t("profilePro.masterCategories.title")}</Title>
			<div className="flex flex-col md:flex-row justify-between gap-24 ml-xxl">
				<div className="flex flex-col md:w-[40%]">
					<Title tag="h2">
						{t("profilePro.masterCategories.masterCategory")}
					</Title>

					<div className="w-[25rem] py-m  w-full">
						<InfoMessage color="primary" withIcon>
							<Trans i18nKey="profilePro.masterCategories.infos" />
						</InfoMessage>
					</div>
					<Button
						type="button"
						color="grey"
						disabled={true}
						size="l"
						className="w-full mb-xl justify-start"
					>
						{userQualifications.data?.mainQualification.name}
					</Button>
					<Title tag="h2" className="mb-m">
						{t("profilePro.masterCategories.otherJobs")}
					</Title>

					<Formik initialValues={{ sector: "" }} onSubmit={() => {}}>
						{() => {
							return (
								<Form>
									{userQualifications.data.secondaryQualifications.map(
										(job: IQualification) => {
											return (
												<Button
													key={job.id}
													type="button"
													color="grey"
													size="l"
													outline
													className="w-full mb-xl justify-between"
													onClick={() =>
														handleDelete(
															job.name,
															job.id
														)
													}
												>
													{job.name}
													<Picto
														icon="trash"
														className="w-5 h-5 [&>*]:text-primary-500"
													/>
												</Button>
											);
										}
									)}
								</Form>
							);
						}}
					</Formik>
				</div>
				<div className="flex flex-col md:w-[60%] gap-m">
					<Title tag="h2">
						{t("profilePro.masterCategories.sectors")}
					</Title>
					<SectorsCard sectors={userSectors.data.sectors} />
				</div>
			</div>
			<ConfirmModal
				title={t("profilePro.masterCategories.modalTitle")}
				isOpen={isModalOpen}
				confirmText={t("profilePro.masterCategories.modalConfirm")}
				onClose={() => setIsModalOpen(false)}
				onConfirm={handleConfirm}
				className="w-[35%]"
			>
				<Trans
					i18nKey="profilePro.masterCategories.modalText"
					values={{ name: currentJob.name }}
				/>
			</ConfirmModal>
		</>
	);
};

export default MasterCategories;
