import api from "./api";

class PayslipServiceClass {
	getPayslips(filters) {
		return api
			.post("/api-360/payslips", {
				months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				years: [2023],
				agencies: ["1337"],
				sorting: "name",
				orderBy: "DESC",
				page: 1,
				limit: 100,
				...filters,
			})
			.then((response) => {
				return response.data;
			});
	}
}

const PayslipService = new PayslipServiceClass();
export default PayslipService;
