import React from "react";
import {
	Button,
	Picto,
	Text,
	Title,
	Tooltip,
} from "@zolteam/react-ras-library";
import { IAgency } from "../../../forms/AgenciesForm/AgenciesForm";
import { phoneNumberFormat } from "../../../utils/phoneNumberFormat";
import "../../../forms/AgenciesForm/Agencies.scss";

export interface IAgenciesCard extends IAgency {
	values: IAgency & {
		city: string;
		postalCode: string;
	};
}
const AgenciesCard: React.FC<IAgenciesCard> = (props: IAgenciesCard) => {
	const { locations, city, street, phone, name, postalCode } = props.values;
	const haveLocations = () => {
		if (locations?.length) {
			return `${locations[0].name} -
				${locations[0].postalCode}`;
		}
		return `${city} - ${postalCode}`;
	};
	return (
		<div className="min-w-full text-center rounded agency-card bg-neutral-50 py-m my-m">
			<Tooltip content={"Cliquez pour ouvrir la carte"}>
				<a
					href={`https://maps.google.com/?q=${encodeURI(
						`${name} ${street}, ${haveLocations()}`
					)}`}
					target="_blank"
					rel="noreferrer"
					className="block p-1 mx-auto w-fit"
				>
					<Title
						tag="h2"
						lineHeight="m"
						size="heading03"
						className="mt-0 pb-s"
					>
						{name}
					</Title>
					<Text tag="p" size="paragraph01">
						{street}
					</Text>
					<Text tag="p" size="paragraph01">
						{locations
							? locations.map(
									(location) =>
										`${location.name} ${location.postalCode}`
							  )
							: `${city} ${postalCode}`}
					</Text>
				</a>
			</Tooltip>
			{!!phone?.length && (
				<a href={"tel:" + phone} className="block mt-2">
					<Button
						color="primary"
						type="button"
						className="border border-solid"
					>
						<Picto
							icon="contact"
							style={{
								width: "30px",
								height: "30px",
							}}
						/>
						<Text
							tag="span"
							size="paragraph01"
							className="!text-white"
						>
							{phoneNumberFormat(phone)}
						</Text>
					</Button>
				</a>
			)}
		</div>
	);
};

export default AgenciesCard;
