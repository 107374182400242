import React, { useCallback, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	Text,
	Title,
	BackLink,
	Button,
	InfoMessage,
} from "@zolteam/react-ras-library";

// Services
import SecurityService from "../../services/SecurityService";

// Templates
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";

// Forms
import { RetrievePasswordForm } from "../../forms/RetrievePasswordForm/RetrievePasswordForm";

// SVG
import SuccessMail from "../../assets/illustrations/email.svg";
import { toast } from "react-toastify";
import LoginIdService from "../../services/LoginIdService";
import SVG from "../../components/atoms/SVG/SVG";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const RetrievePassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [RequestState, setRequestState] = useState({
		loading: false,
		error: "",
		success: false,
		retrieveId: "",
	});

	const handleBackNavigation = () => {
		navigate("/login");
	};

	const handleSubmitForm = ({ retrieveId }) => {
		if (!retrieveId?.length)
			return setRequestState({
				loading: false,
				error: t("retrievePassword.idRequired"),
				success: false,
				retrieveId,
			});

		let uppercasedRetrieveId = retrieveId.toUpperCase();

		setRequestState({
			...RequestState,
			loading: true,
			error: "",
			success: false,
			retrieveId: uppercasedRetrieveId,
		});

		SecurityService.sendResetPasswordLink(uppercasedRetrieveId).then(
			() => {
				pushDataLayer({
					dataLayer: {
						event: "connexion__reinitialiser_mdp",
					},
				});
				LoginIdService.setId(uppercasedRetrieveId);
				setRequestState({
					loading: false,
					error: "",
					success: true,
					retrieveId: uppercasedRetrieveId,
				});
			},
			() => {
				setRequestState({
					...RequestState,
					loading: false,
					error: t("retrievePassword.sendError"),
					success: false,
				});
			}
		);
	};

	const resendResetLink = useCallback(() => {
		let retrieveId = RequestState.retrieveId;
		if (!retrieveId?.length)
			return setRequestState({
				...RequestState,
				loading: false,
				error: t("retrievePassword.idRequired"),
				success: false,
			});
		setRequestState({
			...RequestState,
			loading: true,
			error: "",
			success: true,
		});

		SecurityService.resendResetPasswordLink(retrieveId).then(
			() => {
				toast.success(t("retrievePassword.resendSuccess"));
				setRequestState({
					...RequestState,
					loading: false,
					error: "",
					success: true,
				});
			},
			() => {
				setRequestState({
					...RequestState,
					loading: false,
					error: t("retrievePassword.sendError"),
					success: false,
				});
			}
		);
	}, [RequestState, t]);

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" lineHeight="m" size="heading01" className={"py-m"}>
				{t("retrievePassword.title")}
			</Title>
			{!RequestState.success ? (
				<>
					<InfoMessage withIcon tag="p" lineHeight="l">
						{t("retrievePassword.text")}
					</InfoMessage>
					{RequestState.error?.length > 0 && (
						<InfoMessage
							withIcon
							color={"error"}
							tag="p"
							lineHeight="l"
							className={"w-full"}
						>
							{RequestState.error}
						</InfoMessage>
					)}
					<RetrievePasswordForm
						load={RequestState.loading}
						handleSubmitForm={handleSubmitForm}
					/>
				</>
			) : (
				<>
					<InfoMessage
						color="success"
						withIcon
						tag="p"
						lineHeight="l"
					>
						<Trans
							i18nKey="retrievePassword.emailSend"
							values={{
								buttonLabel: t("retrievePassword.resend"),
							}}
							components={[
								<Button
									type="button"
									color="primary"
									onClick={() => resendResetLink()}
									isLoading={RequestState.loading}
								>
									button
								</Button>,
							]}
						/>
					</InfoMessage>
					<SVG src={SuccessMail} className={"w-full"} />
					<Button
						size="m"
						outline
						className={"w-full mt-xl"}
						type="button"
						color="primary"
						isLoading={RequestState.loading}
						onClick={() => resendResetLink()}
					>
						{t("retrievePassword.resend")}
					</Button>
					<Text tag="p" lineHeight="l" className={"mt-m"}>
						<Trans
							i18nKey="retrievePassword.notReceived"
							values={{
								buttonLabel: t("global.contactAgency"),
							}}
							components={[
								<Button
									type="button"
									color="transparentPrimary"
									onClick={() =>
										navigate("/contact-agencies")
									}
								>
									button
								</Button>,
							]}
						/>
					</Text>
				</>
			)}
		</AuthLayout>
	);
};

export default RetrievePassword;
