import React from "react";
import Select, { ISelectValue } from "../Select/Select";

interface IMonthSelectProps {
	onChange?: (data) => void;
	value?: any;
	className?: string;
}

const MonthSelect: React.FC<IMonthSelectProps> = (props) => {
	const months = [
		{ value: 1, label: "Janvier" },
		{ value: 2, label: "Février" },
		{ value: 3, label: "Mars" },
		{ value: 4, label: "Avril" },
		{ value: 5, label: "Mai" },
		{ value: 6, label: "Juin" },
		{ value: 7, label: "Juillet" },
		{ value: 8, label: "Août" },
		{ value: 9, label: "Septembre" },
		{ value: 10, label: "Octobre" },
		{ value: 11, label: "Novembre" },
		{ value: 12, label: "Décembre" },
	];

	const [selected, setSelected] = React.useState<ISelectValue[]>([]);

	const handleChange = (selected: ISelectValue[]) => {
		setSelected(selected);
	};

	return (
		<Select
			isMulti={true}
			options={months}
			onChange={handleChange}
			name="Période"
			label={"Mois"}
			value={selected}
			closeMenuOnSelect={false}
			{...props}
		/>
	);
};

export default MonthSelect;
