import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
	Title,
	Text,
	BackLink,
	Button,
	InfoMessage,
} from "@zolteam/react-ras-library";
// Templates
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import { Trans } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
// Forms
import { ResetPasswordForm } from "../../forms/ResetPasswordForm/ResetPasswordForm";

import Loader from "../../components/atoms/Loader/Loader";
import SecurityService from "../../services/SecurityService";
import { toast } from "react-toastify";

const ResetPassword: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();

	const [queryState, setQueryState] = useState<any>({
		isLoading: false,
		success: false,
		error: "",
	});

	const handleBackNavigation = () => {
		navigate("/login");
	};

	/**
	 * Handle submit form
	 * @param {Object} values
	 * @param {Function} resetForm
	 * @returns {void}
	 * @memberof ResetPassword
	 * @inner
	 * @function handleSubmitForm
	 */
	const handleSubmitForm = ({ password }, resetForm) => {
		setQueryState({
			isLoading: true,
			success: false,
			error: "",
		});

		SecurityService.resetPassword(params?.token ?? "", password).then(
			() => {
				setQueryState({
					isLoading: false,
					success: true,
					error: "",
				});
				toast.success(t("resetPassword.success"));
				resetForm();
				navigate("/login/reseted-password");
			},
			(error) => {
				setQueryState({
					isLoading: false,
					success: false,
					error: error.response.data.message,
				});
			}
		);
	};

	const { isLoading, error, data } = useQuery(
		["resetPassword"],
		() => SecurityService.checkPasswordResetToken(params?.token ?? ""),
		{
			retry: params?.token && params?.token?.length > 0 ? 1 : 0,
		}
	);

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" lineHeight="m" size="heading01" className={"pt-m"}>
				{t("resetPassword.title")}
			</Title>
			{isLoading ? (
				<Loader className="mx-auto mt-6" />
			) : params.token?.length && !error && data?.data.valid ? (
				<ResetPasswordForm
					load={queryState.isLoading}
					error={queryState.error}
					handleSubmitForm={handleSubmitForm}
				/>
			) : (
				<div>
					<InfoMessage withIcon color="error" tag="p">
						{t("resetPassword.invalidLink")}
					</InfoMessage>
					<br />
					<Button
						type="button"
						className="mt-l"
						outline
						color="primary"
						onClick={() => navigate("/password-retrieve")}
					>
						{t("resetPassword.askNewLink")}
					</Button>
					<Text tag="p" lineHeight="l" className={"mt-m"}>
						<Trans
							i18nKey="retrievePassword.notReceived"
							values={{
								buttonLabel: t("global.contactAgency"),
							}}
							components={[
								<Button
									type="button"
									color="transparentPrimary"
									onClick={() =>
										navigate("/contact-agencies")
									}
								>
									button
								</Button>,
							]}
						/>
					</Text>
				</div>
			)}
		</AuthLayout>
	);
};

export default ResetPassword;
