import React from "react";
import AgenciesService from "../../../services/AgenciesService";
import { useQuery } from "@tanstack/react-query";
import Select, { ISelectProps, ISelectValue } from "../Select/Select";
import { t } from "i18next";

interface IAgencySelectProps extends ISelectProps {
	onChange?: (data: ISelectValue[]) => void;
	query?: {
		key: string[];
		fn: () => Promise<any>;
	};
	options?: any[];
	value?: any;
}

const AgencySelect: React.FC<IAgencySelectProps> = (props) => {
	const { isLoading, error, data } = useQuery(
		props.query?.key ?? ["fetchAgencies"],
		props.query?.fn
			? props.query.fn
			: () => AgenciesService.getMyAgencies(),
		{
			enabled: !props.options,
			placeholderData: props.options ?? {},
		}
	);

	const formatData = (items: []) => {
		return items.map((item: { id: string; name: string }) => ({
			value: item.id,
			label: item.name,
		}));
	};

	if (isLoading) {
		return <div>{t("global.loading")}</div>;
	}

	if (error) {
		return <div>{t("global.error")}</div>;
	}

	return (
		<Select
			isMulti={true}
			{...props}
			className={props.value ? "[&>label]:opacity-100" : ""}
			options={formatData(props.options ?? data?.agencies ?? [])}
		/>
	);
};

export default AgencySelect;
