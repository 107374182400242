export const COMMON_DOCUMENT_ID_CARD_ID = 105;
export const COMMON_DOCUMENT_EUROPEAN_ID_CARD_ID = 663;
export const COMMON_DOCUMENT_PASSPORT_ID = 270;
export const COMMON_DOCUMENT_RESIDENCE_PERMIT_ID = 313;

export const LOCKED_DOCS_IDS = [
	COMMON_DOCUMENT_ID_CARD_ID,
	COMMON_DOCUMENT_EUROPEAN_ID_CARD_ID,
	COMMON_DOCUMENT_PASSPORT_ID,
	COMMON_DOCUMENT_RESIDENCE_PERMIT_ID,
];

export const DOCUMENT_CONFIRMED_STATUS = "confirmed";
export const DOCUMENT_UNCONFIRMED_STATUS = "unconfirmed";
export const DOCUMENT_PRECONFIRMED_STATUS = "preconfirmed";
