import React from "react";
import { Title } from "@zolteam/react-ras-library";
import Drawer from "../../components/molecules/Drawer/Drawer";
import DocumentsService from "../../services/DocumentsService";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import ProDocsDrawer, {
	IDocType,
} from "../../components/templates/ProDocsDrawer/ProDocsDrawer";
import DocTypeTile from "../../components/molecules/DocTypeTile/DocTypeTile";

const DOCS_TYPES: IDocType[] = [
	{
		id: 5,
		typeName: "diplomas",
		name: t("profilePro.diplomas.title"),
		addTitle: t("profilePro.diplomas.uploadBtn"),
		noDataMessage: t("profilePro.diplomas.empty"),
	},
	{
		id: 11,
		typeName: "certificates",
		name: t("profilePro.certificates.title"),
		addTitle: t("profilePro.certificates.uploadBtn"),
		noDataMessage: t("profilePro.certificates.empty"),
	},
	{
		id: 2,
		typeName: "licenses",
		name: t("profilePro.licenses.title"),
		addTitle: t("profilePro.licenses.uploadBtn"),
		noDataMessage: t("profilePro.licenses.empty"),
	},
	{
		id: 15,
		typeName: "habilitations",
		name: t("profilePro.habilitations.title"),
		addTitle: t("profilePro.habilitations.uploadBtn"),
		noDataMessage: t("profilePro.habilitations.empty"),
	},
];

const DEFAULT_TEXTS = {
	addTitle: "Ajouter un document",
	noDataMessage: "Aucun document",
};

const ProDocs: React.FC = () => {
	const [ActiveType, setActiveType] = React.useState<IDocType | null>(null);

	const fetchProofTypes = () =>
		DocumentsService.getProDocsProofTypes().then((resp) => {
			const types = resp.documentProofTypes;

			const merged = types.map((docType) => {
				const found = DOCS_TYPES.find((r) => r.id === docType.id);
				return {
					...DEFAULT_TEXTS,
					...found,
					...docType,
				};
			});

			return merged;
		});

	const { isLoading, data } = useQuery(
		["getDocsProofTypes"],
		fetchProofTypes
	);

	return (
		<div className="relative h-full">
			<Title tag="h1">Documents professionnels</Title>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div className="flex flex-wrap gap-xl mt-xl">
						{data?.map((docType) => (
							<DocTypeTile
								key={docType.id}
								{...docType}
								onClick={() => {
									setActiveType(docType);
								}}
							/>
						))}
					</div>
					<Drawer
						isOpen={!!ActiveType && !!ActiveType.id}
						position="absolute"
						navigation={false}
						onClose={() => {
							setActiveType(null);
						}}
					>
						{({ onClose }) => {
							if (!ActiveType)
								return <div>{t("global.error")}</div>;
							return (
								<ProDocsDrawer
									titleClassName="mb-m"
									onClose={onClose}
									type={ActiveType}
								/>
							);
						}}
					</Drawer>
				</>
			)}
		</div>
	);
};

export default ProDocs;
