import { t } from "i18next";
import InputSearch from "../../components/molecules/InputSearch/InputSearch";
import { Popover } from "@zolteam/react-ras-library";
import React from "react";
import LocationService from "../../services/LocationService";
import { ISector } from "../../views/Offers/Offers";
import { ISelectOption } from "../FiltersForm/OffersFiltersForm";
import { IFilters } from "../../contexts/FiltersContext";

interface ILocationInputFormProps {
	formikProps: any;
	values: IFilters;
	handleSendLocation?: (value: number) => void;
	resetForm?: () => void;
	send?: boolean;
}
const LocationInputForm: React.FC<ILocationInputFormProps> = ({
	formikProps,
	values,
	handleSendLocation,
	resetForm,
	send,
}) => {
	const [locationArray, setLocationArray] = React.useState<ISelectOption[]>(
		[]
	);
	const [inputValueCount, setInputValueCount] = React.useState("");
	const mapToSelectOptions = (data: ISector[]) => {
		let locationArrayOptions = data.map((item: ISector) => ({
			label: `${item.name}  (${item.postalCode})`,
			value: item.id,
		}));
		setLocationArray(locationArrayOptions);
		return locationArrayOptions;
	};
	const promiseLocationOptions = (inputValue: string) => {
		setInputValueCount(inputValue);
		return inputValue.length >= 3
			? LocationService.getSearchLocations(inputValue).then((res) =>
					mapToSelectOptions(res)
			  )
			: Promise.resolve([]);
	};
	return (
		<Popover
			placement="bottom"
			clickInside={true}
			content={
				<div className="h-[250px] overflow-auto">
					{locationArray.length > 0 && inputValueCount.length > 2 ? (
						locationArray.map((item) => (
							<span
								key={item.value}
								className="flex text-left cursor-pointer py-s px-m hover:bg-gray-100"
								onClick={() => {
									formikProps.setFieldValue("location", item);
								}}
							>
								{item.label}
							</span>
						))
					) : (
						<div className="text-center max-w-[300px] pt-[100px]">
							{t("offers.searchOffers.filters.noSearchOption")}
						</div>
					)}
				</div>
			}
		>
			<InputSearch
				label={t("offers.searchOffers.filters.locationsPlaceholder")}
				name="location"
				type="text"
				className="w-full"
				promiseOptions={promiseLocationOptions}
				value={values.location?.label}
				onClick={() => {
					handleSendLocation &&
						handleSendLocation(values.location?.value);
					resetForm && resetForm();
				}}
				resetValueProps={() =>
					formikProps.setFieldValue("location", {}, true)
				}
				send={send ?? false}
			/>
		</Popover>
	);
};

export default LocationInputForm;
