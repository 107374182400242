import * as Yup from "yup";
import LoginIdService from "../../services/LoginIdService";

export const InitializeFormInitValues = {
	id: LoginIdService.getId() ?? "",
};

export const InitializeFormValidation = (t) =>
	Yup.object().shape({
		id: Yup.string().required(t("initialize.error.idRequired")),
	});
