import React from "react";
import { Modal, Title, Button } from "@zolteam/react-ras-library";
import { ReactComponent as NetError } from "../../../assets/illustrations/noNetwork.svg";
import { Trans } from "react-i18next";
import { t } from "i18next";

const OfflineModal: React.FC = () => {
	const [isOffline, setIsOffline] = React.useState(!navigator.onLine);

	const onOffline = React.useCallback(() => {
		setIsOffline(true);
	}, []);

	const onReconnect = React.useCallback(() => {
		setIsOffline(false);
	}, []);

	React.useEffect(() => {
		window.addEventListener("offline", onOffline);
		window.addEventListener("online", onReconnect);

		return () => {
			window.removeEventListener("offline", onOffline);
			window.removeEventListener("online", onReconnect);
		};
	});

	return (
		<Modal
			className="max-w-[90vw] w-auto !min-w-[350px] [&>div:first-child]:hidden"
			size="s"
			isDisplayed={isOffline}
			onClose={() => {
				window.location.reload();
			}}
		>
			<NetError
				src={NetError}
				className="h-[120px] w-[200px]"
				viewBox="0 16 120 90"
			/>

			<Title tag="h2" className="mb-4">
				{t("offlineModal.title")}
			</Title>
			<p>
				<Trans i18nKey="offlineModal.text" />
			</p>
			<Button
				type="button"
				className="mt-8"
				color="primary"
				onClick={() => {
					window.location.reload();
				}}
			>
				{t("global.reload")}
			</Button>
		</Modal>
	);
};

export default OfflineModal;
