import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Title, InfoMessage, BackLink } from "@zolteam/react-ras-library";
// Templates
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";

// Forms
import { InitializeForm } from "../../forms/InitializeForm/InitializeForm";

// Services
import SecurityService from "../../services/SecurityService";
import { useFormikContext } from "formik";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

interface IInitialize {
	Credentials: {
		id: string;
	};
	resetForm: (
		nextState?: Partial<typeof useFormikContext> | undefined
	) => void;
}

const Initialize = () => {
	const handleBackNavigation = () => {
		navigate("/login");
	};

	const navigate = useNavigate();

	const handleInitialize = (
		{ id }: IInitialize["Credentials"],
		resetForm: IInitialize["resetForm"]
	) => {
		let uppercasedId = id.toUpperCase();
		pushDataLayer({
			dataLayer: {
				event: "initialisation__envoi_lien",
			},
		});
		SecurityService.sendResetPasswordLink(uppercasedId).then(() => {
			navigate("/initialize-done", { state: { id: uppercasedId } });
		});
	};

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h1" size="display02">
				{t("initialize.title")}
			</Title>
			<InfoMessage withIcon>{t("initialize.infoEnterId")}</InfoMessage>
			<InitializeForm handleSubmitForm={handleInitialize} />
		</AuthLayout>
	);
};

export default Initialize;
