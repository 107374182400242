import React from "react";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { Button } from "@zolteam/react-ras-library";
import { useNavigate } from "react-router-dom";

// Forms
import {
	RetrievePasswordFormInitValues,
	RetrievePasswordFormValidation,
} from "./validationForm";

// Components
import Field from "../../components/molecules/Field/Field";
import LoginIdService from "../../services/LoginIdService";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

interface IRetrievePasswordFormProps {
	load: boolean;
	handleSubmitForm: Function;
}

export const RetrievePasswordInput: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleRetrieveLogin = () => {
		pushDataLayer({
			dataLayer: {
				event: "connexion__identifiant_oublie",
				pageUrl: window.location.href,
				pageTitle: t("retrievePassword.title").toString(),
			},
		});
		navigate("/login-retrieve");
	};

	return (
		<>
			<Field
				type="text"
				name="retrieveId"
				label={t("auth.login.id")}
				className={"mb-s uppercase"}
				readOnly={false}
			/>
			<Button
				color="transparentPrimary"
				size="s"
				type="button"
				className="block ml-auto"
				onClick={handleRetrieveLogin}
			>
				{t("auth.login.idForgot")}
			</Button>
		</>
	);
};

export const RetrievePasswordForm: React.FC<IRetrievePasswordFormProps> = ({
	load,
	handleSubmitForm,
}: IRetrievePasswordFormProps) => {
	const { t } = useTranslation();

	const RetrievePasswordValueSchema = {
		...RetrievePasswordFormInitValues,
	};

	let previousId = LoginIdService.getId();
	if (previousId?.length) RetrievePasswordValueSchema.retrieveId = previousId;

	return (
		<Formik
			initialValues={RetrievePasswordValueSchema}
			validationSchema={RetrievePasswordFormValidation(t)}
			onSubmit={(values, { resetForm }) => {
				handleSubmitForm(values, resetForm);
			}}
		>
			{({ isValid, dirty }) => (
				<div className={"pt-m px-0 pb-s"}>
					<Form id="form-password-recovery">
						<RetrievePasswordInput />
						<Button
							type="submit"
							isLoading={load}
							color={isValid ? "primary" : "grey"}
							disabled={!isValid}
							size="m"
							className="w-full mt-xl"
							data-testid="button-password-recovery"
						>
							{t("retrievePassword.buttonLabel")}
						</Button>
					</Form>
				</div>
			)}
		</Formik>
	);
};
