import api from "./api";

class ContractsServiceClass {
	getContracts(filters) {
		return api
			.post("/api-360/contract", {
				sorting: "startDate",
				orderBy: "DESC",
				page: 1,
				limit: 50,
				...filters,
			})
			.then((response) => {
				return response.data;
			});
	}

	getContract(contractId: string) {
		return api
			.get(`/api-360/contract/${contractId}/download`, {
				responseType: "blob",
			})
			.then((response) => {
				return response.data;
			});
	}

	signContract(contractId: number, confirmationCode: string) {
		const userAgent =
			navigator.userAgent ??
			navigator.vendor ??
			window.opera ??
			"Unknown User Agent";

		const platform =
			navigator.userAgentfData?.platform ?? navigator.platform ?? "web";

		return api
			.post(`/api-360/contract/sign`, {
				contractId,
				confirmationCode,
				deviceName: userAgent,
				deviceType: platform,
			})
			.then((response) => {
				return response.data;
			});
	}
}

const ContractsService = new ContractsServiceClass();
export default ContractsService;
