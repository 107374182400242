import React from "react";
import { Button, Picto } from "@zolteam/react-ras-library";

import moment from "moment";

export interface IPayslip {
	id: string;
	date: number;
	name?: string;
	path?: string;
	agencyName: string;
	isRead?: boolean;
}

const Payslip: React.FC<IPayslip> = (props) => {
	const { name, date, agencyName, path, isRead } = props;

	return (
		<>
			<div className="flex flex-col sm:flex-row gap-0 sm:gap-m">
				<div className="min-w-[20vw]">
					<span className="flex items-center gap-s">
						{!isRead && (
							<Picto icon="circle" className="text-accent-500" />
						)}
						<p className="font-bold capitalize">
							{
								name ??
									moment(date * 1000).format(
										"MMMM YYYY"
									) /* date*1000 is needed because API returns seconds instead of milliseconds */
							}
						</p>
					</span>
				</div>
				<div className="">{agencyName}</div>
			</div>
			<div className="text-end">
				<Button
					type="button"
					color={"transparent"}
					className="ml-auto"
					disabled={!path}
				>
					<Picto
						icon={"eye"}
						className="[&>g]:!text-primary-500 !mr-0"
					/>
				</Button>
			</div>
		</>
	);
};

export default Payslip;
