import React from "react";
import { t } from "i18next";
import ContractsIcon from "../../assets/illustrations/contract.svg";
import ContractsService from "../../services/ContractsService";
import Contract from "../../components/molecules/Contract/Contract";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { promiseToast } from "../../toastify/toastify";
import ListLayout from "../../components/templates/ListLayout/ListLayout";
import PDFViewer from "../../components/molecules/PDFViewer/PDFViewer";
import { toast } from "react-toastify";
import { IDocsFilters } from "../../forms/FiltersForm/DocsFiltersForm";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const Contracts: React.FC = () => {
	const { id: paramId } = useParams();
	const { state } = useLocation();

	const fetchContracts = (filters: IDocsFilters) =>
		ContractsService.getContracts(filters);

	const fetchContract = (doc: IDocsFilters) =>
		ContractsService.getContract(doc.id);

	const handleSign = (contractId: number, code: string) => {
		if (!contractId)
			return Promise.reject(t("errors.noContractId").toString());
		if (!code) return Promise.reject(t("errors.noCode").toString());
		const prom = ContractsService.signContract(contractId, code).catch(
			(err) => {
				let error = t("PDFViewer.errors.default", {
					code: "NO_ERROR_CODE",
				});
				let msg = err?.response?.data?.message;
				if (msg === "ERR_UE_CON_0063_ALREADY_SIGNED") {
					toast.dismiss("signing");
					toast(
						t("PDFViewer.errors.ERR_UE_CON_0063_ALREADY_SIGNED"),
						{
							type: "error",
						}
					);
					return Promise.resolve();
				}
				if (msg) {
					error = t("PDFViewer.errors." + msg);
					if (error === "PDFViewer.errors." + msg) {
						// if no translation found
						error = t("PDFViewer.errors.default", { code: msg });
					}
				}
				return Promise.reject(error);
			}
		);
		promiseToast(
			prom,
			{},
			{
				toastId: "signing",
			}
		);
		return prom;
	};

	const formatDownloadTitle = (doc: IDocsFilters) => {
		return `${t("contracts.fileName")}_${doc.jobTitle}_${moment(
			doc.startDate
		).format("DD_MMMM_YYYY")}_${doc.id}`;
	};

	return (
		<ListLayout
			title={t("contracts.title").toString()}
			query={{
				key: ["contracts"],
				fn: fetchContracts,
			}}
			backgroundImage={ContractsIcon}
			noDataMessage={t("contracts.noContracts")}
			filters={false}
			activeItemId={paramId ?? null}
			baseUrl="/contracts"
			viewer={{
				render: ({ item, closeViewer }) => {
					return (
						<PDFViewer
							query={
								item
									? {
											key: ["PDF Fetch", item.id],
											fn: () => fetchContract(item),
									  }
									: undefined
							}
							onBack={closeViewer}
							download={true}
							downloadTitle={
								item ? formatDownloadTitle(item) : undefined
							}
							onDownload={() => {
								pushDataLayer({
									dataLayer: {
										event: "contrat__telecharger",
									},
								});
							}}
							file={item?.file ?? false}
							isSigned={item?.isSignedByWorker ?? true}
							sign={{
								onSubmit: (code) => {
									return handleSign(item.id, code);
								},
							}}
						/>
					);
				},
			}}
			onItemClick={(item) => {
				if (item.isSignedByWorker || state?.fromNotification) return;
				pushDataLayer({
					dataLayer: {
						event: "contrat__signer",
						parcours: `${state?.pathname ?? "Contrats"}`,
					},
				});
			}}
		>
			{(item) => <Contract {...item} />}
		</ListLayout>
	);
};

export default Contracts;
