import React, { useRef, useState } from "react";
import ListLayout from "../../components/templates/ListLayout/ListLayout";
import { t } from "i18next";
import Calendar from "../../assets/illustrations/calendar.svg";
import Absence from "../../components/molecules/Absence/Absence";
import AbsencesService, { ISendAbsence } from "../../services/AbsencesService";
import { ModalV2, InfoMessage, Button } from "@zolteam/react-ras-library";
import { IAbsence } from "../../components/molecules/Absence/Absence";
import { AbsencesForm } from "../../forms/AbsencesForm/AbsencesForm";
import moment from "moment";
import formatDate from "../../utils/formatDate";
import { useQueryClient } from "@tanstack/react-query";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const Absences: React.FC = () => {
	const queryClient = useQueryClient();
	const isInitied = useRef(false);
	const [CurrentItem, setCurrentItem] = useState<IAbsence | undefined>();

	const addAbsence = () => {
		pushDataLayer({
			dataLayer: {
				event: "absence__ajout",
			},
		});
		setCurrentItem({
			id: 0,
			startDate: "",
			finishDate: "",
			startTime: "",
			finishTime: "",
			status: "",
			reasonName: "",
			files: [],
		});
	};

	const fetchAbsences = () =>
		AbsencesService.getAbsences().then(({ timeOffRequests }) => {
			const list = timeOffRequests
				.sort((a, b) => {
					return (
						new Date(b.startDate).getTime() -
						new Date(a.startDate).getTime()
					);
				})
				.map((absence) => ({
					...absence,
					startTime: absence.startTime
						? moment(absence.startTime).utcOffset("+00:00")
						: null,
					finishTime: absence.finishTime
						? moment(absence.finishTime).utcOffset("+00:00")
						: null,
				}));
			if (!isInitied.current) {
				pushDataLayer({
					dataLayer: {
						event: "absence",
						nombre_absences: list.length,
					},
				});
				isInitied.current = true;
			}

			return {
				total: list.length,
				items: list,
			};
		});

	const handleSubmit = (values, formik) => {
		pushDataLayer({
			dataLayer: {
				event: "absence__demande_envoyee",
			},
		});

		let sendValues: ISendAbsence = {
			startDate: formatDate(values.formattedStartDate),
			finishDate: formatDate(values.formattedFinishDate),
			startTime: values.startTime,
			finishTime: values.finishTime,
			allDay: values.isAllDay,
			attachments: values.files.map((file) => file.raw) ?? [],
			commonTimeOffReasonId: values.commonTimeOffReasonId,
		};

		if (!values.isAllDay) {
			sendValues.startTime =
				sendValues.startDate + " " + values.startTime;
			sendValues.finishTime =
				sendValues.finishDate + " " + values.finishTime;
		}

		let request = AbsencesService.postAbsence;
		if (values.id) {
			request = AbsencesService.updateAbsence;
			sendValues.id = values.id;
		}

		let promise = request(sendValues)
			.then((res) => {
				setCurrentItem(undefined);
				queryClient.invalidateQueries(["absences"]);
			})
			.catch((err) => {
				let message = err.response.data.message;
				message = message.includes("OVERLAP_TIME_OFF_REQUEST")
					? t("absences.form.errorDatesAbsence")
					: message.includes("OVERLAP_CONTRACT")
					? t("absences.form.errorDatesContract")
					: message;
				formik.setFieldError("dateError", message);
			});
		return promise;
	};

	return (
		<>
			<ListLayout
				title={t("absences.title").toString()}
				backgroundImage={Calendar}
				noDataMessage={t("absences.noAbsences")}
				query={{
					fn: fetchAbsences,
					key: ["absences"],
					opts: {
						refetchOnWindowFocus: false,
					},
				}}
				filters={false}
				onItemClick={(item) => {
					setCurrentItem({
						...item,
						isAllDay:
							item.startTime && item.finishTime ? false : true,
						formattedStartDate: moment(item.startDate).format(
							"DD/MM/YYYY"
						),
						formattedFinishDate: moment(item.finishDate).format(
							"DD/MM/YYYY"
						),
						startTime: item.startTime
							? moment(item.startTime).format("HH:mm")
							: undefined,
						finishTime: item.finishTime
							? moment(item.finishTime).format("HH:mm")
							: undefined,
					});
				}}
				header={() => {
					return (
						<Button
							color="primary"
							size="m"
							type="button"
							className="self-end w-full md:w-auto !p-1 !px-3 sticky top-0"
							onClick={addAbsence}
						>
							{t("absences.addAbsence")}
						</Button>
					);
				}}
				navigation={() => (
					<div className="flex w-full [&>div]:mt-0 justify-end">
						<InfoMessage color="primary" withIcon>
							{t("absences.infoMessage")}
						</InfoMessage>
					</div>
				)}
			>
				{(item) => <Absence absence={item} />}
			</ListLayout>
			<ModalV2
				isDisplayed={!!CurrentItem}
				onClose={() => setCurrentItem(undefined)}
				size="auto"
				className="!min-w-[90%] sm:!min-w-[70%] md:!min-w-[35rem] duration-500"
				title={
					CurrentItem?.id
						? CurrentItem?.status === "approved"
							? t("absences.form.titleView")
							: t("absences.form.titleEdit")
						: t("absences.form.titleAdd")
				}
			>
				<div className="flex flex-col w-full h-full text-left">
					<AbsencesForm
						isOpen={!!CurrentItem}
						onClose={() => setCurrentItem(undefined)}
						absence={CurrentItem ?? ({} as IAbsence)}
						isApproved={CurrentItem?.status === "approved"}
						onSubmit={handleSubmit}
					/>
				</div>
			</ModalV2>
		</>
	);
};

export default Absences;
