import React from "react";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import SignUpForm from "../../forms/SignUpForm/SignUpForm";
import { Title, Text, Button } from "@zolteam/react-ras-library";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const SignUp: React.FC = () => {
	const navigate = useNavigate();

	return (
		<AuthLayout>
			<Title tag="h1" size="display02">
				{t("register.title")}
			</Title>
			<Text tag="span" className="flex mt-s mb-xl flex-wrap gap-x-1">
				<span>{t("register.text")}</span>
				<Button
					color="transparentPrimary"
					type="button"
					onClick={() => {
						pushDataLayer({
							dataLayer: {
								event: "inscription__se_connecter",
							},
						});
						navigate("/login");
					}}
				>
					{t("register.link")}
				</Button>
			</Text>
			<SignUpForm />
		</AuthLayout>
	);
};

export default SignUp;
