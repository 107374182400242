import React, { useEffect, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import WeekDaysSelector from "../../components/atoms/WeekDaysSelector/WeekDaysSelector";
import TimeSlots from "../../components/molecules/TimeSlots/TimeSlots";
import {
	DEFAULT_AVAILABILITIES_API_BODY,
	TIMES_OF_DAY,
} from "./AvailabilitiesFormConst";

interface IAvailabilitiesFormProps {
	handleSubmit?: (values: IDaysSlots) => void;
	formRef?: React.Ref<FormikProps<any>> | undefined;
	onChange: (values: IDaysSlots) => void;
	availabilities?: IDaysSlots;
}

interface ITimeSlotData {
	morning: boolean;
	afternoon: boolean;
	night: boolean;
}

export interface IDaysSlots {
	monday: ITimeSlotData;
	tuesday: ITimeSlotData;
	wednesday: ITimeSlotData;
	thursday: ITimeSlotData;
	friday: ITimeSlotData;
	saturday: ITimeSlotData;
	sunday: ITimeSlotData;
}

const AvailabilitiesForm: React.FC<IAvailabilitiesFormProps> = ({
	handleSubmit,
	onChange,
	formRef,
	availabilities = {},
}) => {
	const [selectedTimesOfDay, setSelectedTimesOfDay] = useState<IDaysSlots>({
		...DEFAULT_AVAILABILITIES_API_BODY,
		...availabilities,
	});

	useEffect(() => {
		setSelectedTimesOfDay({
			...DEFAULT_AVAILABILITIES_API_BODY,
			...availabilities,
		});
	}, [availabilities]);

	const handleDayClick = (
		daySelected: string,
		timeSlotSelected: string,
		state: boolean
	) => {
		let newState = {
			...selectedTimesOfDay,
			[daySelected]: {
				...selectedTimesOfDay[daySelected],
				[timeSlotSelected]: state,
			},
		};
		onChange(newState);
		setSelectedTimesOfDay(newState);
	};

	const isTimeSlotSelected = (day: string, timeSlot: string) => {
		return selectedTimesOfDay[day][timeSlot];
	};

	return (
		<Formik
			initialValues={DEFAULT_AVAILABILITIES_API_BODY}
			onSubmit={
				handleSubmit ? () => handleSubmit(selectedTimesOfDay) : () => {}
			}
			validateOnChange={true}
			innerRef={formRef}
		>
			{() => {
				return (
					<Form className="flex flex-col gap-xl">
						{TIMES_OF_DAY.map((time, index) => {
							return (
								<TimeSlots
									key={index}
									label={time.label}
									subLabel={time.subLabel}
									picto={time.picto}
								>
									<WeekDaysSelector
										onDayClick={(
											dayClick: string,
											state: boolean
										) => {
											handleDayClick(
												dayClick,
												time.value,
												state
											);
										}}
										isSelected={(day: string) =>
											isTimeSlotSelected(day, time.value)
										}
									/>
								</TimeSlots>
							);
						})}
					</Form>
				);
			}}
		</Formik>
	);
};

export default AvailabilitiesForm;
