import React from "react";
import { Button } from "@zolteam/react-ras-library";
import { Form, Formik } from "formik";
import Field from "../../components/molecules/Field/Field";
import cn from "../../utils/cn";
import { t } from "i18next";
import ProfileService from "../../services/ProfileService";
import { promiseToast } from "../../toastify/toastify";
import { IPersonalInformationsCategory } from "./PersonalInformations";
import { SSN_REGEX } from "../../utils/regex";
import * as Yup from "yup";

const isValidSsn = (value) => {
	if (value) {
		const normalKey = 97 - (parseInt(value.substr(0, 13), 10) % 97);
		return (
			SSN_REGEX?.test(value) &&
			normalKey === parseInt(value.substr(value?.length - 2, 2), 10)
		);
	}
	return false;
};

const validationSchema = Yup.object().shape({
	ssn: Yup.string()
		.test(
			"test-ssn",
			t("personalInfos.errors.socialSecurityNumber"),
			isValidSsn
		)
		.required(t("personalInfos.errors.required")),
});

const SocialSecurityInformations: React.FC<IPersonalInformationsCategory> = ({
	infos,
	disabled,
	refetchInfos,
}) => {
	const handleSubmit = (values: any) => {
		const prom = ProfileService.putProfileSocialSecurityNumber({
			ssn: values.ssn,
		});

		promiseToast(prom);

		return prom;
	};

	return (
		<div className={cn([disabled && "cursor-not-allowed"])}>
			<Formik
				initialValues={{
					...infos,
				}}
				onSubmit={(values, { resetForm }) =>
					handleSubmit(values).then(() => {
						resetForm();
						refetchInfos();
					})
				}
				validationSchema={validationSchema}
			>
				{({ dirty, isSubmitting, isValid, values, setFieldValue }) => {
					const isDisabled = disabled || isSubmitting;
					return (
						<Form className="relative flex flex-col items-start justify-start gap-4">
							<div
								className={cn([
									"flex flex-col gap-2 sm:w-auto w-full",
									isDisabled &&
										"pointer-events-none opacity-70",
								])}
							>
								<b>
									{t(
										"personalInfos.socialSecurityNumber.title"
									)}
								</b>
								<div className="flex flex-col gap-2">
									<Field
										label={t(
											"personalInfos.socialSecurityNumber.label"
										)}
										name="ssn"
										type="text"
										maxLength={15}
										value={values.ssn}
										onChange={(e) =>
											setFieldValue(
												"ssn",
												e.target.value.trim()
											)
										}
									/>
								</div>
							</div>
							{dirty && (
								<div className="w-full text-center">
									<Button
										type="submit"
										color={
											isValid && dirty
												? "primary"
												: "grey"
										}
										disabled={!dirty || !isValid}
										className="w-[250px] max-w-full"
										isLoading={isSubmitting}
									>
										{t("global.save")}
									</Button>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default SocialSecurityInformations;
