import React, { useEffect, useState } from "react";

// Components
import { Picto } from "@zolteam/react-ras-library";
import SendingIcon from "../../../assets/icons/sending.svg";

// Utils & misc
import cn from "../../../utils/cn";

// Style
import style from "./InputSearch.module.scss";
import SVG from "../../atoms/SVG/SVG";

interface IInputSearchProps {
	value?: string;
	type: string;
	name: string;
	label: string;
	className: string;
	onClick?: (value: string, e: any) => void;
	disabled?: boolean;
	promiseOptions: (inputValue: string) => void;
	resetValueProps?: (name: string, e?: any) => void;
	send?: boolean;
}

class IInputSearchProps {}

const InputSearch: React.FC<IInputSearchProps> = (props: IInputSearchProps) => {
	const {
		type,
		name,
		label,
		onClick = () => {},
		className,
		disabled = false,
		promiseOptions,
		value = "",
		resetValueProps,
		send = false,
	} = props;
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value || "");
	}, [value]);

	return (
		<div className={cn([className])}>
			<div
				className={cn([
					style.field,
					inputValue.length <= 0 ? style.empty : null,
					disabled && style.disabled,
				])}
			>
				<input
					id={name}
					data-testid={name}
					type={type}
					name={name}
					placeholder={label}
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
						promiseOptions(e.target.value);
					}}
					onKeyPress={(event) =>
						event.key === "Enter" && onClick(inputValue, event)
					}
					autoComplete={"off"}
				/>

				<button
					type="button"
					className={cn([
						style.buttonPicto,
						"duration-200",
						inputValue?.length > 0
							? "opacity-100"
							: "opacity-0 pointer-events-none",
					])}
					onClick={(e) => {
						setInputValue("");
						promiseOptions("");
						resetValueProps && resetValueProps(name, e);
					}}
				>
					<Picto icon="close" className="text-neutral-500" />
				</button>

				<button
					type="button"
					className={style.buttonPicto}
					onClick={(e) => onClick(e, inputValue)}
				>
					{!value || !send ? (
						<Picto icon="search" style={{ height: "18px" }} />
					) : (
						send && (
							<SVG
								src={SendingIcon}
								className={cn(["!fill-primary-500"])}
							/>
						)
					)}
				</button>
			</div>
		</div>
	);
};

export default InputSearch;
