import { Formik, Form } from "formik";
import React from "react";
import {
	SignUpFormInitValues,
	SignUpFormValidation,
} from "./SignUpFormValidation";
import { t } from "i18next";
import Field from "../../components/molecules/Field/Field";
import { Button } from "@zolteam/react-ras-library";
import { useLocation, useNavigate } from "react-router-dom";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const SignUpForm: React.FC = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	return (
		<div>
			<Formik
				initialValues={{ ...SignUpFormInitValues, ...state }}
				validationSchema={SignUpFormValidation(t)}
				validateOnMount={true}
				onSubmit={(values) => {
					pushDataLayer({
						dataLayer: {
							event: "inscription__creer_compte",
							telephone: values.phoneNumber ? "yes" : "no",
						},
					});
					navigate("/signup-consent", { state: values });
				}}
			>
				{({ handleSubmit, isValid, dirty }) => {
					const canSubmit = isValid && (state || dirty);

					return (
						<Form className="flex flex-col gap-5">
							<Field
								name="firstName"
								type="text"
								label="Prénom"
							/>
							<Field name="lastName" type="text" label="Nom" />
							<Field name="email" type="email" label="Email" />
							<Button
								color={canSubmit ? "primary" : "grey"}
								disabled={!canSubmit}
								type="submit"
								className="mt-l !ml-0"
								onClick={handleSubmit}
							>
								{t("register.title")}
							</Button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default SignUpForm;
