import React, { useEffect, useRef } from "react";
import DocsLayout from "../../components/templates/DocsLayout/DocsLayout";
import CVService from "../../services/CVService";
import { IStepComponentProps } from "../../components/templates/InitLayout/InitLayout";
import { t } from "i18next";
import { pushDataLayer } from "../../GoogleTagManager/gtm";

const OnboardingCV: React.FC<IStepComponentProps> = ({
	isActive,
	validateStep,
	unvalidateStep,
	setCustomNextCallback,
	customNextCallback,
	submitStep,
}) => {
	const handleUpload = (files: any) => CVService.uploadCV(files);
	const handleDelete = (doc: any) => CVService.deleteCV(doc.uuid);
	const fetchCV = () => CVService.getCV().then((res) => res.cvs);
	const CVLength = useRef(0);

	useEffect(() => {
		if (isActive && !customNextCallback)
			setCustomNextCallback &&
				setCustomNextCallback(() => {
					pushDataLayer({
						dataLayer: {
							event: "inscription__ajouter_cv",
							nombre_cv_dispo: CVLength.current,
						},
					});
					submitStep();
				});
	});
	if (!isActive) return null;

	return (
		<DocsLayout
			title={t("onboarding.cv.title")}
			addTitle={t("onboarding.cv.addBtnTitle")}
			maxFiles={3}
			query={{
				key: ["onboarding", "cvs"],
				fn: fetchCV,
				opts: {
					onSuccess: (data) => {
						CVLength.current = data?.length;
						if (data?.length) {
							validateStep && validateStep({ CV: true });
						} else unvalidateStep && unvalidateStep();
						return data;
					},
				},
			}}
			onUpload={handleUpload}
			onRemove={handleDelete}
		/>
	);
};

export default OnboardingCV;
