import React from "react";
import { Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import BankInfosForm from "../../forms/BankInfosForm/BankInfosForm";

const BankInfos: React.FC = () => {
	return (
		<div className="flex flex-col h-full">
			<Title tag="h2" className="mb-3">
				{t("bankInfos.title")}
			</Title>
			<BankInfosForm />
		</div>
	);
};

export default BankInfos;
