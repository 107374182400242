import React from "react";
import { BackLink, InfoMessage, Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import { AgenciesForm } from "../../forms/AgenciesForm/AgenciesForm";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthContactAgencies = () => {
	const navigate = useNavigate();

	const handleBackNavigation = () => {
		navigate("/login");
	};
	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<ContactAgencies />
		</AuthLayout>
	);
};

const ContactAgencies: React.FC = (props) => {
	const { state } = useLocation();
	const { infos, from } = state || {};

	return (
		<>
			<Title tag="h2" lineHeight="m" size="heading01" className="pt-m">
				{t("agencies.title")}
			</Title>
			<div className="pt-m pb-xl">
				<InfoMessage color="primary" withIcon>
					{infos ?? t("agencies.infos")}
				</InfoMessage>
			</div>
			<AgenciesForm type="agency" from={from} />
		</>
	);
};

export default ContactAgencies;
