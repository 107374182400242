import { t } from "i18next";
import { toast } from "react-toastify";

export const promiseToast = (promise, messages?, options?: any) => {
	return toast.promise(
		promise,
		{
			pending: t("promiseToast.pending"),
			success: t("promiseToast.success"),
			error: t("promiseToast.error"),
			...messages,
		},
		options
	);
};
