import React, { ReactNode, useCallback, useState } from "react";
import { DefaultTFuncReturn, t } from "i18next";
import {
	FiltersContext,
	FiltersProvider,
	IFilters,
} from "../../../contexts/FiltersContext";
import { IListItem } from "../../atoms/ListItem/ListItem";
import PageHead from "../../molecules/PageHead/PageHead";
import FilterButton from "../../molecules/FiltersButton/FiltersButton";
import List, { AsyncList } from "../../molecules/List/List";
import Drawer from "../../molecules/Drawer/Drawer";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import Spinner from "../../atoms/Spinner/Spinner";

interface IViewerRenderProps {
	item: any;
	closeViewer: () => void;
}
interface IViewerProps {
	render: (props: IViewerRenderProps) => ReactNode;
	nav?: React.FC<any>;
	position?: "fixed" | "absolute";
}

interface IFiltersProps {
	values: IFilters;
	count: (filters: IFilters) => number;
	modalForm: React.FC<any>;
	gtmEvent?: string;
	onApply?: (filters: IFilters) => void;
	onClose?: () => void;
	height?: string;
}

interface IListLayoutProps {
	title?: string; // title of the page
	// query to fetch items
	query?: {
		key: string[] | undefined; // key to use for the query
		fn?: (filters: any) => Promise<any>; // function to fetch items
		opts?: any; // options to pass to the query
	};
	backgroundImage?: any; // background image to display when no data is available
	items?: IListItem[]; // list of items to display
	onItemClick?: (data) => void;
	activeItemId?: string | number | null; // id of the item to display by default
	filters?: IFiltersProps | false | undefined; // filters to pass to the filters context
	header?: React.FC<any>; // component to render in the header
	navigation?: React.FC<any>; // component to render in the navigation
	viewer?: IViewerProps;
	children?: React.FC<any>; // component to render for each item
	noDataMessage?: string | DefaultTFuncReturn; // message to display when no data is available
	baseUrl?: string; // base url to use when closing the viewer
	showResultsCount?: boolean;
	scrollParentId?: string;
	pagination?: IPagination | false;
	isLoading?: boolean;
}

export interface IPagination {
	page: number;
	limit: number;
}

const ListLayout: React.FC<IListLayoutProps> = ({
	title,
	query,
	items,
	activeItemId,
	onItemClick,
	isLoading,
	...props
}) => {
	const [Pagination, setPagination] = useState({
		page: 1,
		limit: 20,
	});
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const [CurrentItem, setCurrentItem] = React.useState<any | null>(null);

	const handleItemClick = useCallback(
		(item) => {
			setCurrentItem(item);
			onItemClick && onItemClick(item);
		},
		[onItemClick]
	);

	const genListCont = () => {
		let commonsProps = {
			backgroundImage: props.backgroundImage,
			noDataMessage: props.noDataMessage,
			children: props.children,
			showResultsCount: props.showResultsCount,
			activeItemId: activeItemId ?? null,
			onItemClick: handleItemClick,
			pagination: props.pagination ?? Pagination,
			setPagination,
			scrollParentId: props.scrollParentId,
		};

		if (!query && isLoading) return <Spinner />;

		if (query)
			return (
				<AsyncList
					{...commonsProps}
					queryKey={[
						...(query.key ? query.key : []),
						Pagination.page.toString(),
					]}
					queryFn={query.fn}
					queryOpts={query.opts}
				/>
			);
		return (
			<List {...commonsProps} items={items ?? []} total={items?.length} />
		);
	};

	const onApplyFilter = (filters: IFilters) => {
		setPagination({ page: 1, limit: 20 });
		if (props.filters?.onApply) props.filters.onApply(filters);
	};

	const filters: IFiltersProps | any =
		props.filters !== false ? props.filters : {};

	return (
		<FiltersProvider
			filters={filters?.values}
			countFilters={filters?.count}
		>
			<div className="relative flex flex-col h-full overflow-hidden">
				<PageHead title={title ?? t("docs.title")}>
					<FiltersContext.Consumer>
						{({ isButtonVisible }) => {
							if (!isButtonVisible || props.filters === false)
								return false;
							return (
								<FilterButton
									gtmEvent={filters?.gtmEvent}
									queryKey={query?.key}
									modalForm={filters?.modalForm}
									onApply={onApplyFilter}
									onClose={filters?.onClose}
									height={filters?.height}
								/>
							);
						}}
					</FiltersContext.Consumer>
					{props.header && props.header(CurrentItem)}
				</PageHead>
				{props.navigation && (
					<div className="my-l">{props.navigation(CurrentItem)}</div>
				)}
				{genListCont()}
				{props.viewer !== undefined && (
					<Drawer
						isOpen={CurrentItem !== null}
						navigation={props.viewer?.nav ?? false}
						onClose={() => {
							setCurrentItem(null);
							if (props.baseUrl) navigate(props.baseUrl);
							if (query?.key)
								queryClient.refetchQueries(query.key);
						}}
						position={props.viewer?.position ?? "fixed"}
					>
						{({ data, onClose }) => {
							if (!props.viewer?.render) return <></>;
							let renderer: any = props.viewer.render;
							return renderer({
								item: CurrentItem,
								data,
								closeViewer: onClose,
							});
						}}
					</Drawer>
				)}
			</div>
		</FiltersProvider>
	);
};

export default ListLayout;
