import React from "react";
import "./Footer.scss";
import { ReactComponent as Legal } from "../../../assets/icons/legal.svg";
import { ReactComponent as CGU } from "../../../assets/icons/cgu.svg";
import { ReactComponent as Help } from "../../../assets/icons/help.svg";

import { ReactComponent as RASLogo } from "../../../assets/icons/RASLogo.svg";
import { ReactComponent as Instagram } from "../../../assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/twitter.svg";
import { ReactComponent as Youtube } from "../../../assets/icons/youtube.svg";
import { ReactComponent as LinkedIn } from "../../../assets/icons/linkedIn.svg";
import { useNavigate } from "react-router-dom";
import { URL_REGEX } from "../../../utils/regex";
import { DefaultTFuncReturn, t } from "i18next";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";

const Footer: React.FC = () => {
	const datalayerPageTitle = "Page d'accueil";
	return (
		<div
			className={`Footer
				flex w-full justify-between items-center flex-wrap gap-m
				p-m mt-auto
				bg-primary-500 text-white duration-300
`}
		>
			<div className="w-full mr-auto xl:w-auto 2xl:w-full 3xl:w-auto">
				<FooterLink
					onClick={() => {
						pushDataLayer({
							dataLayer: {
								event: "demande_aide",
								pageUrl: window.location.href,
								pageTitle: datalayerPageTitle,
							},
						});
					}}
					href="/help"
					icon={<Help />}
					text={t("footer.help")}
				/>
			</div>
			<hr className="block w-full border-white opacity-50 lg:block xl:hidden" />
			<div className="flex flex-wrap legalLinks gap-xl gap-y-3">
				<FooterLink
					href="https://myras.fr/politique-de-confidentialite/"
					icon={<Legal />}
					text={t("footer.legalConfidential")}
				/>
				<FooterLink
					href="https://myras.fr/conditions-generales/"
					icon={<CGU />}
					text={t("footer.cgu")}
				/>
			</div>
			<hr className="block w-full border-white opacity-50 2xl:hidden" />
			<div className="flex flex-wrap justify-end ml-auto socials gap-m">
				<FooterLink
					href="https://www.ras-interim.fr"
					icon={<RASLogo />}
					text="ras-interim.fr"
					textBefore
					title={t("footer.ras")}
				/>
				<FooterLink
					href="https://twitter.com/ras_interim"
					icon={<Twitter />}
					title={t("footer.twitter")}
				/>
				<FooterLink
					href="https://fr.linkedin.com/company/ras-interim-france"
					icon={<LinkedIn />}
					title={t("footer.linkedin")}
				/>
				<FooterLink
					href="https://www.instagram.com/rasinterimfrance/"
					icon={<Instagram />}
					title={t("footer.instagram")}
				/>
				<FooterLink
					href="https://fr-fr.facebook.com/RasInterim/"
					icon={<Facebook />}
					title={t("footer.facebook")}
				/>
				<FooterLink
					href="https://www.youtube.com/channel/UCi45_g6i0UVDuVL5WH0VmqA"
					icon={<Youtube />}
					title={t("footer.youtube")}
				/>
			</div>
		</div>
	);
};

interface IFooterLinkProps {
	onClick?: () => void;
	href: string;
	icon: React.ReactNode;
	title?: string | DefaultTFuncReturn | undefined;
	text?: string | DefaultTFuncReturn | undefined;
	textBefore?: boolean;
}

const FooterLink: React.FC<IFooterLinkProps> = ({
	onClick = () => {},
	href,
	icon,
	title,
	text,
	textBefore,
}) => {
	const navigate = useNavigate();

	// regex to check if href is a valid url
	const isUrl = !href.startsWith("/") && href.match(URL_REGEX);

	const handleClick = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		e.preventDefault();
		onClick && onClick();
		if (isUrl) return window.open(href, "_blank");
		navigate(href);
	};
	return (
		<a
			onClick={handleClick}
			target={isUrl ? "_blank" : undefined}
			rel={isUrl ? "noopener noreferrer" : undefined}
			href={href}
			className={"footerLink flex gap-s items-center"}
			title={(title ?? text)?.toString()}
		>
			{textBefore && text && <span>{text}</span>}
			{icon}
			{!textBefore && text && <span>{text}</span>}
		</a>
	);
};

export default Footer;
