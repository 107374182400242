import api from "./api";

class SecurityServiceClass {
	resetPassword(token: string, password: string) {
		if (!token?.length) return Promise.reject("Token is required");
		if (!password?.length) return Promise.reject("No password provided");
		return api.post("/api/security/reset-password", {
			token,
			password,
		});
	}

	checkPasswordResetToken(token: string) {
		if (!token?.length) return Promise.reject("Token is required");
		return api.post("/api/security/check-password-reset-token", {
			token,
		});
	}

	sendResetPasswordLink(id: string) {
		if (!id?.length) return Promise.reject("Id is required");
		return api.post("/api/security/forgot-password", {
			uniqueId: id,
		});
	}

	resendResetPasswordLink(id: string) {
		if (!id?.length) return Promise.reject("Id is required");
		return api.post("/api/security/reset-password-second-time", {
			uniqueId: id,
		});
	}
}

const SecurityService = new SecurityServiceClass();

export default SecurityService;
