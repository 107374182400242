import * as Yup from "yup";
export const HelpFormInitValues = {
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	agency: { label: "", value: "" },
	topic: { label: "", value: "" },
	problem: "",
};

export const HelpFormValidation = (t) =>
	Yup.object().shape({
		firstName: Yup.string()
			.required(t("help.error.firstNameRequired"))
			.test(
				"len",
				t("help.error.firstNameTooShort"),
				(val) => val?.length > 1
			),
		lastName: Yup.string()
			.required(t("help.error.lastNameRequired"))
			.test(
				"len",
				t("help.error.lastNameTooShort"),
				(val) => val?.length > 1
			),
		email: Yup.string()
			.email(t("help.error.emailInvalid"))
			.required(t("help.error.emailRequired")),
		phone: Yup.string()
			.required(t("help.error.phoneRequired"))
			.test(
				"len",
				t("help.error.phoneInvalid"),
				(val) =>
					val?.length === 10 &&
					val[0] === "0" &&
					(val[1] === "6" || val[1] === "7")
			),
		agency: Yup.object().required(t("help.error.agencyRequired")),
		topic: Yup.object().required(t("help.error.sectionRequired")),
		problem: Yup.string()
			.required(t("help.error.problemRequired"))
			.test(
				"len",
				t("help.error.problemTooLong"),
				(val) => val?.length < 501
			)
			.test(
				"len",
				t("help.error.problemTooShort"),
				(val) => val?.length > 10
			),
	});
