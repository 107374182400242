import React from "react";
import SVG from "../../atoms/SVG/SVG";
import HeartIcon from "../../../assets/icons/heart.svg";
import FilledHeartIcon from "../../../assets/icons/heart-filled.svg";
import cn from "../../../utils/cn";

interface IFavoriteButtonProps {
	onClick: (any) => Promise<any>;
	isFavored: boolean;
	className?: string;
}

const FavoriteButton: React.FC<IFavoriteButtonProps> = ({
	onClick,
	isFavored,
	className,
}) => {
	const [State, setState] = React.useState(isFavored);
	const [isLoading, setIsLoading] = React.useState(false);

	const handleClick = (e) => {
		e.stopPropagation();
		setIsLoading(true);
		setState(!isFavored);
		onClick(!isFavored).then(() => setIsLoading(false));
	};

	return (
		<button
			onClick={handleClick}
			className={cn([className ? className : "w-6 h-6"])}
			disabled={isLoading}
		>
			<SVG
				src={State ? FilledHeartIcon : HeartIcon}
				className={cn([
					`h-full w-full [&>path]:fill-accent-500 cursor-pointer hover:opacity-80`,
					isLoading ? "opacity-50" : "hover:opacity-80",
				])}
			/>
		</button>
	);
};

export default FavoriteButton;
