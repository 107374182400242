import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	Title,
	SelectAsync,
	Tag,
	Picto,
	ModalV2,
	Button,
	RadioButton,
	Modal,
} from "@zolteam/react-ras-library";
import { Form, Formik } from "formik";
import LocationService from "../../services/LocationService";
import { IStepComponentProps } from "../../components/templates/InitLayout/InitLayout";
import cn from "../../utils/cn";
import { t } from "i18next";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/atoms/Loader/Loader";
import { StepsContext } from "../../contexts/StepsContext/StepsContext";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import Spinner from "../../components/atoms/Spinner/Spinner";
import { ReactComponent as Inscription } from "../../assets/illustrations/confirmInscription.svg";

const convToSelectValue = (place) => {
	if (!place) return {};
	return {
		...place,
		value: place.id,
		label: place.name + (place.postalCode ? ` (${place.postalCode})` : ""),
	};
};

const OnboardingWorkplace: React.FC<IStepComponentProps> = (props) => {
	const [Places, setPlaces] = useState<any>([]);
	const [Options, setOptions] = useState<any>([]);
	const [ConfirmModal, setConfirmModal] = useState(false); // TODO: [ConfirmModal] use this to confirm the user's choice
	const [MainLocation, setMainLocation] = useState<any>(null); // TODO: [MainLocation] use this to set the user's main location
	const [ConfirmModalStart, setConfirmModalStart] = useState(true);
	const { validateStep, unvalidateStep, isActive } = props;
	const { setCustomNextCallback, customNextCallback } = props;
	const formRef = useRef<any>(null);
	// needed to keep the Places state up to date in the customNext callback
	const PlacesRef = useRef<any>(Places);
	const { setContextValue } = useContext(StepsContext);

	const customNext = useCallback(
		(newPlaces?) => {
			const places = PlacesRef.current ?? newPlaces ?? [];
			if (places.length === 1) return props.submitStep(formRef);
			else setConfirmModal(true);
		},
		[props]
	);

	useEffect(() => {
		if (isActive && !customNextCallback)
			setCustomNextCallback && setCustomNextCallback(() => customNext());
	}, [setCustomNextCallback, customNextCallback, isActive, customNext]);

	const fetchPlaces = async (inputValue: string) =>
		LocationService.getSearchLocations(inputValue).then((resp) => {
			const opts = resp?.map(convToSelectValue).filter((a) => a);
			setOptions(opts);
			return opts;
		});

	const handleSelect = (option) => {
		const newPlaces = [...Places, option];
		if (newPlaces.length) validateStep({ Places: newPlaces });
		PlacesRef.current = newPlaces;
		setPlaces(newPlaces);
		return newPlaces;
	};

	const handleRemove = (option) => {
		const newPlaces = Places.filter(
			(place) => place.value !== option.value
		);
		if (!newPlaces.length) unvalidateStep();
		PlacesRef.current = newPlaces;
		if (MainLocation?.value === option.value) setMainLocation(null);
		else if (newPlaces.length === 1) setMainLocation(newPlaces[0]);
		setPlaces(newPlaces);
	};

	const { isLoading } = useQuery(
		["onboarding", "userLocations"],
		() => LocationService.getUserLocations(),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				const locations = [
					data?.mainLocation,
					...(data?.locations ?? []),
				].filter((a) => a);
				if (locations?.length) {
					if (data?.mainLocation)
						validateStep && validateStep({ Places: locations });
					PlacesRef.current = locations;
					setPlaces(locations.map(convToSelectValue));
					if (data?.mainLocation)
						setMainLocation(convToSelectValue(data?.mainLocation));
				}
				return data;
			},
		}
	);

	return (
		<div>
			<Title tag="h2" className="mb-4">
				{t("onboarding.workplaces.title")}
			</Title>

			<div className="flex flex-wrap gap-2 mb-4 empty:hidden">
				{Places.map((place) => (
					<button
						onClick={() => handleRemove(place)}
						key={place.value}
					>
						<Tag
							className="flex text-primary-800"
							color="primaryLight"
						>
							{place.label}

							<Picto icon="close" className="w-2 h-2 ml-2" />
						</Tag>
					</button>
				))}
			</div>
			<Formik
				initialValues={{
					search: "",
					places: Places,
					mainLocation: MainLocation,
				}}
				onSubmit={() => {
					pushDataLayer({
						dataLayer: {
							event: "inscription__choix_zones_travail",
						},
					});
					const placesDatas = {
						locations: Places.map((place) => place.value),
						mainLocation: MainLocation?.value
							? MainLocation.value
							: Places[0].value,
					};
					return LocationService.postUserLocations(placesDatas).then(
						(resp) => {
							setContextValue("workplaces", placesDatas);
						}
					);
				}}
				innerRef={(ref) => {
					formRef.current = ref;
					props.formRef.current = ref;
				}}
			>
				{({ values, setFieldValue }) => {
					if (isLoading) return <Loader />;
					return (
						<Form>
							<SelectAsync
								className="hover:[&>div>div]:!bg-white max-w-full [&>div]:max-w-full"
								loadOptions={fetchPlaces}
								defaultOptions={Options}
								onChange={(option) =>
									setFieldValue(
										"places",
										handleSelect(option)
									)
								}
								filterOption={(option, inputValue) => {
									if (
										Places.find(
											(place) =>
												place.value === option.value
										)
									)
										return false;
									return true;
								}}
								noOptionsMessage={({ inputValue }) => {
									if (!inputValue?.length)
										return t(
											"onboarding.workplaces.selectPlaceholder"
										);
									if (inputValue.length < 3)
										return t(
											"onboarding.workplaces.selectPlaceholderMinChar"
										);
									return t(
										"onboarding.workplaces.selectPlaceholderNoResult",
										{
											search: inputValue,
										}
									);
								}}
								loadingMessage={() => {
									return (
										<Spinner
											text={t("global.optionLoading")}
										/>
									);
								}}
								value={values.search}
								type="text"
								name="search"
								label={t("onboarding.workplaces.searchLabel")}
							/>
						</Form>
					);
				}}
			</Formik>
			<MainLocationModal
				isDisplayed={ConfirmModal}
				setConfirmModal={setConfirmModal}
				places={Places}
				mainLocation={MainLocation}
				setMainLocation={setMainLocation}
				onConfirm={() => {
					pushDataLayer({
						dataLayer: {
							event: "inscription__zone_travail_principale",
						},
					});
					setConfirmModal(false);
					props.submitStep();
				}}
			/>
			<Modal
				isDisplayed={ConfirmModalStart}
				onClose={() => {
					pushDataLayer({
						dataLayer: {
							event: "inscription__c_est_parti",
						},
					});
					setConfirmModalStart(false);
				}}
				size="s"
				className="max-w-[90vw] w-auto !min-w-[350px]"
			>
				<Title tag="h2">{t("onboarding.workplaces.modalTitle")}</Title>
				<Inscription className="h-[120px] w-[200px]" />
				<p className="my-4 ">{t("onboarding.workplaces.modalText")}</p>
				<Button
					type="button"
					className="btn btn-primary"
					color="primary"
					onClick={() => {
						pushDataLayer({
							dataLayer: {
								event: "inscription__c_est_parti",
							},
						});
						setConfirmModalStart(false);
					}}
				>
					{t("onboarding.workplaces.modalBtn")}
				</Button>
			</Modal>
		</div>
	);
};

const MainLocationModal = ({
	isDisplayed,
	setConfirmModal,
	places,
	mainLocation,
	setMainLocation,
	onConfirm,
}) => {
	return (
		<ModalV2
			isDisplayed={isDisplayed}
			onClose={() => setConfirmModal(false)}
			title={t("onboarding.workplaces.mainPlace")}
			className="w-1/2"
			size={"s"}
		>
			{
				<>
					<div className="flex flex-col items-start gap-2 my-4">
						{places.map((place) => (
							<LocationItem
								key={place.value}
								place={place}
								checked={mainLocation?.value === place.value}
								onChange={() => {
									setMainLocation(place);
								}}
							/>
						))}
					</div>
					<div className="flex justify-end">
						<Button
							type="button"
							className="btn btn-primary"
							onClick={onConfirm}
							disabled={!mainLocation}
							color={mainLocation ? "primary" : "grey"}
						>
							{t("global.confirm")}
						</Button>
					</div>
				</>
			}
		</ModalV2>
	);
};

const LocationItem = ({ place, checked, onChange }) => {
	return (
		<button
			className={cn([
				"w-full p-1 px-2 cursor-pointer hover:bg-primary-50 rounded-4",
				checked && "bg-primary-100 ",
			])}
			onClick={onChange}
		>
			<RadioButton
				className={cn([
					"m-0 w-full text-left [&>label]:!justify-start [&>label]:!duration-100",
					checked &&
						"[&>label]:!font-bold [&>label]:!text-primary-800",
				])}
				color="primary"
				key={place.value}
				name={`workplace-${place.value}`}
				label={place.label}
				checked={checked}
				onChange={onChange}
			/>
		</button>
	);
};

export default OnboardingWorkplace;
