import React, { createContext, useContext, useState } from "react";
import SessionService from "../../services/SessionService";
import { ENV } from "../../constants/constants";

/*
 **
 */
window.onload = () => {
	const elem: any = document.querySelector("#root");
	elem.dev = (state?: boolean) => {
		let localSettings = JSON.parse(
			localStorage.getItem("settings") || "{}"
		);

		if (typeof state === "boolean") localSettings.devFunctions = state;
		else
			localSettings.devFunctions = localSettings.devFunctions
				? false
				: true;

		localStorage.setItem("settings", JSON.stringify(localSettings));
		window.location.reload();
	};
};

// Define the interface for user settings
interface UserSettings {
	[settingName: string]: any;
}

export interface SettingsContextType {
	setSettings: (value: UserSettings) => {};
	setSetting: (settingName: string, value: any) => {};
	getSettings: (settingName: string, isDevCheck?: boolean) => {};
	isDev: boolean;
}

// Define the interface for the overall settings object
interface Settings {
	[userId: string]: UserSettings;
}

// Define default values for settings
const defaultSettings: Settings = {};

// Create a context for settings
export const SettingsContext = createContext<SettingsContextType>({
	setSettings: (value) => false,
	setSetting: (settingName) => false,
	getSettings: (settingName, isDevCheck) => false,
	isDev: false,
});

// Custom hook to access settings and setSettings function
export const useSettings = () => {
	const context = useContext(SettingsContext);
	if (!context) {
		throw new Error("useSettings must be used within a SettingsProvider");
	}
	return context;
};

interface ISettingsProviderProps extends React.PropsWithChildren<any> {}

// Settings provider component
export const SettingsProvider: React.FC<ISettingsProviderProps> = ({
	children,
}) => {
	const getSettingsRoot = () => {
		const localSettings = JSON.parse(
			localStorage.getItem("settings") || "{}"
		);
		return localSettings;
	};

	const checkDevFunction = () => {
		const localSettings = getSettingsRoot();
		if (ENV !== "production") return true;
		return localSettings.devFunctions === true;
	};

	const getActiveUserSettings = () => {
		const { id: userId } = SessionService.getUser();
		if (!userId) return;

		try {
			const parsedSettings = JSON.parse(
				localStorage.getItem("settings") || "{}"
			);

			if (!parsedSettings[userId]) {
				parsedSettings[userId] = {};
			}
			return parsedSettings[userId];
		} catch (e) {
			return {};
		}
	};

	const [settings, setSettingsState] = useState<Settings>({
		...defaultSettings,
		...getActiveUserSettings(),
	});

	// Function to set new settings and update local storage
	const setSettings = (newValues: Settings) => {
		setSettingsState(newValues);

		const localSettings = JSON.parse(
			localStorage.getItem("settings") || "{}"
		);
		const { id: userId } = SessionService.getUser();
		if (!userId) return;

		const newSettings = { ...localSettings, [userId]: newValues };
		localStorage.setItem("settings", JSON.stringify(newSettings));

		return newSettings;
	};

	const setSetting = (settingName: string, value: any) => {
		const { id: userId } = SessionService.getUser();
		if (!userId) return;
		const newSettings = { ...settings };
		newSettings[settingName] = value;
		setSettings(newSettings);

		return newSettings;
	};

	const getSettings = (settingName: string, isDevCheck?: boolean) => {
		const { id: userId } = SessionService.getUser();
		if (!userId) return false;
		if (ENV !== "production") return true;
		if (isDevCheck && !checkDevFunction()) return false;

		return settings[settingName] ? settings[settingName] : false;
	};

	const values = {
		getSettings,
		setSettings,
		setSetting,
		isDev: checkDevFunction(),
	};
	return (
		<SettingsContext.Provider value={values}>
			{children}
		</SettingsContext.Provider>
	);
};
