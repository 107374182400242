export default function appReducer(state, action) {
	switch (action.type) {
		// AUTH REDUCERS
		case "SET_ITEM": {
			return { ...state, auth: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
