import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { Modal, Title, Button } from "@zolteam/react-ras-library";
import { disconnect } from "../DisconnectButton/DisconnectButton";
import { ReactComponent as AFK } from "../../../assets/illustrations/tertiaire.svg";
import { Trans } from "react-i18next";
import { t } from "i18next";
import Axios from "../../../services/api";

const IdleModal: React.FC = () => {
	const [isIdle, setIsIdle] = React.useState(false);
	const onIdle = () => {
		setIsIdle(true);
	};

	Axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			const originalConfig = error?.config ?? {};

			if (originalConfig?.url === "/api/token/refresh") {
				setIsIdle(true);
			}
			return Promise.reject(error);
		}
	);

	// hook call
	useIdleTimer({
		onIdle,
		timeout: 600_000, // 10 minutes
	});

	return (
		<Modal
			className="max-w-[90vw] w-auto !min-w-[350px] [&>div:first-child]:hidden"
			size="s"
			isDisplayed={isIdle}
			onClose={() => {
				setIsIdle(false);
				disconnect();
			}}
		>
			<AFK className="-mt-[70px] h-[200px] w-[200px]" />
			<Title tag="h2" className="mb-4">
				{t("idleModal.title")}
			</Title>
			<p>
				<Trans i18nKey="idleModal.text" />
			</p>
			<Button
				type="button"
				className="mt-8"
				color="primary"
				onClick={() => {
					setIsIdle(false);
					disconnect();
				}}
			>
				{t("global.reconnect")}
			</Button>
		</Modal>
	);
};

export default IdleModal;
