import React from "react";
import { Title } from "@zolteam/react-ras-library";
import DocTypeTile from "../../components/molecules/DocTypeTile/DocTypeTile";
import ProDocsDrawer from "../../components/templates/ProDocsDrawer/ProDocsDrawer";

import medicalCertificatePicto from "../../assets/illustrations/medicalCertificate.svg";
import IDCardPicto from "../../assets/illustrations/IDCard.svg";
import familyBookletPicto from "../../assets/illustrations/familyBooklet.svg";
import healthInsuranceCertificatePicto from "../../assets/illustrations/healthInsuranceCertificate.svg";
import proofOfAddressPicto from "../../assets/illustrations/proofOfAddress.svg";
import cn from "../../utils/cn";
import ConfirmModal from "../../components/molecules/ConfirmModal/ConfirmModal";
import { t } from "i18next";
import { Trans } from "react-i18next";
import {
	DOCUMENT_CONFIRMED_STATUS,
	LOCKED_DOCS_IDS,
} from "../../constants/documents";
import { useNavigate } from "react-router-dom";

const DOCS_TYPES = [
	{
		typeId: 1,
		name: t("identityDocs.types.identity"),
		picto: IDCardPicto,
	},
	{
		typeId: 6,
		name: t("identityDocs.types.socialSecurity"),
		picto: healthInsuranceCertificatePicto,
	},
	{
		typeId: 7,
		name: t("identityDocs.types.proofOfAddress"),
		picto: proofOfAddressPicto,
	},
	{
		typeId: 51,
		name: t("identityDocs.types.famillySituation"),
		picto: familyBookletPicto,
	},
	{
		typeId: 10,
		name: t("identityDocs.types.healthDocuments"),
		picto: medicalCertificatePicto,
	},
];

const DefaultDeleteState = {
	isDeleting: false,
	name: "",
	callback: () => Promise.reject("No callback set"),
};

const IdentityDocs: React.FC = () => {
	const navigate = useNavigate();

	const [DeleteState, setDeleteState] = React.useState<{
		isDeleting: boolean;
		name?: string;
		callback: () => Promise<any>;
	}>(DefaultDeleteState);

	return (
		<div>
			<Title tag="h1" size="heading01" className="!leading-7 mb-6">
				{t("personalInfos.myDocs")}
			</Title>

			<div
				className={cn([
					"flex flex-col gap-8 max-w-[1000px]",
					DeleteState.isDeleting && "opacity-60 !pointer-events-none",
				])}
			>
				{DOCS_TYPES.map((docType, index) => {
					return (
						<React.Fragment key={docType.typeId}>
							<ProDocsDrawer
								noDataComponent={"empty-tile"}
								titleClassName="-mb-3 !text-xl"
								type={{
									...docType,
									id: docType.typeId,
									typeName: docType.name,
									noDataMessage: "Aucun document",
								}}
								isDocTypeLocked={(docType) => {
									return (
										LOCKED_DOCS_IDS.includes(docType?.id) &&
										docType.hasExistingDoc?.status ===
											DOCUMENT_CONFIRMED_STATUS
									);
								}}
								lockedDocMessage={(docType) => {
									return (
										<button
											className="text-left"
											onClick={() => {
												navigate("/contact-agencies", {
													state: {
														from: "identity-docs",
													},
												});
											}}
										>
											{t("identityDocs.locked")}
										</button>
									);
								}}
								isPersonalDoc={true}
								tileComponent={(props) => {
									const { document } = props;
									return (
										<DocTypeTile
											{...props}
											id={document.commonDocumentId}
											name={document.commonDocumentName}
											onRemove={
												!LOCKED_DOCS_IDS.includes(
													document.commonDocumentId
												) ||
												document.status !==
													DOCUMENT_CONFIRMED_STATUS
													? () => {
															setDeleteState({
																isDeleting:
																	true,
																name: document.commonDocumentName,
																callback:
																	props.customHandleDelete,
															});
													  }
													: undefined
											}
											picto={docType.picto}
											isValid={true}
										/>
									);
								}}
							/>
						</React.Fragment>
					);
				})}
			</div>

			<ConfirmModal
				isOpen={DeleteState.isDeleting}
				onClose={() => setDeleteState(DefaultDeleteState)}
				title={t("docs.confirmDeleteTitle")}
				size="s"
				text={
					<Trans
						i18nKey="docs.confirmDeleteText"
						values={{
							name: DeleteState.name,
						}}
					/>
				}
				onConfirm={() => {
					return DeleteState.callback().then(() => {
						setDeleteState(DefaultDeleteState);
					});
				}}
			/>
		</div>
	);
};

export default IdentityDocs;
