import QRCode from "../../../assets/qr-code.svg";
import {
	useModal,
	Button,
	Modal,
	InfoMessage,
} from "@zolteam/react-ras-library";
import { t } from "i18next";
import React from "react";
import cn from "../../../utils/cn";
import SVG from "../SVG/SVG";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";

interface IDownloadAppButtonProps {
	className?: string;
	color?: string;
	outline?: boolean;
	onClick?: () => void;
	origin?: string;
}

const DownloadAppButton: React.FC<IDownloadAppButtonProps> = ({
	color = "primary",
	outline,
	className,
	origin = "unknown",
}: IDownloadAppButtonProps) => {
	const [modal, toggleModal] = useModal();

	return (
		<>
			<Button
				type="button"
				color={color}
				outline={outline}
				onClick={() => {
					pushDataLayer({
						dataLayer: {
							event: "telecharger_app",
							clic_position: origin,
						},
					});
					toggleModal();
				}}
				className={cn([" !px-3 !py-[0.1rem] ", className])}
			>
				{t("global.download")}
			</Button>
			<Modal
				isDisplayed={modal}
				onClose={toggleModal}
				className="!min-w-[90%] md:!min-w-0"
			>
				<InfoMessage withIcon>{t("home.qrCodeText")}</InfoMessage>
				<SVG src={QRCode} className="min-h-[200px] max-h-[50svh] " />
			</Modal>
		</>
	);
};

export default DownloadAppButton;
